/*
    expression/index.js - Simple expression parser and evaluator

    Operators: + - * / ( ) ^ ! % == != < <= > >= ^= ^!= $= $!= << >> && || <> ><
    Types: Number, true, false, 'literal', "literal", /regexp/, null
    Variables: unadorned. The AST will prefix with "$"
    Literals: quoted. The AST will strip quotes.
    Supports (), cast and functions
    Parsed into an AST for quick execution. The AST is a set of tuples (arrays). 
 */

const MaxRecurse = 100

var TYPE_OPERATOR = 'op'
var TYPE_IDENTIFIER = 'id'
var TYPE_NUMBER = 'number'
var TYPE_BOOLEAN = 'boolean'
var TYPE_STRING = 'string'
var TYPE_REGEXP = 'regexp'
var TYPE_NULL = 'null'

/*
var Context = {
    // constants
    pi: 3.1415926535897932384,
    phi: 1.6180339887498948482
    
    // functions
    abs: Math.abs,
    acos: Math.acos,
    asin: Math.asin,
    atan: Math.atan,
    ceil: Math.ceil,
    cos: Math.cos,
    exp: Math.exp,
    floor: Math.floor,
    ln: Math.ln,
    random: Math.random,
    sin: Math.sin,
    sqrt: Math.sqrt,
    tan: Math.tan
}
*/

class Token {
    constructor(value, type) {
        this.value = value
        if (!type) {
            if (value == 'true') {
                type = TYPE_BOOLEAN
                this.value = true
            } else if (value == 'false') {
                type = TYPE_BOOLEAN
                this.value = false
            } else if (value == 'null') {
                type = TYPE_NULL
                this.value = null
            } else {
                let ch = value[0]
                if ((ch >= '0' && ch <= '9') || ch == '.') {
                    type = TYPE_NUMBER
                    this.value = +this.value
                }
            }
        }
        if (!type) {
            type = TYPE_STRING
        }
        this.type = type
    }
}

class Lexer {
    constructor(str) {
        this.operators = this.toMap([
            '+',
            '-',
            '*',
            '/',
            '(',
            ')',
            '^',
            '!',
            '%',
            '==',
            '!=',
            '<',
            '<=',
            '>',
            '>=',
            '^=',
            '$=',
            '<<',
            '>>',
            '&&',
            '||',
            '<>',
            '><',
            ',',
        ])
        this.input = str
        this.length = str.length
        this.index = 0
        this.nextToken = null
        this.nextIndex = 0
    }

    peekNextChar() {
        return this.index < this.length ? this.input.charAt(this.index) : '\x00'
    }

    getNextChar(count = 1) {
        // let ch = '\x00'
        let ch = undefined
        let idx = this.index
        if (idx < this.length) {
            ch = this.input.charAt(idx)
            this.index += 1
        }
        return ch
    }

    isWhiteSpace(ch) {
        return ch === '\u0009' || ch === ' ' || ch === '\u00A0'
    }

    isLetterChar(ch) {
        return (ch >= 'a' && ch <= 'z') || (ch >= 'A' && ch <= 'Z') || ch == '$' || ch == '_'
    }

    isPropChar(ch) {
        return ch == '$' || ch == '_' || ch == '@' || ch == '.'
    }

    isNumberChar(ch) {
        return (ch >= '0' && ch <= '9') || ch == '.'
    }

    isIdentifierChar(ch) {
        return this.isLetterChar(ch) || this.isNumberChar(ch) || this.isPropChar(ch)
    }

    isLiteralChar(ch) {
        return this.isLetterChar(ch) || this.isNumberChar(ch)
    }

    skipSpaces() {
        let ch
        while (this.index < this.length) {
            ch = this.peekNextChar()
            if (!this.isWhiteSpace(ch)) {
                break
            }
            this.getNextChar()
        }
    }

    matchIdentifier() {
        let ch, id
        ch = this.peekNextChar()
        if (!this.isLetterChar(ch) && ch != '@') {
            return undefined
        }
        id = this.getNextChar()
        while (true) {
            ch = this.peekNextChar()
            if (!this.isIdentifierChar(ch)) {
                break
            }
            id += this.getNextChar()
        }
        if (this.matchReserved(id)) {
            return undefined
        }
        return new Token(id, TYPE_IDENTIFIER)
    }

    matchReserved(id) {
        if (id == 'true' || id == 'false' || id == 'null' || id == 'undefined') {
            return true
        }
        return false
    }

    matchLiteral() {
        let ch, id
        ch = this.peekNextChar()
        if (ch == '/') {
            let id = ''
            this.getNextChar()
            while ((ch = this.getNextChar()) != undefined && (ch != '/' || id.slice(-1) == '\\')) {
                id += ch
            }
            if (ch != '/') {
                throw new SyntaxError('Missing terminating regexp /')
            }
            return new Token(id, TYPE_REGEXP)
        } else if (ch == "'" || ch == '"' || ch == '`') {
            let quote = this.getNextChar()
            let id = ''
            while ((ch = this.getNextChar()) != undefined && (ch != quote || id.slice(-1) == '\\')) {
                id += ch
            }
            if (ch != quote) {
                throw new SyntaxError('Missing terminating quote')
            }
            return new Token(id, TYPE_STRING)
        }
        if (!this.isLiteralChar(ch)) {
            return undefined
        }
        id = this.getNextChar()
        while (true) {
            ch = this.peekNextChar()
            if (!this.isLiteralChar(ch)) {
                break
            }
            id += this.getNextChar()
        }
        return new Token(id)
    }

    matchOperator() {
        let c1 = this.peekNextChar()
        let operator
        if ('+-*/()^%=;,<>~$&|!'.indexOf(c1) >= 0) {
            c1 = this.getNextChar()
            let c2 = this.peekNextChar()
            if (this.operators[c1 + c2] != undefined) {
                operator = c1 + c2
                c2 = this.getNextChar()
            } else if (this.operators[c1] != undefined) {
                operator = c1
            } else {
                throw new SyntaxError(`Unknown operator ${c1}`)
            }
            if (operator) {
                return new Token(operator, TYPE_OPERATOR)
            }
        }
        return undefined
    }

    next() {
        /*
            NNN
            true|false
            'string'
            Identifier
			Operator
         */
        if (this.nextToken) {
            let token = this.nextToken
            delete this.nextToken
            this.index = this.nextIndex
            return token
        }
        this.skipSpaces()
        if (this.index >= this.length) {
            return undefined
        }
        let mark = this.index

        let token = this.matchIdentifier()
        if (token !== undefined) {
            return token
        }
        this.index = mark

        token = this.matchLiteral()
        if (token !== undefined) {
            return token
        }
        this.index = mark

        token = this.matchOperator()
        if (token !== undefined) {
            return token
        }
        throw new SyntaxError(`Unknown token from character ${this.peekNextChar()}`)
    }

    peek() {
        if (this.nextToken) {
            return this.nextToken
        }
        let token, idx
        idx = this.index
        try {
            this.nextToken = token = this.next()
            this.nextIndex = this.index
        } catch (err) {
            // print("Lexer Catch", err)
            throw err
        }
        this.index = idx
        return token
    }

    toMap(a) {
        let result = {}
        for (let i = 0; i < a.length; i++) {
            let item = a[i]
            result[item] = i
        }
        return result
    }
}

class Parser {
    parse(str) {
        if (!str) {
            return null
        }
        str = str.toString()
        this.lexer = new Lexer(str)
        let ast = this.parseExpression()
        let token = this.next()
        if (token !== undefined) {
            throw new SyntaxError('Unexpected token ' + token.value)
        }
        return ast
    }

    parseArgs() {
        let args = []
        while (true) {
            let ast = this.parseExpression()
            if (!ast) {
                break
            }
            args.push(ast)
            let token = this.peek()
            if (!this.matchOp(token, ',')) {
                break
            }
            this.next()
        }
        return args
    }

    parseFunction(name) {
        let args = []
        let token = this.next()
        if (!this.matchOp(token, '(')) {
            throw new SyntaxError('Expecting ( in a function call "' + name + '"')
        }
        token = this.peek()
        if (!this.matchOp(token, ')')) {
            args = this.parseArgs()
        }
        token = this.next()
        if (!this.matchOp(token, ')')) {
            throw new SyntaxError('Expecting ) in a function call "' + name + '"')
        }
        return [name, '()', args]
    }

    parseTerm() {
        let token = this.peek()
        if (token === undefined) {
            throw new SyntaxError('Unexpected termination of expression')
        }
        token = this.next()
        if (token.type == TYPE_IDENTIFIER) {
            if (this.matchOp(this.peek(), '(')) {
                return this.parseFunction(token.value)
            } else {
                return '$' + token.value
            }
        } else {
            if (token.type == TYPE_REGEXP) {
                // return '/' + token.value + '/'
                return [token.value, 'cast', TYPE_REGEXP]
            } else {
                return token.value
            }
        }
    }

    parseUnary() {
        let token = this.peek()
        if (this.matchOp(token, '-') || this.matchOp(token, '+') || this.matchOp(token, '!')) {
            let op = token.value
            token = this.next()
            let ast = this.parseUnary()
            return [0, op, ast]
        } else if (this.matchOp(token, '(')) {
            this.next()
            let ast = this.parseCompound()
            token = this.next()
            if (!this.matchOp(token, ')')) {
                throw new SyntaxError('Expecting )')
            }
            return ast
        }
        return this.parseTerm()
    }

    parseMultiplicative() {
        let ast = this.parseUnary()
        let token = this.peek()
        while (this.matchOp(token, '*') || this.matchOp(token, '/') || this.matchOp(token, '%')) {
            token = this.next()
            ast = [ast, token.value, this.parseUnary()]
            token = this.peek()
        }
        return ast
    }

    parseAdditive() {
        let ast = this.parseMultiplicative()
        let token = this.peek()
        while (this.matchOp(token, '+') || this.matchOp(token, '-')) {
            token = this.next()
            ast = [ast, token.value, this.parseMultiplicative()]
            token = this.peek()
        }
        return ast
    }

    parseRelop() {
        let ast = this.parseAdditive()
        let token = this.peek()
        if (
            this.matchOp(token, '==') ||
            this.matchOp(token, '!=') ||
            this.matchOp(token, '<') ||
            this.matchOp(token, '<=') ||
            this.matchOp(token, '>') ||
            this.matchOp(token, '>=') ||
            this.matchOp(token, '^=') ||
            this.matchOp(token, '$=') ||
            this.matchOp(token, '><') ||
            this.matchOp(token, '<>')
        ) {
            token = this.next()
            ast = [ast, token.value, this.parseAdditive()]
        } else if (token && token.type == TYPE_IDENTIFIER && (token.value == 'in' || token.value == 'notin')) {
            token = this.next()
            ast = [ast, token.value, this.parseAdditive()]
        }
        return ast
    }

    parseCompound() {
        let ast = this.parseRelop()
        let token = this.peek()
        while (this.matchOp(token, '&&') || this.matchOp(token, '||')) {
            token = this.next()
            ast = [ast, token.value, this.parseRelop()]
            token = this.peek()
        }
        return ast
    }

    parseExpression() {
        return this.parseCompound()
    }

    next() {
        return this.lexer.next()
    }

    peek() {
        return this.lexer.peek()
    }

    matchOp(token, value) {
        return token && token.type == TYPE_OPERATOR && token.value == value
    }
}

export default class Expression {
    //  Debug only works for eval() and not for run or parse
    constructor(options = {debug: false}) {
        this.options = options
    }

    parse(expression) {
        let parser = new Parser()
        return parser.parse(expression)
    }

    async eval(expression, context) {
        let parser = new Parser()
        let ast = parser.parse(expression)
        if (this.options.debug) {
            print('AST', this.toString(ast))
        }
        let result = await this.run(ast, context)
        if (this.options.debug) {
            print('RESULT', result)
        }
        return result
    }

    getVar(value, path) {
        let parts = path.split('.')
        for (let i = 0; i < parts.length; i++) {
            if (value == null) {
                break
            }
            value = value[parts[i]]
        }
        return value
    }

    async run(ast, context = {}, steps = 0) {
        if (steps++ > MaxRecurse) {
            throw new Error(`Recursive expression`)
        }
        if (!ast) {
            steps--
            return null
        }
        if (!Array.isArray(ast)) {
            steps--
            if (ast[0] == '$' && ast[1] != '$') {
                return this.getVar(context, ast.slice(1) || '')
            }
            return ast
        }
        let [a, op, b] = ast
        if (Array.isArray(a)) {
            a = await this.run(a, context)
        }
        if (op == null) {
            steps--
            return this.getVar(context, a)
        } 
        if (b == undefined) {
            b = []
        }
        if (op == '()') {
            if (typeof context[a] == 'function') {
                for (let [key, arg] of Object.entries(b)) {
                    if (Array.isArray(arg)) {
                        b[key] = await this.run(arg, context)
                    } else if (arg[0] == '$' && arg[1] != '$') {
                        b[key] = this.getVar(context, arg.slice(1) || '')
                    }
                }
                let result = await context[a](...b)
                steps--
                return result
            }
            steps--
            return null
        } else if (op == 'cast') {
            if (b == TYPE_NULL) {
                a = null
            } else if (b == TYPE_NUMBER) {
                a = +a
            } else if (b == TYPE_BOOLEAN) {
                a = a == 'true' ? true : false
            } else if (b == TYPE_REGEXP) {
                a = new RegExp(a)
            }
            steps--
            return a
        } else if (op == '||' && a) {
            steps--
            return true
        } else if (op == '&&' && !a) {
            steps--
            return false
        }
        if (Array.isArray(b)) {
            b = await this.run(b, context)
        }
        if (a && a[0] == '$' && a[1] != '$') {
            a = this.getVar(context, a.slice(1) || '')
        }
        if (b && b[0] == '$' && b[1] != '$') {
            b = this.getVar(context, b.slice(1) || '')
        }
        let result = this.evalExpression(a, op, b)
        if (this.options.debug) {
            console.log(a, op, b, '=>', result)
        }
        steps--
        return result
    }

    evalExpression(a, op, b) {
        switch (op) {
            case '!':
                return !b
            case '+':
                return a + b
            case '-':
                return a - b
            case '*':
                return a * b
            case '/':
                return a / b
            case '%':
                return a % b
            case '<<':
                return a << b
            case '>>':
                return a >> b
            case '^=':
                return a.startsWith(b)
            case '^!=':
                return !a.startsWith(b)
            case '$=':
                return a.endsWith(b)
            case '$!=':
                return !a.endsWith(b)
            case '<':
                return a < b
            case '<=':
                return a <= b
            case '>':
                return a > b
            case '>=':
                return a >= b
            case '==':
                if (a instanceof RegExp) {
                    return b.toString().match(a) != null
                } else if (b instanceof RegExp) {
                    return a.toString().match(b) != null
                } else {
                    return a == b
                }
            case '!=':
                if (a instanceof RegExp) {
                    return b.toString().match(a) == null
                } else if (b instanceof RegExp) {
                    return a.toString().match(b) == null
                } else {
                    return a != b
                }
            case '><': //  Contains
                if (Array.isArray(a)) {
                    return a.indexOf(b) >= 0
                } else if (typeof a == 'object') {
                    return a[b.toString()] != undefined
                } else {
                    return a.toString().indexOf(b) >= 0
                }
                return false
            case '<>': //  Not contains
                if (Array.isArray(a)) {
                    return a.indexOf(b) < 0
                } else if (typeof a == 'object') {
                    return b[a.toString()] == undefined
                } else {
                    return a.toString().indexOf(b) < 0
                }
                return false
            case '&&':
                return a && b
            case '||':
                return a && b
        }
        return null
    }

    toString(ast) {
        if (!ast) {
            return ast
        }
        let result = []
        let [a, op, b] = ast
        if (ast.length == 1) {
            result.push(a)
        } else if (ast.length == 2) {
            if (op == '=') {
                result.push(a)
            } else if (op == '()') {
                //  What if b is an array or object?
                result.push(a + '(' + this.toString(b) + ')')
            } else if (op == '-') {
                result.push(-a)
            } else if (op == '!') {
                result.push(!a)
            } else {
                result.push(a)
            }
        } else if (op == 'cast') {
            if (b == TYPE_NUMBER) {
                result.push(a)
            } else if (b == TYPE_BOOLEAN) {
                result.push(a)
            } else if (b == TYPE_NULL) {
                result.push(a)
            } else if (b == TYPE_REGEXP) {
                result.push('/' + a + '/')
            } else {
                result.push('"' + a + '"')
            }
        } else {
            if (Array.isArray(a)) {
                result.push(this.toString(a))
            } else if (typeof a == 'string') {
                if (a[0] == '$' && a[1] != '$') {
                    result.push(a.slice(1))
                } else {
                    result.push('"' + a + '"')
                }
            } else {
                result.push(a)
            }
            result.push(op)
            if (Array.isArray(b)) {
                result.push(this.toString(b))
            } else if (typeof b == 'string') {
                if (b[0] == '$' && b[1] != '$') {
                    result.push(b.slice(1))
                } else {
                    result.push('"' + b + '"')
                }
            } else {
                result.push(b)
            }
        }
        return result.join(' ')
    }
}

/*
    Portions copyright:   Ariya Hidayat see: code.google.com/p/tapdigit [BSD License]
 */
