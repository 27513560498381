/*
    Tool tip directive
 */

import './VuTip.css'

export default function VuTip(el, binding) {
    el.classList.add("with-tip")
    el.setAttribute("data-tip", binding.value?.text || binding.value)
    let pos = binding.value.position || getPos(binding.modifiers)
    el.classList.add(`tip--${pos}`)
}

function getPos(modifiers) {
    if (modifiers.top) {
        return 'top'
    } else if (modifiers.right) {
        return 'right'
    } else if (modifiers.bottom) {
        return 'bottom'
    } else if (modifiers.left) {
        return 'left'
    } 
    return 'top'
}