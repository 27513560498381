<script setup>
import {onMounted, reactive, ref, watch} from 'vue'
import {State} from '@/paks/vu-app'
import HelpDoc from './HelpDoc.vue'
import HelpBlog from './HelpBlog.vue'
import HelpNews from './HelpNews.vue'
import HelpChat from './HelpChat.vue'
import HelpStatus from './HelpStatus.vue'

const HelpSection = 0
const DocSection = 1
const ChatSection = 5

const props = defineProps({})
const emit = defineEmits(['input'])

const page = reactive({
    lastSection: HelpSection,
    section: 0,
})

//  Component references
const doc = ref(null)
const chat = ref(null)

watch(() => page.section, changeSection)

onMounted(() => {
    if (State.app.location.query.chat || State.app.needs.help == 'chat') {
        page.section = ChatSection
    }
})

function changeSection() {
    if (page.lastSection == ChatSection) {
        chat.value.close()
    }
    if (page.section == ChatSection) {
        if (chat.value) {
            chat.value.open()
        }
    } else if (page.section == DocSection) {
        window.open('https://www.embedthis.com/builder/doc', '_blank')
        page.section = HelpSection
    }
    page.lastSection = page.section
}

function sectionClicked() {
    if (!page.lastSection && doc.value) {
        doc.value.close()
    }
}

function launchDoc() {
    window.open('https://www.embedthis.com/builder/doc', '_blank')
    page.section = 0
}
</script>

<template>
    <v-card class="help-card" id="help-card">
        <v-toolbar dark color="accent" dense class="pa-0">
            <v-toolbar-title class="headline">Help Center</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
                <v-btn icon @click="emit('input', 'close')"><v-icon icon="$close" /></v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-container class="full-height">
            <v-row justify="space-between">
                <v-tabs stacked centered grow v-model="page.section" class="tabs" slider-size="0" icons-and-text>
                    <v-tab ripple class="tab" @click="sectionClicked">
                        <div>Help</div>
                        <v-icon icon="$bookmark" />
                    </v-tab>
                    <v-tab ripple class="tab" @click="launchDoc">
                        <div>Doc</div>
                        <v-icon icon="$bookmark" />
                    </v-tab>
                    <v-tab ripple class="tab">
                        <div>News</div>
                        <v-icon icon="$bell" />
                    </v-tab>
                    <v-tab ripple class="tab">
                        <div>Blog</div>
                        <v-icon icon="$rss" />
                    </v-tab>
                    <v-tab ripple class="tab">
                        <div>Status</div>
                        <v-icon icon="$swap" />
                    </v-tab>
                    <v-tab ripple class="tab">
                        <div>Chat</div>
                        <v-icon icon="$medical" />
                    </v-tab>
                </v-tabs>
            </v-row>
            <v-row class="full-height">
                <v-col class="help-content">
                    <v-window v-model="page.section" class="section">
                        <v-window-item><HelpDoc ref="doc" /></v-window-item>
                        <v-window-item><h2 class="mt-5 pl-3">Admin Doc Site</h2></v-window-item>
                        <v-window-item><HelpNews /></v-window-item>
                        <v-window-item><HelpBlog /></v-window-item>
                        <v-window-item><HelpStatus /></v-window-item>
                        <v-window-item><HelpChat ref="chat" /></v-window-item>
                    </v-window>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<style lang="scss">
.help-card {
    height: 100% !important;
    box-shadow: none !important;
    margin: 0;
    overflow: scroll;
    background: rgb(var(--v-theme-background-lighten-1));
    border-radius: 0;
    .v-toolbar {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        .headline {
            font-size: 1.5rem;
        }
    }
    .v-tabs-bar {
        height: auto !important;
    }
    .v-tabs {
        height: 90px;
        margin: 10px 10px 0 10px;
        color: rgb(var(--v-theme-text));
        font-size: 12px;
        .v-tab {
            display: block;
            width: 30px;
            height: 80px;
            min-width: 30px;
            margin: 10px 6px !important;
            padding: 10px 0 !important;
            background: rgb(var(--v-theme-background));
            .v-icon {
            display: block;
                margin: 10px;
            }
        }
        .v-tab--selected {
            color: rgb(var(--v-theme-accent)) !important;
        }
    }
    .section {
        height: 100%;
    }
    .full-height {
        height: 100%;
    }
    .help-content {
        padding-top: 0;
    }
    .v-window-item {
        height: 100%;
    }
    ul {
        margin-left: 16px;
    }
}
</style>
