<script setup>
// https://lyollix.github.io/vue-slideout-panel-demo/
import {onBeforeMount, onUnmounted, reactive, ref, watch} from 'vue'
import {State, navigate} from '@/paks/vu-app'

const easeOutCubic = 'cubic-bezier(0.215, 0.61, 0.355, 1)'
const easeOutSine = 'cubic-bezier(0.39, 0.575, 0.565, 1)'
const Animation = easeOutCubic
const Timeout = '0.8s'

const props = defineProps({
    closeHtml: {type: String, default: ''},
    closeAllHtml: {type: String, default: ''},
    extraHtml: {type: String, default: 'Extra show'},
    confirm: {},
    count: {
        type: Number,
        default: 1,
        validator(v) {
            return v > 0 && v <= 2
        },
    },
    isHelp: {type: Boolean, default: false},
    keyboard: {type: Boolean, default: true},
    modelValue: {type: Boolean, default: false, required: true},
    showExtra: {type: Boolean, default: false},
    widths: {
        type: Array,
        default: ['700px'],
        validator(v) {
            return v.reduce((a, b) => a && /col-(\w+-)?\d+|\d+px|\d+%/.test(b), true)
        },
    },
    classes: {
        type: Array,
        default() {
            return new Array(5).fill('default')
        },
        validator(v) {
            return v.reduce((a, b) => a && (!b || typeof b == 'string'), true)
        },
    },
    styles: {
        type: Array,
        default() {
            return [{}, {}, {}, {}, {}]
        },
        validator(v) {
            return v.reduce((a, b) => a && (!b || typeof b == 'object'), true)
        },
    },
})

const page = reactive({
    classes: [],
    dashboard: State.cache.dashboard,
    help: null,
    isVisibleDock: props.modelValue,
    isVisibleSections: [],
    isShifted: false,
    startTouch: null,
    styles: [],
})

watch(
    () => props.modelValue,
    async () => {
        page.isVisibleDock = props.modelValue
        if (props.modelValue) {
            if (State.app && !page.help) {
                page.help = State.app.help
            }
            if (props.keyboard) {
                window.addEventListener('keydown', keyboard, true)
                window.addEventListener('touchstart', touchStart, false)
                window.addEventListener('touchend', touchEnd, false)
            }
        } else {
            if (props.keyboard) {
                window.removeEventListener('keydown', keyboard, true)
                window.removeEventListener('touchstart', touchStart, false)
                window.removeEventListener('touchend', touchEnd, false)
            }
        }
    }
)

const dock = ref(null)
const section = ref(null)

const emit = defineEmits(['close'])

onBeforeMount(() => {
    init()
})

onUnmounted(() => {
    window.removeEventListener('keydown', keyboard)
})

function isVisibleCloseAll() {
    return props.count == 2 && !page.isShifted
}

function getCount() {
    return props.count
}

function init() {
    page.isShifted = false
    page.styles = []
    page.classes = []

    for (let i = 0; i < 5; i++) {
        if (props.classes[i] && props.classes[i].length >= 0) {
            i == 2 && props.classes[i] == 'same'
                ? page.classes.push(props.classes[i - 1])
                : page.classes.push(props.classes[i])
        } else {
            page.classes.push('default')
        }
    }
    if (props.count == 2) {
        page.classes[1] += ' vsp-br'
        page.classes[2] += ' vsp-bl'
    }
    for (let i = 0; i < 5; i++) {
        i == 2 && props.styles[i] && props.styles[i].same
            ? page.styles.push(Object.assign({}, props.styles[i - 1] || {}))
            : page.styles.push(props.styles[i] || {})
    }

    let isSecondSet = false
    let maxWidth = window.innerWidth
    if (props.count == 1) {
        if (! /\d+%/.test(props.widths[0])) {
            props.widths[0] = `${Math.min(parseInt(props.widths[0]), maxWidth)}px`
        }
    } else if (/\d+%/.test(props.widths[1])) {
        page.styles[2].width = `${100 - props.widths[1].slice(0, -1)}%`
        isSecondSet = true
    }
    props.widths.forEach((_w, i) => {
        if (/col-(\w+-)?\d+/.test(_w)) {
            page.classes[i] += ' ' + _w
        } else {
            if (i == 2 && isSecondSet) return
            page.styles[i].width = _w
        }
    })
    page.isVisibleSections = new Array(props.count).fill(1)
}

function keyboard(event) {
    if (event.code === 'Escape' && props.keyboard) {
        closeAll()
    }
}

function touchStart(event) {
    page.startTouch = event.changedTouches[0]
}

function touchEnd(event) {
    let x = event.changedTouches[0].screenX
    let y = event.changedTouches[0].screenY
    if (page.startTouch) {
        let deltaX = Math.abs(x - page.startTouch.screenX)
        let deltaY = Math.abs(y - page.startTouch.screenY)
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
            if (deltaX > 50) {
                //  Navigate back
                navigate(null)
            } else if (deltaX < -50) {
            }
        }
    }
}

function makeStyles(index) {
    return page.styles[index]
}

function makeClasses(index) {
    return page.classes[index]
}

function setTransform(el, v) {
    ;['webkitTransform', 'MozTransform', 'msTransform', 'OTransform', 'transform'].forEach((t) => {
        el.style[t] = v[0]
    })
    el.style.transitionTimingFunction = v[1]
    el.style.transitionDuration = v[2]
}

function shift() {
    let cx = -section.value[1].offsetWidth
    setTransform(dock.value, ['right', Animation, Timeout])
    dock.value.style.right = `${cx}px`
    page.isShifted = true
}

function beforeLeave() {
    if (page.isVisibleSections[1]) {
        dock.value.style.boxShadow = 'none'
        section.value[1].style.boxShadow = '-3px 3px 9px rgba(0, 0, 0, 0.3)'
    } else {
        shift()
    }
}

function afterLeave() {
    if (page.isVisibleSections[1]) {
        dock.value.style.boxShadow = '-3px 3px 9px rgba(0, 0, 0, 0.3)'
        dock.value.style.width = section.value[0].offsetWidth + 'px'
        section.value[0].style.width = '100%'
    }
}

function enter() {
    props.count == 2 && !props.showExtra && shift()
}

function expand() {
    setTransform(dock.value, ['right', Animation, Timeout])
    setTimeout(() => {
        setTransform(dock.value, ['', '', ''])
    }, 800)
    dock.value.style.right = '0'
    page.isShifted = false
}

function closeDock() {
    page.isVisibleDock = false
    init()
    if (State.app) {
        if (!props.isHelp) {
            State.app.setHelp(page.help)
        }
        page.help = null
    }
    emit('close')
}

async function close(index) {
    try {
        if (props.confirm && !(await props.confirm())) {
            return
        }
    } catch (err) {
        console.log(`Exception in confirm`, err.message)
        return
    }
    if (props.count == 1) {
        closeDock()
    } else if (page.isShifted) {
        setTransform(dock.value, ['right', easeOutSine, '1.2s'])
        closeDock()
    } else if (index == 0) {
        page.isVisibleSections[0] = 0
    } else if (index == 1 && page.isVisibleSections[0]) {
        shift()
    } else {
        closeDock()
    }
}

async function closeAll() {
    if (props.confirm && !(await props.confirm('all'))) {
        return
    }
    if (page.isShifted) {
        setTransform(dock.value, ['right', easeOutSine, '1.2ss'])
    }
    closeDock()
}
</script>

<template>
    <div>
        <transition name="fade">
            <div v-if="page.isVisibleDock" class="blackout" @click.stop.prevent="closeAll"></div>
        </transition>
        <transition name="slide-out" v-on:enter="enter">
            <div
                v-if="page.isVisibleDock"
                ref="dock"
                class="dock"
                :style="makeStyles(0)"
                :class="`${makeClasses(0)} ${page.dashboard?.full ? 'full' : ''}`">
                <transition-group
                    name="slide-out"
                    v-on:before-leave="beforeLeave"
                    v-on:afterLeave="afterLeave">
                    <section
                        v-for="(isVisible, i) in page.isVisibleSections"
                        ref="section"
                        class="page panel"
                        :key="'k' + i"
                        :style="makeStyles(i + 1)"
                        :class="makeClasses(i + 1)">
                        <div v-if="i == 0">
                            <slot></slot>
                        </div>
                        <div v-else>
                            <slot name="extra"></slot>
                        </div>

                        <a
                            href="#"
                            class="action-close"
                            :style="makeStyles(3)"
                            :class="makeClasses(3)"
                            @click.prevent="close(i)"
                            v-html="closeHtml"></a>
                        <a
                            v-if="count == 2 && !page.isShifted && i == 0"
                            href="#"
                            class="action-extra"
                            :style="makeStyles(4)"
                            :class="makeClasses(4)"
                            @click.prevent="closeAll"
                            v-html="closeAllHtml"></a>
                        <a
                            v-if="page.isShifted && i == 0"
                            href="#"
                            class="action-extra"
                            :style="makeStyles(4)"
                            :class="makeClasses(4)"
                            @click.prevent="expand"
                            v-html="extraHtml"></a>
                    </section>
                </transition-group>
            </div>
        </transition>
    </div>
</template>

<style scoped>
.blackout {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.3);
}
.dock {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 3000;
    box-shadow: -3px 3px 9px rgba(0, 0, 0, 0.3);
    overflow: hidden;
}
section {
    position: relative;
    display: inline-block;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
}
.dock.default {
    padding-top: 48px;
    z-index: 199 !important;
}
.dock.default.full {
    padding-top: 0 !important;
}
.panel > div {
    height: 100%;
    overflow: auto;
}
.panel.default {
    width: 100%;
    background-color: rgb(var(--v-theme-sidebar));
    color: rgb(var(--v-theme-text));
    padding: 1rem 1rem 1rem;
    padding-top: 16px;
    @media (max-width: 640px) {
        padding-top: 46px !important;
    }
}
.action-close.default {
    position: absolute;
    bottom: 1rem;
    cursor: pointer;
}
.action-extra.default {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    cursor: pointer;
}
.vsp-br {
    border-right: 2px solid rgba(0, 0, 0, 0.2);
}
.vsp-bl {
    border-left: 2px solid rgba(0, 0, 0, 0.1);
}
.slide-out-enter-active,
.slide-out-leave-active {
    transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.slide-out-enter,
.slide-out-leave-to {
    transform: translateX(100%);
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.8s ease-in;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.bg-transparent {
    background-color: transparent !important;
}
</style>

<style lang="scss">
.dock.full {
    .panel.default {
        @media (max-width: 640px) {
            padding-top: 16px !important;
        }
        .vu-form h1 {
            @media (max-width: 640px) {
                margin-left: 28px !important;
            }
        }
    }
}
</style>