/*
    User.js - User model
 */

import {Rest} from '@/paks/js-rest'

export default new Rest('User', {
    comment:    { method: 'POST', uri: '/:controller/comment' },
    login:      { method: 'POST', uri: '/:controller/login' },
    lookupUser: { method: 'POST', uri: '/:controller/lookupUser' },
    prefetch:   { method: 'POST', uri: '/:controller/prefetch' },
    updateRole: { method: 'POST', uri: '/:controller/updateRole' },
}, {service: '/api'})
