<script setup>
import {reactive} from 'vue'
import {Auth, State, navigate} from '@/paks/vu-app'

const page = reactive({
    existing: null,
    password: null,
    rules: [],
    showPassword: true,
    user: State.auth.user,
})

async function save() {
    await Auth.changePassword(page.existing, page.password)
    navigate('/')
}
</script>

<template>
    <vu-form :data="page" :save="save" help="/doc/ui/account/password.html" title="Change Your Password" class="page-form">
        <!--
        <input type="hidden" name="username" :value="page.user.email" />
        -->
        <v-text-field
            v-model="page.existing"
            class="vcol-12"
            label="Current Password"
            name="current-password"
            type="text"
            autocomplete="current-password"
            :append-icon="page.showPassword ? '$eye' : '$eyeOff'"
            :rules="page.rules.password"
            :type="page.showPassword ? 'text' : 'password'"
            @click:append="page.showPassword = !page.showPassword" />
        <v-text-field
            v-model="page.password"
            autocomplete="new-password"
            class="vcol-12"
            counter
            hint="At least 8 characters"
            name="new-password"
            label="New Password"
            type="text"
            :append-icon="page.showPassword ? '$eye' : '$eyeOff'"
            :rules="page.rules.password"
            :type="page.showPassword ? 'text' : 'password'"
            @click:append="page.showPassword = !page.showPassword" />
        <div class="actions">
            <v-btn size="small" color="accent" type="submit">Save</v-btn>
        </div>
    </vu-form>
</template>
