<template>
    <div class="div-component">
        <slot></slot>
    </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.div-component {
    flex: 0 0 100%;
}
</style>
