<script setup>
import {onMounted, reactive, ref} from 'vue'
import {State, navigate} from '@/paks/vu-app'
import Json5 from 'json5'

const props = defineProps({
    widget: Object,
})

const page = reactive({
    items: [],
    tab: 0,
})

const self = ref(null)
defineExpose({update})

onMounted(() => {
    page.title = props.widget.header
    let items = props.widget.items?.trim()
    try {
        if (items[0] == '{' || items[0] == '[') {
            items = Json5.parse(items)
        } else {
            throw true
        }
    } catch (err) {
        items = items.split(',').map((i) => i.trim())
        items = items.map((i) => {
            let [name, to] = i.split(':')
            return {name: name.trim(), to: to.trim(), disabled: false}
        })
    }
    page.items = items
    update()
})

function update() {}

function jump(item) {
    if (item.to) {
        navigate(item.to)
    }
}
</script>

<template>
    <v-toolbar class="tabs-widget" color="surface" flat ref="self">
        <v-toolbar-title v-if="page.title">{{ page.title }}</v-toolbar-title>
        <v-toolbar-items v-if="page.items.length > 1">
            <v-tabs v-model="page.tab" ref="tabs">
                <slot>
                    <v-tab
                        ripple
                        v-for="item of page.items"
                        :key="item.name"
                        :disabled="item.disabled"
                        @click="jump(item)">
                        {{ item.name }}
                    </v-tab>
                </slot>
            </v-tabs>
        </v-toolbar-items>
    </v-toolbar>
</template>

<style lang="scss">
.tabs-widget {
    container-type: size;
    height: 100%;
    width: 100%;

    height: 64px !important;
    background-color: rgb(var(--v-theme-form)) !important;
    border-bottom: 1px solid rgb(var(--v-theme-border-lighten-1)) !important;
}
</style>
