<script setup>
import {reactive, ref} from 'vue'
import {formatValue} from '@/paks/js-format'

const props = defineProps({
    widget: Object,
})

const page = reactive({
    prefix: null,
    suffix: null,
    value: null,
})
const self = ref(null)
const prefix = ref(null)
const suffix = ref(null)
const value = ref(null)

defineExpose({update})

function update() {
    let el = self.value.$el

    //  Widget.Text overrides value
    let value = el.style['--w-content'] || props.widget.text || props.widget.value
    page.value = formatValue(value, {format: props.widget.format})

    let sel = suffix.value
    page.suffix = sel.style['--w-content'] || props.widget.suffix

    let pel = prefix.value
    page.prefix = pel.style['--w-content'] || props.widget.prefix
}
</script>

<template>
    <vu-div ref="self" class="value-widget">
        <span ref="prefix" class="prefix">{{ page.prefix }}</span>
        <span ref="text" class="text">{{ page.value }}</span>
        <span ref="suffix" class="suffix">{{ page.suffix }}</span>
    </vu-div>
</template>

<style lang="scss">
.value-widget {
    position: relative;
    top: 50cqh;
    transform: translateY(-50%);
    display: block;
    text-align: center;
    color: rgb(var(--v-theme-text-lighten-1));
    .text {
        font-size: max(10px, min(min(18cqw, 18cqh), 144px));
        font-weight: bold;
        padding: 0 4px;
    }
    .prefix {
        font-size: max(10px, min(min(5cqw, 5cqh), 36px));
    }
    .suffix {
        font-size: max(14px, min(min(7cqw, 7cqh), 36px));
    }
}
</style>
