<script setup>
const BlogUrl = 'https://www.embedthis.com/blog/latest.html'
</script>

<template>
    <v-sheet class="help-blog">
        <iframe class="frame" :src="BlogUrl" frameborder="0" scrolling="auto"></iframe>
        <div class="footnote">
            <p>
                More blog articles on the
                <a href="https://www.embedthis.com/blog/" target="_blank">Embedthis Blog.</a>
            </p>
        </div>
    </v-sheet>
</template>

<style lang="scss">
.help-blog {
    height: 100%;
    padding: 0;
    margin: -40px;
    .frame {
        height: 100%;
        width: 100%;
    }
    .footnote {
        padding: 10px 0 20px 40px;
        font-size: 1rem;
        a {
            text-decoration: none;
        }
    }
}
</style>
