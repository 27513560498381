/*
    js-error.js -- Error exception classes
 */

function init(self, code, message, context) {
    self.code = `${code}Error`
    self.name = self.constructor.name
    self.message = message
    self.context = context
    if (context) {
        self.context = context
        if (context.status != null) {
            self.status = context.status
        }
    }
    self.date = new Date()
    if (typeof Error.captureStackTrace === 'function') {
        Error.captureStackTrace(self, self.constructor)
    } else {
        self.stack = (new Error(message)).stack
    }
}

export class AbortError extends Error {
    constructor(message, context) {
        super(message)
        init(this, 'Abort', message, context)
    }
}

export class GeneralError extends Error {
    constructor(code, message, context) {
        super(message)
        init(this, code, message, context)
    }
}

export class AuthError extends Error {
    constructor(message, context) {
        super(message)
        init(this, 'Auth', message, context)
    }
}

export class ClientError extends Error {
    constructor(message, context) {
        super(message)
        init(this, 'Client', message, context)
    }
}

export class CodeError extends Error {
    constructor(message, context) {
        super(message)
        init(this, 'Code', message, context)
    }
}

export class DataError extends Error {
    constructor(message) {
        super(message)
        init(this, 'Data', message)
    }
}

export class NetError extends Error {
    constructor(message, context) {
        super(message)
        init(this, 'Validation', message, context)
    }
}

export class OpsError extends Error {
    constructor(message, context) {
        super(message)
        init(this, 'Ops', message, context)
    }
}

export class UserError extends Error {
    constructor(message, context) {
        super(message)
        init(this, 'User', message, context)
    }
}

export class ValidationError extends Error {
    constructor(message, context) {
        super(message)
        init(this, 'Validation', message, context)
    }
}
