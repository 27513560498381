/*
    Test.js - Test model
 */

import {Rest} from '@/paks/js-rest'

export default new Rest('Test', {
    check: { method: 'POST', uri: '/:controller/check' },
    data: { method: 'POST', uri: '/:controller/data' },
}, {service: '/api'})
