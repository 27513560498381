<script setup>
import {getCurrentInstance, onBeforeMount, reactive} from 'vue'
import {Feedback, Progress, State, clone, fetch, navigate} from '@/paks/vu-app'
import {Product} from '@/models'
import Downloads from './Downloads'
import Planner from '@/common/planner'

let {proxy: self} = getCurrentInstance()

const props = defineProps({product: Object})
const emit = defineEmits(['input'])

const page = reactive({
    agent: null,
    downloads: [],
    eval: false,
    image: null,
    needConfirm: false,
    product: null,
    suspended: State.app.suspended ? true : false,
})

let planner = new Planner(State.config.billing)

onBeforeMount(() => {
    let product = props.product

    page.product = product
    page.agent = product.agent
    page.downloads = []
    let plan = product.plan
    let downloads = clone(Downloads)

    for (let [, item] of Object.entries(downloads)) {
        if (item.enable === false || item.filter.indexOf(page.agent) < 0) continue
        if (plan.scope == 'eval' || !plan.current || plan.current.end < Date.now()) {
            //  Plan is an eval plan
            page.eval = true
        } else if (plan.type == 'Volume' && !plan.current.units) {
            page.eval = true
        }
        page.downloads.push(item)
        let formats = []
        for (let format of item.formats) {
            if (!format.eval && page.eval) {
                continue
            }
            if (page.suspended && !format.eval) {
                continue
            }
            format.images = format.images.map((i) => {
                return {title: i, value: i}
            })
            //  Set default download
            format.image = format.images[0].value
            formats.push(format)
        }
        item.formats = formats
        if (plan.eval && State.cache.products.length == 0) {
            item.description += `
                To evaluate Ioto and cloud-based management, check the "product" property in 
                the Ioto agent device.json5 file is set to ${plan.eval}.`
        }
    }
    if (
        plan.pending &&
        !page.eval &&
        plan.start < Date.now() &&
        planner.getPrice(State.auth.account, plan, {upfront: true})
    ) {
        page.needConfirm = true
    }
})

async function save(format) {
    let product = page.product
    let plan = product.plan

    if (State.app.suspended) {
        Feedback.error('Account Billing Error')
        return
    }
    if (
        plan.type == 'Volume' &&
        plan.current.units == 0 &&
        format.image.indexOf('eval') < 0 &&
        format.image.indexOf('docker') < 0
    ) {
        Feedback.error('Multiple devices requires a purchase')
        return
    }
    try {
        format.loading = true
        //  Fix for format not being observed
        self.$forceUpdate()

        let url = await Product.download({
            planId: plan.id || '0',
            image: format.image,
        })
        let path = url.split('?')[0].split('/').slice(-1)[0]
        let a = document.createElement('a')
        a.download = path
        Progress.stop()
        Progress.start('dialog', `Downloading ${path}`)

        let data = await fetch(url, {
            method: 'GET',
            credentials: false,
            nologout: true,
            progress: false,
            blob: true,
        })
        let blob = new Blob([data])
        a.href = window.URL.createObjectURL(blob)
        document.body.append(a)
        a.click()
        a.remove()
        window.URL.revokeObjectURL(blob)
        emit('input')

        let interest = 'existing'
        State.ref.chat.identify({[`builder_${page.agent}`]: interest})
    } catch (err) {
        Feedback.error('Cannot download software', {err})
    } finally {
        Progress.stop()
        format.loading = false
    }
}
</script>

<template>
    <div class="downloads">
        <h1>Downloads</h1>
        <vu-sign
            name="notice-billing"
            :show="page.suspended"
            title="Account Suspended"
            color="error"
            :required="true">
            <p>
                Your account has been suspended due to an outstanding billing issue and only
                evaluation software can be downloaded.
            </p>
            <p>
                Please correct at
                <a @click="navigate('/account/billing')">Account Billing</a>
                or contact support for help.
            </p>
        </vu-sign>
        <vu-sign
            v-if="page.needConfirm && !page.eval"
            color="error"
            name="product-download-sign-0"
            title="Confirm Product"
            subtitle=""
            :required="true">
                <p>
                    Please
                    <a @click="navigate('/account/subscription')">Confirm</a>
                    your product purchase to activate so you can download production software.
                </p>
                <p>
                    <v-btn color="accent" @click="navigate('/account/subscription')">
                        Confirm Product
                    </v-btn>
                </p>
        </vu-sign>

        <vu-card
            v-for="item in page.downloads"
            name="product-download"
            color="accent"
            :key="page.title"
            :title="item.title"
            :required="true">
            {{ item.description }}
            <div v-for="format in item.formats" class="pb-2">
                <vu-input
                    v-model="format.image"
                    :label="`Select ${format.name} Image`"
                    :items="format.images"
                    type="select"
                    class="mt-5" />
                <v-form
                    @submit.prevent="save(format)"
                    class="product-download"
                    help="/doc/ui/products/download.html"
                    title="Downloads">
                    <div class="mb-4">
                        <v-btn size="small" color="accent" type="submit" :loading="format.loading">
                            Download
                        </v-btn>
                        <v-btn size="small" color="none" @click="emit('input')">Cancel</v-btn>
                    </div>
                </v-form>
            </div>
        </vu-card>
    </div>
</template>

<style lang="scss">
.downloads {
    padding: 10px;
    h1 {
        // Override v-form h1
        margin: 0 0 20px 0px !important;
        font-size: 1.7rem;
        color: rgb(var(--v-theme-text));
        font-weight: normal;
        flex-grow: 1;
        flex-basis: 100%;
    }
    .vu-card {
        margin-bottom: 30px;
        width: 100%;
        .card-title {
            font-size: 20px;
        }
        .v-btn {
            margin-right: 10px;
        }
    }
}
</style>
