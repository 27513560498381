<script setup>
import {Account} from '@/models'
import {onMounted, reactive, ref} from 'vue'
import {Feedback, Progress, can, navigate} from '@/paks/vu-app'

const page = reactive({
    accountId: null,
    userId: null,
})

//  Component refs
const confirm = ref(null)

onMounted(async () => {
    if (!can('support')) {
        navigate('/')
        return
    }
})

async function assume() {
    window.open(`https://app.embedthis.com/?assume=${page.userId.value}`, '_blank')
}

async function closeAccount() {
    if (!page.accountId.value) {
        Feedback.error('Missing account')
        return
    }
    let account = await Account.get({id: page.accountId.value})
    if (!account) {
        account = await Account.get({email: page.accountId.value}, {index: 'gs1'})
        if (!account) {
            Feedback.error('Cannot find account')
            return
        }
    }
    if (account.closed == true) {
        Feedback.info(`Account ${account.email} already closed`)
        return
    }
    if (!(await confirm.value.ask(`Do you want to close the account ${account.email}?`))) {
        return
    }
    Progress.start('medium')
    await Account.close({id: account.id})
    Progress.stop()
    Feedback.info(`Account ${account.email} closed`)
}
</script>

<template>
    <div class="tasks page">
        <v-container fluid>
            <v-row>
                <v-col cols="6">
                    <v-card>
                        <v-card-title>Support Assume</v-card-title>
                        <v-card-text><v-text-field v-model="page.userId" label="User Email or ID" /></v-card-text>
                        <v-card-actions>
                            <v-btn variant="elevated" size="small" color="accent" @click="assume">Assume</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col cols="6">
                    <v-card>
                        <v-card-title>Close Account</v-card-title>
                        <v-card-text>
                            <v-text-field v-model="page.accountId" label="Account ID or Email" required />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn variant="elevated" size="small" color="accent" @click="closeAccount">Close Account</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <vu-confirm ref="confirm" />
    </div>
</template>

<style lang="scss" scoped>
.v-card {
    margin: 14px;
    padding: 20px 20px;
    border: solid 1px rgb(var(--v-theme-accent));
}
.v-btn {
    margin-right: 10px;
}
h2 {
    font-weight: normal;
    padding-bottom: 20px;
}
</style>
