<script setup>
import {reactive} from 'vue'

const DefaultWidth = 500

defineExpose({ask})

const page = reactive({
    active: false,
    agree: 'Agree',
    color: null,
    confirmPrompt: null,
    confirmText: null,
    disagree: 'Disagree',
    html: null,
    message: null,
    slotName: 'default',
    title: null,
    width: DefaultWidth,
})

let resolved = null

async function ask(text, options = {}) {
    page.slotName = options.slot ? options.slot : 'default'
    page.confirmText = null
    if (text) {
        if (text.match(/[\<]/g)) {
            page.html = text
            page.message = null
        } else {
            page.message = text
            page.html = null
        }
    }
    page.color = options.color
    page.width = options.width || DefaultWidth
    page.confirmPrompt = options.confirmPrompt
    page.agree = options.agree
    page.disagree = options.disagree
    if (page.disagree == null) {
        page.disagree = 'Disagree'
    }
    if (page.agree == null) {
        page.agree = page.disagree == false ? 'OK' : 'Agree'
    }
    page.title = options.title || (page.disagree == false ? 'Confirm' : 'Are you sure?')
    page.active = true

    let result = await new Promise((resolve, reject) => {
        resolved = resolve
    })

    //  Workaround for v-dialog failing to remove overlay
    let elements = document.getElementsByClassName('v-overlay theme--dark')
    if (elements) {
        for (let overlay of elements) {
            overlay.parentNode.removeChild(overlay)
        }
    }
    return result
}

function callAction(ok) {
    page.active = false
    if (resolved) {
        resolved(ok)
    }
}
</script>

<template>
    <div>
        <v-dialog v-model="page.active" persistent :width="page.width" v-if="page.active">
            <v-card class="vu-confirm" :class="page.color ? `border-${page.color}` : ''">
                <v-card-title class="headline">{{ page.title }}</v-card-title>
                <v-card-text class="vu-confirm-text">
                    <slot :name="page.slotName"></slot>
                    <span v-html="page.html"></span>
                    <span>{{ page.message }}</span>
                    <div v-if="page.confirmPrompt" class="mt-4">
                        <v-text-field
                            v-model="page.confirmText"
                            :label="page.confirmPrompt"></v-text-field>
                    </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-row no-gutters>
                        <v-col v-if="page.agree">
                            <v-btn
                                size="large"
                                depressed
                                tile
                                block
                                variant="elevated"
                                color="accent"
                                :disabled="
                                    page.confirmPrompt && page.confirmPrompt != page.confirmText
                                "
                                @click="callAction(true)">
                                {{ page.agree }}
                            </v-btn>
                        </v-col>
                        <v-col v-if="page.disagree">
                            <v-btn
                                variant="elevated"
                                size="large"
                                depressed
                                tile
                                block
                                color="cancel"
                                @click="callAction(false)">
                                {{ page.disagree }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style lang="scss">
.vu-confirm-text {
    p {
        margin-bottom: 16px !important;
    }
}
</style>

<style lang="scss" scoped>
.vu-confirm {
    padding-bottom: 0 !important;
    border-radius: 0 !important;
    &.border-error {
        border-top: solid 4px rgb(var(--v-theme-error)) !important;
    }
    &.border-accent {
        border-top: solid 4px rgb(var(--v-theme-accent)) !important;
    }
}
.headline {
    background: rgb(var(--v-theme-primary));
    color: white;
    font-weight: bold;
    padding: 16px;
}
.v-card-text {
    padding-top: 14px !important;
    font-size: 1rem;
    padding: 40px !important;
    line-height: 1.5em;
}
.v-card-actions {
    border: none;
    padding: 0 !important;
    min-height: 0;
}
</style>
