<script setup>
import {onBeforeMount, reactive, ref, watch} from 'vue'
import {Feedback, State, clone, money, toTitle} from '@/paks/vu-app'
import Dates from '@/paks/js-dates'
import {Plan} from '@/models'
import Planner from '@/common/planner'

const Basic = {
    current: {units: 0},
    period: 'year',
    type: 'Support',
    units: 0,
}

const Developer = {
    current: {units: 0},
    type: 'Developer',
    units: 0,
}

const emit = defineEmits(['input'])

const page = reactive({
    basic: {},
    current: null,
    developer: {},
    enable: {basic: false, developer: false},
    mock: State.app.mock,
    plans: clone(State.cache.plans),
    prior: {},
    range: null,
    rules: [],
    saving: false,
    total: 0,
})

const planner = new Planner(State.config.billing)
const account = clone(State.auth.account)
const plans = State.config.billing.plans

//  Component references
const form = ref(null)

watch(() => page.enable.basic, changeBasicEnable)
watch(() => page.enable.developer, changeDeveloperEnable)
watch(() => page.basic.period, changePeriod)
watch(() => page.developer.units, changeHours)

onBeforeMount(() => {
    page.basic = page.plans.find((p) => p.type == 'Support') || Basic
    page.developer = page.plans.find((p) => p.type == 'Developer') || Developer

    let {basic, developer} = page
    if (!basic.id) {
        basic = page.basic = planner.initPlan(clone(Basic))
    }
    if (account.billing.agreement) {
        basic.align = false
    }
    if (!developer.id) {
        developer = page.developer = planner.initPlan(clone(Developer))
    }
    page.enable.basic = basic.units ? true : false
    //  Developer support can only be enabled if basic support is enabled
    page.enable.developer =
        page.enable.basic && (developer.current.units || developer.units) ? true : false

    page.prior = clone({basic, developer})
    calculateSupport()
})

function calculateSupport() {
    let {basic, developer} = page
    getRange()

    let yearly = planner.getPrice(account, basic)
    basic.rate = basic.period == 'month' ? yearly / 12 : yearly
    basic.amount = 0

    basic.amount = planner.getPrice(account, basic, {period: true})
    developer.amount = planner.getPrice(account, developer)
    developer.units = +developer.units || 0

    page.total = basic.amount + developer.amount
}

function getRange() {
    let basic = page.basic
    let displayEnd = planner.getDisplayEnd(basic.end)
    let start = Dates.format(basic.start, 'mediumDate')
    let end = Dates.format(displayEnd, 'mediumDate')

    let partial = ''
    if (basic.align) {
        let when = new Date(basic.start.getTime() + 14 * 86400 * 1000)
        let aligned
        if (basic.period == 'month') {
            aligned = new Date(Date.UTC(when.getFullYear(), when.getMonth()))
        } else {
            aligned = new Date(Date.UTC(when.getFullYear()))
        }
        partial =
            Math.abs(basic.start.getTime() - aligned.getTime()) > 86400 * 1000
                ? ` (partial ${basic.period})`
                : ''
    }
    page.range = `${start} to ${end}` + partial
    if (basic.current.units && basic.current.end > Date.now()) {
        start = Dates.format(basic.current.start, 'mediumDate')
        end = Dates.format(basic.current.end, 'mediumDate')
        page.current = `${start} to ${end}` + partial
    }
}

async function confirmSave(confirm) {
    let {basic, developer, enable, prior} = page
    basic.units = enable.basic ? 1 : 0
    let changes = []

    if (
        !enable.basic &&
        !basic.units &&
        !enable.developer &&
        developer.units + developer.current.units
    ) {
        changes.push(
            `<p>Basic support is required to use developer support.</p>` +
                `<p>Cancelling basic support will mean you cannot use your existing purchased developer hours.</p>` +
                `<p>Do you want to cancel basic support?.</p>`
        )
    } else if (basic.units != page.prior.basic.units || basic.period != prior.basic.period) {
        if (basic.units) {
            changes.push(
                `<p>Purchase basic support for \$${basic.amount} this partial ${basic.period}?</p>` +
                    `<p>The subscription will automatically renew each ${basic.period} thereafter.</p>`
            )
        } else if (prior.basic.units) {
            changes.push(`Cancel basic support`)
        }
    }
    if (developer.units != prior.developer.units) {
        let noun = developer.units == 1 ? 'hour' : 'hours'
        if (developer.units) {
            changes.push(
                `Purchase ${developer.units} ${noun} of developer support for \$${developer.amount}`
            )
        } else if (changes.length == 0) {
            changes.push(`Change developer support`)
        }
    }
    if (changes.length) {
        let msg = toTitle(changes.join(' And '))
        if (!msg.endsWith('>')) {
            msg += '?'
        }
        return await confirm.value.ask(msg, {width: 700})
    }
    return true
}

async function save() {
    let {basic, developer, prior} = page
    if (developer.units != prior.developer.units) {
        developer.pending = developer.units ? true : false
        let params = {
            id: developer.id,
            pending: developer.pending,
            period: developer.period,
            start: new Date(),
            end: planner.getEnd(developer),
            type: developer.type,
            units: developer.units,
        }
        if (developer.id) {
            await Plan.update(params)
        } else {
            await Plan.create(params)
        }
    }
    if (basic.units != prior.basic.units || basic.period != prior.basic.period) {
        if (basic.units) {
            //  Agreements are not-aligned for monthly support
            if (basic.period == 'month' && basic.override) {
                delete basic.override.align
            }
            basic.pending = true
        } else {
            basic.pending = false
        }
        let params = {
            id: basic.id,
            align: basic.align,
            pending: basic.pending,
            period: basic.period,
            type: basic.type,
            units: basic.units,
        }
        if (basic.id) {
            await Plan.update(params)
        } else {
            await Plan.create(params)
        }
    }
    emit('input')
}

async function changeBasicEnable(v) {
    let {basic, developer, enable} = page
    basic.units = enable.basic ? 1 : 0
    let validate = form.value?.validate
    if (validate) {
        if (!enable.basic && enable.developer && developer.units + developer.current.units) {
            await validate.fieldError(
                form.value,
                'basic',
                'Basic support is required to use developer support'
            )
        } else {
            await validate.clear()
        }
    }
    page.enable.developer =
        page.enable.basic && (developer.current.units || developer.units) ? true : false
    calculateSupport()
}

function changePeriod(v) {
    let {basic} = page
    basic.start = basic.current.end || new Date()
    basic.end = planner.getEnd(basic)
    calculateSupport()
}

async function changeDeveloperEnable(v) {
    let {basic, developer, enable} = page
    if (enable.developer) {
        if (!enable.basic) {
            basic.units = 1
            enable.basic = true
            Feedback.info(`Must Enable Basic Support if Enabling Developer Support`)
        }
    } else {
        developer.units = 0
        await form.value.validate.clear()
    }
    calculateSupport()
}

function changeHours(v) {
    let {basic, developer, enable} = page
    developer.units = +developer.units || 0
    if (developer.units) {
        if (developer.units == 1) {
            developer.units = 2
        }
        if (!basic.units) {
            basic.period = 'month'
            enable.basic = true
            basic.units = 1
        }
    }
    calculateSupport()
}

function adjYear(d, adj) {
    d = new Date(d)
    return new Date(d.setFullYear(d.getFullYear() + adj))
}
</script>

<template>
    <vu-form
        class="support-edit"
        help="/doc/ui/support/edit.html"
        ref="form"
        title="Modify Support"
        :data="page"
        :confirm="confirmSave"
        :save="save">
        <vu-sign name="support-sign-2" title="Support" subtitle="" color="accent" class="vcol-12">
            <div>
                <p>
                    <b>Basic support</b>
                    provides guidance covering installation and operational usage of the product.
                </p>
                <p>
                    <b>Developer support</b>
                    provides help for design and implementation issues. This covers embedded
                    development, cloud-based device management, developer coding and debugging
                    issues. Developer support has a minimum of 2 hours and requires that basic
                    support be active to utilize.
                </p>
                <p>
                    Basic support is ${{ money(plans.Support.pricing[0].price, 0) }} USD per year,
                    Developer support is ${{ money(plans.Developer.pricing[0].price, 0) }}
                    USD per hour.
                </p>
                <p>
                    See
                    <a
                        :href="`https://www.embedthis.com/builder/doc/plans/support/`"
                        target="_blank">
                        Support Pricing
                    </a>
                    for details.
                </p>
            </div>
        </vu-sign>

        <vu-sign
            class="vcol-12"
            name="support-edit-1"
            subtitle=""
            title="Basic Support"
            color="accent"
            :required="true">
            <div>
                <p v-if="page.basic.current.units && page.basic.current.end > Date.now()">
                    Basic support is paid up until
                    {{ Dates.format(page.basic.current.end, 'mediumDate') }}.
                </p>
                <p>
                    Basic support is ${{ money(plans.Support.pricing[0].price, 0) }} USD per year,
                    paid monthly or yearly in advance.
                </p>
                <v-checkbox
                    v-model="page.enable.basic"
                    name="basic"
                    label="Renew"
                    color="primary"
                    class="mt-3"></v-checkbox>
                <label>Billing Period</label>
                <v-radio-group v-model="page.basic.period" row @change="changePeriod"
                    color="primary">
                    <v-radio label="Monthly" :value="'month'"></v-radio>
                    <v-radio label="Yearly" :value="'year'"></v-radio>
                </v-radio-group>
                <p v-if="page.basic.units">Basic support will renew from {{ page.range }}.</p>
            </div>
        </vu-sign>

        <vu-sign
            class="vcol-12 mt-2"
            name="support-edit-2"
            subtitle=""
            title="Developer Support"
            color="accent"
            :required="true">
                <!--

                    <v-row>
                        <v-col cols="12" class="pt-0">
                            <v-checkbox v-model="page.enable.developer" label="Enable" class="mt-3"></v-checkbox>
                        </v-col>
                    </v-row>
                    -->
                <v-row class="pt-4">
                    <v-col cols="6">
                        <v-text-field
                            :disabled="true"
                            v-model="page.developer.current.units"
                            :label="
                                page.enable.basic
                                    ? 'Available developer support hours'
                                    : 'Developer support hours (requires basic support)'
                            " />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="page.developer.units"
                            placeholder="Support Hours"
                            :rules="page.rules.number"
                            label="Purchase addition developer (min 2 hours)" />
                    </v-col>
                </v-row>
                <p>
                    Developer support is ${{ money(plans.Developer.pricing[0].price, 0) }} per hour
                    and is paid in advance.
                </p>
        </vu-sign>

        <div class="actions">
            <v-btn size="small" color="accent" type="submit" :loading="page.saving">Save</v-btn>
            <v-btn size="small" color="none" @click="emit('input')">Cancel</v-btn>
        </div>

        <!--

        <vu-sign
            v-if="page.enable.basic || page.enable.developer"
            color="accent"
            class="summary vcol-12"
            name="summary"
            title="Subscription Summary"
            :required="true">
            <v-row>
                <v-col cols="12">
                    <v-simple-table dense>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Period</th>
                                <th>Quantity</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="page.current">
                                <td>Current Basic Support</td>
                                <td>{{ page.current }}</td>
                                <td></td>
                                <td>Paid</td>
                            </tr>
                            <tr v-if="page.enable.basic">
                                <td>Basic Support</td>
                                <td>{{ page.range }}</td>
                                <td></td>
                                <td>{{ page.basic.amount ? `$${money(page.basic.amount, 0)}` : 'Purchased' }}</td>
                            </tr>

                            <tr v-if="page.developer.amount">
                                <td>Developer support</td>
                                <td>Ongoing</td>
                                <td>{{ page.developer.units }} {{ page.developer.units == 1 ? 'hour' : 'hours' }}</td>
                                <td>${{ money(page.developer.amount, 0) }}</td>
                            </tr>
                            <tr v-if="page.developer.current.units">
                                <td>Existing developer support</td>
                                <td>Ongoing</td>
                                <td>
                                    {{ page.developer.current.units }}
                                    {{ page.developer.current.units == 1 ? 'hour' : 'hours' }}
                                </td>
                                <td>Purchased</td>
                            </tr>
                            <tr>
                                <td><b>Total</b></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <b>${{ money(page.total, 0) }}</b>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>
            <v-row v-if="page.basic.rate">
                <v-col>
                    <p>Next {{ page.basic.period }} basic support: ${{ money(page.basic.rate, 0) }}.</p>
                </v-col>
            </v-row>
        </vu-sign>
        -->
    </vu-form>
</template>

<style lang="scss">
.support-edit {
    .theme--light.v-chip:not(.v-chip--active) {
        background-color: rgb(var(--v-theme-text-lighten-2));
        color: black;
        margin-top: 1px;
    }
    .v-input {
        margin-top: 2px;
    }
    .col {
        padding-top: 6px;
        padding-bottom: 6px;
    }
    .v-text-field {
        padding-top: 0;
    }
    ul {
        margin-bottom: 16px;
    }
    .actions {
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .summary {
        padding-bottom: 12px;
    }
    .v-input--selection-controls__ripple.primary--text {
        color: rgb(var(--v-theme-accent)) !important;
        caret-color: rgb(var(--v-theme-accent)) !important;
    }
}
.pick-items {
    max-height: 90%;
}
</style>
