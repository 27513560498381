/*
    main.js -- Builder main
 */

/*
    Vue and Vuetify
 */
import {createApp} from 'vue'
import {createRouter, createWebHashHistory} from 'vue-router'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

/*
    Paks
 */
import {initApp, vuetifyProps} from '@/paks/vu-app'
import VuWizard from '@/paks/vu-wizard'
import VuSpell from '@/paks/vu-wizard/VuSpell.vue'
import Login from '@/paks/vu-auth/Login.vue'
import Forgot from '@/paks/vu-auth/Forgot.vue'
import Logout from '@/paks/vu-auth/Logout.vue'
import GraphWidget from '@/paks/vu-widgets/GraphWidget.vue'
import Config from '@/paks/js-config'
import Cognito from '@/paks/js-cognito'
import * as Models from '@/models/index.js'

/*
    Configuration
 */
import display from './display.json5'
import UI from '../ui.json5'
import Product from '../../../product.json5'
import {Roles} from '@/paks/mgr-data'

/*
    Views
 */
import Main from './Main.vue'
import HelpPanel from './views/Help/HelpPanel.vue'
import Home from './views/Home/Home.vue'
import AppList from './views/Apps/AppList.vue'
import ProductList from './views/Products/ProductList.vue'
import SoftwareList from './views/Software/SoftwareList.vue'
import SupportList from './views/Tickets/SupportList.vue'
import Account from './views/Account/Account.vue'
import AccountMemberList from './views/Account/AccountMemberList.vue'
import AccountMemberEdit from './views/Account/AccountMemberEdit.vue'
import AccountSettings from './views/Account/AccountSettings.vue'
import ActionList from './views/Actions/ActionList.vue'
import UserPassword from './views/Account/UserPassword.vue'
import UserProfile from './views/Account/UserProfile.vue'
import Subscription from './views/Billing/Subscription.vue'
import Billing from './views/Billing/Billing.vue'
import CardEdit from './views/Billing/CardEdit.vue'
import CloudList from './views/Clouds/CloudList.vue'
import HostList from './views/Hosts/HostList.vue'
import TokenList from './views/Tokens/TokenList.vue'
import DeviceList from './views/Devices/DeviceList.vue'
import Admin from './views/Admin/Admin.vue'
import AdminMetrics from './views/Admin/Metrics.vue'
import AdminTasks from './views/Admin/Tasks.vue'
import CaseList from './views/Admin/CaseList.vue'
import InvoiceList from './views/Admin/InvoiceList.vue'
import CustomerList from './views/Admin/CustomerList.vue'
import AccountList from './views/Admin/AccountList.vue'
import TrialList from './views/Admin/TrialList.vue'
import {AlertList} from '@/paks/vu-alerts'
import {Dash, DashList} from '@/paks/vu-dash'

import {Gist} from '@/compose/Gist'

/*
    Icons
 */
import {mdiGift, mdiTicket, mdiWebpack, mdiBriefcaseEdit} from '@mdi/js'

const Icons = {
    case: mdiBriefcaseEdit,
    cube: mdiWebpack,
    gift: mdiGift,
    ticket: mdiTicket,
}

async function main() {
    let config = Config([Product, Roles, UI])

    let vue = createApp(Main)
    let router = createRouter({
        history: createWebHashHistory(),
        routes: [],
    })
    let vuetify = createVuetify(
        vuetifyProps({
            components: {
                ...components,
                Account,
                AccountMemberEdit,
                AccountMemberList,
                AccountSettings,
                ActionList,
                AlertList,
                Billing,
                CardEdit,
                CloudList,
                Dash,
                DashList,
                DeviceList,
                Forgot,
                HelpPanel,
                Home,
                HostList,
                Login,
                Logout,
                Main,
                AppList,
                // NotificationList,
                ProductList,
                SoftwareList,
                Subscription,
                SupportList,
                TokenList,
                UserPassword,
                UserProfile,
                VuSpell,
                VuWizard,

                //  Admin only,
                AccountList,
                Admin,
                AdminMetrics,
                AdminTasks,
                CaseList,
                CustomerList,
                InvoiceList,
                TrialList,
            },
            directives: directives,
            icons: Icons,
            themes: display.theme.themes,
        })
    )
    vue.use(vuetify)

    await initApp(
        {
            vue,
            vuetify,
            router,
            display,
            chat: Gist,
            cognito: Cognito,
            models: Models,
            widgets: {graph: GraphWidget},
        },
        config
    )

    vue.use(router)
    vue.mount('#main')
    router.push({path: '/auth/login'})
}

main()
