/*
    Convert array buffer to base 64 in the browser
 */
export function arrayBufferToBase64(buffer) {
    let bytes = new Uint8Array(buffer)
    let len = bytes.byteLength
    let binary = ''
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
}

export function base64ToArrayBuffer(base64) {
    let bstring = window.atob(base64)
    let len = bstring.length
    let bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
        bytes[i] = bstring.charCodeAt(i)
    }
    return bytes.buffer
}

export function stringToArrayBuffer(s) {
    let bstring = window.atob(s)
    let len = bstring.length
    let bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
        bytes[i] = bstring.charCodeAt(i)
    }
    return bytes.buffer
}
