/*
    vu-drag directive - for drag and drop within a simple list

    vu-drag="list" :drag-options="{enable, padding, removeOnSpill, moveable}"
 */
import dragula from 'dragula'

let arrays = []
let drakes = []

export default function (vapp) {
    vapp.directive('drag', {
        bind(container, binding, vnode) {
            let orderProperty = (vnode.data.attrs ? vnode.data.attrs.order : undefined) || 'order'
            let options = (vnode.data.attrs ? vnode.data.attrs['drag-options'] : {}) || {}
            let items = binding.value || []                 // Array of items to manage
            let context = vnode.context                     // Owning component to issue event upon
            let dragIndex                                   // Index of dragged element
            let arrayIndex
            let padding = options.padding || 0

            if (options.enable !== false) {
                let drake = dragula([container], options)
                    .on('drag', (el, source) => {
                        // When we drag an item, memorize its index.
                        dragIndex = findDomIndex(source, el) - padding
                    })
                    .on('drop', (el, target) => {
                        let targetIndex = findDomIndex(target, el) - padding
                        items = arrays[arrayIndex]
                        move(items, dragIndex, targetIndex)
                        if (orderProperty) {
                            resequence(items, orderProperty)
                        }
                        context.$emit('drop', {index: dragIndex, targetIndex})
                    })
                    .on('remove', (el, target) => {
                        items = arrays[arrayIndex]
                        let item = items.splice(dragIndex, 1)[0]
                        context.$emit('remove', {index: dragIndex, item})
                    })
                arrayIndex = addDrake(items, drake)
            }
        },

        update(elm, binding, vnode, oldVnode) {
            if (binding.value) {
                swapArray(binding.oldValue, binding.value)
            }
        },

        unbind(container, binding, vnode) {
            let drake = getDrake(binding.value)
            if (drake) {
                drake.destroy()
            }
        }
    })
}

/*
   Find the index of a DOM element within a given container.
 */
function findDomIndex(container, el) {
    return Array.prototype.indexOf.call(container.children, el)
}

/*
   Move an array item from one index to another. The given array is transformed, not returned.
 */
function move(array, fromIndex, toIndex) {
    array.splice(toIndex, 0, array.splice(fromIndex, 1)[0])
}

function resequence(array, orderProperty) {
    let order = 0
    array.map(e => {
        if (e) {
            e[orderProperty] = order++
        }
    })
}

/*
   Register a drake instance based on the reference of the given array.
 */
function addDrake(array, drake) {
    if (arrays.indexOf(array) >= 0) {
        return
    }
    arrays.push(array)
    drakes.push(drake)
    return arrays.length - 1
}

/*
   Retrieve a drake instance based on the reference of the given array.
 */
function getDrake(array) {
    let drakeIndex = arrays.indexOf(array)
    if (drakeIndex >= 0) {
        return drakes[drakeIndex]
    }
}

function swapArray(oldArray, newArray) {
    let index = arrays.indexOf(oldArray)
    if (index >= 0) {
        arrays[index] = newArray
    }
}
