<script setup>
import {onMounted, reactive, watch} from 'vue'
import {Auth} from '@/paks/vu-app'

const props = defineProps({modelValue: [Object]})
const emit = defineEmits(['input', 'update:modelValue'])

const page = reactive({
    auth: {},
    dialog: true,
    rules: [],
    saving: false,
})

watch(() => page.dialog, onClickAway)

onMounted(() => {
})

async function save() {
    let auth = page.auth
    if (!auth.email) {
        throw new Error('Missing email address')
    }
    await Auth.forgot(auth.email)
    page.dialog = false
    emit('update:modelValue', page.auth)
    emit('input', page.auth)
}

async function onClickAway() {
    if (!page.saving) {
        cancel()
    }
}

function cancel() {
    emit('input', null)
}
</script>

<template>
    <v-dialog v-model="page.dialog" width="550" content-class="forgot-ask-dialog">
        <vu-form :data="page" :save="save" class="forgot-form">
            <v-card>
                <v-card-title class="headline primary" primary-title>Forgot Password?</v-card-title>
                <v-card-text class="mt-4 pt-5 px-4">
                    Enter your email address and we will send you a password reset code.
                </v-card-text>
                <v-card-text class="pt-2 pb-0 px-4">
                    <v-text-field
                        v-model="page.auth.email"
                        single-line
                        label="Email"
                        :rules="page.rules.email"
                        class="ma-0 pa-0" />
                </v-card-text>
                <v-card-actions class="px-4 pa-2 pb-5">
                    <v-btn ripple color="primary" :loading="page.saving" type="submit">Send Reset Code</v-btn>
                    <v-btn ripple @click="cancel">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </vu-form>
    </v-dialog>
</template>

<style lang="scss">
.forgot-form {
    width: 100%;
    padding: 0 !important;
    .v-card {
        width: 100%;
    }
}
.forgot-ask-dialog {
    .headline {
        color: white;
    }
}
</style>
