<script setup>
import {onBeforeMount, reactive, ref, watch} from 'vue'
import {Storage, waitRender} from '@/paks/vu-app'

const Spans = [
    {label: 'Last 5 mins', period: 300},
    {label: 'Last 15 mins', period: 900},
    {label: 'Last Hour', period: 3600},
    {label: 'Last 3 Hours', period: 3 * 3600},
    {label: 'Last 6 Hours', period: 6 * 3600},
    {label: 'Last Day', period: 24 * 3600},
    {label: 'Last Week', period: 7 * 24 * 3600},
    {label: 'Last Month', period: 28 * 24 * 3600},
    {label: 'Last 3 Months', period: 3 * 28 * 24 * 3600},
    {label: 'Last 6 Months', period: 6 * 28 * 24 * 3600},
    {label: 'Last Year', period: 365 * 24 * 3600},
    {label: 'Custom', period: 0},
]

const DefaultRange = {period: 3600}

const props = defineProps({
    density: {type: String},
    modelValue: {type: Object},
    name: {type: String},
    loading: {type: Boolean},
    mode: {type: String, default: 'dateTime'},
})

const emit = defineEmits(['update:modelValue'])

watch(() => props.modelValue.period, prepRange)

const page = reactive({
    attributes: [{dot: 'red', dates: new Date()}],
    custom: {},
    customConfig: {},
    durationTitle: null,
    range: {},
    timezone: 'local',
})

//  Component references
const picker = ref(null)

onBeforeMount(() => {
    prepRange()
})

function prepRange() {
    let last = Storage.getValue(`/date-range/${props.name || 'default'}`)
    let range = Object.assign({}, DefaultRange, last, props.modelValue)
    let now = new Date()
    let end, start
    if (range.anchor == 'relative') {
        start = getAlignedDate(new Date(now.getTime() - range.period * 1000))
        end = now
    } else {
        start = range.start
        end = new Date(start.getTime() + range.period * 1000)
    }
    page.custom = {start, end}
    page.range = range
    setTitle(page.range)
    return page.range
}

function setTitle(range) {
    if (range.anchor == 'absolute') {
        page.durationTitle = 'Custom'
    } else {
        let spans = Spans.slice(0).sort((a, b) => a.period - b.period)
        for (let span of spans) {
            if (range.period <= span.period) {
                page.durationTitle = span.label
                break
            }
        }
    }
}

async function durationPicked(title) {
    let range = page.range

    let spans = Spans.slice(0).sort((a, b) => a.period - b.period)
    let span = spans.find((s) => s.label == title)

    page.durationTitle = title
    range.period = span.period

    if (title == 'Custom') {
        range.anchor = 'absolute'
        await waitRender()
        if (picker.value) {
            picker.value.showPopover()
        }
        await waitRender()
    } else {
        range.anchor = 'relative'
        trigger()
    }
}

async function customChanged(values) {
    let range = page.range
    let {start, end} = values
    if (!start || !end) {
        return
    }
    range.period = parseInt((end - start) / 1000)
    range.start = start
    trigger()
}

function getAlignedDate(when) {
    when = new Date(when)
    return new Date(
        when.getFullYear(),
        when.getMonth(),
        when.getDate(),
        when.getHours(),
        when.getMinutes()
    )
}

function trigger() {
    let range = page.range
    Storage.setValue(`/date-range/${props.name || 'default'}`, range)
    emit('update:modelValue', range)
}
</script>

<template>
    <div class="date-range">
        <div class="picker">
            <vu-date-picker
                v-if="page.durationTitle == 'Custom'"
                ref="picker"
                v-model.range="page.custom"
                :mode="mode"
                :attributes="page.attributes"
                :model-config="page.customConfig"
                :update-on-input="false"
                :timezone="page.timezone == 'local' ? null : 'UTC'"
                @update:modelValue="customChanged"
                >
                <template v-slot="{inputValue, inputEvents}">
                    <div class="flex justify-center items-center">
                        <input
                            :value="inputValue.start"
                            v-on="inputEvents.start"
                            class="custom-input" />
                        <v-icon icon="$start" />
                        <input
                            :value="inputValue.end"
                            v-on="inputEvents.end"
                            class="custom-input mr-5" />
                    </div>
                </template>
            </vu-date-picker>

            <vu-pick
                class="flex"
                :density="density"
                :label="page.durationTitle"
                :items="Spans"
                item-text="label"
                item-value="label"
                :loading="loading"
                width="120px"
                @click="durationPicked" />
        </div>
    </div>
</template>

<style lang="scss">
.date-range {
    display: inline-flex;
    z-index: 198;
    .picker {
        display: inline-flex;
    }
    .v-icon {
        margin: 0 10px 0 10px;
    }
    .custom-input {
        font-size: 0.9rem;
        padding: 8px 10px 8px 10px;
        margin: 0;
        min-width: 62px;
        border: 1px solid rgb(var(--v-theme-border-lighten-1));
        border-radius: 4px;
        background: none !important;
        box-shadow: none !important;
    }
}
.vc-time {
    .vc-select {
        select {
            font-size: 0.75rem !important;
        }
    }
    .vc-am-pm {
        button {
            font-size: 0.75rem;
        }
    }
}
</style>
