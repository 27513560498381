<script setup>
const props = defineProps({
    header: String,
    widget: Object,
    value: Object,
})

function clicked(item) {
    window.open(item.link, '_blank')
}
</script>

<template>
    <div class="list-widget">
        <v-card ref="card">
            <v-card-title>
                <span class="title-class">{{ props.header || widget.header }}</span>
            </v-card-title>
            <v-card-text>
                <v-list subheader two-line>
                    <v-list-item v-for="item in (props.value || widget.value)" :key="item.title" @click="clicked(item)">
                        <template v-slot:prepend>
                            <v-btn
                                :icon="item.icon"
                                :color="item.color"
                                size="x-large"
                                class="nicon" />
                        </template>
                        <v-list-item-title v-text="item.title" />
                        <v-list-item-subtitle v-text="item.subtitle" />
                        <template v-slot:append>
                            <v-icon icon="$info" size="large" @click="clicked(item)" class="more" />
                        </template>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </div>
</template>

<style lang="scss" scoped>
.list-widget {
    height: 100%;
    width: 100%;
    .v-card {
        height: 100%;
        width: 100%;
        .v-card-title {
            padding-top: 14px;
            background: rgb(var(--v-theme-background));
            .title-class {
                color: rgb(var(--v-theme-text));
                font-size: 1.6rem;
                font-weight: bold;
            }
        }
        .v-card-text {
            display: block;
            padding-top: 10px !important;
            .v-list-item {
                margin-bottom: 10px;
                .v-list-item-title {
                    font-size: 1.2rem;
                    margin-bottom: 6px;
                }
                .v-list-item-subtitle {
                    font-size: 1rem;
                }
            }
        }
        .nicon {
            height: 40px;
            width: 40px;
            margin-right: 20px;
        }
        .more {
            color: rgb(var(--v-theme-border));
        }
    }
}
</style>
