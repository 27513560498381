<script setup>
import {getCurrentInstance, onMounted, reactive, ref, watch} from 'vue'
import {Feedback, Progress, State, can, toTitle} from '@/paks/vu-app'
import Dates from '@/paks/js-dates'
import {Token} from '@/models'
import TokenEdit from './TokenEdit.vue'

const {proxy: self} = getCurrentInstance()

const Fields = [
    {name: 'edit', icon: '$edit'},
    {name: 'type'},
    {name: 'target'},
    {name: 'resource'},
    {name: 'description'},
    {name: 'state'},
    {name: 'role'},
    {name: 'id', slot: true},
    {name: 'expires', format: (v) => Dates.format(v, 'mmm dd yyyy')},
]

const page = reactive({
    clouds: [],
    eval: State.config.evalProductToken,
    evalCloud: State.config.evalCloud,
    path: null,
    select: {
        actions: {
            Add: 0,
            Delete: 2,
            Edit: 1,
            New: 1,
            Replace: 2,
            Resume: 2,
            Suspend: 2,
        },
        multi: true,
        property: 'select',
    },
    showEdit: false,
    showTable: false,
    token: {},
    tokens: [],
})

//  Component refs
const confirm = ref(null)
const table = ref(null)

watch(
    () => State.app.mock,
    () => {
        if (table.value) {
            table.value.update()
        }
    }
)

watch(
    () => page.cloudId,
    () => {
        State.app.setPrefer('cloudId', page.cloudId)
        page.showTable = false
        if (page.cloudId) {
            page.showTable = true
            if (table.value) {
                table.value.update()
            }
        }
    }
)

onMounted(async () => {
    page.path = self.$route.path.split('/').at(-1)
    page.clouds = State.cache.clouds.filter(
        (c) => (can('support') && !State.app.mock) || c.type != 'host'
    )
    page.cloudId = State.app.prefer('cloudId')
    if (page.cloudId) {
        page.showTable = true
    }
    if (page.path == 'builder') {
        page.showTable = true
    }
})

async function getData(args) {
    Progress.start()

    let tokens
    if (page.path == 'device') {
        if (page.cloudId) {
            tokens = await Token.find({cloudId: page.cloudId, type: 'DeviceToCloud'}, args)
            page.showTable = true
        } else {
            page.showTable = false
        }
    } else if (page.path == 'cloud') {
        if (page.cloudId) {
            tokens = await Token.find({cloudId: page.cloudId, type: 'CloudAPI'}, args)
            page.showTable = true
        } else {
            page.showTable = false
        }
    } else {
        tokens = await Token.find({}, args)
        if (!can('support') || State.app.mock) {
            //  Remove CloudToBuilder and eval cloud tokens
            tokens = tokens.filter((t) => t.type != 'CloudToBuilder')
            tokens = tokens.filter((t) => t.cloudId != page.evalCloud)
        } else {
            tokens = tokens.filter((t) => t.type != 'CloudAPI')
        }
        page.showTable = true
    }
    for (let token of tokens) {
        if (token.owner) {
            let [model, id] = token.owner.split(':')
            model = toTitle(model)
            let item = State.get(model, id)
            if (item) {
                if (model == 'Cloud') {
                    token.resource = `${model}: ${item.name} in ${item.region}`
                } else if (model == 'Product') {
                    token.resource = 'Account'
                } else {
                    token.resource = `${model} ${item.name}`
                }
            } else {
                let [owner, id] = token.owner.split(':')
                token.resource = `${toTitle(owner)}: ${id} `
            }
        }
        token.target = getTarget(token)
        token.state = getState(token)
    }
    page.tokens = tokens
    Progress.stop()
    return page.tokens
}

function getState(token) {
    let state
    if (!token.enable) {
        state = 'Suspended'
    } else if (token.expires < new Date()) {
        state = 'Expired'
    } else {
        state = 'Active'
    }
    return state
}

function getTarget(token) {
    switch (token.type) {
        case 'BuilderAPI':
            return 'Builder'
        case 'DeviceToCloud':
            return 'DeviceCloud'
        case 'CloudAPI':
            return 'DeviceCloud'
        case 'CloudToBuilder':
            return 'Builder'
        case 'ProductID':
            return 'Builder'
    }
}

async function tokenAction(action, tokens) {
    if (!(await confirm.value.ask(`Do you want to ${action} the selected tokens?`))) {
        return
    }
    // let ids = items.map((i) => i.id)
    await Token.action({action, tokens})
    Feedback.info(`Tokens ${action}d`)
    await table.value.update()
}

async function clicked({action, item, items, column}) {
    if (action == 'cell') {
        if (column.name == 'id') {
            navigator.clipboard.writeText(item.id)
            Feedback.info(`${item.type} token copied to clipboard`)
        } else {
            editToken(item)
        }
    } else if (action == 'edit') {
        editToken(item)
    } else if (action == 'add') {
        editToken({})
    } else if (
        action == 'delete' ||
        action == 'new' ||
        action == 'replace' ||
        action == 'resume' ||
        action == 'suspend'
    ) {
        await tokenAction(action, items)
    }
}

async function editToken(value) {
    page.showEdit = value ? true : false
    page.token = value || {}
    if (!page.showEdit) {
        await table.value.update()
    }
}
</script>

<template>
    <div class="page tokens-list">
        <vu-help url="/doc/ui/tokens/list.html" v-if="!page.showEdit" />

        <vu-sign name="token-list-sign-0" title="Token Management" color="accent">
            <p>
                The Builder and Ioto use authentication tokens to provide secure, granular
                authorized access. You can perform actions on tokens to: add, delete, modify,
                resume, suspend and create new tokens. See the Help for details about each of these
                actions.
            </p>
            <p>
                When evaluating Ioto, you can use the evaluation Product ID Token
                <b>{{ page.eval }}</b>
                in your Ioto agent
                <b>device.json5</b>
                "product" property. Otherwise, use your
                <b>ProductID</b>
                token as the "product" property in your Ioto device.json5 configuration file.
            </p>
        </vu-sign>

        <v-sheet v-if="page.path != 'builder'" class="cloud-select">
            <vu-input
                v-model="page.cloudId"
                label="Show Devices for Cloud"
                type="select"
                :items="page.clouds"
                item-title="name"
                item-value="id"
                :cols="4" />
        </v-sheet>
        <vu-table
            v-if="page.showTable"
            name="tokens"
            title="Tokens"
            options="dynamic,filter,toolbar"
            sort="type:asc"
            _title="Tokens"
            :data="getData"
            :fields="Fields"
            :pageSize="25"
            :select="page.select"
            ref="table"
            @click="clicked">
            <template v-slot:table-col-id="props">
                <span>{{ '...' + props.field.value.slice(-6) }}</span>
                <v-icon icon="$copy" class="copy" size="14" />
            </template>

            <template v-slot:more="props">
                <v-btn color="accent" class="mr-2" @click="editToken({})">Add Token</v-btn>
            </template>
        </vu-table>

        <vu-panel v-model="page.showEdit" @close="editToken" :widths="['700px']">
            <TokenEdit
                :token="page.token"
                :cloudId="page.token.cloudId || page.cloudId"
                @input="editToken" />
        </vu-panel>

        <vu-confirm ref="confirm" />
    </div>
</template>

<style lang="scss">
.tokens-list {
    .copy {
        float: right;
        margin-top: 4px;
    }
    .cloud-select {
        display: flex;
        padding: 16px 20px 8px 20px;
        margin-bottom: 20px;
        border: 1px solid rgb(var(--v-theme-border)) !important;
    }
}
</style>
