/*
    js-query -- Get a query string variable
 */

export default function(variable) {
    let location = window.location
    let query = location.search.substring(1) || location.hash.split('?')[1]
    if (query) {
        let vars = query.split('&')
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=')
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1])
            }
        }
    }
}

