const WidgetSets = [
    {
        name: 'Default',
        description: 'Default Dashboard',
        widgets: [{
            type: 'numeric',
            title: 'Devices Made',
            namespace: 'Embedthis/Builder',
            cloudId: 'builder',
            metric: 'DevicesMade',
            statistic: 'sum',
            width: 0.48,
            height: 340,
        }, {
            type: 'gauge',
            title: 'Support Tickets',
            namespace: 'Embedthis/Builder',
            cloudId: 'builder',
            metric: 'SupportTickets',
            statistic: 'sum',
            width: 0.48,
            height: 340,
        }, {
            type: 'graph',
            title: 'Devices Made',
            namespace: 'Embedthis/Builder',
            cloudId: 'builder',
            metric: 'DevicesMade',
            statistic: 'sum',
            width: 0.99,
            height: 340,
        }
    ]
    },
    {
        name: 'iot-mqtt-overview',
        description: 'IoT Overview',
        require: 'hub',
        widgets: [
            {
                title: 'Publish Incoming',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/IoT',
                metric: 'PublishIn.Success',
                dimensions: {Protocol: 'MQTT'},
                statistic: 'sum',
                top: 0,
                left: 0,
                width: 0.47,
                height: 300,
            },
            {
                title: 'Publish Outgoing',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/IoT',
                metric: 'PublishOut.Success',
                dimensions: {Protocol: 'MQTT'},
                statistic: 'sum',
                top: 0,
                left: 0.48,
                width: 0.47,
                height: 300,
            },
            {
                title: 'Connections',
                type: 'gauge',
                namespace: '@AWS/IoT',
                metric: 'Connect.Success',
                dimensions: {Protocol: 'MQTT'},
                statistic: 'sum',
                top: 320,
                left: 0,
                width: 0.15,
                height: 300,
            },
            {
                title: 'Subscribes',
                type: 'gauge',
                namespace: '@AWS/IoT',
                metric: 'Subscribe.Success',
                dimensions: {Protocol: 'MQTT'},
                statistic: 'sum',
                top: 320,
                left: 0.16,
                width: 0.15,
                height: 300,
            },
            {
                title: 'Rules Executed',
                type: 'gauge',
                namespace: '@AWS/IoT',
                metric: 'RulesExecuted',
                statistic: 'sum',
                top: 320,
                left: 0.32,
                width: 0.15,
                height: 300,
            },
            {
                title: 'Connected',
                type: 'gauge',
                namespace: 'Embedthis/Ioto',
                metric: 'DevicesConnected',
                statistic: 'Avg',
                top: 320,
                left: 0.48,
                width: 0.15,
                height: 300,
            },
            {
                title: 'DB Sync In',
                type: 'gauge',
                namespace: 'Embedthis/Ioto',
                metric: 'SyncIn',
                statistic: 'sum',
                top: 320,
                left: 0.64,
                width: 0.15,
                height: 300,
            },
            {
                title: 'DB Sync Out',
                type: 'gauge',
                namespace: 'Embedthis/Ioto',
                metric: 'SyncOut',
                statistic: 'sum',
                top: 320,
                left: 0.8,
                width: 0.15,
                height: 300,
            },
            {
                title: 'Devices Made',
                type: 'graph',
                presentation: 'time',
                owner: 'hub',
                namespace: 'Embedthis/Ioto',
                metric: 'DevicesMade',
                statistic: 'sum',
                top: 640,
                left: 0,
                width: 0.47,
                height: 300,
            },
            {
                title: 'Devices Claimed',
                type: 'graph',
                presentation: 'time',
                namespace: 'Embedthis/Ioto',
                metric: 'DevicesClaimed',
                statistic: 'sum',
                top: 640,
                left: 0.48,
                width: 0.47,
                height: 300,

        /*
        }, {
            title: 'Failure', type: 'gauge',
            namespace: '@AWS/IoT', metric: 'FailureSuccess', statistic: 'sum', dimensions: {Protocol: 'MQTT'},
        }, {
            title: 'ParseError', type: 'gauge',
            namespace: '@AWS/IoT', metric: 'ParseError', statistic: 'sum', dimensions: {Protocol: 'MQTT'},
        }, {
            title: 'Ping Success', type: 'gauge',
            namespace: '@AWS/IoT', metric: 'Ping.Success', statistic: 'sum', dimensions: {Protocol: 'MQTT'},
        }, {
            title: 'Subscribe Error', type: 'gauge',
            namespace: '@AWS/IoT', metric: 'Subscribe.AuthError', statistic: 'sum', dimensions: {Protocol: 'MQTT'},
        }, {
            title: 'Unsubscribe Success', type: 'gauge',
            namespace: '@AWS/IoT', metric: 'UnSubscribe.Success',  statistic: 'sum', dimensions: {Protocol: 'MQTT'},
            */
            },
        ],
    },

    {
        name: 'lambda-overview',
        description: 'Lambda Overview',
        require: 'dedicated',
        widgets: [
            {
                title: 'Lambda Invocations',
                type: 'gauge',
                namespace: '@AWS/Lambda',
                metric: 'Invocations',
                statistic: 'sum',
            },
            /*
            {
                title: 'Lambda Cold Starts',
                type: 'gauge',
                namespace: '@SenseDeep/Lambda',
                metric: 'ColdStarts',
                statistic: 'sum',
            },
            {
                title: 'Lambda Errors',
                type: 'gauge',
                namespace: '@SenseDeep/Lambda',
                metric: 'Errors',
                statistic: 'sum',
            }, */
            {
                title: 'Lambda Duration',
                type: 'gauge',
                namespace: '@AWS/Lambda',
                metric: 'Duration',
                statistic: 'avg',
                units: 'ms',
            },
            {
                title: 'Lambda Invocations',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/Lambda',
                metric: 'Invocations',
                statistic: 'sum',
            },
            {
                title: 'Lambda Concurrent',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/Lambda',
                metric: 'ConcurrentExecutions',
                statistic: 'max',
            },
            {
                title: 'Lambda Throttles',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/Lambda',
                metric: 'Throttles',
                statistic: 'sum',
            },
            /*
            {
                title: 'Lambda Cost',
                type: 'graph',
                presentation: 'time',
                namespace: '@SenseDeep/Lambda',
                metric: 'Cost',
                statistic: 'sum',
            }, */
        ],
    },
    {
        name: 'billing',
        description: 'Billing Overview',
        require: 'dedicated',
        widgets: [
            {
                title: 'AWS Charges',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/Billing',
                metric: 'EstimatedCharges',
                dimensions: {Currency: 'USD'},
                statistic: 'max',
                region: 'us-east-1',
            },
            {
                title: 'Lambda Charges',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/Billing',
                metric: 'EstimatedCharges',
                dimensions: {ServiceName: 'AWSLambda', Currency: 'USD'},
                statistic: 'max',
                region: 'us-east-1',
            },
            {
                title: 'DynamoDB Charges',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/Billing',
                metric: 'EstimatedCharges',
                dimensions: {ServiceName: 'AmazonDynamoDB', Currency: 'USD'},
                statistic: 'max',
                region: 'us-east-1',
            },
            {
                title: 'CloudWatch Charges',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/Billing',
                metric: 'EstimatedCharges',
                dimensions: {ServiceName: 'AmazonCloudWatch', Currency: 'USD'},
                statistic: 'max',
                region: 'us-east-1',
            },
            {
                title: 'DataTransfer Charges',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/Billing',
                metric: 'EstimatedCharges',
                dimensions: {ServiceName: 'AWSDataTransfer', Currency: 'USD'},
                statistic: 'max',
                region: 'us-east-1',
            },
            {
                title: 'S3 Charges',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/Billing',
                metric: 'EstimatedCharges',
                dimensions: {ServiceName: 'AmazonS3', Currency: 'USD'},
                statistic: 'max',
                region: 'us-east-1',
            },
            {
                title: 'API Gateway Charges',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/Billing',
                metric: 'EstimatedCharges',
                dimensions: {ServiceName: 'AmazonApiGateway', Currency: 'USD'},
                statistic: 'max',
                region: 'us-east-1',
            },
            {
                title: 'CloudFront Charges',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/Billing',
                metric: 'EstimatedCharges',
                dimensions: {ServiceName: 'AmazonCloudFront', Currency: 'USD'},
                statistic: 'max',
                region: 'us-east-1',
            },
            {
                title: 'Cognito Charges',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/Billing',
                metric: 'EstimatedCharges',
                dimensions: {ServiceName: 'AmazonCognito', Currency: 'USD'},
                statistic: 'max',
                region: 'us-east-1',
            },
            {
                title: 'SES Charges',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/Billing',
                metric: 'EstimatedCharges',
                dimensions: {ServiceName: 'AmazonSES', Currency: 'USD'},
                statistic: 'max',
                region: 'us-east-1',
            },
        ],
    },
    {
        name: 'dynamodb',
        description: 'DynamoDB Overview',
        require: 'dedicated',
        widgets: [
            //  UserErrors, ThrottledRequests, ReadThrottleEvents, WriteThrottleEvents
            {
                //SUM
                title: 'DynamoDB RCU',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/DynamoDB',
                metric: 'ConsumedReadCapacityUnits',
                statistic: 'avg',
            },
            {
                //SUM
                title: 'DynamoDB WCU',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/DynamoDB',
                metric: 'ConsumedWriteCapacityUnits',
                statistic: 'avg',
            },
            {
                title: 'Account Write Provisioning',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/DynamoDB',
                metric: 'AccountProvisionedWriteCapacityUtilization',
                statistic: 'avg',
            },
            {
                title: 'Account Read Provisioning',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/DynamoDB',
                metric: 'AccountProvisionedReadCapacityUtilization',
                statistic: 'avg',
            },
            {
                title: 'Account Read Max',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/DynamoDB',
                metric: 'AccountMaxReads',
                statistic: 'avg',
            },
            {
                title: 'Account Write Max',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/DynamoDB',
                metric: 'AccountMaxWrites',
                statistic: 'avg',
            },
            {
                title: 'Table Write Max',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/DynamoDB',
                metric: 'AccountMaxTableLevelWrites',
                statistic: 'avg',
            },
            {
                title: 'Table Read Max',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/DynamoDB',
                metric: 'AccountMaxTableLevelReads',
                statistic: 'avg',
            },
        ],
    },
    {
        name: 'eventbridge',
        description: 'EventBridge Overview',
        require: 'dedicated',
        widgets: [
            {
                title: 'Event Invocations',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/Events',
                metric: 'Invocations',
                statistic: 'sum',
            },
            {
                title: 'Event Triggered Rules',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/Events',
                metric: 'TriggeredRules',
                statistic: 'sum',
            },
            {
                title: 'Event Invocations',
                type: 'gauge',
                presentation: 'time',
                namespace: '@AWS/Events',
                metric: 'Invocations',
                statistic: 'sum',
            },
            {
                title: 'Event Triggered Rules',
                type: 'gauge',
                presentation: 'time',
                namespace: '@AWS/Events',
                metric: 'TriggeredRules',
                statistic: 'sum',
            },
        ],
    },
    {
        name: 'cloudwatchlogs',
        description: 'CloudWatch Logs Overview',
        require: 'dedicated',
        widgets: [
            {
                title: 'Incoming Log Events',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/Logs',
                metric: 'IncomingLogEvents',
                statistic: 'sum',
            },
            {
                title: 'Incoming Log Bytes',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/Logs',
                metric: 'IncomingBytes',
                statistic: 'sum',
            },
            {
                title: 'Incoming Log Bytes',
                type: 'gauge',
                presentation: 'time',
                namespace: '@AWS/Logs',
                metric: 'IncomingLogBytes',
                statistic: 'sum',
            },
            {
                title: 'Incoming Log Events',
                type: 'gauge',
                presentation: 'time',
                namespace: '@AWS/Logs',
                metric: 'IncomingLogEvents',
                statistic: 'sum',
            },
        ],
    },
    {
        name: 'sns',
        description: 'SNS Overview',
        require: 'dedicated',
        widgets: [
            {
                title: 'SNS Month Spend',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/SNS',
                metric: 'SMSMonthToDateSpentUSD',
                statistic: 'avg',
            },
        ],
    },
    {
        name: 'ses',
        description: 'SES Overview',
        require: 'dedicated',
        widgets: [
            {
                title: 'SES Bounces',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/SES',
                metric: 'Bounce',
                statistic: 'sum',
            },
            {
                title: 'SES Reputation Bounce Rate',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/SES',
                metric: 'Reputation.BounceRate',
                statistic: 'sum',
            },
            {
                title: 'SES Reputation Complaint Rate',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/SES',
                metric: 'Reputation.ComplaintRate',
                statistic: 'sum',
            },
            {
                title: 'SES Deliveries',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/SES',
                metric: 'Delivery',
                statistic: 'sum',
            },
            {
                title: 'SES Send',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/SES',
                metric: 'Send',
                statistic: 'sum',
            },
        ],

        //  Disabled
    },
    {
        name: 'apigateway',
        description: 'API Gateway Overview',
        require: 'dedicated',
        enable: false,
        //  No All
        widgets: [
            {
                title: 'API Count',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/ApiGateway',
                metric: 'count',
                statistic: 'sum',
            },
            {
                title: 'API 4XX Errors',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/ApiGateway',
                metric: '4xx',
                statistic: 'sum',
            },
            {
                title: 'API 5XX Errors',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/ApiGateway',
                metric: '5xx',
                statistic: 'sum',
            },
            {
                title: 'API Latency',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/ApiGateway',
                metric: 'latency',
                statistic: 'avg',
            },
            {
                title: 'API Integration Latency',
                type: 'graph',
                namespace: '@AWS/ApiGateway',
                metric: 'IntegrationLatency',
                statistic: 'avg',
            },
            {
                title: 'API Cache Hit Count',
                type: 'graph',
                namespace: '@AWS/ApiGateway',
                metric: 'Errors',
                statistic: 'sum',
            },
            {
                title: 'API Cache Miss Count',
                type: 'graph',
                namespace: '@AWS/ApiGateway',
                metric: 'Duration',
                statistic: 'sum',
            },
        ],
    },
    {
        name: 'cloudfront',
        description: 'Cloudfront Overview',
        require: 'dedicated',
        // No all
        enable: false,
        widgets: [
            {
                title: 'CloudFront Requests',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/CloudFront',
                metric: 'count',
                statistic: 'sum',
            },
            {
                title: 'CloudFront 4XX Errors',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/CloudFront',
                metric: '4xxErrorRate',
                statistic: 'avg',
            },
            {
                title: 'CloudFront 5XX Errors',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/CloudFront',
                metric: '5xxErrorRate',
                statistic: 'avg',
            },
            {
                title: 'CloudFront Downloaded Bytes',
                type: 'graph',
                presentation: 'time',
                namespace: '@AWS/CloudFront',
                metric: 'BytesDownloaded',
                statistic: 'sum',
            },
            {
                title: 'CloudFront Uploaded Bytes',
                type: 'graph',
                namespace: '@AWS/CloudFront',
                metric: 'BytesUploaded',
                statistic: 'sum',
            },
            {
                title: 'CloudFront Total Errors',
                type: 'graph',
                namespace: '@AWS/CloudFront',
                metric: 'TotalErrorRate',
                statistic: 'avg',
            },
        ],
    },
    {
        name: 'kinesis',
        description: 'Kinesis Firehose Overview',
        enable: false,
        widgets: [],
    },
    {
        name: 'route53',
        description: 'Route 53 Overview',
        enable: false,
        widgets: [],
    },
    {
        name: 'step',
        description: 'Step Functions Overview',
        enable: false,
        widgets: [],
    },
    {
        name: 's3',
        description: 'S3 Overview',
        enable: false,
        widgets: [],
    },
    {
        name: 'certificates',
        description: 'Certificate Manager Overview',
        enable: false,
        widgets: [
            {
                title: 'Certificates Expiry',
                type: 'gauge',
                namespace: '@AWS/CertificateManager',
                metric: 'DaysToExpiry',
                statistic: 'Minimum',
                range: '1week',
            },
        ],
    },
    {
        name: 'states',
        description: 'API States Overview',
        // no All
        enable: false,
        widgets: [],
    },
    {
        name: 'xray',
        description: 'X-Ray Overview',
        //  no All
        enable: false,
        widgets: [],
    },
    {
        name: 'usage',
        description: 'AWS Usage Overview',
        // no All
        enable: false,
        widgets: [],
    },
]
export default WidgetSets
