<template>
    <div />
</template>

<script setup>
import {onMounted} from 'vue'
import {State} from '@/paks/vu-app'

const props = defineProps({
    url: String,
})

onMounted(() => {
    State.app.setHelp(props.url)
})
</script>