<script setup>
import {onMounted, reactive, ref, watch} from 'vue'
import {Feedback, Progress, State, can, clone, getRoute} from '@/paks/vu-app'
import Query from '@/paks/js-query'
import DeviceRelease from './DeviceRelease.vue'
import {Device} from '@/models'

const Fields = [
    {name: 'id', title: 'Device ID'},
    {name: 'product'},
    {name: 'description', style: 'max-width: 400px; text-overflow: ellipsis;'},
    {name: 'state', align: 'left'},
    {name: 'cloud', align: 'left'},
    {name: 'region', align: 'left'},
    {name: 'managerAccountId', title: 'Manager Account', align: 'left'},
    {name: 'test', icon: 'tick'},
]

const page = reactive({
    canRemoveAll: null,
    device: {},
    devices: [],
    fetching: false,
    productId: null,
    products: [],
    select: {},
    showEdit: false,
})

//  Component references
const table = ref(null)
const confirm = ref(null)

const route = getRoute()

watch(
    () => page.productId,
    () => {
        State.app.setPrefer('productId', page.productId)
        if (table && table.value) {
            table.value.update()
        }
    }
)

onMounted(async () => {
    //  Filter Ioto only
    let products = page.products = clone(State.cache.products)
    if (products.length == 0) return
    page.productId = State.app.prefer('productId')
    if (route.params.id) {
        page.devices = await Device.find({productId: page.productId})
        let device = page.devices.find((d) => d.id == route.params.id)
        if (device) {
            page.device = device
            editDevice(device)
        }
    } else if (Query('add')) {
        editDevice({})
    }
    page.canRemoveAll = !State.app.mock && (can('support') || State.auth.email.indexOf('@embedthis.com'))
})

async function getData(args) {
    if (page.productId) {
        let product = State.get('Product', page.productId)
        page.devices = await Device.find({productId: page.productId}, args)
        for (let device of page.devices) {
            device.product = product.name
            device.description = product.description
            if (product.name == 'Eval Master') {
                device.product = 'Eval'
                device.description = 'Ioto Evaluation Product'
            }
            device.state = 'Registered'
            if (device.cloudId) {
                let cloud = State.get('Cloud', device.cloudId)
                if (cloud) {
                    device.state = 'Claimed'
                    device.cloud = cloud.name
                    device.region = cloud.region
                }
            }
            if (can('admin')) {
                //  Cannot claim here as we don't have a manager accountId
                page.select = {actions: {Release: 2, Remove: 2}, multi: true, property: 'select'}
            }
        }
    }
    return page.devices
}

async function releaseDevices(items) {
    if (!(await confirm.value.ask(`Do you want to release the selected devices?`))) {
        return
    }
    Progress.start()
    for (let item of items) {
        await Device.release({id: item.id})
    }
    await table.value.update('repeat')
    Feedback.info('Device Released')
    Progress.stop()
}

async function removeDevices(items) {
    if (!(await confirm.value.ask(`Do you want to remove the selected devices?`))) {
        return
    }
    Progress.start('repeat')
    for (let item of items) {
        await Device.remove({id: item.id})
    }
    await table.value.update()
    Progress.stop()
    Feedback.info('Device Removed')
}

async function clicked({action, item, items, column}) {
    if (action == 'add') {
        editDevice({})
    } else if (action == 'edit') {
        for (let item of items) {
            editDevice(item)
            break
        }
    } else if (action == 'delete') {
        for (let item of items) {
            await deleteDevice(item)
        }
    } else if (action == 'cell') {
        if (can('admin')) {
            editDevice(item)
        }
    } else if (action == 'claim') {
        await editDevice({})
    } else if (action == 'release') {
        await releaseDevices(items)
    } else if (action == 'remove') {
        await removeDevices(items)
    }
}

async function editDevice(device) {
    page.showEdit = device ? true : false
    page.device = device || {}
    if (!device) {
        //  Workaround
        if (table.value) {
            table.value.update()
        }
    }
}

async function removeAll() {
    if (!(await confirm.value.ask(`Are your REALLY sure you want to remove all devices?`))) {
        return
    }
    await Device.removeAll({productId: page.productId})

}
</script>

<template>
    <div class="page device-list">
        <vu-help url="/doc/ui/devices/list.html" />

        <vu-sign name="device-list-sign-1" title="Device Management" subtitle="" color="accent">
            <p>
                The Device list shows the connected, non-eval, Ioto-based devices for a selected
                product.
            </p>
        </vu-sign>

        <v-sheet class="product-select">
            <vu-input
                v-model="page.productId"
                label="Show Devices for Product"
                type="select"
                :items="page.products"
                item-title="name"
                item-value="id"
                :cols="4" />
        </v-sheet>

        <vu-table
            v-if="page.productId"
            title="Devices"
            name="devices"
            options="dynamic,filter,toolbar"
            sort="name:asc"
            ref="table"
            width="100%"
            :data="getData"
            :fields="Fields"
            :pageSize="25"
            :select="page.select"
            subtitle="This is the list of registered Ioto-based devices. It does not include update only devices."
            @click="clicked">
            <template v-slot:more="props">
                <v-btn v-if="page.canRemoveAll" color="error" class="mr-2" @click="removeAll()">Remove All Devices</v-btn>
            </template>
        </vu-table>

        <!--
        <vu-panel v-model="page.showEdit" @close="editDevice" :widths="['600px']">
            <DeviceClaim :id="page.device.id" @input="editDevice" ref="edit" />
        </vu-panel>
        -->
        <vu-panel v-model="page.showEdit" @close="editDevice" :widths="['600px']">
            <DeviceRelease :id="page.device.id" @input="editDevice" ref="edit" />
        </vu-panel>

        <vu-confirm ref="confirm" />
    </div>
</template>

<style lang="scss">
.device-list {
    .product-select {
        display: flex;
        padding: 16px 20px 16px 20px;
        margin-bottom: 20px;
        border: 1px solid rgb(var(--v-theme-border)) !important;
    }
    .v-input--switch {
        .v-input__slot {
            width: auto;
        }
    }
    .action {
        margin-right: 10px !important;
        border: 1px solid rgb(var(--v-theme-border));
        background: none !important;
        box-shadow: none !important;
    }
}
</style>
