<script setup>
import {getCurrentInstance, onMounted, reactive, ref} from 'vue'
import {onBeforeRouteLeave} from 'vue-router'
import {Feedback, Progress, State, can, clone, navigate, query, getRoute} from '@/paks/vu-app'
import Dates from '@/paks/js-dates'
import {Ticket} from '@/models'
import Planner from '@/common/planner'
import TicketEdit from './TicketEdit.vue'
import SupportEdit from './SupportEdit.vue'

const {proxy: self} = getCurrentInstance()

const Basic = {
    current: {},
    period: 'month',
    scope: 'basic',
    type: 'Support',
    units: 0,
    current: {units: 0},
}

const Developer = {
    current: {},
    period: 'month',
    scope: 'developer',
    type: 'Developer',
    current: {units: 0},
    units: 0,
}

const page = reactive({
    canAdmin: false,
    canAddTicket: true,
    basic: Basic,
    developer: Developer,
    fields: [
        {name: 'edit', icon: '$edit'},
        {name: 'status'},
        {name: 'subject', width: '50%'},
        {name: 'id', title: 'Case Number', format: (v) => v.slice(-6)},
        {name: 'updated', format: (v) => Dates.format(v, 'mmm dd yyyy, HH:MM:ss')},
        {name: 'severity', align: 'center'},
        {name: 'hours', align: 'right'},
    ],
    filter: 'open',
    needConfirm: false,
    select: null,
    showTicket: false,
    showSupportEdit: false,
    tickets: [],
    ticket: null,
})

const route = getRoute()

//  Component Refs
const edit = ref(null)
const confirm = ref(null)
const table = ref(null)

let planner = new Planner(State.config.billing)

onMounted(async () => {
    if (query('modify')) {
        editSupport({})
    } else if (route.params.id) {
        let ticket = await Ticket.get({id: route.params.id})
        editTicket(ticket)
    }
    if (can('support')) {
        page.select = {actions: {Add: 0, Edit: 1, Delete: 2}, multi: true, property: 'select'}
    } else {
        page.select = {actions: {Add: 0, Edit: 1}, multi: true, property: 'select'}
        page.fields = page.fields.filter((f) => f.name != 'edit')
    }
    prepSupport()
})

onBeforeRouteLeave(() => confirmCloseEdit(true))

function prepSupport() {
    let plans = clone(State.cache.plans)
    page.basic = plans.find((plan) => plan.type == 'Support') || Basic
    page.developer = plans.find((plan) => plan.type == 'Developer') || Developer
    let {basic, developer} = page
    if (!basic.pricing) {
        planner.initPlan(basic)
    }
    basic.due = planner.getDue(basic) <= new Date()

    if (!developer.pricing) {
        planner.initPlan(Developer)
    }
    developer.due = planner.getDue(developer) <= new Date()

    /*
        Allow opening tickets when developer support hours remaining
    */
    let grace = Date.now() - 86400 * 4 * 28 * 1000
    page.canAddTicket =
        !State.app.suspended &&
        ((basic.current.units > 0 && basic.current.end > grace) || developer?.current.units > 0)
    page.canAdmin = can('admin')

    page.needConfirm = false
    //  Don't test due
    if (
        (basic.pending && basic.units && basic.start < Date.now()) ||
        (developer.pending && developer.units)
    ) {
        page.needConfirm = true
    }
}

async function getData(args) {
    let props = {}
    if (page.filter == 'open') {
        args.where = '${status} <> {closed}'
    } else if (page.filter == 'closed') {
        props.status = 'closed'
    } else if (page.filter == 'active') {
        props.status = 'progressing'
    }
    page.tickets = await Ticket.find(props, args)

    page.tickets = page.tickets
        .sort((a, b) => {
            if (a.updated < b.updated) {
                return -1
            } else if (a.updated > b.updated) {
                return 1
            } else {
                return 0
            }
        })
        .reverse()
    return page.tickets
}

async function deleteTicket(items) {
    if (!(await confirm.value.ask(`Do you want to delete the selected ticket?`))) {
        return
    }
    Progress.start('dialog', 'Deleting Tickets')
    for (let item of items) {
        await Ticket.remove(item)
    }
    await table.value.update()
    Progress.stop()
    Feedback.info('Ticket Deleted')
}

async function clicked({action, item, items}) {
    if (action == 'add') {
        editTicket({})
    } else if (action == 'delete') {
        await deleteTicket(items)
    } else if (action == 'cell') {
        editTicket(item)
    }
}

async function filter(pattern) {
    page.filter = pattern
    if (table.value) {
        await table.value.update()
    }
}

async function editTicket(ticket) {
    page.showTicket = ticket ? true : false
    page.ticket = ticket || {}
    if (!ticket) {
        await table.value.update()
    }
}

async function editSupport(open) {
    page.showSupportEdit = open ? true : false
    if (!open) {
        await State.cache.update()
        prepSupport()
        self.$forceUpdate()
    }
}

async function confirmCloseEdit(discard) {
    if (!edit.value) return true
    let ticket = edit.value.page.ticket || {}
    let post = edit.value.page.post || {}
    if ((discard && ticket.id == null && ticket.subject) || post.message) {
        if (await confirm.value.ask(`Do you want to discard changes? `)) {
            return true
        }
        return false
    }
    return true
}
</script>

<template>
    <div class="page support-list">
        <vu-help url="/doc/ui/support/list.html" v-if="!page.showTicket" />

        <vu-sign
            name="support-list-sign-0"
            title="Confirm Support"
            subtitle=""
            color="error"
            :required="true"
            v-if="page.needConfirm">
            <p>
                Please
                <a @click="navigate('/account/subscription')">Confirm</a>
                your support purchase to activate.
            </p>
            <p>
                <v-btn color="accent" @click="navigate('/account/subscription')">
                    Confirm Support
                </v-btn>
            </p>
        </vu-sign>

        <vu-sign
            v-else
            name="support-list-sign-1"
            title="Support Center"
            subtitle=""
            color="accent">
            <p>
                In the Support Center can tailor your support plan and open support issue
                tickets and track them to resolution.
            </p>
            <p>EmbedThis offers two types of support:</p>
            <ul class="ml-5 mb-3">
                <li>Basic Support</li>
                <li>Developer Support</li>
            </ul>
            <p>
                <b>Basic support</b>
                provides guidance covering installation and operational usage of the product.
            </p>
            <p>
                <b>Developer support</b>
                provides developer help for design and implementation issues. This includes embedded
                development, cloud-based device management, developer coding and debug issues.
            </p>
            <p>
                See
                <a :href="`https://www.embedthis.com/builder/doc/plans/support/`" target="_blank">
                    Support Pricing
                </a>
                for details. Click
                <a @click="editSupport(true)">Support</a> to change your support plan.
            </p>
        </vu-sign>

        <vu-sign
            name="support-list-sign-2"
            title="Support Credits"
            subtitle=""
            color="accent"
            :required="true">
            <v-row>
                <v-col cols="4">
                    <label>Basic Support</label>
                    <p class="metric">
                        {{ page.basic.current.units > 0 ? 'Renewing' : 'Disabled' }}
                    </p>
                    <p v-if="page.basic.current.units && page.basic.current.end > Date.now()">
                        Basic support will expire on
                        {{ Dates.format(page.basic.current.end, 'mediumDate') }}.
                    </p>
                </v-col>
                <v-col cols="4">
                    <label>Available Developer Support</label>
                    <p class="metric">
                        {{ page.developer.current.units }}
                        {{ page.developer.current.units == 1 ? 'hour' : 'hours' }}
                    </p>
                </v-col>
            </v-row>
        </vu-sign>

        <vu-table
            name="tickets"
            options="dynamic,filter,refilter,toolbar"
            sort="created:desc"
            title="Cases"
            ref="table"
            width="100%"
            :data="getData"
            :fields="page.fields"
            :pageSize="25"
            :select="page.select"
            @click="clicked">
            <template v-slot:more="props">
                <v-btn class="action" @click="filter('active')">Active</v-btn>
                <v-btn class="action" @click="filter('open')">Open</v-btn>
                <v-btn class="action" @click="filter('closed')">Closed</v-btn>
                <v-btn class="action" @click="filter('all')">All</v-btn>
                <v-btn class="mr-2" color="accent" :disabled="!can('admin')" @click="editSupport(true)">
                    Support
                </v-btn>
                <v-btn color="accent" :disabled="!page.canAddTicket" @click="editTicket({})">
                    New
                </v-btn>
            </template>
        </vu-table>

        <vu-panel
            v-model="page.showTicket"
            @close="editTicket"
            :widths="['900px']"
            :confirm="confirmCloseEdit">
            <TicketEdit :arg="page.ticket" @input="editTicket" ref="edit" />
        </vu-panel>

        <vu-panel v-model="page.showSupportEdit" @close="editSupport" :widths="['700px']">
            <SupportEdit @input="editSupport" />
        </vu-panel>

        <vu-confirm ref="confirm"></vu-confirm>
    </div>
</template>

<style lang="scss">
.support-list {
    .v-input--switch {
        .v-input__slot {
            width: auto;
        }
    }
    .action {
        margin-right: 10px !important;
        margin-bottom: 4px;
        border: 1px solid rgb(var(--v-theme-border-lighten-1));
        background: none !important;
        box-shadow: none !important;
    }
    .metric {
        margin: 20px 0 20px 0;
        font-size: 1.25rem;
    }
}
</style>
