<script setup>
import {getCurrentInstance, onMounted, reactive, ref, watch} from 'vue'
import {Feedback, Progress, State, can, clone, delay, deny} from '@/paks/vu-app'
import {Token} from '@/models'

const {proxy: self} = getCurrentInstance()

let Forever = new Date(Date.now() + 25 * 365 * 86400 * 1000)

const Explain = {
    delete: 'Revoke this token and delete it from the system',
    new: 'create a new token of the same type',
    replace: 'replace this token with a new active token',
    resume: 'resume this token',
    suspend: 'suspend all use of this token',
}

const props = defineProps({
    token: Object,
    cloudId: String,
})
const emit = defineEmits(['input'])

let DefaultExpires = Date.now() + 25 * 365 * 86400 * 1000

const page = reactive({
    add: null,
    ask: {},
    canAdd: can('admin'),
    canSupport: can('support') && !State.app.mock,
    clouds: [],
    cloudId: null,
    loading: {},
    path: null,
    productId: null,
    products: [],
    roles: [],
    rules: [],
    saving: false,
    state: null,
    token: {
        enable: true,
        role: 'user',
        expires: Forever,
    },
    types: ['BuilderAPI', 'CloudAPI', 'CloudToBuilder', 'DeviceToCloud', 'ProductID'],
})

const DefaultRoles = {
    BuilderAPI: 'admin',
    CloudAPI: 'admin',
    CloudToBuilder: 'cloud',
    DeviceToCloud: 'device',
    ProductID: 'public',
}

const Ask = {
    BuilderAPI: {owner: 'account', replace: true, role: true},
    CloudAPI: {owner: 'cloud', cloud: true, replace: true, role: true},
    DeviceToCloud: {device: true, cloud: true, replace: true, owner: 'device', warn: true},
    ProductID: {owner: 'product', product: true, warn: true},

    //  Cannot replace these
    CloudToBuilder: {owner: 'cloud', cloud: true, warn: true},
}

const confirm = ref(null)

watch(() => page.token.type, changeType)
watch(() => page.cloudId, changeCloud)
watch(() => page.productId, changeProduct)
watch(() => page.token.id, changeId)

onMounted(async () => {
    let token = (page.token = clone(props.token))
    if (!token.id) {
        token.enable = true
        token.expires = DefaultExpires
    }
    if (State.cache.clouds.length == 0) {
        page.types = page.types.filter((t) => t == token.type || t != 'CloudToBuilder')
    }
    page.products = State.cache.products.map((p) => {
        return {title: p.name, value: p.id}
    })
    page.path = self.$route.path.split('/').at(-1)

    let clouds = State.cache.clouds.filter(
        (c) => c.type == 'hosted' || c.type == 'dedicated' || page.canSupport
    )
    page.clouds = clouds.map((c) => {
        return {title: `${c.name} (${c.region})`, value: c.id}
    })

    page.add = token.id == null ? true : false
    if (!token.id) {
        if (props.cloudId) {
            page.cloudId = props.cloudId
        } else {
            page.cloudId = page.clouds[0].id
        }
    } else {
        page.cloudId = props.cloudId
    }

    if (!token.type) {
        if (page.path == 'service') {
            token.type = 'BuilderAPI'
        } else if (page.path == 'cloud') {
            token.type = 'CloudAPI'
        } else {
            token.type = 'DeviceToCloud'
        }
    }
    page.ask = Ask[token.type]
    if (token.owner) {
        let [type, id] = token.owner.split(':')
        token[`${type}Id`] = id
    }
    if (token.type != 'CloudAPI' && token.type != 'DeviceToCloud') {
        delete token.cloudId
    }
    getState(page.token)

    if (token.type == 'ProductID') {
        let tokens = await Token.find({type: 'ProductID', owner: token.owner})
        if (tokens.length == 1) {
            page.ask.replace = false
        }
    }
    if (page.canSupport && !token.add) {
        page.ask.replace = true
    }
})

function changeType() {
    let token = page.token
    let type = token.type
    token.role = DefaultRoles[type]
    page.ask = Ask[type]

    let roles = Object.keys(State.config.roles)
    let role = State.app.mock ? 'owner' : State.auth.role
    roles = roles.slice(0, roles.indexOf(role) + 1)

    if (type == 'BuilderAPI') {
        roles = roles.filter((r) => r != 'device')
    } else if (type == 'CloudAPI') {
        roles = roles.filter((r) => r != 'device')
    } else if (type == 'CloudToBuilder') {
        roles = roles.filter((r) => r != 'device')
    } else if (type == 'DeviceToCloud') {
        roles = ['device']
    } else if (type == 'ProductID') {
        roles = ['public']
        if (page.products.length == 1) {
            page.token.productId = page.products[0].value
        }
    }
    page.roles = roles
    setOwner(page.token)
}

function changeCloud() {
    setOwner(page.token)
}

function changeProduct() {
    setOwner(page.token)
}

function changeId() {
    setOwner(page.token)
}

function setOwner() {
    let id = page.token[`${page.ask.owner}Id`]
    if (id) {
        page.token.owner = `${page.ask.owner}:${id}`
    } else if (page.ask.owner == 'cloud') {
        page.token.owner = `${page.ask.owner}:${page.cloudId}`
    } else {
        page.token.owner = undefined
    }
}

function getState(token) {
    let state
    if (!token.enable) {
        state = 'Suspended'
    } else if (token.expires < new Date()) {
        state = 'Expired'
    } else {
        state = 'Active'
    }
    page.state = state
}

async function save() {
    let {token} = page

    token = deny(token, ['deviceId', 'productId'])
    token.cloudId = token.type == 'CloudAPI' || token.type == 'DeviceToCloud' ? page.cloudId : null
    token.description = token.description.trim()
    setOwner(token)

    if (page.add) {
        await Token.create(token)
    } else {
        await Token.update({
            id: token.id,
            cloudId: token.cloudId,
            description: token.description,
            enable: token.enable,
            expires: token.expires,
            role: token.role,
            owner: token.owner,
            type: token.type,
        })
    }
    emit('input')
}

async function tokenAction(action) {
    if (!(await confirm.value.ask(`Do you want to ${Explain[action]}?`))) {
        return
    }
    Progress.start('short')
    page.loading[action] = true
    await Token.action({action, tokens: [page.token]})

    /*
        Certain actions perform multiple database operations in the builder.
        Delay here so TokenList can render once complete
    */
    await delay(1000)

    page.loading[action] = false
    Progress.stop()
    Feedback.info(`Token ${action} complete`)
}

async function clicked(action) {
    await tokenAction(action, page.token)
    emit('input')
}

function clipboard() {
    navigator.clipboard.writeText(page.token.id)
    Feedback.info(`${page.token.type} token copied to clipboard`)
}
</script>

<template>
    <vu-form
        :data="page"
        :save="save"
        help="/doc/ui/tokens/edit.html"
        :title="`${page.add ? 'Add' : 'Modify'} Token`">
        <vu-input
            v-model="page.token.type"
            name="Type"
            type="select"
            :items="page.types"
            :disabled="page.token.id ? true : false" />
        <vu-input v-if="page.token.id" v-model="page.state" :disabled="true" label="Status" />
        <vu-input v-model="page.token.description" name="Description" :rules="page.rules.text" />

        <vu-input v-model="page.token.expires" name="Expires" type="date" />

        <vu-input
            v-model="page.token.role"
            name="Role"
            type="select"
            :disabled="page.role ? true : false"
            :items="page.roles" />

        <vu-input
            v-if="page.ask.cloud"
            type="select"
            label="Cloud"
            v-model="page.cloudId"
            :disabled="page.token.id ? true : false"
            :items="page.clouds"
            cols="12" />

        <vu-input
            v-if="page.ask.product"
            type="select"
            label="Product"
            v-model="page.token.productId"
            :disabled="page.token.id ? true : false"
            :items="page.products"
            cols="12" />

        <vu-input
            v-if="page.ask.device"
            type="text"
            label="Manager Account ID"
            v-model="page.token.accountId"
            cols="12" />

        <vu-input
            v-if="page.ask.device"
            type="text"
            label="Device Claim ID"
            v-model="page.token.deviceId"
            cols="12" />

        <vu-input
            v-if="page.token.id || page.canSupport"
            v-model="page.token.id"
            :disabled="!page.canSupport"
            label="ID"
            cols="10" />

        <v-icon
            v-if="page.token.id || page.canSupport"
            icon="$copy"
            class="ml-3"
            size="18"
            @click="clipboard(page.token.id)" />

        <vu-input
            v-if="page.token.owner"
            v-model="page.token.owner"
            :disabled="true"
            label="Owner"
            cols="10" />

        <v-icon
            v-if="page.token.owner"
            icon="$copy"
            class="ml-3"
            size="18"
            @click="clipboard(page.token.owner.split(':')[1])" />

        <div class="actions">
            <v-btn
                size="small"
                color="accent"
                v-if="page.token.type"
                :loading="page.saving"
                type="submit">
                Save
            </v-btn>
            <v-btn size="small" color="none" @click="emit('input')">Cancel</v-btn>
            <span v-if="page.token.id">
                <v-btn
                    size="small"
                    color="accent"
                    @click="clicked('new')"
                    :loading="page.loading.new">
                    New
                </v-btn>
                <v-btn
                    v-if="page.ask.replace"
                    size="small"
                    color="accent"
                    @click="clicked('replace')"
                    :loading="page.loading.replace">
                    Replace
                </v-btn>
                <v-btn
                    size="small"
                    color="accent"
                    :disabled="page.state != 'Suspended'"
                    :loading="page.loading.resume"
                    @click="clicked('resume')">
                    Resume
                </v-btn>
                <v-btn
                    size="small"
                    color="error"
                    :disabled="page.state != 'Active'"
                    :loading="page.loading.suspend"
                    @click="clicked('suspend')">
                    Suspend
                </v-btn>
                <v-btn
                    v-if="page.ask.replace"
                    size="small"
                    color="error"
                    @click="clicked('delete')"
                    :loading="page.loading.delete">
                    Delete
                </v-btn>
            </span>
        </div>
        <vu-confirm ref="confirm" />
    </vu-form>
</template>

<style lang="scss">
.token-edit {
    .v-btn.outlined {
        margin-right: 10px !important;
        border: 1px solid rgb(var(--v-theme-border-lighten-1)) !important;
        background: none !important;
        box-shadow: none !important;
    }
}
</style>
