<script setup>
import {onMounted, reactive, ref, watch} from 'vue'
import {State, can, getModels, navigate} from '@/paks/vu-app'
import {DashEdit} from '@/paks/vu-dash'
import ExportDash from './ExportDash.vue'
import ImportDash from './ImportDash.vue'

const Fields = [
    {name: 'edit', icon: '$edit', width: '5%'},
    {name: 'name'},
    {name: 'layout'},
    {name: 'snap'},
    {name: 'framed'},
    {name: 'full'},
]

const page = reactive({
    fields: Fields,
    helper: null,
    showDashEdit: false,
    showImport: false,
    showExport: false,
})

const table = ref(null)
const {Dashboard} = getModels()

watch(
    () => State.app.needs.dashboard,
    async (v) => {
        if (table.value) {
            await table.value.update()
        }
    }
)


onMounted(() => {
    page.select = {
        actions: {Add: 0, Edit: 1, Delete: 2, Export: 1, Import: 0},
        multi: true,
        property: 'select',
    }
})
async function getData() {
    return State.cache.dashboards
}

async function clicked({action, item, items, column, rec, row}) {
    if (action == 'add') {
        if (!can('admin')) {
            return
        }
        editDashboard({})
        await reload()
    } else if (action == 'import') {
        editImport({})
    } else if (action == 'export') {
        editExport(item)
    } else if (action == 'edit' || (action == 'cell' && column.name == 'edit')) {
        if (can('admin')) {
            editDashboard(item)
            await reload()
        }
    } else if (action == 'cell') {
        //  Select
        await Dashboard.set(item)
        State.app.setNeed('dash', 'reload')
        navigate('/')
    }
}

async function editDashboard(dashboard) {
    page.showDashEdit = dashboard ? true : false
    if (dashboard) {
        page.dashboard = Object.assign({}, dashboard)
    } else {
        page.dashboard = State.cache.dashboard
    }
    reload()
}

async function editImport(dashboard) {
    page.dashboard = dashboard
    page.showImport = dashboard ? true : false
}

async function editExport(dashboard) {
    page.dashboard = dashboard
    page.showExport = dashboard ? true : false
}

async function reload() {
    if (table.value) {
        await table.value.update()
    }
}
</script>

<template>
    <div class="dashboards page">
        <vu-help url="/doc/ui/dashboard/dashboard-list.html" />
        <vu-table
            :data="getData"
            :fields="page.fields"
            :select="page.select"
            :title="`${State.config.builder ? 'Dashboards' : ''}`"
            name="dashboards"
            nodata=" "
            options="dynamic,refilter,toolbar"
            ref="table"
            sort="name:asc"
            subtitle="Click to select a dashboard"
            width="100%"
            @click="clicked">
            <template v-slot:more="props">
                <v-btn v-if="can('admin')" color="accent" @click="editDashboard">Add</v-btn>
            </template>
        </vu-table>

        <vu-panel
            v-model="page.showDashEdit"
            :keyboard="false"
            :widths="['550px']"
            @close="editDashboard">
            <DashEdit :dashboard="page.dashboard" @input="editDashboard" />
        </vu-panel>
        <vu-panel
            v-model="page.showImport"
            :keyboard="false"
            :widths="['550px']"
            @close="editImport">
            <ImportDash @input="editImport" />
        </vu-panel>
        <vu-panel
            v-model="page.showExport"
            :keyboard="false"
            :widths="['550px']"
            @close="editExport">
            <ExportDash :dashboard="page.dashboard" @input="editExport" />
        </vu-panel>
    </div>
</template>
