<script setup>
import {onBeforeMount, reactive} from 'vue'
import {useTheme} from 'vuetify'
import {Auth, State, clone, navigate} from '@/paks/vu-app'
import {User} from '@/models'

const page = reactive({
    bright: State.app.bright || 'auto',
    reset: false,
    rules: [],
    saving: false,
    user: null,
})

const theme = useTheme()
const social = State.auth.social

onBeforeMount(() => {
    let user = page.user = clone(State.auth.user)
    user.preferences = user.preferences || {signposts: true}
})

async function save() {
    let user = page.user
    setMode()
    if (user.first && user.last) {
        await Auth.setUser(user.id, {
            given_name: user.first,
            family_name: user.last,
        })
        await User.update({
            id: user.id,
            first: user.first,
            last: user.last,
            email: user.email,
            preferences: user.preferences,
        })
    }
    State.auth.user.preferences.signposts = user.preferences.signposts
    if (page.reset) {
        State.app.resetNotices()
    }
    State.ref.chat.identify()
    navigate('/')
}

function setMode() {
    let mode = page.bright
    State.app.bright = mode
    if (mode == 'auto') {
        mode = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
    }
    State.app.dark = mode == 'dark'
    theme.global.name.value = mode
}
</script>

<template>
    <vu-form :data="page" :save="save" help="/doc/ui/account/profile.html" title="Personal Profile" class="page-form">
        <vu-input v-model="page.user.first" label="First Name" :disabled="social" type="text" />
        <vu-input v-model="page.user.last" label="Last Name" :disabled="social" type="text" />
        <vu-input v-model="page.user.email" label="Email" :disabled="true" type="text" />

        <vu-input v-model="page.user.preferences.signposts" label="Enable Guides" color="accent" type="switch" cols="3" />
        <vu-input
            v-if="page.user.preferences.signposts"
            v-model="page.reset"
            label="Re-Enable All"
            type="checkbox"
            cols="3" 
        />
        <vu-input 
            type="radio"
            v-model="page.bright"
            label="Dark Mode"
            :items="{Light: 'light', Dark: 'dark', Auto: 'auto'}" />
        <div class="actions">
            <v-btn size="small" color="accent" type="submit" :loading="page.saving">Save</v-btn>
        </div>
    </vu-form>
</template>