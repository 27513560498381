<script setup>
/*
    CssEdit
 */
import {onBeforeMount, onUnmounted, reactive, ref, watch} from 'vue'

const props = defineProps({
    modelValue: [Object],
    title: String,
})

const page = reactive({
    css: [],
    properties: [],
    select: null,
    showColors: {},
    rules: [],
    types: null,
})

const CssFields = [
    {name: 'delete', icon: '$close', width: '2%'},
    {name: 'name', editable: true, width: '30%'},
    {name: 'value', editable: true},
]

const emit = defineEmits(['update:modelValue'])

const table = ref(null)

watch(() => props.modelValue, () => {
    cssToProperties()
}, {deep: true})

onBeforeMount(() => {
    page.css = props.modelValue
    window.addEventListener('keydown', keyboard, true)
    cssToProperties()
})

onUnmounted(() => {
    window.removeEventListener('keydown', keyboard, true)
})

function cssToProperties() {
    let properties = []
    if (page.css) {
        for (let {name, value} of page.css) {
            properties.push({name, value})
        }
    }
    // page.properties = properties.sort((a, b) => a.name.localeCompare(b.name))
    page.properties = properties
}

function propertiesToCss() {
    let css = []
    if (page.properties) {
        for (let property of page.properties) {
            if (!property.name) continue
            css.push(property)
        }
    }
    page.css = css
    emit('update:modelValue', css)
}

async function editableEnterKey(event, props) {
    let {field, item, row} = props
    let value = event.target.innerText
    await blur(item, field, value, row)
    event.target.blur()
}

async function editableFocus(event, props) {}

async function editableBlur(event, props) {
    let {field, item, row} = props
    let value = event.target.innerText
    await blur(item, field, value, row)
}

async function blur(item, vfield, value, row = -1) {
    let {properties} = page
    delete item._select_
    if (vfield.name == 'name') {
        properties[row].name = value
    } else if (vfield.name == 'value') {
        properties[row].value = value
    }
    propertiesToCss()
}

async function addProperty() {
    page.properties.push({name: '', value: ''})
}

async function clicked({action, column, item, items, row}) {
    if (action == 'add') {
        page.properties.push({name: '', value: ''})
    } else if (action == 'delete') {
        for (let item of items) {
            let index = page.properties.findIndex((p) => p.name == item.name)
            page.properties.splice(index, 1)
            propertiesToCss()
        }
    } else if (column?.name == 'delete') {
        page.properties.splice(row, 1)
        propertiesToCss()
    } else if (action == 'cell') {
    }
}

function formatCss(item, vfield, value) {
    let fieldName = vfield.name
    vfield.attribute = fieldName
    if (fieldName == 'name' || fieldName == 'value') {
        vfield.editable = true
        vfield.css += ' editable'
    } else {
        vfield.editable = false
    }
    return (value || '').toString()
}

function colorPicked(props, value) {
    props.field.value = value
    let name = props.item.name
    page.css[props.row] = {name, value}
}

function keyboard(e) {
    page.showColors = {}
}

function colorChange() {
    page.showColors = {}
}
</script>

<template>
    <vu-table
        class="css-edit"
        name="css-list"
        options="dynamic,refilter,toolbar"
        ref="table"
        :title="title"
        width="100%"
        nodata="No Items"
        :reload="false"
        :callbacks="{format: formatCss}"
        :data="page.properties"
        :fields="CssFields"
        @click="clicked">
        <template v-slot:more="props">
            <v-btn size="small" color="accent" class="mr-2 add" @click="addProperty">Add</v-btn>
        </template>
        <template v-slot:cell="props">
            <div
                v-if="props.field.editable"
                :contenteditable="props.field.editable"
                spellcheck="false"
                class="cell-value"
                :id="props.field.id"
                @blur="editableBlur($event, props)"
                @focus="editableFocus($event, props)"
                @keydown.enter.prevent="editableEnterKey($event, props)">
                {{ props.field.value || ' ' }}
            </div>
            <div v-else class="cell-value" contenteditable="false">{{ props.field.value }}</div>
            <div v-if="props.field.name == 'value'" class="color-icon">
                <v-icon size="medium" @click="page.showColors[props.item.name] = true">
                    $edit
                </v-icon>
            </div>
            <v-dialog
                v-model="page.showColors[props.item.name]"
                width="500"
                content-class="color-picker"
                @afterLeave="colorChange">
                <v-color-picker
                    v-if="page.showColors[props.item.name]"
                    v-model="props.field.value"
                    @update:modelValue="colorPicked(props, $event)"
                    :hide-sliders="false"></v-color-picker>
            </v-dialog>
        </template>
    </vu-table>
</template>

<style lang="scss">
.css-edit {
    width: 100%;
    h2 {
        font-size: 1.4rem;
        font-weight: normal;
    }
    .v-alert {
        width: 100%;
    }
    label {
        color: rgb(var(--v-theme-text));
        font-weight: normal;
        font-size: 1.2rem;
    }
    .color-picker {
        margin: 6px 0 16px 0;
        border: solid 1px rgb(var(--v-theme-border));
        max-width: 100% !important;
        width: 100% !important;
    }
    td.editable {
        padding: 4px 7px 4px 7px;
        background: rgba(220, 255, 220, 0.4) !important;
        margin: 2px;
        cursor: text;
        &:focus {
            outline: 2px solid rgba(0, 0, 255, 0.5) !important;
            background: white !important;
            width: calc(100% - 4px);
        }
    }
    .table-cell {
        padding: 4px !important;
        .cell-attribute {
            padding: 3px;
            background: rgba(50, 50, 50, 0.1);
            color: black;
        }
    }
    .table-cell.table-col-name {
        .cell-value {
            display: inline-block;
            width: 100%;
            margin: 1px;
            padding: 3px;
        }
    }
    .table-cell.table-col-value {
        .cell-value {
            display: inline-block;
            width: calc(100% - 20px);
            margin: 1px;
            padding: 3px;
            padding-right: 0;
        }
    }
    .color-icon {
        margin-top: 2px;
        float: right;
        width: 16px;
    }
    .table-title {
        font-size: 1rem !important;
        padding-top: 8px;
    }
    .add {
        margin-top: 8px;
        font-size: 12px;
    }
}
</style>
