<script setup>
import {onMounted, reactive} from 'vue'

const Services = [
    {name: 'API', status: 'online'},
    {name: 'Authentication', status: 'online'},
    {name: 'Admin Service', status: 'online'},
    {name: 'Cloud Manager', status: 'online'},
    {name: 'Billing', status: 'online'},
]

const page = reactive({
    services: Services,
})

onMounted(() => {
    //TBD
})
</script>

<template>
    <v-container fluid class="help-status">
        <!--
        <div class="outage">
            <h2>Current AWS Outage in us-east-1</h2>
            <p>Some AWS CloudWatch logs are being impacted by the current AWS outage in us-east-1. You may experience delays in accessing logs that are congested in us-east-1. </p>
            <p>Amazon says expect delays of a few hours while they recover. </p>
            <p>Detailed <a href="https://status.aws.amazon.com">AWS service status</a> regarding the outage at: https://status.aws.amazon.com.</p>
        </div>
    -->
        <v-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">Service</th>
                        <th class="text-right">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="service in page.services" :key="service.name">
                        <td>{{ service.name }}</td>
                        <td class="text-right">{{ service.status }}</td>
                    </tr>
                </tbody>
            </template>
        </v-table>

        <v-row class="links">
            <v-col>
                <h2 class="mt-3">Links</h2>
                <div><a href="https://www.embedthis.com" target="_blank">Embedthis Web Site</a></div>
                <div><a href="https://www.embedthis.com/blog" target="_blank">Embedthis Blog</a></div>
                <div><a href="https://status.aws.amazon.com" target="_blank">AWS Service Status</a></div>
            </v-col>
        </v-row>
    </v-container>
</template>

<style lang="scss">
.help-status {
    padding: 20px;
    .outage {
        padding: 10px;
        font-size: 1rem;
        h2 {
            padding-bottom: 10px;
        }
    }
    th {
        font-size: 1.125rem !important;
    }
    td {
        font-size: 1rem !important;
    }
    .links {
        padding: 14px 0 0 14px;
        h2 {
            margin-bottom: 8px;
            color: rgb(var(--v-theme-text));
            font-size: 1rem !important;
            font-weight: bold;
        }
        a {
            color: rgb(var(--v-theme-text));
            text-decoration: none;
            font-size: 1rem !important;
        }
    }
}
</style>
