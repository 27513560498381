/*
    Ticket.js -
 */

import {Rest} from '@/paks/js-rest'

export default new Rest('Ticket', {
    fetch: { method: 'POST', uri: '/:controller/fetch' },
    updateWorked: { method: 'POST', uri: '/:controller/updateWorked' },
}, {cache: false, service: '/api'})
