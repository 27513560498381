<script setup>
import {ListWidget} from '@/paks/vu-widgets'
import {onMounted, reactive, ref, watch} from 'vue'
import {Chat, State, can, clone, navigate} from '@/paks/vu-app'

let account = clone(State.auth.account)

const page = reactive({
    agreement: account?.billing?.agreement,
    billing: account?.billing || {},
    hasCardError: account?.billing?.error ? true : false,
    hasPending: State.app.getBillTotal() && !can('support') ? true : false,
    mock: State.app.mock,
    outage: {enable: false, start: new Date(0), end: new Date(0)},
    suspended: State.app.suspended ? true : false,
    upgrade: false,
    updating: false,
})

defineExpose({hasNotice})

onMounted(async () => {
    let maintenance = State.config.maintenance
    if (maintenance && maintenance.enable) {
        if (
            true ||
            (new Date(maintenance.start) < Date.now() && Date.now() < new Date(maintenance.end))
        ) {
            page.outage = {
                description: 'EmbedThis Builder will be down for maintenance',
                start: new Date(maintenance.start).toLocaleString(),
                end: new Date(maintenance.end).toLocaleString(),
                enable: true,
            }
        }
    }
    watch(
        () => State.cache.lastUpdated,
        () => {
            updateNotices()
        },
        {immediate: true}
    )

    watch(
        () => State.app.needs,
        () => {
            ;(page.mock = State.app.mock), updateNotices()
        },
        {immediate: true}
    )
})

async function updateNotices() {
    let account = State.auth.account
    if (!account || page.updating || !State.auth.ready) {
        return
    }
    page.agreement = account.billing.agreement
    page.billing = account.billing
    page.updating = true
    page.hasCardError = account.billing.error ? true : false
    page.hasPending = State.app.getBillTotal() && !can('support') ? true : false

    let clouds = State.cache.clouds
    for (let cloud of clouds) {
        if (+cloud.version < State.app.versions.cloud) {
            page.upgrade = true
        }
    }
}

function hasNotice() {
    if (
        page.outage.enable ||
        page.suspended ||
        page.upgrade ||
        page.hasCardError ||
        page.hasPending
    ) {
        return true
    }
    return false
}

function chat() {
    Chat.open()
}
</script>

<template>
    <div class="notice">
        <vu-sign
            name="notice-maintenance"
            color="accent"
            title="Site Maintenance"
            :required="true"
            :show="page.outage.enable">
            <p>
                {{ page.outage.description }} from {{ page.outage.start }} until
                {{ page.outage.end }}.
            </p>
        </vu-sign>

        <vu-sign
            name="notice-billing"
            :show="page.suspended"
            title="Account Suspended"
            color="error"
            :required="true">
            <p>
                Your account has been suspended due to an outstanding billing issue. Please correct
                at
                <a @click="navigate('/account/billing')">Account Billing</a>
                or contact support for help.
            </p>
        </vu-sign>

        <vu-sign
            color="error"
            name="notice-card"
            title="Billing Error"
            :required="true"
            :show="page.hasCardError && !page.suspended"
            :subtitle="page.billing.error">
            <p>
                Please correct your card error at
                <a @click="navigate('/account/billing')">Account Billing</a>
                .
            </p>
            {{ page.billing.error }}
        </vu-sign>

        <vu-sign
            name="notice-upgrade"
            :show="page.upgrade"
            title="Upgrade Required"
            color="error"
            :required="true">
            <p>You need to upgrade your device clouds.</p>
            <p>
                Please
                <b>re-save your device clouds</b>
                to update the IAM roles to support new functionality.
            </p>
            <p>
                <v-btn size="small" color="primary" @click="navigate('/clouds')">Cloud List</v-btn>
            </p>
        </vu-sign>

        <vu-sign
            color="error"
            name="notice-pending"
            title="Pending Subscriptions"
            :required="true"
            :show="!page.hasCardError && page.hasPending && !page.mock">
            <p class="review">
                <a @click="navigate('/account/subscription')">Review Subscription</a>
                <span v-if="!page.billing.cardId && !page.agreement">
                    and enter a
                    <a @click="navigate('/account/billing?add-card')">Billing Card.</a>
                </span>
            </p>
        </vu-sign>
    </div>
</template>

<style lang="scss">
.notice {
    .vu-sign {
        margin: 32px 16px 16px 48px;
    }
    i.close {
        margin-top: 0px !important;
    }
    .review {
        font-size: 1.2rem;
        margin-bottom: 12px;
    }
}
a {
    text-decoration: none;
    cursor: pointer;
}
</style>
