<script setup>
import {onMounted, reactive, ref} from 'vue'
import {Feedback, State, clone, deny, titlecase} from '@/paks/vu-app'
import WidgetData from './WidgetData.vue'
import WidgetActions from './WidgetActions.vue'
import WidgetBasic from './WidgetBasic.vue'
import WidgetPresentation from './WidgetPresentation.vue'
import UUID from '@/paks/js-uuid'
import Json5 from 'json5'

const props = defineProps({
    id: String,
    dashboard: Object,
    modelValue: Object,
})

/*
    const model = defineModel()
    model.value...
*/

const page = reactive({
    applying: false,
    clouds: State.cache.clouds.sort(sortByName),
    deleting: false,
    fields: null,
    hasActions: false,
    ready: false,
    rules: [],
    saving: false,
    tab: null,
    widget: {
        cloudId: undefined,
        dimensions: null,
        field: null,
        fields: null,
        namespace: null,
        metric: null,
        model: null,
        statistic: null,
        type: null,
    },
})

const confirm = ref(null)
const form = ref(null)

const emit = defineEmits(['apply', 'input', 'remove', 'update:modelValue'])
defineExpose({page})

onMounted(async () => {
    let clouds = clone(State.cache.clouds)

    if (State.config.builder) {
        clouds.push({id: 'builder', name: 'Builder', type: 'service'})
    }
    if (State.config.features.dash?.actions) {
        page.hasActions = true
    }
    clouds.forEach((c) => (c.name = `${titlecase(c.type)}: ${c.name}`))
    page.clouds = clouds.sort(sortByName)
    let widget = clone(props.modelValue)
    page.widget = widget
    page.ready = true
})

function save() {
    apply()
    emit('input')
}

function copy() {
    let widget = clone(page.widget)
    delete widget.id
    widget = makeWidget(widget)
    emit('apply', widget)
    emit('input')
}

function apply() {
    let widget = page.widget
    page.applying = true
    if (widget.type == 'set') {
        let name = 'Default'
        let cloudId = widget.cloudId
        let set = State.app.widgetSets.find((s) => s.name == name)
        for (let [key, widget] of Object.entries(set.widgets)) {
            if (widget.enable === false) continue
            widget = clone(widget)
            let cloud = State.get('Cloud', cloudId)
            if (cloud) {
                widget.title = `${cloud.name} ${widget.title || widget.name || ''}`
                widget.cloudId = cloudId
            }
            widget = makeWidget(widget)
            emit('apply', widget)
            emit('update:modelValue', widget)
        }
        Feedback.info(`Widget Set Created`)
    } else {
        widget = makeWidget(widget)
        emit('apply', widget)
        emit('update:modelValue', widget)
    }
    page.widget = widget
    page.applying = false
}

function makeWidget(widget) {
    if (widget._new && !widget.title && !widget.header) {
        delete widget._new
        if (widget.metric) {
            widget.header = `${widget.namespace} ${widget.metric}`
        } else if (widget.model) {
            widget.header = `${widget.model} ${widget.field || ''}`
        }
    }
    //  These fixes are needed for Hub stored dashboards
    if (!widget.namespace || widget.namespace == 'None') {
        widget.namespace = widget.metric = widget.dimensions = widget.statistic = null
    }
    //  TODO - refactor - should have a single field to test (noinput)
    if (widget.type == 'image' || widget.type == 'audio') {
        widget = deny(widget, [
            'dimensions',
            'fields',
            'metric',
            'model',
            'range',
            'namespace',
            'statistic',
            'value',
        ])
        if (widget.url) {
            delete widget.text
        }
    } else {
        if (widget.type != 'text' && widget.type != 'button') {
            delete widget.text
        }
        if (widget.type == 'toolbar' && !widget.id) {
            widget.framed = false
        }
        delete widget.url
        if (widget.type != 'input') {
            delete widget.input
        }
    }
    if (!widget.id) {
        widget.id = UUID()
    }
    if (widget.min != null) {
        widget.min = parseInt(widget.min) || 0
    }
    if (widget.max != null) {
        widget.max = parseInt(widget.max) || 0
    }
    if (widget.fields) {
        try {
            widget.fields = Json5.parse(widget.fields)
        } catch (err) {
            widget.fields = widget.fields.toString().split(',').map(i => i.trim())
        }
    }
    if (widget.action && widget.action.conditions) {
        if (widget.action.conditions.length == 0 || !widget.action.conditions[0]?.expression) {
            delete widget.action.conditions
        }
    }
    return widget
}

async function deleteWidget() {
    if (!page.widget.id) {
        return
    }
    if (!(await confirm.value.ask(`Do you want to delete the widget?`))) {
        return
    }
    page.deleting = true
    Feedback.info('widget Deleted')
    page.deleting = false
    emit('remove', page.widget)
    emit('input')
}

function sortByName(a, b) {
    if (a.name < b.name) {
        return -1
    } else if (b.name < a.name) {
        return 1
    }
    return 0
}
</script>

<template>
    <vu-form
        ref="form"
        class="widget-edit"
        help="/doc/ui/dashboard/widget-edit.html"
        :data="page"
        :save="save"
        :title="`${page.widget.id ? 'Modify' : 'Add'} widget`">
        <v-container fluid class="pa-0">
            <v-tabs v-model="page.tab" ref="tabs" class="widget-tabs mb-5" fixed-tabs>
                <v-tab value="basic">Basic</v-tab>
                <v-tab value="presentation">Presentation</v-tab>
                <v-tab value="data">Data</v-tab>
                <v-tab value="actions" v-if="page.hasActions">Actions</v-tab>
            </v-tabs>

            <v-window v-if="page.ready" v-model="page.tab" class="pt-4">
                <v-window-item value="basic">
                    <WidgetBasic :widget="page.widget" @apply="apply" />
                </v-window-item>

                <v-window-item value="presentation">
                    <WidgetPresentation :widget="page.widget" @apply="apply" />
                </v-window-item>

                <v-window-item value="data">
                    <WidgetData :widget="page.widget" />
                </v-window-item>

                <v-window-item value="actions" v-if="page.hasActions">
                    <WidgetActions :widget="page.widget" />
                </v-window-item>
            </v-window>
        </v-container>

        <div class="actions">
            <v-btn size="small" color="accent" type="submit" :loading="page.saving">Save</v-btn>
            <v-btn size="small" color="accent" @click="apply" :loading="page.applying">Apply</v-btn>
            <v-btn size="small" color="teal" @click="copy">Copy</v-btn>
            <v-btn size="small" color="none" @click="emit('input', null)">Cancel</v-btn>
            <v-btn
                size="small"
                color="error"
                v-if="page.widget.id"
                @click="deleteWidget"
                :loading="page.deleting">
                Delete
            </v-btn>
        </div>
        <vu-confirm ref="confirm" />
    </vu-form>
</template>

<style lang="scss">
.widget-edit {
    h2 {
        font-size: 1.4rem;
        font-weight: normal;
    }
    .v-alert {
        width: 100%;
    }
    .widget-tabs {
        background: rgb(var(--v-theme-background));
        border: solid 1px rgb(var(--v-theme-border-lighten-1));
        .v-tab--selected {
            background: rgb(var(--v-theme-accent));
            color: white;
        }
        .v-btn {
            padding: 0 8px;
        }
    }
    @media (max-width: 640px) {
        font-size: 12px;
    }
}
</style>
