/*
    Card.js - Card model
 */

import {Rest} from '@/paks/js-rest'

export default new Rest('Card', {
    getStripe: { method: 'POST', uri: '/:controller/getStripe' },
    updateCurrent: { method: 'POST', uri: '/:controller/getStripe' },
}, {cache: false, service: '/api'})
