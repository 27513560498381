/*
    Account.js - Account model
 */

import {Rest} from '@/paks/vu-app'

export default new Rest('Account', {
    close:          { method: 'POST', uri: '/:controller/close' },
    credit:         { method: 'POST', uri: '/:controller/credit' },
    getID:          { method: 'POST', uri: '/:controller/getID' },
    make:           { method: 'POST', uri: '/:controller/make' },
    suspend:        { method: 'POST', uri: '/:controller/suspend' },
    updateBilling:  { method: 'POST', uri: '/:controller/updateBilling' },
    updateIdentity: { method: 'POST', uri: '/:controller/updateIdentity' },
    updateMetrics:  { method: 'POST', uri: '/:controller/updateMetrics' },
    updatePayment:  { method: 'POST', uri: '/:controller/updatePayment' },
}, {service: '/api'})
