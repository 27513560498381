<script setup>
//  https://www.embedthis.com/images/logos/embedthis-logo-big.avif

import {onMounted, reactive, ref, watch} from 'vue'

const props = defineProps({widget: Object})

const page = reactive({
    audio: null,
    duration: 1,
    playing: false,
})

defineExpose({update})

const self = ref(null)

onMounted(() => {
    page.audio = new Audio(props.widget.url)
    // page.audio.muted = true
})

function update() {
    let el = self.value.$el
    page.duration = el.style['duration'] || 10

    if (props.widget.value) {
        page.audio.play()
        page.playing = true
        setTimeout(() => {
            if (page.playing) {
                page.pause()
                page.playing = false
            }
        }, page.duration * 1000)

    } else {
        if (page.playing) {
            page.audio.pause()
            page.playing = false
        }
    }
}
</script>

<template>
    <vu-div ref="self" class="audio-widget" />
</template>

<style lang="scss">
.audio-widget {
    height: 100%;
    width: 100%;
}
</style>
