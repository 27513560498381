<script>
/*
    Give user feedback
    WARNING: do not import vu-app
 */

import {State} from '@/paks/vu-state'

class FeedbackClass {
    page = reactive({
        active: null,
        dialog: null,
        message: null,
        severity: null,
        severityStyle: null,
    })

    constructor() {
        this.page.dialog = true
    }

    away() {
        this.clear()
        return true
    }

    clear() {
        let page = this.page
        page.message = ''
        page.severity = 'info'
        page.severityStyle = ''
        page.active = false
    }

    error(msg, context) {
        this.init('error', msg)
    }

    init(sev, msg) {
        setTimeout(() => {
            let page = this.page
            page.severity = sev
            page.severityStyle = `feedback-common feedback-${sev}`
            page.message = msg
            if (sev == 'error' || true) {
                page.active = true
            }
        }, 1)
    }

    info(msg) {
        this.init('info', msg)
    }

    response(response, message) {
        if (response) {
            if (typeof message == 'object' && message.message) {
                message = message.message
            }
            this.init(response.status == 200 ? 'info' : 'error', message)
        }
    }

    warn(msg) {
        this.init('warn', msg)
    }
}
const Feedback = new FeedbackClass()
export {Feedback}
</script>

<script setup>
import {onMounted, onUnmounted, reactive} from 'vue'

const page = Feedback.page

onMounted(() => {
    document.body.addEventListener('click', Feedback.away.bind(Feedback))
})

onUnmounted(() => {
    document.body.removeEventListener('click', Feedback.away.bind(Feedback))
})
</script>

<template>
    <div class="feedback">
        <v-dialog v-if="page.dialog" v-model="page.active" location="bottom"
            content-class="feedback-dialog" :__opacity="0" :scrim="false">
            <v-alert class="vrow" :type="page.severity">{{ page.message }}</v-alert>
        </v-dialog>
        <div v-else class="feedback-nav">
            <span :class="page.severityStyle">{{ page.message }}</span>
        </div>
    </div>
</template>

<style lang="scss">
.feedback-dialog {
    position: absolute;
    bottom: 0;
    width: calc(100vw - 60px) !important;
}
.feedback-nav {
    display: block;
    flex-grow: 4;
    span {
        top: 0;
        height: 48px;
        padding: 12px 3px 3px 3px;
        font-weight: bold;
        font-size: 1.2em;
        text-align: center;
        z-index: 200;
    }
    .feedback-common {
        width: 100%;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .feedback-error {
        background: #f55a4e;
        color: rgba(255, 255, 255, 0.85);
        background-color: #f55a4e;
        background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #f8877e), color-stop(1, #f22d1e));
        background: -ms-linear-gradient(bottom, #f8877e, #f22d1e);
        background: -moz-linear-gradient(center bottom, #f8877e 0%, #f22d1e 100%);
        background: -o-linear-gradient(#f22d1e, #f8877e);
        z-index: 200;
        top: -100px;
    }
    .feedback-error:empty {
        display: none;
    }
    .feedback-warn {
        background: #fbc02d;
        color: rgba(255, 255, 255, 0.85);
        font-size: 1.2em;
        top: -100px;
    }
    .feedback-warn:empty {
        display: none;
    }
    //  THEME
    .feedback-info {
        background: #3f51b5;
        color: rgba(255, 255, 255, 0.85);
        top: -100px;
    }
    .feedback-info:empty {
        display: none;
    }
}
/*
@-moz-keyframes feedback-transition {
    0% {
        opacity: 1;
        top: 0px;
    }
    80% {
        opacity: 1;
        top: 0px;
    }
    90% {
        opacity: 0.5;
        top: 0px;
    }
    100% {
        opacity: 0;
        top: -100px;
    }
}
@-webkit-keyframes feedback-transition {
    0% {
        opacity: 1;
        top: 0px;
    }
    80% {
        opacity: 1;
        top: 0px;
    }
    90% {
        opacity: 0.5;
        top: 0px;
    }
    100% {
        opacity: 0;
        top: -100px;
    }
}
@-o-keyframes feedback-transition {
    0% {
        opacity: 1;
        top: 0px;
    }
    80% {
        opacity: 1;
        top: 0px;
    }
    90% {
        opacity: 0.5;
        top: 0px;
    }
    100% {
        opacity: 0;
        top: -100px;
    }
}
@keyframes feedback-transition {
    0% {
        opacity: 1;
        top: 0px;
    }
    80% {
        opacity: 1;
        top: 0px;
    }
    90% {
        opacity: 0.5;
        top: 0px;
    }
    100% {
        opacity: 0;
        top: -100px;
    }
}
*/
</style>
