<script setup>
import {onMounted, reactive, ref} from 'vue'
import {Feedback, State, can, clone, navigate} from '@/paks/vu-app'
import Dates from '@/paks/js-dates'
import {Metric, Software} from '@/models'
import SoftwareEdit from './SoftwareEdit.vue'

const page = reactive({
    canAdd: false,
    clouds: [],
    fields: [
        {name: 'edit', icon: (v, rec) => (rec.id ? '$edit' : '')},
        {name: 'product.name', title: 'Product'},
        {name: 'version', format: (v) => (v ? v : 0)},
        {name: 'cloud'},
        {name: 'devices', align: 'right', format: (v) => (v ? v : 0)},
        {name: 'enable', title: 'Enabled', icon: 'tick'},
        {
            name: 'created',
            title: 'Published',
            format: (v) => v ? Dates.format(v, 'mmm dd yyyy, HH:MM:ss') : '',
        },
    ],
    item: {},
    select: null,
    showEdit: false,
    software: [],
})

//  Component Refs
const table = ref(null)
const confirm = ref(null)

onMounted(async () => {
    let clouds = State.cache.clouds.filter((c) => c.type == 'hosted' || c.type == 'dedicated')
    page.clouds = clouds = clouds.filter(c => c.id != State.config.evalCloud)
    page.canAdd =
        can('admin') &&
        State.cache.products.length &&
        clouds.length > 0 &&
        !State.app.suspended
})

async function getData(args) {
    page.software = clone(State.cache.software)
    let items = []
    await addBaseVersions()
    for (let s of page.software) {
        s.product = State.get('Product', s.productId)
        s.namever = `${s.product.name}-${s.version}`
        s.updated = 0
        s.outstanding = 0
        let cloud = State.get('Cloud', s.cloudId)
        if (cloud) {
            s.cloud = `${cloud.name} ${cloud.region}`
        } else {
            cloud = {}
        }
        s.created = s.created || (s.id ? Date.now() : 0)
        items.push(
            {
                metric: 'UpdateDevices',
                dimensions: {Product: s.product.name, Version: s.version},
                cloudId: cloud.id,
                statistic: 'sum',
            },
            {
                metric: 'UpdateSuccess',
                dimensions: {Product: s.product.name, Version: s.version},
                cloudId: cloud.id,
                statistic: 'sum',
            }
        )
    }
    if (can('admin')) {
        page.select = {actions: {Add: 0, Edit: 1, Delete: 2}, multi: true, property: 'select'}
    } else {
        page.fields = page.fields.filter((f) => f.name != 'edit')
    }
    items.forEach((i) => {
        i.namespace = 'Embedthis/Device'
        i.accumulate = true
        i.period = 28 * 86400
    })
    let metrics = await Metric.fetch({items})
    let i = 0
    for (let s of page.software) {
        s.devices = metrics[i++][0]?.points[0]?.value || 0
        s.updated = metrics[i++][0]?.points[0]?.value || 0
        /*
            Updated is the number of successful attempts. If device version is not updated,
            then the update may happen again.
         */
        if (s.devices < s.updated) {
            s.updated = s.devices
        }
        s.outstanding = s.devices - s.updated
    }
    // await delay(250)
    return page.software
}

/*
    Add base version of software seen but without updates defined to create this version
 */
async function addBaseVersions() {
    page.software = clone(State.cache.software)
    let metrics = {}
    for (let s of page.software) {
        if (!s.cloudId) continue
        let index = `${s.cloudId}-${s.productId}`
        if (metrics[index]) continue
        let list = await Metric.getMetricList({
            namespace: 'Embedthis/Device',
            metric: 'UpdateDevices',
            cloudId: s.cloudId,
        })
        for (let dim of list.dimensions) {
            if (dim.Product && dim.Version) {
                let product = State.cache.products.find((p) => p.name == dim.Product)
                if (!page.software.find((s) => s.version == dim.Version && s.productId == product?.id)) {
                    page.software.push({
                        productId: s.productId,
                        version: dim.Version,
                        cloudId: s.cloudId,
                    })
                    metrics[index] = list
                }
            }
        }
    }
}

async function deleteSoftware(items) {
    if (!(await confirm.value.ask(`Do you want to delete the selected update?`))) {
        return
    }
    for (let item of items) {
        await Software.remove({id: item.id})
    }
    Feedback.info('Update Deleted')
}

async function clicked({action, item, items}) {
    if (action == 'add') {
        editSoftware({})
    } else if (action == 'delete') {
        await deleteSoftware(items)
    } else if (action == 'cell') {
        editSoftware(item)
    }
}

async function editSoftware(item) {
    page.showEdit = item ? true : false
    page.item = item || {}
    if (!item && table.value) {
        await table.value.update()
    }
}
</script>

<template>
    <div class="page software-list">
        <vu-help url="/doc/ui/software/list.html" v-if="!page.showEdit" />

        <vu-sign name="software-list-sign-1" title="Device Update Management" subtitle="" color="accent">
            <p>You can update device software using over-the-air software updates and 
            target any cohort of your device base using distribution policies.</p>
            
            <p>Any type of device agent can utilize the Builder's update service.</p>
            <v-alert v-if="!page.canAdd" type="error" class="mb-4">You need to have a non-eval device cloud to be able to create and manage software updates.
                <a @click="navigate('/clouds', {add:true})" class="addCloud">Click to add a Device Cloud.</a>
            </v-alert>

            <p>Click Add Update, to add your first deployment package.</p>
            <p>Learn more at <a href="https://www.embedthis.com/blog/builder/software-update.html" target="_blank">Seamless Updates Blog Post</a>.</p>
        </vu-sign>

        <vu-table
            name="software"
            options="dynamic,filter,refilter,toolbar"
            nodata="No Updates"
            ref="table"
            sort="namever:asc"
            :subtitle="page.clouds.length == 0 ? 'Must first create a device cloud' : null"
            title="Updates"
            width="100%"
            :data="getData"
            :fields="page.fields"
            :pageSize="25"
            :select="page.select"
            @click="clicked">
            <template v-slot:more="props">
                <v-btn v-if="page.canAdd" color="accent" @click="editSoftware({})">
                    Add Update
                </v-btn>
            </template>
        </vu-table>

        <vu-panel v-model="page.showEdit" @close="editSoftware" :widths="['700px']">
            <SoftwareEdit :id="page.item.id" @input="editSoftware" />
        </vu-panel>

        <vu-confirm ref="confirm" />
    </div>
</template>

<style lang="scss">
.software-list {
    .v-input--switch {
        .v-input__slot {
            width: auto;
        }
    }
    .action {
        margin-right: 10px !important;
        border: 1px solid rgb(var(--v-theme-border-lighten-1));
        background: none !important;
        box-shadow: none !important;
    }
    .addCloud {
        color: white;
        font-weight: bold;
    }
}
</style>
