/*
    ExactLayout.js -- Exact positioning

    - Responsive causes widgets to overlap
        Should all width and heights be %
        - Need to be able to select multiple widgets (box and move)
        - Need to be able to align (top, left, bottom, right), middle center
 */
const GridSize = 20
const WidgetMargin = GridSize

export default class Layout {
    rendering = false

    constructor(dash) {
        this.dash = dash
    }

    run(widgets, params = {reset: false}) {
        if (widgets.length == 0) return

        if (this.rendering) return
        this.rendering = true

        let vh = this.dash.$el.clientHeight
        let vw = this.dash.$el.clientWidth
        let x = 0,
            y = 0,
            z = 0,
            rowHeight = 0,
            type = null

        widgets = this.sortWidgets(widgets, params.reset ? 'type' : 'xy')

        for (let widget of widgets) {
            let el = document.getElementById(widget.id)
            if (!el) continue
            if (widget.proposed) {
                widget.left = widget.proposed._left
                widget.top = widget.proposed._top
            }
            let {top, left, height, width} = widget

            top = +(top || 0)
            height = +(height || 0)

            if (width) {
                if (width == 'rest') {
                    width = vw - x - 20
                } else if (width.toString().indexOf('%') >= 0) {
                    width = Math.max((vw * parseInt(width)) / 100, 100)
                } else {
                    width = +width
                    if (width <= 1) {
                        width = width * vw
                    }
                }
            } else {
                height = this.align(el.offsetHeight)
                width = el.offsetWidth
            }
            if (left) {
                if (left.toString().indexOf('%') >= 0) {
                    left = Math.max((vw * parseInt(left)) / 100, 100)
                } else {
                    left = +left
                    if (left <= 1) {
                        left = left * vw
                    }
                }
            } else {
                left = 0
            }
            let anchor = widget.anchor
            if (anchor) {
                let {horizontalOffset, verticalOffset} = anchor
                //REPAIR
                if (typeof horizontalOffset == 'number') {
                    horizontalOffset = horizontalOffset + '' 
                }
                let voff =
                    verticalOffset?.indexOf('%') >= 0
                        ? Math.max((vh * parseInt(verticalOffset)) / 100, 100)
                        : +(verticalOffset || 0)
                let hoff =
                    horizontalOffset?.indexOf('%') >= 0
                        ? Math.max((vh * parseInt(horizontalOffset)) / 100, 100)
                        : +(horizontalOffset || 0)
                if (anchor.vertical == 'Top') {
                    top = voff
                } else if (anchor.vertical == 'Middle') {
                    top = (vh - height) / 2 - voff
                } else if (anchor.vertical == 'Bottom') {
                    top = vh - height - voff
                }
                if (anchor.horizontal == 'Right') {
                    left = vw - width - hoff
                } else if (anchor.horizontal == 'Center') {
                    left = (vw - width) / 2 - hoff
                } else if (anchor.horizontal == 'Left') {
                    left = hoff
                }
            }
            //  Convert back to percentages
            width = width / vw
            left = left / vw

            if (params.reset || widget.left == null) {
                //  Place widget
                let w = width <= 1 ? el.offsetWidth : width
                if (x > 0 && x + w > vw) {
                    //  New row
                    top = this.align(y + rowHeight)
                    x = 0
                    rowHeight = 0
                } else {
                    top = y
                    left = x
                }
                left = x / vw
            } else {
                if (top >= y + rowHeight && left <= x) {
                    //  Detect the start of a new row
                    rowHeight = 0
                    x = el.offsetLeft
                }
            }
            if (
                widget.top != top ||
                widget.left != left ||
                widget.height != height ||
                widget.width != width
            ) {
                widget.top = top
                widget.left = left
                widget.height = height
                widget.width = width
                widget.reposition = null
            }
            let style = el.style
            style.top = top + 'px'
            if (left > 1) {
                left = left / vw
            }
            if (width > 1) {
                width = width / vw
            }
            style.left = left * 100 + '%'
            widget.left = left
            if (widget.type == 'toolbar') {
                style.width = (width * vw) + 'px'
                widget.width = width
            } else {
                style.width = width * 100 + '%'
                widget.width = width
            }
            style.height = height + 'px'
            style.zIndex = widget.z ? widget.z : z++

            y = top
            let {offsetHeight, offsetWidth} = el
            if (offsetHeight == 0) {
                offsetHeight = +widget.height
            }
            if (offsetWidth == 0) {
                offsetWidth = +widget.width * vw
            }
            x += this.align(offsetWidth + WidgetMargin)
            rowHeight = Math.max(rowHeight, offsetHeight + WidgetMargin)
            type = widget.type
        }
        this.rendering = false
    }

    sortWidgets(widgets, field) {
        let typeOrder = ['graph', 'gauge', 'alert', 'lambda', 'log']
        return widgets.sort((a, b) => {
            if (field == 'type') {
                a = typeOrder.indexOf(a[field])
                b = typeOrder.indexOf(b[field])
                if (a < b) {
                    return -1
                } else if (a > b) {
                    return 1
                }
            } else if (field == 'xy') {
                if (a.reposition) {
                    if (b.reposition) {
                        return 0
                    }
                    return 1
                } else if (b.reposition) {
                    return -1
                }
                if (a.top == null) {
                    if (b.top == null) {
                        return 0
                    }
                    return 1
                } else if (b.top == null) {
                    return -1
                } else if (a.top < b.top) {
                    return -1
                } else if (a.top > b.top) {
                    return 1
                } else if (a.left < b.left) {
                    return -1
                } else if (a.left > b.left) {
                    return 1
                }
            } else {
                if (a[field] < b[field]) {
                    return -1
                } else if (a[field] > b[field]) {
                    return 1
                }
            }
            return 0
        })
    }

    align(v) {
        return Math.round(v / GridSize) * GridSize
    }
}
