/*
    App.js -- Application global singleton
 */

import {debug} from '@/paks/js-polyfill'
import Chat from '@/paks/js-chat'
import {Rest} from '@/paks/js-rest'
import Net from '@/paks/js-net'
import Storage from '@/paks/js-storage'
import {Log} from '@/paks/js-log'
import {Store, State} from '@/paks/vu-state'

import Routes from '@/paks/vu-routes'

import Calendar from 'v-calendar'
import VuCard from '@/paks/vu-card'
import VuClip from '@/paks/vu-clip'
import VuConfirm from '@/paks/vu-confirm'
import VuDate from '@/paks/vu-date'
import VuDiv from '@/paks/vu-div'
import VuDrag from '@/paks/vu-drag'
import VuFeedback from '@/paks/vu-feedback'
import VuForm from '@/paks/vu-form'
import VuHelp from '@/paks/vu-nav/Help.vue'
import VuInput from '@/paks/vu-input'
import VuInputGroup from '@/paks/vu-input-group'
import VuPanel from '@/paks/vu-panel'
import VuPick from '@/paks/vu-pick'
import VuProgress from '@/paks/vu-progress'
import VuSign from '@/paks/vu-sign'
import VuTable from '@/paks/vu-table'
import VuTabs from '@/paks/vu-tabs'
import VuTip from '@/paks/vu-tip'
import VuValidate from '@/paks/vu-validate'

import {Dash} from '@/paks/vu-dash'
import {Auth, AuthState} from '@/paks/vu-auth'
import {SidebarState} from '@/paks/vu-nav'
import AppState from '@/state/AppState.js'
import CacheState from '@/state/CacheState.js'
import ConfigState from './ConfigState.js'
import RefState from './RefState.js'

import 'v-calendar/style.css'

class AppClass {
    chat = new Chat(this)
    config = {}
    log = Log
    params = {}
    routes = new Routes(this)
    started = new Date()
    storage = new Storage(this)

    async create(params, config) {
        let {cognito, models, router, vue, vuetify} = params

        if (!vue || !router) {
            throw new Error('Missing required vue and router arguments')
        }
        this.vue = vue
        this.router = router
        this.vuetify = vuetify
        Object.assign(this.config, config)
        Object.assign(this.params, params)

        this.log.info(
            `Starting ${config.title} ${config.version || '1.0'}.${config.build || 0} built for "${config.profile}"`
        )

        this.storage.init({prefix: config.name})
        await Store.init({persist: `${config.name}-state`, lazy: true, config})
        
        Store.add('app', new AppState())
        Store.add('auth', new AuthState())
        Store.add('cache', new CacheState())
        Store.add('config', new ConfigState())
        Store.add('ref', new RefState(), {reactive: false})
        Store.add('sidebar', new SidebarState())

        State.ref.vue = vue
        State.ref.router = router
        State.ref.vuetify = vuetify
        State.ref.params = params
        State.ref.widgets = params.widgets
        State.ref.chat = params.chat
        State.ref.models = params.models

        this.setConfig(config, params)
        Net.setConfig(config)
        Rest.setConfig(config)

        let {Account, User} = models
        Auth.init(this, config.cognito, {
            account: Account,
            user: User,
            cognito: cognito,
        })
        Auth.parseUri()

        this.routes.init(router, params.components)

        vue.use(VuDrag)
        vue.use(Calendar, {componentPrefix: 'vu'})
        vue.component('vu-date', VuDate)
        vue.component('vu-card', VuCard)
        vue.component('vu-clip', VuClip)
        vue.component('vu-confirm', VuConfirm)
        vue.component('vu-div', VuDiv)
        vue.component('vu-feedback', VuFeedback)
        vue.component('vu-form', VuForm)
        vue.component('vu-help', VuHelp)
        vue.component('vu-input', VuInput)
        vue.component('vu-input-group', VuInputGroup)
        vue.component('vu-panel', VuPanel)
        vue.component('vu-pick', VuPick)
        vue.component('vu-progress', VuProgress)
        vue.component('vu-table', VuTable)
        vue.component('vu-tabs', VuTabs)
        vue.component('vu-validate', VuValidate)
        vue.component('vu-sign', VuSign)
        vue.directive('tip', VuTip)

        vue.component('dash', Dash)

        //  LEGACY 'dashboard'
        vue.component('dashboard', Dash)

        if (params.schema) {
            //  Set default schema. This may be replaced on login
            State.app.setSchema(params.schema)
        }
        if (params.components) {
            for (let [name, component] of Object.entries(params.components)) {
                vue.component(name, component)
            }
        }
        if (params.cognito) {
            State.app.setCognito(params.cognito)
        }
        if (params.display) {
            if (this.config.title) {
                params.display.title = this.config.title
            }
            await State.app.setDisplay(params.display)
        }
        if (params.chat) {
            this.chat.provide(params.chat)
            this.chat.init(this.config)
        }
        if (params.widgets) {
            this.widgets = params.widgets
            for (let component of Object.values(params.widgets)) {
                vue.component(component.__name, component)
            }
        }
        await this.routes.addRoutes(params.display)
    }

    /* private */
    setConfig(config, params) {
        if (!config) return
        Object.assign(State.config, config)
        if (config.profile != 'prod' || window.location.hostname == 'localhost') {
            debug(config.profile)
        }
        if (config.logo) {
            State.app.setLogo(config.logo)
        } else {
            State.app.setLogo('/images/logo.png')
        }
        if (config.title) {
            State.app.setTitle(config.title)
        }
        if (params.display?.features) {
            State.app.setFeatures(params.display.features)
        }
        if (config.features) {
            State.app.setFeatures(config.features)
        }
        if (config.api) {
            State.app.setApi(config.api)
        }
        State.app.local = config.cognito ? false : true
    }
}

const App = new AppClass()

export {App}

export async function initApp(params, config) {
    await App.create(params, config)
    return App
}