/*
    Metric.js - Usage metrics model
 */

import {Rest} from '@/paks/js-rest'

export default new Rest(
    'Metric',
    {
        fetch: {method: 'POST', uri: '/:controller/fetch'},
        getMetricList: {method: 'POST', uri: '/:controller/getMetricList'},
        getDeviceSchema: {method: 'POST', uri: '/:controller/getDeviceSchema'},
    },
    {service: '/api'}
)
