<script setup>
import {reactive} from 'vue'
import {Feedback} from '@/paks/vu-app'
import {Account} from '@/models'

const Agents = [
    {text: 'Ioto Device Agent', value: 'Ioto'},
    {text: 'Appweb Web Server', value: 'Appweb'},
    {text: 'GoAhead Web Server', value: 'GoAhead'},
]

const Scopes = [
    {text: 'Evaluation', value: 'eval'},
    {text: 'Volume Limited 10', value: 'units-10'},
    {text: 'Volume Limited 100', value: 'units-100'},
    {text: 'Volume Limited 500', value: 'units-500'},
    {text: 'Single Product', value: 'product'},
    {text: 'Family of Products', value: 'family'},
    {text: 'Business Line -- Multiple Product Families', value: 'business'},
]

// const props = defineProps({arg: String})
const emit = defineEmits(['input'])

const page = reactive({
    account: {},
    basic: {units: 1},
    billing: {
        agreement: true,
        canWire: true,
        period: 'year',
        renew: null,
        terms: 30,
        wire: true,
    },
    developer: {units: 10},
    product: {
        name: 'TBD',
        description: 'TBD',
        url: 'TBD',
    },
    saving: false,
    user: {},
})

defineExpose({account: page.account})

async function preSave() {
    let {account, billing, product, user} = page
    if (!account.name) {
        Feedback.error('Missing account name')
        return false
    }
    if (!billing.renew) {
        Feedback.error('Missing renew field')
        return false
    }
    if (!product.agent || !product.scope) {
        Feedback.error('Incomplete product')
        return false
    }
    if (!user.first || !user.last || !user.email) {
        Feedback.error('Incomplete user')
        return false
    }
    return true
}

async function confirmSave(confirm) {
    return await confirm.value.ask(`Do you want to create the account "${page.account.name}"? `)
}

async function save() {
    let {account, basic, billing, developer, product, user} = page
    let renew = new Date(billing.renew)
    renew = new Date(Date.UTC(renew.getFullYear(), renew.getMonth(), renew.getDate()))

    let email = user.email
    if (email.match(/<.*>/)) {
        email = email.replace(/^.*\<(.*)\>.*$/, '$1')
    }
    email = email.toLowerCase()

    let def = {
        agreement: billing.agreement,
        name: account.name,
        billing: {
            company: billing.name || account.name,
            address: billing.address,
            renew: billing.renew,
            canWire: billing.canWire,
            wire: billing.wire,
            terms: billing.wire ? 30 : 0,
        },
        users: [
            {
                first: user.first,
                last: user.last,
                email: email,
            },
        ],
        products: [
            {
                name: product.name,
                description: product.description,
                url: product.url,
                agent: product.agent,
                scope: product.scope,
                memo: product.memo,
                period: billing.period,
            },
        ],
        support: {
            units: basic.units,
            period: billing.period,
        },
        developer: {
            units: developer.units,
            period: billing.period,
        },
    }
    let result = await Account.make(def)
    emit('input')
}
</script>

<template>
    <vu-form
        :confirm="confirmSave"
        :data="page"
        :pre-save="preSave"
        :save="save"
        help="/doc/ui/admin/add-account.html"
        title="Create Account">
        <vu-card title="Account" row>
            <vu-input v-model="page.account.name" label="Account Name" cols="6" />
            <vu-input v-model="page.billing.company" label="Legal Name" cols="6" />
            <vu-input v-model="page.billing.address" label="Address" type="textarea" cols="12" rows="3" />
            <vu-input v-model="page.billing.renew" label="Renew Date" type="date" datetime="date" cols="4" />
            <vu-input v-model="page.billing.period" label="Billing Period" cols="4" />
            <vu-input v-model="page.billing.terms" label="Billing Terms" cols="4" />
            <vu-input v-model="page.billing.agreement" label="Agreement Account" type="checkbox" cols="4" />
            <vu-input v-model="page.billing.canWire" label="Allow Wire Transfers" type="checkbox" cols="4" />
            <vu-input v-model="page.billing.wire" label="Enable Wire Transfers" type="checkbox" cols="4" />
        </vu-card>

        <vu-card title="Product" row>
            <vu-input v-model="page.product.name" label="Name" cols="4" />
            <vu-input v-model="page.product.description" label="Description" cols="4" />
            <vu-input v-model="page.product.url" label="URL" cols="4" />
            <vu-input v-model="page.product.agent" label="Agent" type="select" :items="Agents" cols="4" />
            <vu-input v-model="page.product.scope" label="Scope" type="select" :items="Scopes" cols="4" />
            <vu-input v-model="page.product.memo" label="Invoice Memo" cols="4" />
        </vu-card>

        <vu-card title="Support" row>
            <vu-input v-model="page.basic.units" label="Basic Support" type="checkbox" cols="6" />
            <vu-input v-model="page.developer.units" label="Developer Hours" cols="6" />
        </vu-card>

        <vu-card title="User" row>
            <vu-input v-model="page.user.first" label="First" cols="3" />
            <vu-input v-model="page.user.last" label="Last" cols="3" />
            <vu-input v-model="page.user.email" label="Email" cols="3" />
            <vu-input v-model="page.user.invite" label="Send Invite" type="checkbox" cols="3" />
        </vu-card>

        <div class="actions">
            <v-btn color="accent" type="submit" :loading="page.saving">Save</v-btn>
            <v-btn color="none" @click="emit('input')">Cancel</v-btn>
        </div>
    </vu-form>
</template>

<style lang="scss" scoped>
.v-card {
    width: 100%;
}
.card-body {
    margin: 12px;
}
</style>
