<script setup>
import {onMounted, onBeforeUnmount, reactive, ref} from 'vue'
import {State} from '@/paks/vu-app'
import UUID from '@/paks/js-uuid'

const props = defineProps({
    container: Object,
    dashboard: Object,
})

const emit = defineEmits(['input'])

const WidgetTypes = [
    {type: 'audio', title: 'Audio'},
    {type: 'button', title: 'Button'},
    {type: 'event', title: 'Event'},
    {type: 'form', title: 'Form'},
    {type: 'gauge', title: 'Gauge'},
    {type: 'graph', title: 'Graph'},
    {type: 'image', title: 'Image'},
    {type: 'input', title: 'Input'},
    {type: 'label', title: 'Label'},
    {type: 'led', title: 'Led'},
    {type: 'numeric', title: 'Numeric'},
    {type: 'progress', title: 'Progress'},
    {type: 'set', title: 'Overview'},
    {type: 'shape', title: 'Shape'},
    {type: 'sign', title: 'Sign'},
    {type: 'table', title: 'Table'},
    {type: 'tabs', title: 'Tabs'},
    {type: 'toolbar', title: 'Toolbar'},
]
const InputTypes = [
    {type: 'checkbox', title: 'Checkbox'},
    {type: 'combo', title: 'Combo Box'},
    {type: 'date', title: 'Date'},
    {type: 'file', title: 'File'},
    {type: 'password', title: 'Password'},
    {type: 'radio', title: 'Radio'},
    {type: 'select', title: 'Select'},
    {type: 'slider', title: 'Slider'},
    {type: 'switch', title: 'Switch'},
    {type: 'text', title: 'Text Field'},
    {type: 'textarea', title: 'Text Area'},
]

const page = reactive({
    full: false,
    grid: 20,
    item: null,
    pos: {},
    showWidget: null,
})

const WidgetSize = 200
const widgetRef = ref(null)
let listeners = []

onMounted(() => {
    page.full = State.cache.dashboard.full
})

onBeforeUnmount(() => {
    removeListeners()
})

function startCreate(e, item) {
    e.preventDefault()
    let pos = getPos(e)
    widgetRef.value.style.top = pos.y + 'px'
    widgetRef.value.style.left = pos.x + 'px'
    page.showWidget = true
    page.item = item
    addListener(document, 'mousemove', move)
    addListener(document, 'touchmove', move)
    addListener(document, 'mouseup', finishCreate)
    addListener(document, 'touchend', finishCreate)
}

async function move(e) {
    let pos = getPos(e)
    widgetRef.value.style.top = pos.y + 'px'
    widgetRef.value.style.left = pos.x + 'px'
}

function finishCreate(e) {
    e.preventDefault()
    let pos = getPos(e)
    let container = props.container
    let x = Math.max(pos.x - container.offsetLeft - page.grid, 0)
    let y = Math.max(pos.y - container.offsetTop - page.grid, 0)
    removeListeners()
    let item = page.item
    let cloudId = State.app.prefer('cloudId')
    let z = 0
    let dashboard = props.dashboard
    let widgets = dashboard.widgets
    if (dashboard.layout == 'exact') {
        z = Math.max(widgets.length, Math.max(...widgets.map(w => w.z)) + 1)
    }
    let type = item.type
    let input
    if (InputTypes.find(t => t.type == type)) {
        input = type
        type = 'input'
    }
    let widget = {
        id: UUID(),
        cloudId,
        type: type,
        input: input,
        css: [],
        range: {},
        anchor: {},
        top: y,
        left: x,
        z: z,
        width: WidgetSize,
        height: WidgetSize,
        _new: true,
    }
    page.showWidget = false
    props.dashboard.widgets.push(widget)
    State.app.setNeed('dash', 'refresh')
    State.app.setNeed('editWidget', widget)
}

function getPos(e) {
    let x, y
    if (e.touches) {
        x = e.touches[0].clientX
        y = e.touches[0].clientY
    } else {
        x = e.clientX
        y = e.clientY
    }
    return {x, y}
}

function addListener(base, event, fn) {
    listeners.push({base, event, fn})
    base.addEventListener(event, fn)
}

function removeListeners(events) {
    for (let listener of listeners) {
        if (!events || events.indexOf(listener.event) >= 0) {
            listener.base.removeEventListener(listener.event, listener.fn)
        }
    }
    listeners = []
}
</script>

<template>
    <v-sheet class="toolbox" :class="page.full ? 'full' : ''">
        <v-list class="mt-1 mb-3 text-right">
            <v-list-subheader class="text-right">Widgets</v-list-subheader>
            <v-list-item v-for="item in WidgetTypes" class="text-right" density="compact" ripple>
                <v-list-item-title
                    @mousedown.stop="startCreate($event, item)"
                    @touchstart.stop="startCreate($event, item)">
                    {{ item.title }}
                </v-list-item-title>
            </v-list-item>
            <label class="divider mt-5 ml-4">Input Widgets</label>
            <v-list-item v-for="item in InputTypes" class="text-right" density="compact" ripple>
                <v-list-item-title
                    @mousedown.stop="startCreate($event, item)"
                    @touchstart.stop="startCreate($event, item)">
                    {{ item.title }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-sheet>
    <div class="new-widget" id="new-widget" ref="widgetRef" v-show="page.showWidget" />
</template>

<style lang="scss">
.toolbox {
    width: 140px;
    border-right: 1px solid rgb(var(--v-theme-border-lighten-1)) !important;
    overflow-y: scroll;

    .v-list {
        margin-top: 0 !important;
        background: rgb(var(--v-theme-background-lighten-1));
    }
    .v-list-subheader {
        font-size: 1.25rem;
        padding-left: 20px;
    }
    .v-list-item {
        cursor: grab;
        padding: 2px 8px !important;
    }
    .v-list-item-title {
        font-size: 1rem;
        color: rgb(var(--v-theme-text));
    }
    a.theme--dark {
        .v-list-item-title {
            color: rgb(var(--v-theme-text-lighten-1));
        }
    }
    .v-list-item:hover.theme--dark {
        background-color: black;
    }
    .divider {
        font-weight: bold;
        display: block;
        padding-right: 8px;
    }
}
.toolbox.full {
    padding-top: 50px !important;
}
.new-widget {
    width: 200px;
    height: 200px;
    position: fixed;
    border: solid 4px rgb(var(--v-theme-accent));
    z-index: 200;
    cursor: move;
}
</style>
