<script setup>
/*
    This implements login for both cloud-based (Cognito) and local (web server).
    Cloud-based auth has a split authentication and service login.
 */
import UUID from '@/paks/js-uuid'
import {onBeforeMount, reactive, ref} from 'vue'
import {Auth, State, disableAutoComplete} from '@/paks/vu-app'
import SocialLogin from './SocialLogin.vue'
import Forgot from './Forgot.vue'
import ConfirmEmail from './ConfirmEmail.vue'

const props = defineProps({
    logo: String,
    forgot: Boolean,
    social: Boolean,
})

const page = reactive({
    activity: {},
    auth: {},
    dialog: false,
    forgot: null,
    local: null,
    logo: null,
    mode: null,
    rules: [],
    social: null,
    showPassword: false,
    state: 'begin',
    title: null,
})

const form = ref(null)
const validate = ref(null)

onBeforeMount(async () => {
    let config = State.config
    let theme = config.theme || {}
    page.logo = props.logo || State.app.logo || theme.logo || config.logo
    page.title = State.app.title || theme.title || config.title
    let features = config.features.auth || {}
    page.social = State.app.social ? true : false
    page.forgot = features.forgot || props.forgot
    page.local = State.app.local
    page.state = 'begin'

    let query = State.app.location.query
    if (query.invite) {
        page.auth.email = query.invite.split(':').slice(-1)[0]
        await Auth.logout()
    } else if (query.assume) {
        State.auth.assume = query.assume
    }
    let auth = await Auth.checkSession()
    if (auth) {
        page.auth = auth
        page.state = 'login'
    } else if (page.social) {
        page.state = 'authenticate'
    }
    pumpState()
})

/*
    Pump the state machine
    States: begin, authenticate, register, confirm, login, forgot, complete
    Events: creds, code, reset
 */
async function pumpState(auth) {
    if (auth == 'forgot') {
        page.state == 'forgot'
    }
    if (State.auth.email && State.auth.email != page.auth.email) {
        await Auth.logout()
        page.state = 'authenticate'
        page.auth = {email: page.auth.email, password: page.auth.password}
    }
    let current
    do {
        current = page.state

        switch (page.state) {
            case 'begin':
                page.state = current = 'authenticate'
                break

            case 'authenticate':
                page.state = await authenticate()
                break

            case 'register':
                page.state = await register()
                break

            case 'confirm':
                page.title = 'Confirm Registration'
                if (State.auth.authenticated) {
                    page.state = await login()
                }
                break

            case 'login':
                page.state = await login()
                break

            case 'forgot':
                page.state = 'begin'
                break

            case 'complete':
                Auth.redirect()
                break
        }
    } while (page.state != current)

    page.dialog =
        page.state == 'authenticate' ||
        page.state == 'confirm' ||
        page.state == 'forgot' ||
        page.state == 'login'
            ? true
            : false
    page.activity = {}
}

function formError(err, validate) {
    page.activity = {}
    page.mode = null
}

async function authenticate() {
    try {
        let auth = page.auth
        if (auth.email) {
            auth.email = auth.email.toLowerCase().trim()
        }
        if (auth.password) {
            auth.password = auth.password.trim()
        }
        page.activity[page.mode] = true

        if (await Auth.authenticate(auth, {throw: false})) {
            return 'login'
        } else if (page.mode == 'register') {
            return 'register'
        }
        throw new Error('User or password not found')
    } catch (err) {
        if (err.message == 'User already exists' && !page.local) {
            page.state = await sendCode(page.auth.id)
        } else if (err.message == 'Please Define Password' && page.local) {
            page.mode = 'register'
            if (!page.email) {
                await disableAutoComplete()
            }
        } else {
            showError(err)
        }
    }
    return page.state
}

async function register() {
    let auth = page.auth
    //  Allocate a unique ID for Cognito to use as the username
    auth.username = UUID()
    page.activity[page.mode] = true
    //  Register user with authentication service (Cognito)
    await Auth.registerUser({
        username: auth.username,
        password: auth.password,
        email: auth.email,
        family_name: auth.last,
        given_name: auth.first,
    })
    return 'confirm'
}

//  Login to the app using existing credentials. Throws if login fails for any reason.
async function login() {
    try {
        await Auth.login({register: page.mode == 'register'})
        return 'complete'
    } catch (err) {
        if (err.message == 'User is not confirmed') {
            return 'confirm'
        }
        showError(err)
    }
    return page.state
}

async function submit(mode) {
    page.mode = mode
    page.activity[page.mode] = true
    if (validate && validate.value) {
        validate.value.clear()
    }
    form.value.$el.requestSubmit()
}

async function sendCode() {
    try {
        await Auth.sendCode(page.auth.id)
    } catch (err) {
        if (err.message == 'User is already confirmed') {
            return 'login'
        }
        showError(err)
    }
    return page.state
}

function showError(err) {
    if (validate && validate.value) {
        validate.value.error(err.message)
    }
    page.activity = {}
    page.mode = null
}

function toggleShowPassword() {
    page.showPassword = !page.showPassword
}
</script>

<template>
    <v-dialog persistent v-model="page.dialog" width="320" content-class="auth-dialog">
        <v-card class="login-card">
            <v-card-title class="headline center text-center" primary-title>
                <v-img v-if="page.logo" :src="page.logo" contain height="60px" aspect-ratio="1" />
                <div class="title mt-3">{{ page.title }}</div>
            </v-card-title>
            <vu-validate ref="validate" />
            <vu-form
                v-if="
                    page.state == 'authenticate' ||
                    page.state == 'login' ||
                    page.state == 'register'
                "
                ref="form"
                :data="page"
                :save="pumpState"
                :error="formError"
                class="login">
                <v-card-text class="details">
                    <SocialLogin v-if="page.social" />
                    <v-text-field
                        v-model="page.auth.email"
                        name="email"
                        class="ma-0 pa-0"
                        density="compact"
                        autocomplete="email"
                        placeholder="Email"
                        variant="underlined"
                        :rules="page.local ? page.rules.required : page.rules.email" />

                    <v-text-field
                        v-model="page.auth.password"
                        name="password"
                        autocomplete="current-password"
                        class="ma-0 pa-0"
                        density="compact"
                        placeholder="Password"
                        variant="underlined"
                        :hide-details="true"
                        :type="page.showPassword ? 'text' : 'password'"
                        :rules="page.local ? page.rules.required : page.rules.password"
                        :append-inner-icon="page.showPassword ? '$eye' : '$eyeOff'"
                        @click:append-inner="toggleShowPassword" />
                    <a
                        v-if="page.forgot"
                        @click="page.state = 'forgot'"
                        name="forgot"
                        class="forgot-login"
                        >
                        Forgot or change password?
                    </a>
                </v-card-text>

                <v-card-actions class="pt-3 pb-0 vcol-12">
                    <v-col>
                        <v-btn
                            ripple
                            block
                            color="primary"
                            type="submit"
                            variant="elevated"
                            :disabled="page.mode == 'register'"
                            :loading="page.activity.login"
                            @click.prevent.stop="submit('login')">
                            Login
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn
                            color="primary"
                            variant="elevated"
                            :disabled="page.mode == 'login'"
                            :loading="page.activity.register"
                            @click.prevent.stop="submit('register')">
                            Register
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </vu-form>
            <ConfirmEmail
                v-else-if="page.state == 'confirm' && !page.local"
                :auth="page.auth"
                @input="pumpState" />
        </v-card>
    </v-dialog>
    <Forgot v-if="page.dialog && page.state == 'forgot'" @input="pumpState" />
    <div v-if="!page.dialog" class="loading">loading ...</div>
</template>

<style lang="scss">
.auth-dialog {
    .login-card {
        background-color: rgb(var(--v-theme-none-lighten-1)) !important;
        border: solid 1px rgb(var(--v-theme-accent)) !important;
    }
    .headline {
        padding-top: 20px;
        display: block !important;
        .title {
            color: rgb(var(--v-theme-text));
        }
    }
    .v-messages {
        min-height: 0;
    }
    .validate {
        margin-top: -2px;
    }
    .login {
        padding: 0 0 20px 0 !important;
        input {
            background: none;
        }
        .details {
            padding: 30px 20px 0 20px;
            .v-input--selection-controls {
                margin-top: -10px !important;
                padding-top: 0 !important;
            }
        }
        .v-responsive__sizer {
            padding-bottom: 120px !important;
        }
        .forgot-login {
            position: relative;
            top: 4px;
            display: block;
            text-align: right;
            padding: 0;
            cursor: pointer;
            font-size: 0.75rem;
            color: rgb(var(--v-theme-text-lighten-2)) !important;
            &:hover {
                color: #03a9f4;
            }
        }
        .validate {
            margin-top: -4px;
            padding: 0 !important;
        }
        .v-btn {
            width: 100% !important;
        }
    }
}
</style>
