<script setup>
import {onBeforeMount, onMounted, reactive, ref} from 'vue'
import {Progress, State} from '@/paks/vu-app'
import StripeClient from '@/paks/js-stripe-client'
import {Account, Card} from '@/models'
import {runBilling} from '@/compose/Billing'

const Mock = false

const props = defineProps({id: String})
const emit = defineEmits(['input'])

const page = reactive({
    card: {},
    changeDefault: false,
    otherDefault: false,
    rules: [],
    readonly: false,
    saving: false,
})

//  Component refs
const confirm = ref(null)

onBeforeMount(() => {
    if (!window.Stripe) {
        let script = document.createElement('script')
        script.setAttribute('src', 'https://js.stripe.com/v2/')
        document.body.appendChild(script)
    }
})

onMounted(async () => {
    let cards = await Card.find()
    if (props.id) {
        page.card = await Card.get({id: props.id})
        page.readonly = true
        for (let c of cards) {
            if (c.id != page.card.id && c.number && !c.error && c.current) {
                page.otherDefault = true
            }
        }
        page.card.month = parseInt(page.card.month)
        page.card.year = parseInt(page.card.year)
        page.otherDefault = true
    } else {
        if (!cards || cards.length == 0) {
            page.card.current = true
        }
        if (Mock) {
            page.card = {
                //  Test number only
                number: 4242424242424242,
                name: 'Peter Perfect',
                month: 10,
                year: 2020,
                cvc: 123,
                current: true,
            }
        }
    }
    page.changeDefault = !page.card.current || !props.id
})

async function save() {
    let card = page.card
    if (!card.id) {
        let stripe = await Card.getStripe()
        let client = new StripeClient(stripe)
        let token = await client.createStripeToken(card)
        card.number = card.number.toString().slice(-4)
        card.stripeToken = token.id
    }
    if (props.id) {
        card = await Card.updateCurrent({id: card.id, current: card.current})
    } else {
        let params = {
            name: card.name.trim(),
            number: card.number.trim(),
            year: card.year.trim(),
            month: card.month.trim(),
            current: card.current,
            stripeToken: card.stripeToken,
        }
        card = await Card.create(params)
    }
    if (card.current) {
        await updateAccount()
        await runBilling(card.accountId)
    }
    emit('input')
}

async function remove() {
    if (!page.card.name) {
        return
    }
    if (!(await confirm.value.ask(`Do you want to remove this card "${page.card.number}"`))) {
        return
    }
    Progress.start()
    await Card.remove({id: page.card.id})
    await updateAccount()
    Progress.stop()
    emit('input')
}

async function updateAccount() {
    let account = await Account.get({id: State.auth.account.id}, {refresh: true})
    State.auth.setAccount(account)
}

function pad(num, size) {
    var s = num + ''
    while (s.length < size) {
        s = '0' + s
    }
    return s
}
</script>

<!--
    CardEdit.vue

    Test cards
    4242424242424242            ok
    4000000000000341            declined - Can enter this as a card, but will not accept charges.

    4000000000000077            ok
    4000000000000010            declined address failure -- but this seems to work (we are not validating addresses)
    4000000000000069            declined - expired card (can't enter)
    4242424242424241            declined - wrong card number

    4000000000000119            declined - processing failure
    4000000000000341            cad accepted, but processing declined
-->
<template>
    <vu-form :data="page" :save="save" :title="`${page.card.id ? 'View Card' : 'Add Card'}`">
        <vu-input
            v-model="page.card.name"
            autocomplete="cc-name"
            cols="12"
            label="Card Holder"
            name="ccname"
            type="text"
            :disabled="page.readonly"
            :rules="page.rules.name" />
        <vu-input
            v-model="page.card.number"
            autocomplete="cc-number"
            cols="12"
            label="Card Number"
            name="cardnumber"
            type="text"
            :disabled="page.readonly"
            :rules="page.rules.number" />
        <vu-input
            v-model="page.card.month"
            autocomplete="cc-exp-month"
            cols="4"
            label="Card Month"
            name="cc-exp-month"
            type="text"
            :disabled="page.readonly" />
        <vu-input
            v-model="page.card.year"
            autocomplete="cc-exp-year"
            cols="4"
            name="cc-exp-year"
            label="Card Year"
            type="text"
            :disabled="page.readonly"
            :rules="page.rules.number" />
        <vu-input
            v-model="page.card.cvc"
            autocomplete="cc-cvc"
            cols="4"
            label="CVC"
            name="cvc"
            type="text"
            :disabled="page.readonly"
            :rules="page.rules.number" />
        <vu-input class="mt-0 pt-0" v-model="page.card.current" label="Set as Default" cols="12" type="checkbox" />
        <div class="actions">
            <v-btn size="small" color="accent" class="mr-2" type="submit" :loading="page.saving" v-if="page.changeDefault || !id">
                Save
            </v-btn>
            <v-btn size="small" color="none" @click="emit('input')">Cancel</v-btn>
            <v-btn size="small" color="error" @click="remove" :disabled="!page.otherDefault">Delete</v-btn>
        </div>
        <vu-confirm ref="confirm" />
    </vu-form>
</template>

<style lang="scss">
.card-edit {
    .v-text-field {
        padding-top: 0;
    }
}
</style>
