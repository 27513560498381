/*
    Auth.js -- Authentication component

    This authentication module supports a split auth model where authentication of user credentials
    may be done by a separate auth server (Cognito).
 */
import {Log} from '@/paks/js-log'
import Net from '@/paks/js-net'
import Task from '@/paks/js-task'
import {State, Store} from '@/paks/vu-state'

const SessionDuration = 60 * 60 * 1000

let Account, User

class AuthClass {
    app = null
    cognito = null
    cognitoConfig = null
    monitoring = null
    task = null

    init(app, config, {cognito, account, user}) {
        this.app = app
        this.config = config
        Account = account
        User = user
        if (config && cognito) {
            this.cognito = new cognito(config)
        }
        this.cognitoConfig = config
        this.monitoring = false
        this.task = new Task(State.config.roles)
    }

    async checkSession() {
        let cognito = this.cognito
        let auth = null
        if (!State.app.isSocial) {
            try {
                if (cognito) {
                    auth = await cognito.checkLoggedIn()
                    if (auth) {
                        State.auth.setAuthState(auth, cognito.tokens)
                    }
                } else {
                    auth = await User.checkSession(null, {nologout: true})
                    if (auth) {
                        State.auth.setAuthState(auth, null)
                    }
                }
            } catch (err) {}
        }
        return auth
    }

    /*
        Authenticate the user's credentials. 
        If already logged in, this will use the existing login session.
        If the authentication is successful, the AuthState is updated with the auth and tokens.
        Also returns the auth.
     */
    async authenticate(params, options = {throw: false}) {
        let auth = State.auth.auth
        let cognito = this.cognito
        try {
            if (State.app.isSocial) {
                params = {social: State.app.location.search}
            } else if (params) {
                params.username = params.username || params.email
            }
            if (auth && ((params && auth.email != params.email) || State.auth.getInvite() || State.app.isSocial)) {
                //  Cannot use an existing cognito session if social, has different login email or is an invite.
                if (cognito) {
                    await cognito.logout()
                } else {
                    await User.logout()
                }
                State.auth.clearAuth()
                auth = null
            }
            if (!auth && params) {
                if (cognito) {
                    auth = await cognito.login(params)
                } else {
                    delete params.email
                    auth = await User.authenticate(params)
                }
            }
        } catch (err) {
            if (err.message == 'Please Define Password') {
                throw err
            }
            if (options.throw !== false) {
                throw err
            }
        }
        if (auth) {
            /*
                Set the authenticated details and session tokens. 
                The session tokens are used in js-rest requests.
            */
            State.auth.setAuthState(auth, cognito ? cognito.tokens : null)
            State.app.setActivity()
        }
        return auth
    }

    /*
        Login the user. Call only after being authenticated.
        Will throw an exception if the login fails for any reason.
    */
    async login(params = {}) {
        if (this.cognito && !State.auth.authenticated) {
            throw new Error('User is not authenticated')
        }
        params = Object.assign({}, {invite: State.auth.getInvite()}, params)
        let data = await User.login(params, {
            log: false,
            feedback: false,
            nologout: true,
        })
        if (data) {
            let id = data.account?.id || this.id
            await Store.loadState(`${this.app.config.name}-${id}-state`)
            this.monitorSession()
            await this.setLoginState(data)
            State.auth.setInvite(null)
        }
    }

    /*
        After login, cache data and define the user role
     */
    async setLoginState({account, cache, keys, schema, user, versions}) {
        if (account) {
            State.auth.setAccount(account)
        }
        if (user) {
            State.auth.setUser(user)
        }
        if (versions) {
            State.app.setVersions(versions)
        }
        if (schema) {
            State.app.setSchema(schema)
        }
        State.auth.setAuthorized(true)
        await State.cache.update({cache, authorized: true, keys})
        State.auth.setReady(true)

        //  Workaround for navbar not noticing ready
        State.app.setNeed('dash', 'reload')

        if (State.ref.chat) {
            State.ref.chat.identify()
        }
    }

    /*
        Logout the user and navigate the app to the login form
    */
    async logout(options = {}) {
        if (this.cognito) {
            await this.cognito.logout()
            if (options.redirect && State.app.isSocial) {
                if (State.auth.auth.username.indexOf('Google') >= 0) {
                    //  Ugh! Patch to force a logout of a Google social login to ensure we get the Google account chooser.
                    let net = new Net()
                    await net.fetch('https://accounts.google.com/logout', {
                        feedback: false,
                        throw: false,
                        log: false,
                    })
                }
                let url = this.cognito.socialLogoutUrl()
                if (url) {
                    State.auth.clearAuth()
                    Store.save()
                    window.open(url, '_self')
                    return
                }
            }
        } else {
            await User.logout()
        }
        State.auth.clearAuth()
        this.monitoring = false
    }

    async forgot(username) {
        if (this.cognito) {
            if (username) {
                username = username.toLowerCase()
            }
            await this.cognito.forgot(username)
        }
    }

    async forgotConfirmation(username, password, code) {
        if (username) {
            username = username.toLowerCase()
        }
        await this.cognito.forgotConfirmation(username, password, code)
    }

    /*
        Register a user with cognito
     */
    async registerUser(params) {
        if (this.cognito) {
            await this.cognito.register(params)
        }
    }

    /*
        Check the user registration confirmation code. Throws on failure.
    */
    async registerUserConfirmation(params, code) {
        if (this.cognito) {
            //  This throws if the confirmation fails
            await this.cognito.registerConfirmation(params.username, code)
            await this.authenticate(params, {throw: true})
        }
    }

    async sendCode(username) {
        if (this.cognito) {
            await this.cognito.resend(username)
        }
    }

    async setUser(username, details) {
        if (this.cognito) {
            await this.cognito.update(username, details)
        }
    }

    async changePassword(oldPassword, newPassword) {
        if (this.cognito) {
            return await this.cognito.changePassword(oldPassword, newPassword)
        }
    }

    async removeUser() {
        if (this.id && this.cognito) {
            Log.info(`Delete user ${this.email}`)
            await this.cognito.deleteUser(this.user.id)
        }
    }

    async monitorSession() {
        State.app.setActivity()
        if (this.monitoring) return
        this.monitoring = true

        while (this.monitoring) {
            if (State.auth.authenticated) {
                let timeout = State.config.timeouts.session * 1000
                let elapsed = Date.now() - State.app.lastAccess
                let remaining = timeout - elapsed
                if (remaining < 0) {
                    await this.logout({redirect: true})
                    State.ref.router.push({path: '/auth/login'}).catch(() => {})
                } else if (this.cognito) {
                    Log.trace(`Session remaining: ${((timeout - elapsed) / 1000 / 60).toFixed(0)} mins`)
                    let cognitoElapsed = Date.now() - State.auth.tokensRefreshed
                    Log.trace(`Session elapsed: ${(cognitoElapsed / 1000 / 60).toFixed(2)} mins`)

                    if (cognitoElapsed > SessionDuration / 3) {
                        State.auth.tokens = await this.cognito.refresh()
                        if (State.auth.tokens) {
                            State.auth.tokensRefreshed = new Date()
                            State.auth.setAuthState(State.auth, State.auth.tokens)
                        }
                    }
                }
            }
            await new Promise(resolve => setTimeout(resolve, 60 * 1000))
        }
        this.monitoring = false
    }

    parseUri() {
        let loc = window.location
        let path = loc.pathname

        if (path != '/') {
            //  Convert to hash form with query after hash and reload
            loc.href = `${loc.origin}/#${path}${loc.search}`
            return
        }
        /*
            Save and persist the original URL including path, hash and query.
            When doing social logins, Cognito will redirect to /auth/login/social which is saved
            before redirecting to /auth/login to process.
            We support (non-standard) query after hash which we use for the redirection above
        */
        let [hash, search] = loc.hash.split('?')

        let location = State.app.location
        location.hash = hash
        location.url = hash ? hash.slice(1) : path
        location.search = search ? `?${search}` : null

        let vars = []
        if (loc.search) {
            vars.push(...loc.search.substring(1).split('&'))
        }
        if (search) {
            vars.push(...search.split('&'))
        }
        let query = {}
        if (vars.length) {
            for (let i = 0; i < vars.length; i++) {
                let [key, value] = vars[i].split('=')
                key = decodeURIComponent(key)
                value = decodeURIComponent(value || true)
                query[key] = value
                if (key == 'url') {
                    location.url = value
                }
            }
        }
        location.query = query
        
        if (query.upgrading) {
            State.app.upgrading = true
        }
        let features = query['features']
        if (features) {
            for (let feature of features.split(',')) {
                let [name, value] = feature.split(':')
                let obj = State.config.features
                let parts = name.split('.')
                for (let [index, thisKey] of Object.entries(parts)) {
                    if (index == parts.length - 1) {
                        obj[thisKey] = value === undefined ? true : value
                    } else {
                        obj = obj[thisKey]
                    }
                }
            }
        }
    }

    /*
        Redirect after authentication
        This will redirect to an original url fragment and will restore original query paramemters
    */
    redirect() {
        let {query, url} = State.app.location

        let target = url || '/'
        if (target.indexOf('/auth') == 0) {
            target = '/'
        }
        let path = window.location.pathname + window.location.hash.replace(/^#\//, '')
        if (target != path) {
            if (query) {
                delete query.code
                if (query.invite === null) {
                    delete query.invite
                }
                delete query.url
            }
            this.app.router.push({path: target, query: query}, () => {})
        }
    }

    can(role) {
        return this.task.can(State.auth.role, role)
    }

    canUser(user, role) {
        return this.task.can(user.role, role)
    }
}

export const Auth = new AuthClass()