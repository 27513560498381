<script setup>
import {reactive} from 'vue'
import {State, Store} from '@/paks/vu-app'

const props = defineProps({
    verb: {type: String, default: 'Login with'},
})

const page = reactive({
    loading: false,
})

async function socialLogin(vendor) {
    page.loading = vendor
    await Store.save()
    let cognito = new State.app.cognito(State.config.cognito)
    let url = cognito.socialLoginUrl(vendor)
    window.open(url, '_self')
}
</script>

<template>
    <div class="social-login">
        <v-btn
            size="small"
            ripple
            block
            color="accent"
            class="social"
            @click="socialLogin('Amazon')"
            :loading="page.loading == 'Amazon'">
            <v-icon size="x-large" icon="$aws" />
            <span>{{ verb }} Amazon</span>
        </v-btn>
        <!--
        <v-btn
            size="small"
            ripple
            block
            color="accent"
            class="social"
            :loading="page.loading == 'Facebook'"
            @click="socialLogin('Facebook')">
            <v-icon size="x-large" icon="$facebook" />
            <span>{{ verb }} Facebook</span>
        </v-btn>
        -->
        <v-btn
            size="small"
            ripple
            block
            color="accent"
            class="social"
            @click="socialLogin('Google')"
            :loading="page.loading == 'Google'">
            <v-icon size="x-large" icon="$google" />
            <span>{{ verb }} Google</span>
        </v-btn>
        <p class="text-center mt-5 mb-3">&mdash; OR &mdash;</p>
    </div>
</template>

<style lang="scss" scoped>
.social-login {
    .social {
        margin-bottom: 10px;
        justify-content: left;
        .v-icon {
            margin-right: 20px;
            --v-icon-size-multiplier: 1;
        }
    }
}
</style>
