<script setup>
import {onBeforeMount, onMounted, reactive} from 'vue'
import {Feedback, Progress, State, can, clone, money, query, waitRender} from '@/paks/vu-app'
import {Card, Invoice} from '@/models'
import Dates from '@/paks/js-dates'
import CardEdit from './CardEdit.vue'
import WireEdit from './WireEdit.vue'
import InvoiceDetails from './InvoiceDetails.vue'
import Planner from '@/common/planner'
import {runBilling} from '@/compose/Billing'

const CardFields = [
    {name: 'edit', icon: true},
    {name: 'number'},
    {name: 'expires'},
    {name: 'current', title: 'Default Card', icon: true},
    {name: 'error', title: 'Status', format: (v) => (v ? v : 'Ok')},
]

const InvoiceFields = [
    {name: 'status'},
    {name: 'date', format: (v) => formatDate(v)},
    {name: 'due', format: (v) => formatDate(v)},
    {name: 'number'},
    {name: 'description', style: 'max-width: 400px'},
    {name: 'amount', align: 'right', format: (v) => `\$${money(v, 2)}`},
    {name: 'paid', align: 'right', format: (v) => `\$${money(v, 2)}`},
    {name: 'download', icon: true},
    {name: 'reissue', icon: true},
]

const account = clone(State.auth.account)

const page = reactive({
    account: account,
    billing: account.billing,
    agreement: account.billing.agreement,
    canBill: false,
    card: {},
    cards: [],
    deleteInvoices: null,
    canWire:
        account.billing.canWire ||
        account.billing.wire ||
        account.billing.agreement ||
        can('support'),
    demo: State.app.mock,
    hasCardError: false,
    invoices: [],
    loading: false,
    showEdit: false,
    showInvoice: false,
    showWire: false,
    support: can('support'),
    wire: account.billing.wire,
})

const planner = new Planner(State.config.billing)
const products = clone(State.cache.products)
const clouds = clone(State.cache.clouds)
const plans = clone(State.cache.plans)

//  Initiate an update
State.cache.update()

onBeforeMount(async () => {
    let invoices = await Invoice.findOutstanding()
    let bill = planner.makeBill(account, clouds, products, plans, invoices)
    page.canBill = bill.total ? true : false
    if (State.config.features.debug && can('support')) {
        page.deleteInvoices = 'delete'
    }
})

onMounted(async () => {
    if (query('add-card')) {
        editCard({})
    } else if (query('address')) {
        editInvoice({})
    } else if (query('bank')) {
        editWire({})
    }
    await load()
})

async function load() {
    let promises = []
    Progress.start()
    promises.push(Invoice.find({}, {refresh: true}))
    promises.push(Card.find({}, {refresh: true}))
    let result = await Promise.all(promises)
    let [invoices, cards] = result

    page.invoices = invoices || []
    page.cards = cards || []

    for (let card of page.cards) {
        if (card.current) {
            page.card = card
        }
        card.number = `Card ending in ${card.number.toString().slice(0, 4)}`
        card.expires = `Expires ${card.month}/${card.year}`
        card.current = card.current ? 'default card' : ''
    }
    page.invoices.forEach((i) => {
        if (i.paid >= i.total) {
            i.status = 'Paid'
        } else if (i.paid > 0) {
            i.status = 'Partial'
        } else {
            i.status = 'Outstanding'
        }
    })
    page.hasCardError = page.billing.error ? true : false
    Progress.stop()
}

function sortByDate(a, b) {
    if (a.created < b.created) {
        return -1
    } else if (b.created < a.created) {
        return 1
    }
    return 0
}

async function cardClicked({rec, column}) {
    editCard(await Card.get({id: rec.id}))
}

async function invoiceClicked({rec, column}) {
    if (column.name == 'delete' && can('support') && State.config.features.debug) {
        Progress.start()
        await Invoice.remove({id: rec.id})
        page.invoices = await Invoice.find({}, {refresh: true})
        Progress.stop()
    }
    if (column.name == 'reissue' && can('admin')) {
        await reissue(rec)
    }
}

async function editCard(card) {
    page.showEdit = card ? true : false
    page.card = card || {}
    if (!card) {
        await load()
    }
}

function editInvoice(invoice) {
    page.showInvoice = invoice ? true : false
}

function editWire(wire) {
    page.showWire = wire ? true : false
    if (!wire) {
        page.account = State.auth.account
        page.billing = page.account.billing
        page.agreement = page.billing.agreement
        page.wire = page.billing.wire
    }
}

function currency(amount) {
    return (Math.round(amount * 10) / 10).toFixed(2)
}

//  TOOD remove
function enableIcon(field) {
    if (field.value) {
        return '$complete'
    } else {
        return '$close'
    }
}

async function download(invoice) {
    let a = document.createElement('a')
    a.download = `invoice-${invoice.number}.pdf`
    let blob

    try {
        Progress.start('dialog', `Downloading ${a.download}`)
        let response = await Invoice.download({id: invoice.id})
        let arr = new Uint8Array(response.data)
        blob = new Blob([arr], {type: 'application/pdf'})
        a.href = window.URL.createObjectURL(blob)
        document.body.appendChild(a)
    } catch (err) {
        Feedback.error('Cannot download', {err})
    } finally {
        Progress.stop()
    }
    await waitRender()
    a.click()
    a.remove()
    window.URL.revokeObjectURL(blob)
}

async function reissue(rec) {
    try {
        Progress.start('dialog', 'Reissue Invoice')
        await Invoice.reissue({id: rec.id})
    } catch (err) {
        Feedback.error(`Cannot reissue invoice ${err.message}`)
    } finally {
        Progress.stop()
    }
}

async function runCard(accountId) {
    await runBilling(accountId)
    await load()
}

function formatDate(date) {
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours())
    return Dates.format(date, State.config.formats.date)
}
</script>

<template>
    <div class="sheet billing">
        <vu-help url="/doc/ui/account/billing/billing.html" />
        <v-sheet class="pb-4" max-width="1200px" width="100%">
            <h2 class="banner">Billing</h2>

            <vu-sign
                name="billing-issue-sign-1"
                title="Account Status"
                class="pb-4"
                v-if="
                    !page.billing.wire &&
                    (page.hasCardError || (!page.billing.cardId && page.canBill))
                "
                :required="true"
                :color="page.hasCardError ? 'error' : 'error'">
                <div v-if="page.billing.cardId">
                    <!-- Card Supplied -->
                    <div v-if="page.hasCardError">
                        <v-alert type="error">Card Error: {{ page.hasCardError }}</v-alert>
                        <p class="description">
                            Please correct the billing error and add a new card if necessary.
                        </p>
                    </div>
                </div>
                <div v-else>
                    <p class="description">Please add a billing card.</p>
                </div>
            </vu-sign>

            <vu-table
                class="mt-3"
                nodata="No card added yet"
                max-width="1200px"
                options="dynamic,toolbar"
                sort="default:asc"
                title="Payment Cards"
                width="100%"
                :data="page.cards"
                :fields="CardFields"
                :header="true"
                @click="cardClicked">
                <template v-slot:table-col-edit="props">
                    <v-icon icon="$edit" size="24" @click.stop="editCard(props.item)" />
                </template>
                <template v-slot:table-col-current="props">
                    <v-icon
                        :icon="enableIcon(props.field)"
                        size="24"
                        :class="`type-${props.field.value}`" />
                </template>
                <template v-slot:more="props" v-if="can('admin')">
                    <v-btn size="small" color="accent" class="mr-2" @click="editCard({})">Add Card</v-btn>
                    <v-btn
                        size="small"
                        color="accent"
                        class="mr-2"
                        v-if="page.canWire"
                        @click="editWire({})">
                        Bank Transfers
                    </v-btn>
                    <v-btn
                        size="small"
                        outlined
                        class="mr-2"
                        v-if="page.hasCardError"
                        @click="runCard(page.account.id)">
                        Run Card
                    </v-btn>
                </template>
            </vu-table>

            <vu-table
                class="mt-5"
                max-width="1200px"
                nodata="No invoices yet"
                sort="date:desc"
                title="Invoices"
                width="100%"
                :data="page.invoices"
                :extra="page.deleteInvoices"
                :fields="InvoiceFields"
                :pageSize="10"
                @click="invoiceClicked">
                <template v-slot:table-col-paid="props">
                    <v-icon
                        :icon="enableIcon(props.field)"
                        size="24"
                        :class="`type-${props.field.value}`" />
                </template>
                <template v-slot:table-col-download="props">
                    <v-icon icon="$download" @click="download(props.item)" />
                </template>
                <template v-slot:table-col-reissue="props">
                    <v-icon icon="$redo" @click="reissue(props.item)" />
                </template>
                <template v-slot:more="props" v-if="can('admin')">
                    <v-btn
                        size="small"
                        color="accent"
                        class="mr-2"
                        v-if="page.support && !page.demo"
                        @click="runCard()">
                        Run Billing
                    </v-btn>
                    <v-btn size="small" color="accent" class="mr-2" @click="editInvoice({})">
                        Invoice Details
                    </v-btn>
                </template>
            </vu-table>
        </v-sheet>

        <vu-panel v-model="page.showEdit" @close="editCard" :widths="['40%']">
            <CardEdit :id="page.card.id" @input="editCard" />
        </vu-panel>

        <vu-panel v-model="page.showInvoice" @close="editInvoice" :widths="['40%']">
            <InvoiceDetails @input="editInvoice" source="billing" />
        </vu-panel>

        <vu-panel v-model="page.showWire" @close="editWire" :widths="['40%']">
            <WireEdit @input="editWire" />
        </vu-panel>
    </div>
</template>

<style lang="scss">
.billing {
    min-height: 500px;
    h2 {
        margin-bottom: 10px;
        color: rgb(var(--v-theme-text));
        font-weight: normal;
        font-size: 1.7rem;
        padding-bottom: 20px;
    }
    font-size: 1rem;
    a {
        text-decoration: none;
    }
    .vu-table {
        width: 100%;
        padding: 0 0 10px 0;
        .title {
            font-size: 1.25rem !important;
        }
    }
    .vu-table {
        .v-icon {
            font-size: 1.25rem;
        }
    }
    .table-title {
        font-size: 1.7rem !important;
    }
    .v-alert {
        h2 {
            color: white;
            font-size: 1.7rem;
        }
    }
    .vu-sign {
        margin-top: 16px;
        .v-card-title {
            font-size: 1.7rem !important;
        }
    }
    label {
        font-size: 1rem;
    }
}
</style>
