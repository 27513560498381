<script setup>
import {onUnmounted, onMounted, reactive, ref, watch} from 'vue'
import {Progress, State} from '@/paks/vu-app'
import {
    can,
    clone,
    delay,
    fixShadow,
    getModels,
    getRoute,
    getValue,
    waitRender,
} from '@/paks/vu-app'

import Widgets from './Widgets.vue'
import WidgetEdit from './WidgetEdit.vue'
import DashEdit from './DashEdit.vue'
import ToolbarWidget from '@/paks/vu-widgets/ToolbarWidget.vue'
import Toolbox from './Toolbox.vue'
import UUID from '@/paks/js-uuid'

const props = defineProps({
    refresh: Number,
})

const page = reactive({
    active: true,
    canDash: true,
    canEdit: null,
    currentRange: null,
    dashboard: {widgets: []},
    dashboardNames: [],
    expand: false,
    hasMetrics: null,
    item: null,
    key: null,
    loadingRange: null,
    mobile: State.app.mobile,
    multiple: null,
    range: {anchor: 'relative', period: 3600},
    ready: false,
    refresh: 60,
    resizing: null,
    saving: null,
    showWidgetEdit: false,
    showDashEdit: false,
    toolbar: {},
    view: {},
    widget: {},
})

//  Component references
const container = ref(null)
const widgets = ref(null)
const route = getRoute()
const confirm = ref(null)
const widgetEdit = ref(null)
const toolbar = ref(null)
const {Dashboard} = getModels()

defineExpose({page})

watch(
    () => State.app.needs.dash,
    async (v) => {
        if (v == null) {
            return
        }
        State.app.noNeed('dash')
        if (v == 'compact') {
            await renderDash({initialize: true, compact: true, fetch: true})
            setNeedSave()
        } else if (v == 'design') {
            page.dashboard.design = !page.dashboard.design
            await save()
        } else if (v == 'editWidget') {
            editWidget({})
        } else if (v == 'expand') {
            await renderDash({initialize: true, expand: true, fetch: true})
            setNeedSave()
        } else if (v == 'refresh') {
            await prepDashboard()
            await renderDash({initialize: true, layout: true, wait: true})
        } else if (v == 'reload') {
            page.dashboard = clone(State.cache.dashboard)
            await prepDashboard({reset: true})
            await renderDash({initialize: true, layout: true, wait: true})
            if (route.meta.view?.widgets) {
                await save()
            }
        } else if (v == 'editDash') {
            page.dashboard = clone(State.cache.dashboard)
            editDashboard(page.dashboard)
        }
    }
)

watch(
    () => State.app.needs.editWidget,
    async (v) => {
        if (v) {
            State.app.noNeed('editWidget')
            editWidget(v)
        }
    }
)

onMounted(async () => {
    if (!page.active || !State.auth.ready) {
        return
    }
    // onBeforeRouteLeave(() => confirmCloseWidgetEdit(true))
    let route = getRoute()
    page.view = route.meta.view

    page.item = State.app.context.deviceId
    let initial = State.cache.dashboard == null ? true : false
    let dashboard = clone(await Dashboard.getCurrent())
    if (initial && State.config.name == 'builder' && dashboard.widgets.length == 0) {
        if (State.app.widgetSets) {
            let set = State.app.widgetSets.find((s) => s.name == 'Default')
            if (set) {
                dashboard.widgets = set.widgets
            }
        } else {
            dashboard.widgets = []
        }
        dashboard.widgets.forEach((w) => {
            w.range = w.range || {}
            w.anchor = w.anchor || {}
            w.css = w.css || []
            w.id = UUID()
        })
        page.expand = true
        State.app.setNeed('save')
    }
    page.dashboard = dashboard

    if (
        State.config.builder &&
        State.cache.clouds.filter((c) => c.type != 'host' && c.id != State.config.evalCloud)
            .length == 0
    ) {
        if (!can('support') || State.app.mock) {
            page.canDash = false
        }
    }
    window.addEventListener('resize', resizeWindow)
    page.ready = true
    daemon()
})

onUnmounted(() => {
    page.active = false
    window.removeEventListener('resize', resizeWindow)
    fixShadow()
})

async function renderDash(params = {}) {
    if (params.progress !== false) {
        Progress.start('repeat')
    }
    State.app.noNeed('dash')
    if (params.initialize) {
        params.wait = true
        await waitRender()
    }
    let dashboard = page.dashboard
    if (!widgets.value && params.wait !== false) {
        await waitRender()
    }
    if (container.value) {
        for (let {name, value} of dashboard.css) {
            container.value.style[name] = value
        }
    }
    if (widgets.value) {
        await widgets.value.update(params)
    }
    if (toolbar.value) {
        await toolbar.value.update(params)
    }
    if (params.progress !== false) {
        Progress.stop()
    }
}

async function resizeWindow() {
    if (page.resizing) return
    page.resizing = setTimeout(async () => {
        if (widgets.value) {
            await prepDashboard()
            await renderDash({initialize: true, fetch: true, progress: false, wait: true})
        }
        page.resizing = null
    }, 500)
}

async function prepDashboard(options = {}) {
    let dashboard = page.dashboard
    let features = State.config.features.dash || {}
    page.multiple = features.multiple
    page.canEdit = can('admin') && features.edit

    /*
        Use inline widgets
     */
    let view = route.meta.view
    if (view?.widgets && (options.reset || dashboard.widgets?.length === 0)) {
        dashboard.snap = true
        dashboard.css = dashboard.css || view.css || []
        dashboard.widgetCss = dashboard.widgetCss || view?.widgetCss || []
        dashboard.widgets = prepRouteWidgets(dashboard, view?.widgets)
    } else {
        dashboard.widgets = clone(dashboard.widgets)
    }
    /*
        Give preference to dashboard setting, route setting, config (from product.json5 & display.timeouts)
     */
    page.refresh = getValue(
        dashboard.refresh || page.view.refresh || State.config?.timeouts?.dashboard || 60
    )
    dashboard.remaining = page.refresh
    page.hasMetrics =
        features.metrics && dashboard.widgets.filter((w) => w.metric).length ? true : false
    page.dashboardNames = State.cache.dashboards.map((d) => d.name).sort()
    Object.assign(page.range, page.dashboard.range)
    page.currentRange = clone(page.range)
    await waitRender()
}

/*
    Prepare widgets defined in the display.json5 routes
 */
function prepRouteWidgets(dashboard, widgets) {
    widgets = clone(widgets)
    let id = 1
    for (let widget of widgets) {
        if (State.cache.clouds.length == 1) {
            widget.cloudId = State.cache.clouds[0].id
        }
        //  Elevate data {}
        for (let [key, value] of Object.entries(widget.data || {})) {
            if (key == 'filter') {
                key = 'dimensions'
            }
            widget[key] = value
            widget.namespace = widget.namespace || 'Database'
        }
        for (let [key, value] of Object.entries(widget.metric || {})) {
            if (key == 'name') {
                key = 'metric'
            } else if (key == 'filter') {
                key = 'dimensions'
            }
            widget[key] = value
        }
        if (widget.to) {
            widget.action = {type: 'link', target: widget.to}
            delete widget.to
        }
        widget.css = widget.css || []
        widget.dimensions = widget.dimensions || {}
        widget.range = widget.range || {}
        widget.id = `${id++}`
        delete widget.data
    }
    return widgets
}

async function daemon() {
    //  First time, initialize widgets, then fetch only. Expand if using a new widget set.
    let params = {initialize: true, expand: page.expand}

    while (page.active) {
        let dashboard = page.dashboard
        while (dashboard.remaining > 0) {
            await delay(1000)
            if (dashboard.remaining > 0) {
                dashboard.remaining--
            }
        }
        if (State.auth.ready && (dashboard.live || params.initialize)) {
            await renderDash(params)
        }
        dashboard.remaining = page.refresh
        params = {}
    }
}

async function save() {
    page.saving = true
    page.dashboard.range = page.range
    clearNeedSave()
    await Dashboard.set(page.dashboard)
    await Dashboard.update(page.dashboard)
    page.saving = false
    await renderDash({initialize: true})
}

async function editDashboard(dashboard) {
    page.showDashEdit = dashboard ? true : false
    if (dashboard === undefined) {
        await prepDashboard()
        State.app.setNeed('dash', 'reload')
        //  Force a complete repaint incase frameless changes
        //  FUTURE - should do this also if the widget css changes
        page.key = !page.key
        await renderDash({initialize: true})
    } else if (dashboard) {
        dashboard.range = page.range
        page.dashboard = Object.assign({}, dashboard)
    }
}

/*
    Control the widget edit panel. Widget is set for open, null for close.
 */
async function editWidget(widget) {
    page.showWidgetEdit = widget ? true : false
    if (widget) {
        if (!widget.id) {
            let cloudId = State.app.prefer('cloudId')
            widget = {type: 'graph', css: [], range: {}, cloudId, anchor: {}}
            if (!widget.cloudId && State.config.builder) {
                widget.cloudId = 'builder'
            }
        }
        //  Open panel
        page.widget = widget
    } else {
        //  FUTURE - should do this if the widget css changes
        if (widget !== null) {
            page.widget = {value: null}
            page.key = !page.key
            await save()
        }
    }
}

//  Called for apply
async function applyWidget(widget) {
    let dashboard = page.dashboard
    if (widget) {
        let index = dashboard.widgets.findIndex((v) => v.id == widget.id)
        if (index >= 0) {
            dashboard.widgets[index] = widget
        } else {
            createWidget(dashboard, widget)
        }
    }
    page.hasMetrics =
        State.config.features?.dash?.metrics &&
        page.dashboard.widgets.filter((w) => w.metric).length
            ? true
            : false
    await renderDash({initialize: true})
    setNeedSave()
}

async function removeWidget(widget) {
    let index = page.dashboard.widgets.findIndex((w) => w.id == widget.id)
    if (index >= 0) {
        page.dashboard.widgets.splice(index, 1)
    }
    await renderDash({initialize: true})
    setNeedSave()
}

function canSave() {
    return can('admin') || State.auth.orgName == 'personal'
}

function setNeedSave() {
    if (canSave() && page.ready) {
        State.app.setNeed('save')
    }
}

function clearNeedSave() {
    State.app.setNeed('save', false)
}

//  Called for widget resize, move
async function updateWidgets(list) {
    let dashboard = page.dashboard
    for (let widget of list) {
        let index = dashboard.widgets.findIndex((v) => v.id == widget.id)
        if (index >= 0) {
            dashboard.widgets.splice(index, 1, widget)
        } else {
            createWidget(dashboard, widget)
        }
    }
    await renderDash({initialize: true, fetch: true})
    State.app.setNeed('save')
}

//  Private Functions

function createWidget(dashboard, widget, show = true) {
    let dv = dashboard.widgets.find((v) => v.id == widget.id)
    if (!dv) {
        dashboard.widgets.push(widget)
    }
}

async function confirmCloseWidgetEdit(discard) {
    let widget = widgetEdit.value?.page?.widget
    if (discard && widget && !widget.id && (widget.css.length || widget.model || widget.metric)) {
        if (await confirm.value.ask(`Do you want to discard changes? `)) {
            return true
        }
        return false
    }
    return true
}
</script>

<template>
    <div class="dash-design">
        <Toolbox v-if="page.dashboard.design && !page.dashboard.full" :dashboard="page.dashboard" :container="container" />
        <div class="dash">
            <ToolbarWidget
                v-if="page.ready && page.dashboard.design"
                ref="toolbar"
                class="dash-toolbar"
                :class="`${page.dashboard.full ? 'full' : ''}`"
                :dashboard="page.dashboard"
                :widget="page.toolbar"
                :state="State" />

            <div
                v-if="page.canDash"
                :key="page.key"
                class="dash-container"
                :class="`${page.dashboard.full ? 'full' : ''} ${
                    page.dashboard.design ? 'design' : 'fixed'
                } ${page.dashboard.emulate == 'Mobile' ? 'emulate-mobile' : ''} ${
                    page.dashboard.layout == 'grid' && page.dashboard.snap && page.dashboard.design
                        ? 'grid'
                        : ''
                } ${State.app.dark ? 'dark' : 'light'} `"
                fill-height
                ref="container">
                <Widgets
                    v-if="page.ready"
                    class="dash-widgets"
                    :dashboard="page.dashboard"
                    :layout="page.dashboard.layout"
                    :range="page.range"
                    @remove="removeWidget"
                    @edit="editWidget"
                    @update="updateWidgets"
                    ref="widgets" />
            </div>

            <vu-panel
                v-model="page.showWidgetEdit"
                :confirm="confirmCloseWidgetEdit"
                @close="editWidget(null)"
                :widths="['600px']"
                :keyboard="false">
                <WidgetEdit
                    ref="widgetEdit"
                    v-model="page.widget"
                    :dashboard="page.dashboard"
                    @input="editWidget"
                    @apply="applyWidget"
                    @remove="removeWidget" />
            </vu-panel>

            <vu-panel
                v-model="page.showDashEdit"
                @close="editDashboard(null)"
                :widths="['550px']"
                :keyboard="false">
                <DashEdit :dashboard="page.dashboard" :range="page.range" @input="editDashboard" />
            </vu-panel>

            <div v-if="!page.ready">
                <div class="loading">loading ...</div>
            </div>
            <vu-confirm ref="confirm" />
        </div>
    </div>
</template>

<style lang="scss">
.dash-design {
    display: flex;
    flex-direction: row;
    .dash {
        width: 100%;

        .dash-toolbar {
            background: rgb(var(--v-theme-background-lighten-1)) !important;
            border-bottom: 1px solid rgb(var(--v-theme-border-lighten-1));
            padding: 14px 32px 6px 32px;
            width: 100%;
            @media (max-width: 640px) {
                padding-left: 20px;
            }
        }
        .dash-toolbar.full {
            padding-left: 48px;
        }
        .dash-container {
            background: rgb(var(--v-theme-background));
            width: 100%;
            height: 100%;
            max-width: none;
            padding: 20px;
        }
        .dash-container.grid.light {
            background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px),
                linear-gradient(180deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px);
            background-size: 20px 20px;
            background-repeat: repeat;
        }
        .dash-container.grid.dark {
            background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
                linear-gradient(180deg, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
            background-size: 20px 20px;
            background-repeat: repeat;
        }
        .dash-container.emulate-mobile.design:not(.full) {
            width: 430px;
            height: 932px;
            position: absolute;
            _left: 282px;
        }
        .dash-container.emulate-mobile:not(.design):not(.full) {
            width: 430px;
            height: 932px;
            position: absolute;
            left: 160px;
        }
        .dash-container.emulate-mobile.full {
            width: 430px;
            height: 932px;
            position: absolute;
            left: 0;
        }
        .dash-container.full.fixed {
            padding: 0 !important;
        }
        .dash-container.full.design {
            padding: 20px 0 0 20px !important;
        }
        .dash-widgets {
            padding: 0;
            margin: 0px;
            --base-width: 100%;
            width: round(down, var(--base-width), 20px);
        }
        .dispatcher {
            width: 100%;
        }
        .dashboard-update {
            margin-left: 4px;
            color: rgba(80, 80, 80, 0.5);
            font-size: 12px;
        }
        .v-progress-circular__content {
            font-size: 12px;
        }
    }
}
</style>
