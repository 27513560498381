<script setup>
import {onBeforeUnmount, onMounted, reactive} from 'vue'
import {State} from '@/paks/vu-app'

defineExpose({close, open})

const page = reactive({
    loading: true,
})

onMounted(async () => {
    await open()
    page.loading = false
})

onBeforeUnmount(() => {
    close()
})

function close() {
    State.ref.chat.close()
}

async function open() {
    await State.ref.chat.open()
}
</script>

<template>
    <div class="help-chat">
        <v-progress-circular v-if="page.loading" :size="50" color="primary" indeterminate />
    </div>
</template>

<style lang="scss">
.help-chat {
    display: block;
    height: 100%;
    margin-top: 48%;
    text-align: center;
    .v-progress-circular {
        color: rgb(var(--v-theme-text));
    }
}

.gist-messenger-iframe {
    right: 105px !important;
    bottom: 50px !important;
}
</style>
