<script setup>
import ForgotAsk from './ForgotAsk.vue'
import ForgotConfirm from './ForgotConfirm.vue'
import {reactive} from 'vue'

const emit = defineEmits(['input'])
const page = reactive({
    asked: false,
    auth: {},
})

async function asked(auth) {
    if (auth) {
        page.asked = true
    } else {
        cancel()
    }
}

async function confirmed(ok) {
    emit('input')
}

async function cancel() {
    emit('input')
}
</script>

<template>
    <div>
        <ForgotAsk v-if="!page.asked" v-model="page.auth" @input="asked" />
        <ForgotConfirm v-if="page.asked" v-model="page.auth" @input="confirmed" />
    </div>
</template>
