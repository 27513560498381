<script setup>
import {onMounted, reactive, ref} from 'vue'
import {onBeforeRouteLeave} from 'vue-router'
import {Feedback, can, navigate} from '@/paks/vu-app'
import Dates from '@/paks/js-dates'
import TicketEdit from '../Tickets/TicketEdit.vue'
import {Account, Ticket} from '@/models'

const CaseFields = [
    {name: 'edit', icon: true},
    {name: 'status'},
    {name: 'email'},
    {name: 'subject', width: '50%'},
    {name: 'id', format: (v) => v.slice(-6)},
    {name: 'updated', format: (v) => Dates.format(v, 'mmm dd yyyy, HH:MM:ss')},
    {name: 'severity', align: 'center'},
    // {name: 'accountId', title: 'Account'},
    // {name: 'assigned'},
]

const page = reactive({
    accounts: [],
    allTickets: [],
    filter: 'open',
    ready: false,
    select: {actions: {Add: 0, Edit: 1, Delete: 2}, multi: true, property: 'select'},
    showTicket: false,
    ticket: {},
    tickets: [],
})

//  Component References
const confirm = ref(null)
const tickets = ref(null)
const edit = ref(null)

onMounted(async () => {
    if (!can('support')) {
        navigate('/')
        return
    }
    page.accounts = await Account.find({}, {index: 'gs1'})
    page.ready = true
})

onBeforeRouteLeave(() => confirmCloseEdit(true))

async function getTickets(args) {
    let props = {}
    if (page.filter == 'open') {
        args.where = '${status} <> {closed}'
    } else if (page.filter == 'closed') {
        props.status = 'closed'
    } else if (page.filter == 'active') {
        props.status = 'progressing'
    }
    let list = await Ticket.fetch(props, args)
    page.tickets = list
        .sort((a, b) => {
            if (a.updated < b.updated) {
                return -1
            } else if (a.updated > b.updated) {
                return 1
            } else {
                return 0
            }
        })
        .reverse()
    for (let ticket of page.tickets) {
        let account = page.accounts.find((a) => a.id == ticket.accountId)
        if (account) {
            ticket.email = account.email
        }
    }
    page.allTickets = page.tickets.slice(0)
    return page.tickets
}

async function filter(pattern) {
    page.filter = pattern
    if (tickets.value) {
        await tickets.value.update()
    }
}

async function deleteTicket(items) {
    if (!(await confirm.value.ask(`Do you want to delete the selected ticket?`))) {
        return
    }
    for (let item of items) {
        await Ticket.remove(item)
    }
    tickets.value.update()
    Feedback.info('Ticket Deleted')
}

async function editTicket(ticket) {
    page.showTicket = ticket ? true : false
    page.ticket = ticket || {}
    if (!ticket) {
        tickets.value.update()
    }
}

async function clickTicket({action, column, item, items}) {
    if (action == 'add') {
        editTicket({})
    } else if (action == 'edit') {
        for (let item of items) {
            editTicket(item)
            break
        }
    } else if (action == 'delete') {
        if (await confirm.value.ask(`Do you want to remove ${item.name} ? `)) {
            await deleteTicket(items)
        }
    } else {
        editTicket(item)
    }
}

async function confirmCloseEdit(discard) {
    if (!edit.value) return true
    let ticket = edit.value?.page?.ticket || {}
    let post = edit.value.page.post || {}
    if ((discard && ticket.id == null && ticket.subject) || post.message) {
        if (await confirm.value.ask(`Do you want to discard changes? `)) {
            return true
        }
        return false
    }
    return true
}
</script>

<template>
    <div class="page cases">
        <vu-confirm ref="confirm" />
        <div v-if="page.ready">
            <vu-table
                title="Support Cases"
                name="tickets"
                options="dynamic,filter,refilter,toolbar"
                sort="created:desc"
                ref="tickets"
                width="100%"
                :data="getTickets"
                :fields="CaseFields"
                :pageSize="10"
                :select="page.select"
                @click="clickTicket">
                <template v-slot:table-col-edit="props">
                    <v-icon icon="$edit" size="24" @click.stop="editTicket(props.item)" />
                </template>
                <template v-slot:more="props">
                    <v-btn class="action" @click="filter('active')">Active</v-btn>
                    <v-btn class="action" @click="filter('open')">Open</v-btn>
                    <v-btn class="action" @click="filter('closed')">Closed</v-btn>
                    <v-btn class="action" @click="filter('all')">All</v-btn>
                </template>
            </vu-table>
            <vu-panel
                v-model="page.showTicket"
                @close="editTicket"
                :widths="['900px']"
                :confirm="confirmCloseEdit">
                <TicketEdit :arg="page.ticket" @input="editTicket" ref="edit" />
            </vu-panel>
        </div>
    </div>
</template>

<style lang="scss">
.cases {
    .v-input--switch {
        .v-input__slot {
            width: auto;
        }
    }
    .action {
        margin-right: 10px !important;
        border: 1px solid rgb(var(--v-theme-border));
        background: none !important;
        box-shadow: none !important;
    }
    .metric {
        margin: 20px 0 20px 0;
        font-size: 1.25rem;
    }
}
</style>
