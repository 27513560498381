/*
    js-task -- Test if a subjects role can perform the desired task.

    Roles can reference multiple tasks and tasks can reference other roles.
 */

const RECURSE_LIMIT = 50

export default class Task {
    constructor(roles) {
        this.roles = roles
    }

    /*
        Can this role do the given task.

        Tasks may be a task string or may be other tasks/roles that contain other roles[]
     */
    can(role, task) {
        if (task == null || role == task) {
            return true
        }
        if (role == null) {
            role = 'public'
        }
        return this.canDo(this.roles[role], task)
    }

    canDo(tasks, task, depth = 0) {
        if (!tasks) {
            return false
        }
        if (depth > RECURSE_LIMIT) {
            console.error(`Recursion depth exceeded`, {roles: this.roles, task})
            return false
        }
        for (let t of tasks) {
            if (t == task) {
                return true
            }
            if (this.roles[t] && this.canDo(this.roles[t], task, depth + 1)) {
                return true
            }
        }
        return false
    }
}
