<script setup>
import {onMounted} from 'vue'

const props = defineProps({
    widget: Object,
})

onMounted(() => {})

function buttonClick() {}
function clicked() {}
</script>

<template>
    <vu-sign :name="`sign-widget-${widget.header}`" :title="widget.header" color="accent" class="sign-widget">
        <p>{{ widget.value }}</p>
    </vu-sign>
</template>

<style lang="scss">
.sign-widget {
    height: 100%;
    font-size: 1rem;
    .sign-title {
        font-size: 1rem !important;
    }
    border-radius: 0 !important;
}
</style>
