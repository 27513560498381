<script setup>
import {onMounted, reactive, ref} from 'vue'
import {onBeforeRouteLeave} from 'vue-router'
import {Feedback, Progress, State, can, clone, getRoute} from '@/paks/vu-app'
import Query from '@/paks/js-query'
import HostEdit from './HostEdit.vue'
import {Cloud} from '@/models'

const Fields = [
    {name: 'edit', icon: '$edit', width: '5%'},
    {name: 'enable', title: 'Enabled', icon: 'check', align: 'center', width: '5%'},
    {name: 'open', title: 'Open', icon: 'check', align: 'center', width: '5%'},
    {name: 'name'},
    {name: 'region', title: 'Region'},
    {name: 'awsAccount', title: 'Account'},
    {name: 'load'},
    {name: 'hubs'},
    {name: 'tenants'},
    {name: 'devices'},
    {name: 'details'},
]

const page = reactive({
    canAdd: can('admin') && !State.app.suspended,
    clouds: [],
    cloud: {},
    showEdit: false,
    select: null,
})

const route = getRoute()

//  Component references
const confirm = ref(null)
const edit = ref(null)
const table = ref(null)

onMounted(async () => {
    if (page.canAdd) {
        page.select = {
            actions: {Add: 0, Edit: 1, Delete: 2},
            multi: true,
            property: 'select',
        }
    }
    if (route.params.id) {
        let cloud = page.clouds.find((c) => c.id == route.params.id)
        if (cloud) {
            page.cloud = cloud
            editHost(cloud)
        }
    } else if (Query('add')) {
        editHost({})
    }
})

onBeforeRouteLeave(() => confirmCloseEdit(true))

async function getData(args) {
    if (args.reload) {
        await State.cache.update()
    }
    let clouds = State.find('Cloud').filter(c => c.type == "host")

    let list = []
    for (let cloud of Object.values(clouds)) {
        let details
        if (cloud.error) {
            details = cloud.error
        } else if (!cloud.enable) {
            details = `Host disabled.`
        } else if (+cloud.version < State.app.versions.cloud) {
            details = `Host version ${cloud.version} needs updating to version ${State.app.versions.cloud}. Edit and re-save.`
        } else if (cloud.connected) {
            details = 'Operational'
        }
        let item = clone(cloud)
        let plan = State.get('Plan', cloud.planId)

        Object.assign(item, {
            id: cloud.id,
            enable: cloud.enable,
            name: cloud.name,
            region: cloud.region,
            awsAccount: cloud.awsAccount,
            devices: plan ? plan.units : 0,
            details,
        })
        list.push(item)
    }
    page.clouds = list
    return page.clouds
}

async function clicked({action, item, items}) {
    if (action == 'add') {
        editHost({})
    } else if (action == 'edit') {
        for (let cloud of items) {
            editHost(cloud)
            break
        }
    } else if (action == 'delete') {
        for (let item of items) {
            await deleteHost(item)
        }
    } else if (action == 'cell') {
        if (can('admin')) {
            editHost(item)
        }
    }
}

async function deleteHost(cloud) {
    if (
        await confirm.value.ask(
            `Do you want to delete the "${cloud.name}".` +
                `This will delete all associated device data.` +
                `It will not impact your data stored in AWS.`
        )
    ) {
        try {
            Progress.start('repeat')
            await Cloud.remove({id: cloud.id})
            Feedback.info('Host Deleted')
        } catch (err) {
            Feedback.error('Cannot delete host')
            throw err
        } finally {
            await State.cache.update()
            Progress.stop()
        }
    }
}

async function editHost(cloud) {
    page.showEdit = cloud ? true : false
    page.cloud = cloud || {}
    if (!cloud) {
        //  Refresh cloud and plans list
        await State.cache.update()
        //  Workaround
        if (table.value) {
            table.value.update()
        }
    }
}

async function confirmCloseEdit(discard) {
    let saving = page.saving || false
    if (discard && saving) {
        return false
    }
    return true
}

function aws() {
    let url = `https://console.aws.amazon.com/cloudformation/home?filteringStatus=active&filteringText=&viewNested=true&hideStacks=false`
    window.open(url, '_blank')
}
</script>

<template>
    <div class="page host-list">
        <vu-table
            name="host-list"
            options="dynamic,filter,refilter,toolbar"
            ref="table"
            sort="name:asc"
            subtitle="This is the list of device cloud hosts."
            title="Hosts"
            width="100%"
            :data="getData"
            :fields="Fields"
            :select="page.select"
            @click="clicked">
            <template v-slot:more="props">
                <v-btn outlined class="action mr-4" @click="aws">
                    <v-icon icon="$aws" size="large" />
                </v-btn>
                <v-btn color="accent" class="mr-2" id="_add" @click="editHost({})" :disabled="!page.canAdd">
                    Add Host
                </v-btn>
            </template>
        </vu-table>

        <vu-panel v-model="page.showEdit" @close="editHost" :widths="['700px']" :confirm="confirmCloseEdit">
            <HostEdit :id="page.cloud.id" @input="editHost" ref="edit" />
        </vu-panel>

        <vu-confirm ref="confirm" />
    </div>
</template>

<style lang="scss">
.host-list {
    #add {
        color: white !important;
        background: rgb(var(--v-theme-background)) !important;
    }
}
</style>
