<script setup>
import {onMounted, reactive, ref} from 'vue'
import {formatValue} from '@/paks/js-format'

const props = defineProps({
    widget: Object,
    loading: Boolean,
})

const page = reactive({
})

const self = ref(null)
defineExpose({update})

onMounted(() => {
    update()
})

function update() {
}
</script>

<template>
    <vu-div ref="self" class="shape-widget">
    </vu-div>
</template>

<style lang="scss">
.shape-widget {
    container-type: size;
    height: 100%;
    width: 100%;
}
</style>
