<script setup>
import {onBeforeMount, reactive, watch} from 'vue'
import {State, clone} from '@/paks/vu-app'
import {Account} from '@/models'

const props = defineProps({
    id: String,
    source: String,
})
const emit = defineEmits(['input'])

const page = reactive({
    billing: clone(State.auth.account.billing),
    taxRate: 0,
    rules: [],
    saving: false,
})

watch(() => page.billing.invoiceEmail, changeEmail)

onBeforeMount(() => {
    let billing = page.billing
    billing.company = billing.company || State.auth.account.name
    billing.tax = billing.tax || 0
    if (billing.tax && billing.showTax == undefined) {
        billing.showTax = true
    }
    page.taxRate = billing.tax ? (billing.tax * 100).toFixed(5) : 0
})

async function save() {
    let billing = page.billing
    billing.tax = page.taxRate / 100
    let account = await Account.updateBilling({
        id: State.auth.account.id,
        address: billing.address,
        company: billing.company,
        invoiceEmail: billing.invoiceEmail,
        order: billing.order ? billing.order : null,
        subject: billing.subject,
        tax: billing.tax,
        showTax: billing.showTax,
    })
    State.auth.setAccount(account)
    emit('input')
}

function changeEmail() {
    let billing = page.billing
    if (billing.invoiceEmail.match(/<.*>/)) {
        billing.invoiceEmail = billing.invoiceEmail.replace(/^.*\<(.*)\>.*$/, '$1')
    }
}
</script>

<template>
    <vu-form :data="page" :save="save" help="/doc/ui/account/billing/invoice-details.html" title="Invoice Details" :options="{enter:false}">
        <vu-input v-model="page.billing.company" label="Company Name" :rules="page.rules.name" type="text" />
        <vu-input v-model="page.billing.address" label="Billing Address" type="textarea" />
        <vu-input v-if="source != 'billing'"
            v-model="page.billing.order"
            label="Purchase Order"
            placeholder="Purchase order number for your invoices"
            type="text" />
        <vu-input
            v-model="page.billing.invoiceEmail"
            label="Email address to send the invoice"
            type="text"
            :rules="page.rules.email" />
        <vu-input
            v-model="page.billing.subject"
            label="Invoice Email Subject"
            placeholder="Subject line to use with email sending your invoice"
            type="text" />
        <vu-input 
            v-if="page.billing.agreement"
            v-model="page.taxRate"
            label="Withholding tax"
            placeholder="Withholding tax rate"
            suffix="%"
            type="text" 
            cols="6"
            />
        <vu-input 
            v-if="page.billing.agreement"
            class="ml-5"
            v-model="page.billing.showTax"
            cols="6"
            suffix="Show tax line item"
            type="switch" 
            :inset="false"
            />
        <div class="actions">
            <v-btn size="small" color="accent" type="submit" :loading="page.saving">Save</v-btn>
            <v-btn size="small" color="none" @click="emit('input')">Cancel</v-btn>
        </div>
    </vu-form>
</template>

<style lang="scss">
.invoice-edit {
    label {
        color: rgb(var(--v-theme-text));
        font-weight: normal;
        font-size: 1rem;
    }
    h2 {
        margin-bottom: 20px;
        color: #484848;
        font-weight: normal;
        font-size: 1.25rem;
    }
    .v-text-field .v-label {
        padding-bottom: 20px !important;
    }
    .v-textarea {
        textarea {
            background-color: rgba(250, 250, 250, 0.35);
            margin-top: 8px;
            padding: 4px;
        }
    }
}
</style>
