<script setup>
import {getCurrentInstance, nextTick, onBeforeUnmount, reactive, ref, watch, onMounted} from 'vue'
import {Widget} from '@/paks/vu-widgets'
import {State, allow, clone, delay, getModels, getValue, waitRender} from '@/paks/vu-app'
import Expression from '@/paks/js-expression'
import GridLayout from './GridLayout'
import ExactLayout from './ExactLayout'

const {proxy: self} = getCurrentInstance()

const props = defineProps({
    dashboard: Object,
    id: String,
    layout: String,
    range: Object,
})

const page = reactive({
    canDoWidgets: null,
    grid: 4,
    message: null,
    pos: null,
    ready: false,
    updating: false,
    width: null,
    widgets: [],
    zoom: false,
    zoomedWidget: {},
    zoomReady: false,
})

const emit = defineEmits(['edit', 'remove', 'update'])
defineExpose({update})

const DefaultInputHeight = 80
const DefaultHeight = 200
const DefaultWidth = '.24'

let layout = null
let listeners = []

//  Group in a drag {}
let copy = null
let movingElement = null
let movingWidget = null
let startPos = null

const widgetRefs = ref(null)
const zoomedWidget = ref(null)
const {Generic, Metric} = getModels()

watch(() => page.zoom, zoomChanged)

onMounted(async () => {
    if (!State.cache.dashboard.full) {
        page.message = 'Add your first widget ...'
    }
    State.app.setNeed('dash', 'reload')
})

onBeforeUnmount(() => {
    removeListeners()
})

async function prepWidgets() {
    let dashboard = props.dashboard
    page.grid = dashboard.layout == 'grid' ? 20 : 4
    let widgets = []
    for (let widget of dashboard.widgets) {
        widgets.push(widget)
        prepWidget(widget)
    }
    page.widgets = widgets
}

function prepWidget(widget) {
    try {
        let type = widget.type
        let prefix
        if (widget.cloudId) {
            let cloud = State.get('Cloud', widget.cloudId)
            if (cloud) {
                prefix = cloud.name
            } else if (widget.cloudId != 'builder' && State.cache.clouds.length == 1) {
                // if cloud recreated
                widget.cloudId = State.cache.clouds[0].id
            }
        }
        if (!prefix) {
            prefix = widget.namespace
        }
        let dimensions = ''
        if (dimensions) {
            //  Convert the dimensions into a string
            dimensions = widget.dimensions
                .map((d) => {
                    return d
                        .split(',')
                        .map((t) => t.split('=')[1])
                        .join(', ')
                })
                .join(', ')
            if (dimensions !== 'true') {
                if (widget.type == 'numeric') {
                    widget.footer = dimensions
                } else {
                    widget.footer = dimensions
                }
            }
        }
        if (type == 'numeric' || type == 'gauge') {
            widget.value = 0
        } else if (type == 'graph') {
            widget.value = {}
        }
        widget.width = widget.width || DefaultWidth
        if (widget.type == 'input') {
            if (widget.input == 'textarea') {
                widget.height = widget.height || DefaultHeight
            } else {
                widget.height = widget.height || DefaultInputHeight
            }
        } else if (widget.type == 'form') {
            widget.height = widget.height || DefaultInputHeight
        } else {
            widget.height = widget.height || DefaultHeight
        }
        widget.range = widget.range || props.range
        // widget.units = widget.units || getUnits(widget)
    } catch (err) {
        // continue
    }
}

/*
    Update widgets
    Compact will eliminate space between widgets
    Expand will grow widgets to fill horizontal view port
    Fetch is when there is new data (not used here)
    Initialize is for first time 
    Layout is to recalc the layout
    Lazy return if already updating
 */
async function update(
    params = {expand: false, fetch: null, initialize: false, layout: true, lazy: false}
) {
    while (page.updating || !State.auth.ready) {
        if (params.lazy) return 'updating'
        await delay(100)
    }
    page.updating = true
    params.range = props.range
    try {
        if (params.initialize) {
            await prepWidgets()
            await waitRender()
        }
        if (params.fetch !== false) {
            await fetchData()
        }
        if (params.layout != false) {
            if (self.$el.clientWidth <= page.grid) {
                await waitRender()
            }
            page.width = align(self.$el.clientWidth, page.grid, 'down')
            if (props.layout == 'grid') {
                layout = new GridLayout({dash: props.dashboard, parent: self, vw: page.width})
                page.widgets.forEach((w) => (w.z = 0))
            } else {
                layout = new ExactLayout(self)
            }
            let widgets = await showWidgets()
            layout.run(widgets, params)
        }
        page.ready = true
        await propagate(params)
    } catch (err) {
        console.log('Widget update exception', {err})
    } finally {
        page.updating = false
    }
}

/*
    Fetch data for widgets
 */
async function fetchData() {
    let widgets = page.widgets
    if (widgets == null || widgets.length == 0) return

    let items = []
    for (let widget of widgets) {
        if (!widget.metric && !widget.model) {
            continue
        }
        if (props.dashboard.live == false && widget.value) {
            continue
        }
        let item = allow(widget, [
            'accumulate',
            'cloudId',
            'dimensions',
            'field',
            'namespace',
            'metric',
            'model',
            'period',
            'statistic',
        ])
        let range = widget.range?.override ? widget.range : props.range
        item.period = getValue(range.period || 86400)
        if (range.anchor == 'absolute') {
            item.start = getStart(range).getTime()
        }
        item.accumulate = widget.type == 'timeline' || widget.type == 'graph' ? false : true
        items.push(item)
    }
    let results
    if (items.length) {
        try {
            results = await Metric.fetch({items})
        } catch (err) {}
    }
    if (!results) {
        return
    }
    let index = 0
    for (let widget of widgets) {
        if (!widget.metric && !widget.model) {
            continue
        }
        if (props.dashboard.live == false && widget.value) {
            continue
        }
        let result = results[index++]
        if (!result || !result.length) {
            continue
        }
        let type = widget.type
        if (widget.metric && widget.namespace != 'Database') {
            let metric = result[0] || {}
            if (type == 'graph') {
                let data = metric.points
                    ? metric.points.map((p) => {
                          return {x: p.timestamp, y: p.value}
                      })
                    : []
                widget.value = data
                delete widget.max
            } else if (metric.points?.length) {
                widget.value = metric.points.at(-1).value
                widget.max = Math.pow(10, (Math.abs(widget.value - 0.51).toFixed(0) + '').length)
            } else {
                widget.value = metric.value || 0
            }
            // widget.units = getUnits(widget)
        } else if (widget.model) {
            if (widget.field && widget.type != 'table') {
                //  FUTURE -- extract the field server/side and receive back points[0] == value
                let item = result[0]
                widget.value = widget.field ? item[widget.field] : item
                if (widget.type == 'graph' || widget.type == 'gauge' || widget.type == 'numeric') {
                    widget.value = parseInt(widget.value)
                }
            } else {
                widget.value = result
            }
        }
    }
}

/*
    Propagate data to the widgets by calling widget.update()
 */
async function propagate(params) {
    //  Allow layout to render
    await waitRender()

    if (widgetRefs.value) {
        for (let wref of widgetRefs.value) {
            //  So that graphWidget can access State
            wref.dark = State.app.dark
            if (wref.update && typeof wref.update == 'function') {
                await wref.update(params)
            } else {
                console.warn(`Widget missing update function`, ref)
            }
        }
    }
}

async function showWidgets() {
    let widgets = []
    for (let widget of page.widgets) {
        if (await showWidget(widget)) {
            widgets.push(widget)
            widget.hide = false
        } else {
            widget.hide = true
        }
    }
    return widgets
}

async function showWidget(widget) {
    if (!widget.show) return true
    let dashboard = props.dashboard
    let expression = new Expression({debug: false})
    let ast = expression.parse(widget.show)
    let context = {
        agent: navigator.userAgent,
        dark: State.app.dark,
        design: props.dashboard.design,
        desktop: !State.app.mobile,
        framed: props.dashboard.framed,
        full: props.dashboard.full,
        height: window.innerWidth,
        language: navigator.language,
        live: props.dashboard.live,
        mobile: State.app.mobile,
        value: widget.value,
        width: window.innerWidth,
        db: async (table, field, props) => {
            try {
                let item = await Generic.get(table, props)
                if (item && item[field] != null) {
                    return item[field]
                }
            } catch (err) {}
            return null
        }
    }
    let show = await expression.run(ast, context, 50)

    /*
        Store if the widget should be shown. If in design mode, the widget will be shown with "X"
     */
    if (dashboard.design) {
        widget.shadow = !show
        return true
    }
    widget.shadow = false
    return show
}


function getStart(range) {
    if (range.anchor == 'absolute') {
        return new Date(range.start)
    }
    let start = new Date(Date.now() - range.period * 1000)
    return getAlignedDate(start)
}

function getAlignedDate(when) {
    when = new Date(when)
    return new Date(
        when.getFullYear(),
        when.getMonth(),
        when.getDate(),
        when.getHours(),
        when.getMinutes()
    )
}

function findWidget(el) {
    let dash = document.getElementById('widgets')
    if (!dash) return {}
    while (el != el.parentElement) {
        let index = Array.prototype.indexOf.call(dash.children, el)
        if (index >= 0) {
            let widget = page.widgets.find((w) => w.id == el.id)
            return {element: el, widget}
        }
        el = el.parentElement
    }
    return {}
}

function createCopy(element) {
    let attach = document.getElementById('dash-attach')
    if (!attach) return null
    let cn = element.cloneNode(false)
    let height = element.offsetHeight || 40
    let width = element.offsetWidth || 300

    cn.style.top = element.offsetTop + 'px'
    cn.style.left = element.offsetLeft + 'px'
    cn.style.height = height + 'px'
    cn.style.width = width + 'px'
    cn.style.display = 'normal'
    cn.className += ' copy-widget'
    cn.id = 'copy-widget'
    attach.appendChild(cn)
    return cn
}

function startMove(e) {
    let {element, widget} = findWidget(e.target)
    if (!element || !widget || e.button == 2 || !props.dashboard.design) {
        return
    }
    if (e.ctrlKey && props.dashboard.layout == 'exact') {
        widget.z = Math.max(...props.dashboard.widgets.filter(w => w.type != 'toolbar').map(w => w.z)) + 1
        layout.run(page.widgets)
        return
    }
    if (e.metaKey) {
        return openZoom(widget)
    }
    if (e.altKey) {
        State.app.setNeed('editWidget', widget)
        return
    }
    if (widget.type == 'toolbar') {
        if (e.button == 2) {
            return
        }
        if (e.target.classList.toString().indexOf('toolbar') < 0) {
            return
        }
    }
    /*
        Create a copied element to move via the mouse
        movingWidget and movingElement are the original widget/element. These will be placed by the layout mgr.
    */
    copy = createCopy(element)
    movingElement = element
    movingWidget = widget
    element.className += ' moving-widget'

    widget.moving = true
    startPos = page.pos = getWidgetPos(e, copy)
    startPos.left = align(startPos.left, page.grid, 'round')
    startPos.top = align(startPos.top, page.grid, 'round')

    setProposed(widget)

    addListener(document, 'mousemove', move)
    addListener(document, 'mouseup', finishMove)
    addListener(document, 'touchmove', move)
    addListener(document, 'touchend', finishMove)
}

async function move(e) {
    if (!copy) return
    let pos = (page.pos = getWidgetPos(e, copy))
    let left = pos.x - startPos.x + startPos.left
    let top = pos.y - startPos.y + startPos.top

    copy.style.top = top + 'px'
    copy.style.left = left + 'px'
    copy.style.cursor = 'move'
    let widget = movingWidget

    let deltaX = pos.x - startPos.x
    let deltaY = pos.y - startPos.y

    setProposed(widget, deltaX, deltaY)

    nextTick(async () => {
        //  This will move the widget as to a proposed layout position
        await layout.run(page.widgets)
    })
}

async function finishMove(e) {
    if (!copy) {
        return
    }
    let pos = getWidgetPos(e, copy)
    let deltaX = pos.x - startPos.x
    let deltaY = pos.y - startPos.y

    let widget = movingWidget
    setProposed(widget, deltaX, deltaY)

    movingElement.style.top = widget.top + 'px'
    movingElement.style.left = widget.left * 100 + '%'
    movingElement.className = movingElement.className.replace(/ moving-widget/, '')

    let attach = document.getElementById('dash-attach')

    removeListeners(['mousemove', 'mouseup', 'touchmove', 'touchend'])

    movingWidget = null
    movingElement = null

    if (deltaX || deltaY) {
        await layout.run(page.widgets, {layout: true})
    }
    delete widget.moving
    delete widget.prior
    delete widget.proposed

    attach.removeChild(copy)
    copy = null

    if (deltaX || deltaY) {
        emit('update', page.widgets)
        widget.moved = new Date()
    }
    let el = document.elementFromPoint(e.clientX, e.clientY)
    if (el && typeof el.click == 'function') {
        el.click()
    }
}

function setProposed(widget, deltaX = 0, deltaY = 0) {
    widget.proposed = {
        _top: Math.max(align(startPos.top + deltaY, page.grid, 'round'), 0),
        _left: Math.max(align(startPos.left + deltaX, page.grid, 'round'), 0),
    }
}

async function resizeWidget(params) {
    let cmd = params.cmd
    let widget = page.widgets.find((w) => w.id == params.id)
    if (!widget) return
    if (cmd == 'start-resize') {
    } else if (cmd == 'resize') {
    } else if (cmd == 'finish-resize') {
        widget.width = Math.min(params.width / page.width, 1)
        widget.height = params.height
        emit('update', page.widgets)
    }
}

function getWidgetPos(event, el) {
    let dash = document.getElementById('widgets')
    let dashRect = dash.getBoundingClientRect()
    let rect = el.getBoundingClientRect()
    let left = rect.left - dashRect.left - 2
    let top = rect.top - dashRect.top
    let width, height, x, y
    if (event.touches) {
        if (event.touches.length) {
            x = event.touches[0].clientX
            y = event.touches[0].clientY
        } else {
            x = page.pos.x
            y = page.pos.y
        }
    } else {
        x = event.clientX
        y = event.clientY
    }
    width = x - rect.left
    height = y - rect.top
    return {height, width, left, top, rect, x, y}
}

async function addWidget(widget) {
    emit('edit')
}

async function editWidget(widget) {
    emit('edit', widget)
}

async function removeWidget(params) {
    let widget = page.widgets.find((w) => w.id == params.id)
    if (widget) {
        emit('remove', widget)
    }
}

async function openZoom(widget) {
    widget = page.zoomedWidget = clone(widget)
    widget.height = 1.0
    widget.width = 1.0
    widget.z = 1
    widget.zoomed = 'zoomed'
    page.zoom = true
    page.zoomReady = true
    for (let i = 0; i < 50; i++) {
        await waitRender()
        await delay(10)
        if (zoomedWidget.value.update && typeof zoomedWidget.value.update == 'function') {
            await zoomedWidget.value.update()
            break
        }
    }
}

async function closeZoom() {
    page.zoom = false
    page.zoomReady = false
    page.zoomedWidget = {}
}

function zoomChanged(visible) {
    if (!visible) {
        closeZoom()
    }
}

function addListener(base, event, fn) {
    listeners.push({base, event, fn})
    base.addEventListener(event, fn)
}

function removeListeners(events) {
    for (let listener of listeners) {
        if (!events || events.indexOf(listener.event) >= 0) {
            listener.base.removeEventListener(listener.event, listener.fn)
        }
    }
    listeners = []
}

function align(v, round = page.grid, dir = 'up') {
    if (round && props.dashboard.snap) {
        if (dir == 'down') {
            v = Math.floor(v / round) * round
        } else if (dir == 'up') {
            v = Math.ceil(v / round) * round
        } else {
            v = Math.round(v / round) * round
        }
    }
    return parseInt(v) - 0
}
</script>

<template>
    <div class="widgets" id="widgets">
        <Widget
            v-show="page.ready"
            v-for="widget of page.widgets"
            :dashboard="props.dashboard"
            :key="widget.id"
            :id="widget.id"
            :value="widget.value"
            :widget="widget"
            ref="widgetRefs"
            @resize="resizeWidget"
            @edit="editWidget"
            @remove="removeWidget"
            @mousedown="startMove"
            @dblclick.stop="openZoom" />

        <div id="dash-attach" />

        <div v-if="page.widgets.length == 0">
            <h2 class="no-widgets" v-if="page.widgets.length == 0" @click="addWidget">
                {{ page.message }}
            </h2>
        </div>

        <v-dialog
            v-model="page.zoomReady"
            overlay-opacity="50%"
            height="100%"
            width="100%"
            content-class="zoom">
            <v-container>
                <Widget
                    v-if="page.zoomReady"
                    class="zoomed"
                    ref="zoomedWidget"
                    :dashboard="props.dashboard"
                    :id="page.zoomedWidget.id"
                    :value="page.zoomedWidget.value"
                    :widget="page.zoomedWidget"
                    @remove="closeZoom">
                    <template v-slot:widgets="widget"></template>
                </Widget>
            </v-container>
        </v-dialog>
    </div>
</template>

<style lang="scss">
.widgets {
    height: 100%;
    width: 100%;
    min-height: 500px;
    min-width: 20px;
    margin: 0;
    padding: 0;
    display: block;
    position: relative;
    padding-bottom: 40px;
    .widget {
        position: absolute;
        height: initial;
    }
    .copy-widget {
        border: 2px solid rgb(var(--v-theme-border-darken-2)) !important;
        border: 2px solid #222222 !important;
        border-radius: 0;
        background-color: rgb(var(--v-secondary)) !important;
        opacity: 0.75;
        z-index: 10 !important;
    }
    .moving-widget {
        opacity: 0.75;
        z-index: 5 !important;
    }
    .no-widgets {
        padding: 20px 0 0 40px;
        font-size: 2rem;
        color: rgb(var(--v-theme-text)) !important;
    }
}
.zoom {
    height: 100%;
    width: 100%;
    margin: 0 !important;
    max-height: 100% !important;
    max-width: 100% !important;
    border-radius: 0 !important;
    height: 100%;
    background: rgb(var(--v-theme-secondary-lighten-3));
    .v-container {
        height: 100% !important;
        width: 100% !important;
        padding: 0 !important;
        max-width: 100% !important;
        display: block;
        .widget {
            height: 100%;
            width: 100%;
        }
        .zoomed {
            height: 100%;
            width: 100%;
        }
    }
}
</style>
