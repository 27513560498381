<script setup>
import {Device} from '@/models'
import {onMounted, reactive} from 'vue'
import {Progress} from '@/paks/vu-app'

const props = defineProps({
    id: String,
})
const page = reactive({
    device: {},
    releasing: false,
    removing: false,
})
const emit = defineEmits(['input'])

onMounted(async () => {
    page.device = await Device.get({id: props.id})

})

async function release() {
    Progress.start()
    page.releasing = true
    await Device.release({id: page.device.id})
    page.releasing = false
    Progress.stop()
    emit('input')
}

async function remove() {
    Progress.start()
    page.removing = true
    await Device.remove({id: page.device.id})
    page.removing = false
    Progress.stop()
    emit('input')
}
</script>

<template>
    <vu-form :data="page" title="Release Device" help="/doc/ui/devices/release.html">
        <vu-input v-model="page.device.id" label="Device Claim ID" />
        <div class="actions">
            <v-btn v-if="page.device.id" :disabled="!page.device.cloudId" color="accent" @click="release" :loading="page.releasing">Release</v-btn>
            <v-btn v-if="page.device.id" color="accent" @click="remove" :loading="page.removing">Remove</v-btn>
            <v-btn color="none" @click="emit('input')">Cancel</v-btn>
        </div>
    </vu-form>
</template>
