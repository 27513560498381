/*
    Device.js -
 */

import {Rest} from '@/paks/js-rest'

export default new Rest('Device', {
    claim:   { method: 'POST', uri: '/:controller/claim' },
    release: { method: 'POST', uri: '/:controller/release' },
    removeAll: { method: 'POST', uri: '/:controller/removeAll' },
}, {cache: false, service: '/api'})
