<script setup>
import {onMounted, reactive, ref} from 'vue'
import {formatValue} from '@/paks/js-format'

const props = defineProps({
    widget: Object,
    loading: Boolean,
})

const page = reactive({
    appendIcon: null,
    color: null,
    icon: null,
    prependIcon: null,
    rounded: null,
    size: null,
    text: null,
    variant: null,
})

const self = ref(null)
defineExpose({click, update})

onMounted(() => {
    update()
})

function update() {
    let el = self.value.$el
    let widget = props.widget
    page.text = formatValue(widget.text || widget.value, widget.format)

    page.background = el.style['background'] || 'accent'
    page.icon = el.style['--w-icon']
    page.elevation = el.style['--w-elevation'] || 0
    page.prependIcon = el.style['--w-prepend-icon']
    page.appendIcon = el.style['--w-append-icon']
    page.rounded = el.style['--w-rounded']
    page.variant = el.style['--w-variant']

    if (widget.prefix) {
        let [kind, text] = widget.prefix.split(':')
        if (kind == 'icon') {
            page.prependIcon = text
        } else {
            page.prefix = text
        }
    }
    if (widget.suffix) {
        let [kind, text] = widget.suffix.split(':')
        if (kind == 'icon') {
            page.appendIcon = text
        } else {
            page.suffix = text
        }
    }
}

function click() {}
</script>

<template>
    <vu-div ref="self" class="button-widget">
        <v-btn
            v-if="page.text"
            class="button widget-text-button"
            :color="page.background"
            :elevation="page.elevation"
            :append-icon="page.appendIcon"
            :prepend-icon="page.prependIcon"
            :loading="loading"
            :rounded="page.rounded"
            :variant="page.variant">
            {{ page.text }}
        </v-btn>
        <v-btn
            v-else-if="page.icon"
            class="button widdget-icon-button"
            :color="page.background"
            :elevation="page.elevation"
            :icon="page.icon"
            :loading="loading"
            :variant="page.variant"></v-btn>
    </vu-div>
</template>

<style lang="scss">
.button-widget {
    container-type: size;
    height: 100%;
    width: 100%;
    text-align: center;
    .v-btn {
        height: 100cqh !important;
        width: 100cqw !important;
        position: relative;
        top: 50cqh;
        transform: translateY(-50%);
        background-color: rgb(var(--v-theme-accent));
        color: rgb(var(--v-theme-on-accent));
        border: none;
        .v-icon {
            font-size: max(10px, min(min(9cqw, 25cqh), 128px));
        }
    }
    .v-btn.widget-text-button {
        font-size: max(10px, min(min(9cqw, 20cqh), 144px));
        font-weight: bold;
    }
    .v-btn.widget-icon-button {
        font-size: max(10px, min(min(20cqw, 30cqh), 144px));
        font-weight: bold;
    }
}
</style>
