/*
    AuthState.js -- Auth state management
 */
import {State} from '@/paks/vu-state'
import {allow} from '@/paks/js-polyfill'
import {Auth} from './Auth'

const PersistState = []

export default class AuthState {
    account = null
    accountName = null
    accountId = null
    assume = null
    attributes = {}
    auth = null
    authenticated = null //  Authenticated with cognito
    authorized = null //  Authorized with the app
    email = null
    first = null
    id = null
    last = null
    owner = null
    ready = null
    role = null
    tokens = null
    tokensRefreshed = new Date()
    user = null
    username = null
    userId = null

    saveState(state) {
        return allow(state, PersistState)
    }

    loadState(state) {
        return allow(state, PersistState)
    }

    get details() {
        return {
            attributes: this.attributes,
            email: this.email,
            first: this.first,
            id: this.id,
            last: this.last,
            accountName: this.accountName,
            role: this.role,
            user: this.user,
        }
    }

    /*
        Clear Auth, User and Account
     */
    clearAuth() {
        this.ready = false
        this.account = null
        this.accountName = null
        this.accountId = null
        this.assume = null
        this.authenticated = null
        this.authorized = null
        this.auth = null
        this.attributes = null
        this.email = null
        this.first = null
        this.last = null
        this.role = null
        this.user = null
        this.usename = null
        this.userId = null
        State.cache.reset()
    }

    setAccount(account) {
        if (this.account == account) {
            return
        }
        this.account = account
        this.accountId = account.id
        this.accountName = account.name
    }

    /*
        Save cognito attributes after authentication
        Called by Auth.js:authenticate()
    */
    setAuthState(auth, tokens) {
        if (!auth) return
        this.attributes = auth.attributes
        let attributes = auth.attributes
        if (attributes) {
            if (attributes.email) {
                this.email = attributes.email
            }
            if (attributes.given_name) {
                this.first = attributes.given_name
            }
            if (attributes.family_name) {
                this.last = attributes.family_name
            }
        }
        this.auth = auth
        this.id = auth.id || auth.username
        if (auth.role) {
            if (auth.user) {
                this.setUser(auth.user)
            } else if (auth.username) {
                this.setUser(auth)
            }
        }
        this.authenticated = true
        this.setTokens(tokens)
    }

    setAuthorized(value) {
        this.authorized = value
    }

    setReady(value) {
        this.ready = value
    }

    setTokens(tokens) {
        this.tokens = tokens
        this.tokensRefreshed = new Date()
    }

    setUser(user) {
        if (user.id == this.userId) {
            return
        }
        this.user = user
        this.userId = user.id
        this.role = user.role
        this.email = user.email
        this.username = user.username
        this.admin = Auth.can('admin')
        this.owner = Auth.can('owner')
    }

    getInvite() {
        return State.app.location.query.invite
    }

    setInvite(value) {
        return (State.app.location.query.invite = value)
    }
}
