<script setup>
import {getCurrentInstance, onMounted, provide, ref} from 'vue'
import {Feedback, Progress, Rules, waitRender} from '@/paks/vu-app'

const emit = defineEmits(['submit'])

const {proxy: self} = getCurrentInstance()

const props = defineProps({
    classes: String,
    confirm: [String, Function],
    data: Object,
    error: Function,
    flat: Boolean,
    help: {type: String, default: null},
    options: {type: Object, default: () => ({progress: 'repeat'})},
    postSave: Function,
    preSave: Function,
    save: Function,
    styles: String,
    title: String,
})

//  Component references
const confirm = ref(null)
const form = ref(null)
const validate = ref(null)

//  Gather the input components
const inputs = ref({})
provide('inputs', inputs)

defineExpose({confirm, inputs, save, validate})

onMounted(() => {
    if (props.data) {
        //  This causes input errors until input satisfies 
        // props.data.rules = Object.assign({}, Rules)
    }
})

async function enter() {
    if (props.options.enter !== false) {
        await save()
    }
}

async function save() {
    if (props.data) {
        props.data.errors = props.data.errors || []
        props.data.saving = true
        props.data.rules = Object.assign({}, Rules)
        await waitRender()
    }
    let val = validate.value
    let error

    try {
        if (val && !(await val.check(form.value))) {
            throw new Error('One or more fields do not validate')
        }
        if (props.preSave && !(await props.preSave(val))) {
            throw new Error('One or more fields do not validate')
        }
        if (val && val.hasErrors()) {
            throw new Error('Form has errors')
        }
        if (props.confirm) {
            if (typeof props.confirm == 'function') {
                if (!(await props.confirm(confirm))) {
                    return false
                }
            } else if (typeof props.confirm == 'string') {
                if (!(await confirm.value.ask(props.confirm))) {
                    return false
                }
            }
        }
        if (props.options.progress !== false) {
            Progress.start(props.options.progress, props.options.title)
        }
        await waitRender()
        if (props.save) {
            await props.save(val)
        }
    } catch (err) {
        error = err.message || err
        Feedback.error(error)
        if (val) {
            val.error(error)
            if (props.error) {
                await props.error(err, val)
            }
        }
        return false
    } finally {
        try {
            if (props.postSave) {
                await props.postSave(error, val)
            }
        } catch (err) {
            error = err.message
            Feedback.error(error)
            if (val) {
                val.error(error)
                if (props.error) {
                    await props.error(err, val)
                }
            }
            return false
        } finally {
            if (props.data) {
                props.data.saving = false
            }
            if (props.options.progress !== false) {
                Progress.stop()
            }
        }
    }
    return true
}

function findParent(elt, property, value) {
    while (elt) {
        if (elt[property]) {
            return elt
        }
        elt = elt.$parent
    }
    return null
}

function findComponent(ref, css) {
    const stack = [ref.value]
    while (stack.length > 0) {
        let current = stack.pop()
        if (current.$el && current.$el.classList.contains(css)) {
            // Do something with the component
            return current
        }
        if (current.$refs) {
            stack.push(...Object.values(current.$refs))
        }
    }
}
</script>

<template>
    <v-form
        ref="form"
        class="vu-form"
        :class="props.classes"
        :style="props.styles"
        @keyup.enter="enter"
        @submit.prevent="save">
        <h1 v-if="title">{{ title }}</h1>
        <vu-validate ref="validate" :data="data" />
        <slot></slot>
        <vu-confirm ref="confirm" v-show="false" />
        <vu-help v-if="help" :url="help" v-show="false" />
    </v-form>
</template>

<style lang="scss">
.vu-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px 20px 20px;
    @media (max-width: 640px) {
        padding: 0 10px 10px 10px;
        width: auto;
    }
    column-gap: 12px;
    h1 {
        // Override v-form h1
        margin: 0 0 20px 0px !important;
        font-size: 1.8rem;
        color: rgb(var(--v-theme-text));
        font-weight: normal;
        flex-grow: 1;
        flex-basis: 100%;
    }
    .validate {
        flex: 0 0 100%;
    }
    .actions {
        margin: 16px 0 16px 0;
        flex: 0 0 100%;
        .v-btn {
            margin-right: 10px;
            @media (max-width: 640px) {
                margin-right: 6px;
                padding: 0 6px;
            }
        }
    }
    p {
        margin-bottom: 16px;
    }
    .vrow {
        display: flex;
        width: 100%;
        column-gap: 12px;
        flex-wrap: wrap;
    }
    .vcol-1 {
        flex: 1 1 8%;
    }
    .vcol-2 {
        flex: 1 1 15%;
    }
    .vcol-3 {
        flex: 1 1 23%;
    }
    .vcol-4 {
        flex: 1 1 31%;
    }
    .vcol-5 {
        flex: 1 1 40%;
    }
    .vcol-6 {
        // flex: 1 1 50%;
        flex: 1 1 46%;
    }
    .vcol-7 {
        flex: 1 1 56%;
    }
    .vcol-8 {
        flex: 1 1 64%;
    }
    .vcol-9 {
        flex: 1 1 73%;
    }
    .vcol-10 {
        flex: 1 1 81%;
    }
    .vcol-11 {
        flex: 0 0 90%;
    }
    .vcol-12 {
        flex: 0 0 100%;
    }
}
.page-form {
    max-width: 700px;
    background-color: rgb(var(--v-theme-form));
    border: 1px solid rgb(var(--v-theme-border));
    padding: 28px;
    @media (max-width: 640px) {
        padding: 18px;
    }
}
.flat-form {
    display: block;
    padding: 0 !important;
}
</style>
