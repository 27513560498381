<script setup>
import {onBeforeMount, onMounted, onUnmounted, reactive, ref} from 'vue'
import {fixShadow} from '@/paks/vu-app'
import {Dash, Notice} from '@/paks/vu-dash'
import HomeWizard from './HomeWizard.vue'
import Greeting from './Greeting.vue'

const greeting = ref(null)

const page = reactive({
    showDash: null
})

onBeforeMount(async () => {
    fixShadow()
})

onMounted(async () => {
    page.showDash = !greeting.value.page.visible
})

onUnmounted(() => {
    fixShadow()
})

function showDash() {
    page.showDash = true
}

</script>

<template>
    <div class="home">
        <vu-help url="/doc/ui/dashboard/home.html" />
        <HomeWizard />
        <Notice />
        <Greeting ref="greeting" @click="showDash" />
        <Dash v-if="page.showDash" />
    </div>
</template>

<style lang="scss">
.home {
    height: 100%;
    overflow-x: scroll;
}
</style>
