<script>
/*
    Give user progress feedback

    WARNING: do not import vu-app
 */
import {Feedback} from '@/paks/vu-feedback'

const Durations = {
    short: 3000,
    medium: 5000,
    long: 10000,
    vlong: 30000,
}
const NapTime = 250

class ProgressClass {

    page = reactive({
        active: false,
        bar: false,
        cancel: false,
        classes: '',
        dialog: null,
        duration: Durations.short,
        hint: 'Please wait...',
        inc: 0,
        percent: 1,
        progress: 0,
        ready: true,
        repeat: false,
        seq: 0,
        started: new Date(),
        styling: {width: null, transition: null},
        title: null,
        value: 0,
    })

    clear() {
        let page = this.page
        page.value = 0
        page.active = false
        page.styling = {}
        page.classes = ''
        page.seq++
    }

    start(duration, message, options = {}) {
        let page = this.page
        page.started = new Date()
        page.title = message
        page.hint = options.hint || 'Please wait...'
        page.bar = options.bar
        page.cancel = options.cancel
        if (duration === false) {
            return
        } else if (typeof duration == 'number') {
            duration *= 1000
        } else if (duration == 'repeat') {
            duration = options.duration || Durations.short
            page.repeat = true
        } else if (duration == 'dialog') {
            duration = options.duration || Durations.short
            page.repeat = true
            page.dialog = true
        } else if (typeof duration != 'number') {
            duration = Durations[duration || 'short']
            if (!duration) {
                duration = Durations.short
            }
        }
        if (duration < NapTime || duration > 9999999999) {
            duration = Durations.short
        }
        page.active = true
        page.value = 0
        page.duration = duration
        page.inc = duration / NapTime
        page.classes = page.classes.replace(/fade/g, '')
        page.seq++
    }

    stop(msg) {
        let page = this.page
        page.dialog = null
        page.repeat = false
        page.classes += ' fade'
        page.value = 100
        page.active = false
        page.started = null
        page.message = null
        page.seq++
        if (msg) {
            if (typeof msg == 'string') {
                Feedback.info(msg)
            } else if (msg.info) {
                Feedback.info(msg)
            } else if (msg.error) {
                Feedback.error(msg)
            }
        }
    }

    message(message, hint = '') {
        let page = this.page
        page.title = message
        page.hint = hint || page.hint
        page.seq++
    }

    async cancel() {
        let page = this.page
        if (typeof page.cancel == 'function') {
            await page.cancel()
        }
        this.stop()
    }
}
const Progress = new ProgressClass()
export {Progress}
</script>

<script setup>
import {reactive, watch} from 'vue'

const page = Progress.page

watch(() => page.seq, update, {deep: true})

async function update() {
    do {
        while (page.active && page.value <= 100) {
            setWidth(page.value)
            if (page.bar) {
                page.percent = (((Date.now() - page.started.getTime()) / page.duration) * 100).toFixed(0)
                if (page.percent == 0) {
                    page.percent = 1
                } else if (page.percent > 100) {
                    page.percent = 100
                }
            }
            await delay(NapTime)
            page.value = page.value + 100 / page.inc
        }
        await delay(NapTime)
        page.value = 0
        setWidth(0)
    } while (page.repeat)
    page.classes += ' fade'
    page.active = false
}

function setWidth(width) {
    page.classes = page.classes.replace(' fade', '')
    if (width > 100) {
        width = 100
    }
    if (width > 0) {
        page.styling.transition = 'width 1s linear 0s'
        page.styling.width = `${width}%`
    } else {
        page.styling.transition = 'none'
        page.styling.width = '0%'
    }
}

function outside(v) {
    return false
}

//  Inline here to avoid cross-dep with vu-app
async function delay(time) {
    return new Promise(function (resolve, reject) {
        setTimeout(() => resolve(true), time)
    })
}
</script>

<template>
    <div class="progress" v-show="page.ready">
        <div class="progress-bar" :class="page.classes" :style="page.styling" />
        <v-dialog
            v-model="page.dialog"
            persistent
            width="500"
            content-class="progress-dialog"
            @click:outside.stop.prevent="outside">
            <v-card>
                <v-card-title class="headline" _primary-title>{{ page.title }}</v-card-title>
                <v-card-text>
                    <v-progress-linear v-if="page.bar" v-model="page.percent" height="20" color="accent" />
                    <v-progress-circular v-else :size="100" :width="6" indeterminate color="teal"></v-progress-circular>
                    <p class="hint">{{ page.hint }}</p>
                    <v-btn v-if="page.cancel" @click="Progress.cancel()" class="mt-4 pa-4 cancel">Cancel</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<style lang="scss">
.progress-bar {
    z-index: 201 !important;
    position: fixed;
    background: rgb(var(--v-theme-accent));
    left: 0;
    height: 6px;
    padding: 0;
    margin: 0;
    border-radius: 0;
    opacity: 1;
    transition: width 1.5s linear;
}
.progress-dialog {
    padding: 0;
    .v-card {
        color: white;
        .v-card-title {
            color: white;
        }
        .v-card-text {
            padding: 40px !important;
            text-align: center;
            font-weight: bold;
            font-size: 1.2rem;
            color: rgb(var(--v-theme-text));
        }
        .v-progress-linear {
            margin-top: 20px;
        }
        .hint {
            font-size: 1rem;
            font-weight: normal;
            margin: 30px 0 0 0;
            min-height: 20px;
        }
    }
    .headline {
        background: rgb(var(--v-theme-primary));
        color: white;
        font-weight: bold;
        padding: 16px;
    }
}
.progress-bar.fade {
    width: 100%;
    animation: progress-bar-fade 0.5s ease-in 0.2s;
    animation-fill-mode: forwards;
}
@keyframes progress-bar-fade {
    0% {
        opacity: 0.75;
    }
    100% {
        opacity: 0;
    }
}
</style>
