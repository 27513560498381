<script setup>
import {onMounted, reactive} from 'vue'
import {can, navigate} from '@/paks/vu-app'

const page = reactive({
    items: [],
})

onMounted(async () => {
    if (!can('support')) {
        navigate('/')
        return
    }
    let path = window.location.pathname + window.location.hash.replace(/^#\//, '')
    if (path == '/admin') {
        navigate('/admin/metrics')
        return
    }
    setMenu()
})

function setMenu() {
    page.items = [
        {text: 'Metrics', path: '/admin/metrics', enable: true},
        {text: 'Accounts', path: '/admin/accounts', enable: true},
        {text: 'Customers', path: '/admin/customers', enable: true},
        {text: 'Invoices', path: '/admin/invoices', enable: true},
        {text: 'Trials', path: '/admin/trials', enable: true},
        {text: 'Tasks', path: '/admin/tasks', enable: true},
        {text: 'Hosts', path: '/admin/hosts', enable: true},
    ]
}
</script>

<template>
    <v-row>
        <v-sheet class="submenu">
            <v-list class="pt-4 mt-4">
                <v-list-subheader class="text-right">Admin</v-list-subheader>
                <v-list-item
                    class="text-right"
                    ripple
                    v-for="(item, i) in page.items.filter((i) => i.enable)"
                    :key="i"
                    :to="item.path"
                    :id="'nav-' + item.text">
                    <v-row>
                        <v-col class="text-right align-self-start">
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-col>
                    </v-row>
                </v-list-item>
            </v-list>
        </v-sheet>
        <div class="pane">
            <router-view></router-view>
        </div>
    </v-row>
</template>

<style lang="scss" scoped>
.submenu {
    height: 100vh !important;
    padding-top: 36px;
    width: 150px;
    border-right: 1px solid rgb(var(--v-theme-border-lighten-1));

    .v-list-subheader {
        font-size: 1.25rem;
        padding-left: 20px;
    }
    .v-list-item-title {
        font-size: 1rem;
        color: rgb(var(--v-theme-text));
    }
}
.pane {
    width: max(500px, 100% - 150px);
}
</style>
