<script setup>
/*
    WidgetStyle - Change widget styles
 */
import {onBeforeMount, reactive, watch} from 'vue'
import {State, blend, titlecase} from '@/paks/vu-app'
import CssEdit from './CssEdit.vue'
import Json5 from 'json5'

const props = defineProps({
    widget: Object,
})

const page = reactive({
    dashboard: State.cache.dashboard,
    properties: [],
    rules: [],
    select: {},
    showColors: {},
    tableFields: null,
    types: null,
    widget: {},
})

const Fields = [
    {name: 'accept', hint: ''},
    {name: 'datetime', __hint: 'Date or time'},
    {name: 'field', hint: 'Database item field to receive changes'},
    {name: 'fields', hint: 'Table fields definition (JSON)'},
    {name: 'footer', hint: ''},
    {name: 'form', hint: ''},
    {name: 'format', hint: ''},
    {name: 'header', hint: ''},
    {name: 'items', hint: ''},
    {name: 'label', hint: ''},
    {name: 'max', hint: ''},
    {name: 'min', hint: ''},
    {name: 'multiple', hint: ''},
    {name: 'pivot', hint: 'Enter pivot column'},
    {name: 'placeholder', hint: ''},
    {name: 'prefix', hint: ''},
    {name: 'presentation', hint: ''},
    {name: 'rows', hint: ''},
    {name: 'subtitle', hint: ''},
    {name: 'suffix', hint: ''},
    {name: 'text', hint: ''},
    {name: 'timezone', hint: ''},
    {name: 'url', hint: ''},
    {name: 'validate', hint: ''},
    {name: 'z', hint: 'Z-Index order number'},
]

const emit = defineEmits(['apply'])

onBeforeMount(() => {
    // page.rules = Rules
    let widget = (page.widget = props.widget)
    if (widget.fields) {
        if (typeof widget.fields != 'string') {
            let fields = widget.fields.filter((f) => f)
            if (typeof fields[0] != 'string') {
                widget.fields = Json5.stringify(fields)
            }
        }
    }
    if (!page.widget.id) {
        changeType()
    }
})

function changeType(v) {
    let widget = page.widget
    widget.max = widget.min = null
    widget.prefix = widget.suffix = null
    if (widget.type == 'shape' && !widget.id && widget.css.length == 0) {
        //  Create a default box
        widget.css.push({name: 'border', value: 'solid 1px black'})
    }
}

function getRules(field) {
    if (field == 'field' || field == 'label') {
        return page.rules.required
    }
    return []
}
</script>

<template>
    <v-container fluid class="widget-style pa-0">

        <div class="vrow" v-if="page.dashboard.layout == 'exact'">

            <vu-input
                v-model="page.widget.anchor.vertical"
                label="Vertical Anchor"
                type="radio"
                :items="['None', 'Top', 'Middle', 'Bottom']"
                cols="10" />
            <vu-input
                class="mt-4"
                v-model="page.widget.anchor.verticalOffset"
                label="Offset"
                name="vertical-offset"
                placeholder="Pixels or %"
                type="text"
                cols="2" />
        </div>
        <div class="vrow" v-if="page.dashboard.layout == 'exact'">
            <vu-input
                v-model="page.widget.anchor.horizontal"
                label="Horizontal Anchor"
                type="radio"
                :items="['None', 'Left', 'Center', 'Right']"
                cols="10" />
            <vu-input
                class="mt-4"
                v-model="page.widget.anchor.horizontalOffset"
                label="Offset"
                name="horizontal-offset"
                placeholder="Pixels or %"
                type="text"
                cols="2" />
        </div>

        <div class="vrow">
            <vu-input
                v-if="page.dashboard.layout == 'exact'"
                v-model="page.widget.z"
                label="Z Order"
                type="text"
                cols="6"
            />
            <vu-input
                v-model="page.widget.show"
                label="Show Expression"
                placeholder="Expression to show if true"
                type="text"
                cols="6"
            />
        </div>

        <vu-input
            v-model="page.widget.framed"
            label="Frame"
            type="radio"
            cols="6"
            :items="{Inherit: null, Bare: false, Framed: true}" />

        <CssEdit v-model="page.widget.css" title="CSS Properties" />
    </v-container>
</template>

<style lang="scss">
.widget-style {
    h2 {
        font-size: 1.4rem;
        font-weight: normal;
    }
    .v-alert {
        width: 100%;
    }
    label {
        color: rgb(var(--v-theme-text));
        font-weight: normal;
        font-size: 1.2rem;
    }
    .color-picker {
        margin: 6px 0 16px 0;
        border: solid 1px rgb(var(--v-theme-border));
        max-width: 100% !important;
        width: 100% !important;
    }
    td.editable {
        padding: 4px 7px 4px 7px;
        background: rgba(220, 255, 220, 0.4) !important;
        margin: 2px;
        cursor: text;
        &:focus {
            outline: 2px solid rgba(0, 0, 255, 0.5) !important;
            background: white !important;
            width: calc(100% - 4px);
        }
    }
    .table-cell {
        padding: 4px !important;
        .cell-attribute {
            padding: 3px;
            background: rgba(50, 50, 50, 0.1);
            color: black;
        }
    }
    .table-cell.table-col-name {
        .cell-value {
            display: inline-block;
            width: 100%;
            margin: 1px;
            padding: 3px;
        }
    }
    .table-cell.table-col-value {
        .cell-value {
            display: inline-block;
            width: calc(100% - 20px);
            margin: 1px;
            padding: 3px;
            padding-right: 0;
        }
    }
    .color-icon {
        margin-top: 2px;
        float: right;
        width: 16px;
    }
    .table-title {
        font-size: 1.6rem !important;
    }
    .add {
        font-size: 12px;
    }
    .divider {
        font-size: 1.2rem !important;
        margin-bottom: 16px !important;
        clear: both;
        width: 100%;
    }
    .v-label {
        font-size: 0.9rem;
    }
}
</style>
