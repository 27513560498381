<script setup>
import {onMounted, reactive, ref} from 'vue'
import {Feedback, State, can} from '@/paks/vu-app'
import {Software} from '@/models'
import {arrayBufferToBase64} from '@/paks/js-base64'

const MaxAttachment = 100 * 1024 * 1024

const props = defineProps({id: String})
const emit = defineEmits(['input'])

const clouds = State.cache.clouds.filter((c) => c.type != 'host')
const products = State.find('Product')

const page = reactive({
    canDelete: false,
    files: [],
    products: products,
    rules: [],
    saving: false,
    software: State.get('Software', props.id) || {
        enable: true,
        productId: (products[0] || {}).id,
        version: null,
        description: null,
        policy: 'all',
        limit: 'all',
        percentage: '100%',
        rate: 0,
        period: 0,
    },
    upload: false,
})

//  Component references
const confirm = ref(null)

onMounted(() => {
    let software = page.software
    page.canDelete = can('admin')
    if (software.productId) {
        software.product = State.get('Product', software.productId) || {}
    }
    if (clouds.length == 1) {
        software.cloudId = clouds[0].id
    }
    software.percentage = parseInt(software.percentage || '100')
    software.limit = software.limit || 'all'
    software.policy = software.policy || 'all'
    software.rate = software.rate || 'unlimited'
    software.period = software.period || 'forever'
    page.upload = software.image ? false : true
})

async function save() {
    let sw = page.software
    let {name, size, data} = await getAttachment()
    if (!data && !sw.id) {
        throw new Error('Missing software image')
    }
    let policy = (sw.policy || 'all').trim()
    if (policy == 'all') {
        policy = ''
    }
    let limit = sw.limit == 'all' ? 0 : +sw.limit
    let percentage = parseInt(sw.percentage || '100')
    let rate = sw.rate == 'unlimited' ? 0 : +sw.rate
    let period = sw.period == 'forever' ? 0 : +sw.period
    let params = {
        id: sw.id,
        attachment: data ? {name, size, data} : undefined,
        cloudId: sw.cloudId,
        description: (sw.description || '').trim(),
        enable: sw.enable,
        image: name || sw.image,
        policy: policy,
        productId: sw.productId,
        size: size || sw.size,
        version: sw.version.trim(),
        url: sw.url,
        limit: limit,
        percentage: percentage,
        rate: rate,
        period: period,
    }
    if (props.id) {
        await Software.update(params)
    } else {
        await Software.create(params)
    }
    emit('input')
    return true
}

async function deleteSoftware() {
    if (
        !(await confirm.value.ask(
            `Do you want to delete the update "${page.software.description}"? `
        ))
    ) {
        return
    }
    await Software.remove({id: page.software.id}, {refresh: true})
    Feedback.info('Update Deleted')
    emit('input')
}

async function getAttachment() {
    let file = page.files[0]
    if (!file) return {}
    if (file.size > MaxAttachment) {
        throw new Error(
            `Attachment ${file.name} is bigger than max ${MaxAttachment / 1024 / 1024} MB`
        )
    }
    let reader = new FileReader()
    reader.readAsArrayBuffer(file)
    let data = await new Promise((resolve, reject) => {
        reader.onload = async () => {
            try {
                resolve(reader.result)
            } catch (err) {
                reject(err)
            }
        }
    })
    data = arrayBufferToBase64(data)
    return {
        name: file.name,
        size: file.size,
        data,
    }
}
</script>

<template>
    <vu-form
        class="software-edit"
        help="/doc/ui/software/edit.html"
        :data="page"
        :save="save"
        :options="{progress: 'dialog', title: 'Uploading'}"
        :title="`${page.software.id ? 'Modify' : 'Add'} Updates`">
        <vu-input
            v-model="page.software.productId"
            cols="12"
            item-title="name"
            item-value="id"
            label="Product"
            type="select"
            :disabled="page.software.id ? true : false"
            :items="page.products" />

        <vu-input
            class="mt-2"
            v-model="page.software.cloudId"
            cols="12"
            item-value="id"
            item-title="name"
            label="Device Cloud"
            type="select"
            :items="clouds"
            :disabled="page.software.id ? true : false"
            :rules="page.rules.required" />

        <vu-input
            v-model="page.software.version"
            cols="12"
            label="Update Version (SemVer)"
            placeholder="1.0.0"
            type="text"
            :disabled="page.software.id ? true : false"
            :rules="page.rules.version" />

        <!--
        <vu-input v-model="page.upload" label="Upload New Software" type="checkbox" cols="5" />
        <vu-input
            v-if="page.software.image && !page.upload"
            v-model="page.software.image"
            cols="7"
            density="compact"
            label="Image name"
            type="text" 
            :placeholder="`File ${page.software.image.name ? '(currently ' + page.software.image.name + ')' : ''}`"
            :disabled="true"
            />
            -->
        <vu-input
            v-model="page.files"
            cols="7"
            density="compact"
            label="Software Image..."
            show-size
            :clearable="true"
            :hide-details="true"
            :placeholder="`File ${
                page.software.image ? '(currently ' + page.software.image + ')' : ''
            }`"
            type="file" />

        <vu-input
            class="mt-4"
            v-model="page.software.policy"
            cols="12"
            label="Distribution Policy"
            type="text"
            placeholder="Blank for all, otherwise expression: (field == 'value' && major >= 1 && minor <= 4)" />

        <label class="vrow">Update Limits</label>
        <vu-input
            class="mt-4 pr-4"
            v-model="page.software.limit"
            cols="7"
            label="Max devices to update"
            type="text"
            suffix="and"
            placeholder="Number or blank for all" />
        <vu-input
            class="mt-4"
            v-model="page.software.percentage"
            cols="5"
            label="Percentage of devices to apply update"
            suffix="%"
            type="text"
            placeholder="Percentage or blank for all" />

        <label class="vrow">Gradual Rollout</label>
        <vu-input
            class="mt-4 pr-4"
            v-model="page.software.rate"
            cols="7"
            label="Max updates per period"
            placeholder="Number or blank for unlimited"
            suffix="per"
            type="text" />

        <vu-input
            class="mt-4"
            v-model="page.software.period"
            cols="5"
            label="Update period in seconds"
            placeholder="Seconds or blank for unlimited"
            suffix="secs"
            type="text" />

        <vu-input
            class="mt-4"
            v-model="page.software.description"
            cols="12"
            label="Update Description"
            rows="2"
            type="textarea"
        />

        <!--
        <vu-input
            v-model="page.software.url"
            density="compact"
            cols="12"
            label="GitHub Commit URL/Tag"
            placeholder="https://github.com/repo/commit/sha..." />
        -->

        <vu-input v-model="page.software.enable" label="Enable" type="checkbox" cols="12" />

        <div class="actions">
            <v-btn
                v-if="
                    page.software.version &&
                    (page.software.image || page.files[0])
                "
                color="accent"
                type="submit"
                :loading="page.saving">
                Save
            </v-btn>
            <v-btn color="none" @click="emit('input')">Cancel</v-btn>
            <v-btn color="error" v-if="page.software.id && page.canDelete" @click="deleteSoftware">
                Delete
            </v-btn>
        </div>
        <vu-confirm ref="confirm" />
    </vu-form>
</template>

<style>
.software-edit {
    .v-file-input {
        .v-field--active {
            .v-field-label {
                display: none !important;
            }
        }
    }
    label.vrow {
        font-size: 1.1rem;
    }
}
</style>
