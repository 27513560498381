<template>
    <vu-wizard name="home-1" first="welcome">
        <vu-spell
            id="welcome"
            title="Welcome to EmbedThis Builder"
            :nudge="{y: -120, x: 60}"
            ref="first">
            <p>
                EmbedThis Builder is a developer studio for creating and managing Internet connected
                devices.
            </p>
            <p>
                This wizard will highlight some important/tips so you can get the most out of the Builder.
            </p>
            <p>
                To navigate through the wizard, click
                <b>Next</b>
                or press
                <b>Enter</b>
                or the arrow keys.
            </p>
            <p>
                You can resume this tip wizard at anytime by clicking the
                <v-icon icon="$wizard" size="small" />
                icon in the top navigation bar.
            </p>
        </vu-spell>

        <vu-spell id="dashboards" position="#nav-dashboards:right" title="Dashboards" :nudge="{y: -10}">
            <p>
                Dashboards are a customizable panels that displays information Widgets for the Builder and your device clouds.
            </p>
            <p>
                You can create and position widgets to create your own personal dashboards.
            </p>
        </vu-spell>

        <vu-spell id="products" position="#nav-products:right" :nudge="{y: -10}" title="Products">
            <p>
                The Products list displays the product definitions you have created for your
                devices.
            </p>
            <p>
                From the product list you can create new product definitions and modify existing
                products.
            </p>
            <p>
                For each product you can select the device agent you wish to embed, configure your
                subscription and download software.
            </p>
        </vu-spell>

        <vu-spell id="clouds" position="#nav-clouds:right" title="Clouds" :nudge="{y: -10}">
            <p>
                Device clouds are the cloud-side management service for storing device data and
                managing device communications with the cloud.
            </p>
            <p>The device cloud list displays your device clouds.</p>
            <p>From the list you can create, delete and configure your device clouds.</p>
        </vu-spell>

        <vu-spell id="managers" position="#nav-managers:right" title="Managers" :nudge="{y: -10}">
            <p>Device managers are the user interfaces to your devices.</p>
            <p>
                You can create one or more device managers and customize each by naming your
                manager, changing the logo or by uploading a custom build of the manager.
            </p>
        </vu-spell>

        <vu-spell id="updates" position="#nav-updates:right" :nudge="{y: -10}" title="Updates">
            <p>
                The Updates list displays software / firmware upgrades you have defined for your
                devices.
            </p>
            <p>
                From the software list, you can create software updates and control how your devices
                will apply upgrades.
            </p>
        </vu-spell>

        <vu-spell id="support" position="#nav-support:right" :nudge="{y: -10}" title="Support">
            <p>The Builder has an integrated support center.</p>
            <p>
                From the support center, you can configure your support options, purchase support,
                create new support issues and track issues until resolved.
            </p>
        </vu-spell>

        <vu-spell id="account" position="#nav-account:right" title="Account">
            <p>
                Everything regarding your Builder account is here. You can change your profile,
                password, account name and configure your subscription and billing plan.
            </p>
            <p>
                If you have additional users, you can invite them to be part of your organization
                with their own login.
            </p>
        </vu-spell>


        <vu-spell
            id="widgets"
            position="add-widget-icon:left"
            :nudge="{x: -20, y: -30}"
            title="Widgets">
            <p>
                Click the Add Widget icon to add a widget to your dashboard. You can create graph
                and gauge widgets for key device data and metrics.
            </p>
        </vu-spell>

        <vu-spell id="help" position="help:left" :nudge="{x: -6, y: -10}" title="Help">
            <p>
                Click the
                <b>help</b>
                menu option to read the documention, search for tips or open chat dialog so you can
                converse with our support staff.
            </p>
            <p>If we are away, you can send a message and we'll respond within 24 hours.</p>
        </vu-spell>

        <vu-spell id="wizard" position="#launch-wizard" :nudge="{x: -70}" title="Wizard">
            <p>Click the wizard icon to re-launch this help wizard.</p>
        </vu-spell>

        <vu-spell id="thanks" :benudge="{x: 30}" title="Thanks">
            <p>
                Please let us know if you see anything we could do differently or better. We highly
                value all feedback.
            </p>
            <p>
                Contact us at:
                <a href="mailto:support@embedthis.com">support@embedthis.com</a>
                and tell us what you need.
            </p>
        </vu-spell>
    </vu-wizard>
</template>
