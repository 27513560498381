<script setup>
import {onBeforeMount, reactive, ref} from 'vue'
import {Feedback, State, can, clone, getModels} from '@/paks/vu-app'
import Dates from '@/paks/js-dates'

const props = defineProps({modelValue: [Object]})

const page = reactive({
    alert: {},
    context: {},
    canGoto: null,
    members: [],
})

const emit = defineEmits(['input'])
const confirm = ref(null)
const {Alert} = getModels()

onBeforeMount(() => {
    let alert = (page.alert = clone(props.modelValue))
    alert.time = Dates.format(alert.timestamp / 1000, State.config.formats.time)
    alert.severity = alert.severity || 'info'
    let context = page.context = Object.assign({}, alert.context, {
        count: alert.count,
    })
    for (let key of ['pk', 'sk', '_type', '_source', 'accountId', 'cloudId', 'updated']) {
        delete context[key]
    }
})

async function save() {
    let alert = page.alert
    if (!can('admin')) {
        Feedback.error('Insufficient Privilege')
        return
    }
    let params = {
        id: alert.id,
        resolved: alert.resolved,
    }
    await Alert.update(params)
    emit('input')
}

async function deleteAlert() {
    let alert = page.alert
    if (!(await confirm.value.ask(`Do you want to delete the alert "${alert.name}"?`))) {
        return
    }
    if (alert) {
        await Alert.remove({id: alert.id})
    }
    Feedback.info('Alert Deleted')
    emit('input')
}

function getAlertType() {
    let alert = page.alert
    if (alert.severity == 'critical') {
        return 'error'
    }
    return alert.severity || 'info'
}

</script>

<template>
    <div class="alert-edit">
        <vu-form
            class="alert-edit"
            help="/doc/ui/automations/alerts/edit.html"
            ref="form"
            :title="`${page.alert.name} Alert`"
            :data="page"
            :save="save">

            <label class="timestamp vrow">{{ page.alert.time }}</label>

            <v-alert class="vrow" :type="getAlertType()">{{ page.alert.subject }}</v-alert>

            <pre class="vrow">{{ page.alert.message }}</pre>

            <vu-table title="Context" class="mt-5 mb-3" pivot="name" :data="[page.context]" />

            <vu-input
                v-model="page.alert.resolved"
                label="Resolve"
                type="checkbox"
                :hide-details="true"
                cols="6" />

            <div class="actions">
                <v-btn color="accent" type="submit" :loading="page.saving">Save</v-btn>
                <v-btn color="none" @click="emit('input')">Cancel</v-btn>
                <v-btn
                    color="error"
                    v-if="can('admin')"
                    @click="deleteAlert"
                    :loading="page.deleting">
                    Delete
                </v-btn>
            </div>
            <vu-confirm ref="confirm" />
        </vu-form>
    </div>
</template>

<style lang="scss">
.alert-edit {
    .timestamp {
        margin-bottom: 16px;
        font-size: 1.4rem;
    }
    pre {
        margin-top: 28px;
        width: 100%;
        background: rgb(var(--v-theme-background));
        border: solid 1px rgb(var(--v-theme-border-lighten-1));
        text-wrap: pretty;
        padding: 4px;
    }
    .vu-table {
        width: 100%;
        .table-title {
            font-size: 1.4rem !important;
        }
    }
    .actions {
        .v-btn {
            margin-right: 10px;
        }
    }
}
</style>
