<script setup>
import {onMounted, reactive, ref} from 'vue'
import {Feedback, Progress, clone} from '@/paks/vu-app'
import {Account, Plan} from '@/models'

const props = defineProps({arg: Object})

const emit = defineEmits(['input'])

const account = reactive(clone(props.arg))
const billing = reactive(account.billing || {})

//  REPAIR
billing.renewing = billing.renewing != null ? billing.renewing : true

const page = reactive({
    hours: 0,
    suspended: account.suspended ? true : false,
    saving: false,
})

const confirm = ref(null)
let developer = null

onMounted(async () => {
    developer = await Plan.get({accountId: account.id, type: 'Developer'})
    if (developer) {
        page.hours = developer.current.units
    }
})

async function confirmSave(confirm) {
    return await confirm.value.ask(`Do you want to make changes to the account "${account.name}"? `)
}

async function save() {
    let terms = billing.terms || (billing.wire ? 30 : 0)
    let result = await Account.updatePayment({
        id: account.id,
        canWire: billing.wire,
        credit: +billing.credit,
        wire: billing.wire,
        renewing: billing.renewing,
        terms: +terms,
    })
    if (developer && page.hours != developer.current.units) {
        await Plan.update({
            accountId: account.id,
            id: developer.id,
            current: {units: page.hours},
        })
    }
    if (page.suspended != account.suspended) {
        result = await Account.suspend({id: account.id, suspended: page.suspended})
    }
    emit('input')
}

async function closeAccount() {
    if (!(await confirm.value.ask(`Do you want to close the account "${account.name}"? `))) {
        return
    }
    Progress.start('repeat')
    await Account.close({id: account.id})
    Progress.stop()
    Feedback.info(`Account ${account.email} closed`)
    emit('input')
}
</script>

<template>
    <vu-form
        :data="page"
        :confirm="confirmSave"
        :save="save"
        help="/doc/ui/admin/account-edit.html"
        title="Modify Account">
        <vu-input v-model="account.name" :disabled="true" label="Account Name" />
        <vu-input v-model="account.email" :disabled="true" label="Account Email" />
        <vu-input v-model="billing.terms" label="Billing Terms" />
        <vu-input v-model="billing.credit" label="Account Credit" prefix="$" />
        <vu-input v-model="page.hours" label="Developer Support Hours" />
        <vu-input v-model="billing.agreement" label="Agreement" type="checkbox" cols="6" />
        <vu-input v-model="billing.wire" label="Allow Wire Transfers" type="checkbox" cols="6" />
        <vu-input v-model="page.suspended" label="Suspend Account" type="checkbox" cols="6" />
        <vu-input v-model="billing.renewing" label="Renewing" type="checkbox" cols="6" />

        <div class="actions">
            <v-btn color="accent" type="submit" :loading="page.saving">Save</v-btn>
            <v-btn color="none" @click="emit('input')">Cancel</v-btn>
            <v-btn color="error" @click="closeAccount">Close Account</v-btn>
        </div>
        <vu-confirm ref="confirm" />
    </vu-form>
</template>

<style lang="scss">
/*
.account-edit {
    .v-sheet {
        .theme--light.v-chip:not(.v-chip--active) {
            background-color: rgb(var(--v-theme-background-lighten-1));
            color: black;
            margin-top: 1px;
        }
        .v-input {
            margin-top: 2px;
        }
        .col {
            padding-top: 6px;
            padding-bottom: 6px;
        }
        .v-text-field {
            padding-top: 0;
        }
    }
    .v-input--selection-controls__ripple.primary--text {
        color: rgb(var(--v-theme-accent)) !important;
        caret-color: rgb(var(--v-theme-accent)) !important;
    }
}
*/
</style>
