<script setup>
import {onMounted, reactive, ref} from 'vue'
import {Feedback, State, can, money, navigate} from '@/paks/vu-app'
import Dates from '@/paks/js-dates'
import {Account, User} from '@/models'
import AccountEdit from './AccountEdit.vue'

const EmailSubject = `Can I please get some feedback on EmbedThis Builder`
const EmailFeedback = `Hi,

I know you are probably very busy, so I’ll keep this really brief.

You recently tried our EmbedThis Builder, would you like a follow up call or email?

We're happy to help answer any questions or do a Zoom product demo.

Let me know if this would help.

Kind regards
`

const CustomerFields = [
    {name: 'edit', icon: true},
    {name: 'Gist', icon: '$edit'},
    {name: 'Assume', icon: '$login'},
    {name: 'name'},
    {name: 'id'},
    {name: 'email'},
    {name: 'suspended'},
    {name: 'renew', format: (v) => Dates.format(v, 'mmm dd yyyy')},
    {name: 'activity', format: (v) => Dates.format(v, 'mmm dd yyyy')},
    {name: 'created', format: (v) => Dates.format(v, 'mmm dd yyyy')},
    {name: 'metrics.support', title: 'Support', icon: 'check'},
    {name: 'metrics.developer', title: 'Developer', format: (v) => (v || 0) + ' hours'},
    {name: 'metrics.balance', title: 'Balance', format: (v) => '$' + money(v, 0)},
    {name: 'metrics.products', title: 'Products'},
    {name: 'metrics.clouds', title: 'Clouds'},
    {name: 'metrics.users', title: 'Users'},
    {name: 'metrics.plans', title: 'Plans'},
]

const page = reactive({
    account: {},
    accounts: [],
    ready: false,
    select: {actions: {Add: 0, Edit: 1, Delete: 2}, multi: true, property: 'select'},
    showAccount: false,
    ticket: {},
    tickets: [],
})

//  Component References
const confirm = ref(null)
const customers = ref(null)

onMounted(async () => {
    if (!can('support')) {
        navigate('/')
        return
    }
    await prepCustomers()
    page.ready = true
})

async function prepCustomers() {
    let accounts = page.accounts = await Account.find({}, {index: 'gs1'})
    for (let account of accounts) {
        account.activity = new Date(account.activity)
        account.created = new Date(account.created)
        account._credit = account.billing.credit
        account.billing.credit = `${money(account.billing.credit || 0, 0)}`
        account.renew = account.billing.renew
    }
    let mark = new Date(Date.now() - ((365 + 180) * 86400 * 1000))
    page.customers = accounts.filter((a) => a.closed != true && a.metrics.customer && a.activity > mark && 
        a.billing.renewing != false /* && !a.suspended */)
}


async function accountClick({item, rec, column}) {
    if (column.name == 'Gist') {
        let data = await User.lookupUser({email: item.email})
        if (!data) {
            Feedback.error('Cannot find email')
        } else {
            let id = data.id
            window.open(`https://app.getgist.com/projects/ndcwj9n2/contacts/${id}`, '_blank')
        }
    } else if (column.name == 'Assume') {
        let url = `${window.location.origin}?assume=${rec.email}`
        window.open(url, '_blank')
    } else if (column.name == 'email') {
        let subject = EmailSubject.replace(/ /g, '%20')
        let body = EmailFeedback.replace(/ /gm, '%20').replace(/\n/g, '%0D%0A')
        let url = `mailto:${rec.email}?subject=${subject}&body=${body}`
        window.open(url, '_blank')
        let account = await Account.updateMetrics({
            id: item.id,
            metrics: {emails: {initial: new Date()}},
        })
    } else if (column.name == 'id') {
        launchSenseDeep(rec.id)
    } else {
        editAccount(rec)
    }
}

function launchSenseDeep(accountId) {
    let query = {
        filters: [],
        index: 'primary',
        indexes: ['primary', 'gs1'],
        operation: 'Begins with',
        limit: 100,
        run: true,
        tab: 'Query',
        name: '',
        schema: 'Current',
        hash: `account#${accountId}`,
        type: 'Query',
    }
    let path = 'tables/browse/' + window.btoa(JSON.stringify(query))
    let prof = State.config.profile == 'prod' ? '' : 'qa.'
    let url = `https://${prof}app.sensedeep.com/${path}`
    console.log('Launch', url)
    window.open(url, '_blank')
}


async function editAccount(account) {
    page.showAccount = account ? true : false
    page.account = account || {}
    if (!account) {
        await prepCustomers()
        await customers.value.update()
    }
}

</script>

<template>
    <div class="page customers">
        <vu-confirm ref="confirm" />
        <div v-if="page.ready">

            <vu-table
                title="Active Customers"
                class="pt-4"
                options="dynamic,filter,refilter,toolbar"
                sort="renew:asc"
                ref="customers"
                width="100%"
                :data="page.customers"
                :fields="CustomerFields"
                @click="accountClick">
                <template v-slot:table-col-edit="props">
                    <v-icon icon="$edit" size="24" @click.stop="editAccount(props.item)" />
                </template>
            </vu-table>

        </div>

        <vu-panel v-model="page.showAccount" @close="editAccount" :widths="['600px']">
            <AccountEdit :arg="page.account" @input="editAccount" />
        </vu-panel>
    </div>
</template>

<style lang="scss">
.customers {
    .v-input--switch {
        .v-input__slot {
            width: auto;
        }
    }
    .action {
        margin-right: 10px !important;
        border: 1px solid rgb(var(--v-theme-border-lighten-1));
        background: none !important;
        box-shadow: none !important;
    }
    .metric {
        margin: 20px 0 20px 0;
        font-size: 1.25rem;
    }
}
</style>
