/*
    RefState.js - App references
 */

export default class RefState {
    chat = null
    dash = null
    models = null
    vue = null
    vuetify = null
    router = null
    widgets = null
}
