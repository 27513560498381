<script setup>
import {onMounted, reactive} from 'vue'
import {State, clone} from '@/paks/vu-app'
import Dates from '@/paks/js-dates'

const MaxEvents = 7

const props = defineProps({
    widget: Object,
})

const page = reactive({
    max: props.widget.max || MaxEvents,
    events: [],
})

defineExpose({update})

onMounted(() => {
    update()
})

function update() {
    page.events = clone(props.widget.value)
    if (Array.isArray(page.events) && page.events.length) {
        page.events = page.events.slice(0, page.max)
        for (let event of page.events) {
            event.timestamp = Dates.format(event.timestamp, State.config.theme.formats.time)
            if (event.severity == 'info') {
                event.icon = '$info'
            } else if (event.severity == 'warning') {
                event.icon = '$warn'
            } else {
                event.icon = '$info'
            }
        }
    }
}
</script>

<template>
    <div class="event-widget" @click="click">
        <v-list dense class="event-list">
            <v-list-item v-for="(event, i) in page.events" :key="event.id" @click="click(event)">
                <v-icon :icon="event.icon" :class="`severity-${event.severity}`" size="x-large"/>
                <span class="content">
                    {{ event.subject }}
                </span>
            </v-list-item>
        </v-list>
    </div>
</template>

<style lang="scss" scoped>
.event-widget {
    width: 100%;
    display: block;
    .v-list {
        width: 100%;
        padding: 16px;
        .v-list-item {
            padding: 0;
            font-size: 12px !important;
            min-height: 34px !important;
            margin: 0;
            .v-icon {
                padding: 4px 8px 0 0 !important;
                vertical-align: bottom;
                &.severity-info {
                    color: green;
                }
                &.severity-warn {
                    color: orange;
                }
                &.severity-error {
                    color: red;
                }
                &.severity-critical {
                    color: red;
                }
            }
            .content {
                padding: 0 !important;
                margin-top: 8px;
                margin-bottom: 0px !important;
            }
            &:not(:last-child) {
                border-bottom: 1px solid lighten(gray, 40%);
            }
        }
    }
}
</style>
