<script setup>
import {onMounted, reactive} from 'vue'
import {Feedback, State, can, money, navigate} from '@/paks/vu-app'
import {Account, Usage, User} from '@/models'

const EmailSubject = `Can I please get some feedback on EmbedThis Builder`
const EmailFeedback = `Hi,

I know you are probably way too busy, so I’ll keep this really short.

You recently tried our EmbedThis Builder and I'd love to get some feedback. Could you please tell me what you thought about it?

Did it meet your needs or live up to your expectations?  Are we missing something you need?

Or would you like a follow up call or email?  We're here to help.

We thrive on feedback, so don’t hold back ... let me have it!

Kind regards

Michael O’Brien
Founder Embedthis Software`

//  I appreciate your time is valuable, so in return for your feedback, I can give you 3 months free use of Ioto.

const page = reactive({
    ready: false,
    accounts: [],
    activeTrials: [],
    customers: [],
    expiredTrials: [],
    bounced: [],
    closed: [],
    fields: [],
    usage: {},
    plans: {},
})

onMounted(async () => {
    if (!can('support')) {
        navigate('/')
        return
    }
    let accounts = (page.accounts = await Account.find({}, {index: 'gs1'}))

    let oneMonthAgo = new Date(Date.now() - 86400 * 28 * 1000)

    for (let account of accounts) {
        account.activity = new Date(account.activity)
        account.created = new Date(account.created)
        account._credit = account.billing.credit
        account.billing.credit = `${money(account.billing.credit || 0, 0)}`
    }
    page.customers = accounts.filter((a) => a.closed != true && a.metrics.customer)

    page.activeTrials = accounts.filter(
        (a) => a.closed != true && !a.metrics.customer && a.metrics.products && a.activity > oneMonthAgo
    )
    page.expiredTrials = accounts.filter((a) => !a.metrics.customer && a.activity < oneMonthAgo)

    page.bounced = accounts.filter((a) => a.closed != true && !a.metrics.products)
    page.closed = accounts.filter((a) => a.closed == true)

    page.plans.expired = page.expiredTrials.length
    page.plans.bounced = page.bounced.length
    page.plans.activeTrials = page.activeTrials.length

    page.usage = await Usage.get()
    for (let f of ['ytd', 'forecast', 'mtd', 'mrr', 'yrr']) {
        if (page.usage[f] != null) {
            page.usage[f] = `${money(page.usage[f], 0)}`
        }
    }
    page.fields = [
        {name: 'Gist', icon: '$edit'},
        {name: 'Assume', icon: '$login'},
        {name: 'email'},
        {name: 'activity'},
        {name: 'created'},
        {name: 'metrics.balance', title: 'Balance', format: (v) => money(v, 0)},
        {name: 'metrics.products', title: 'Products'},
        {name: 'metrics.clouds', title: 'Clouds'},
        {name: 'metrics.users', title: 'Users'},
        {name: 'metrics.plans', title: 'Plans'},
        {name: 'billing.credit', format: (v) => money(v, 0)},
    ]
    page.ready = true
})

async function click({rec, column}) {
    if (column.name == 'Gist') {
        let data = await User.lookupUser({email: rec.email})
        if (!data) {
            Feedback.error('Cannot find email')
        } else {
            let id = data.id
            window.open(`https://app.getgist.com/projects/ndcwj9n2/contacts/${id}`, '_blank')
        }
    } else if (column.name == 'Assume') {
        let sub = State.config.profile == 'prod' ? '' : '-qa'
        window.open(`https://admin${sub}.embedthis.com?assume=${rec.email}`, '_blank')
    } else if (column.name == 'email') {
        let subject = EmailSubject.replace(/ /g, '%20')
        let body = EmailFeedback.replace(/ /gm, '%20').replace(/\n/g, '%0D%0A')
        let url = `mailto:${rec.email}?subject=${subject}&body=${body}`
        window.open(url, '_blank')
    }
}
</script>

<template>
    <div class="admin-metrics page" v-if="page.ready">
        <h1 class="mb-4">Metrics</h1>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>Revenue</v-card-title>
                    <v-card-text>${{ page.usage.ytd }}</v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title>Forecast</v-card-title>
                    <v-card-text>${{ page.usage.forecast }}</v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title>MTD</v-card-title>
                    <v-card-text>${{ page.usage.mtd }}</v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title>MRR</v-card-title>
                    <v-card-text>${{ page.usage.mrr }}</v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title>YRR</v-card-title>
                    <v-card-text>${{ page.usage.yrr }}</v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mb-5">
            <v-col>
                <v-card>
                    <v-card-title>Total Accounts</v-card-title>
                    <v-card-text>{{ page.usage.accounts }}</v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title>Monthly Accounts</v-card-title>
                    <v-card-text>{{ page.usage.monthAccounts }}</v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title>Weekly Accounts</v-card-title>
                    <v-card-text>{{ page.usage.weekAccounts }}</v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title>Daily Accounts</v-card-title>
                    <v-card-text>{{ page.usage.dayAccounts }}</v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>Customers</v-card-title>
                    <v-card-text>{{ page.customers.length }}</v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title>Active Trials</v-card-title>
                    <v-card-text>{{ page.plans.activeTrials }}</v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title>Bounced Trials</v-card-title>
                    <v-card-text>{{ page.plans.bounced }}</v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title>Expired Trials</v-card-title>
                    <v-card-text>{{ page.plans.expired }}</v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<style lang="scss">
.admin-metrics {
    .vu-table .toolbar .filter {
        margin-top: 6px !important;
        padding-top: 6px !important;
    }
    .v-card {
        margin: 4px;
        padding: 4px;
        border: solid 1px rgba(var(--v-theme-accent));
        .v-card-title {
            font-size: 1.25rem;
            text-align: center;
            display: inherit;
        }
        .v-card-text {
            text-align: center;
            font-size: 2rem;
            font-weight: bold;
        }
    }
}
</style>
