<script setup>
import {onBeforeMount, reactive, ref} from 'vue'
import {Feedback, State, getModels} from '@/paks/vu-app'

const props = defineProps({
    widget: Object,
    loading: Boolean,
})

const emit = defineEmits(['input'])

const page = reactive({
    size: '16px',
    text: props.widget.text || 'Save',
})

defineExpose({update})

const self = ref(null)

onBeforeMount(() => {
    props.widget.value = {}
    if (props.widget.form) {
        State.cache.setForm(props.widget.form, props.widget)
    }
})

function update() {
    //  MOB - document
    let el = self.value.$el
    page.size = el.style['--w-size'] || 'small'
}

async function save() {
    await submit()
    emit('input', props.widget)
}

async function saveError() {}

async function postSave() {}

async function submit() {
    let widget = props.widget
    let {type, target} = widget.action
    let {Action} = getModels()
    if (target && type == 'action') {
        widget.loading = true
        try {
            if (State.app.context.deviceId) {
                widget.value.deviceId = State.app.context.deviceId
            }
            await Action.invoke({name: target, params: widget.value})
        } catch (err) {
            Feedback.error(err.message)
        }
        widget.loading = false
    }
}

/*
    Size could be --w-size
 */
</script>

<template>
    <vu-form
        ref="self"
        :data="page"
        :save="save"
        :error="saveError"
        :post-save="postSave"
        class="form-widget">
        <div class="actions">
            <v-btn :size="page.size" color="accent" type="submit" :loading="page.saving">
                {{ page.text }}
            </v-btn>
        </div>
    </vu-form>
</template>

<style lang="scss">
.form-widget {
    container-type: size;
    height: 100%;
    width: 100%;
    background-color: inherit;
    padding: 0 10px 0 10px;
    .actions {
        width: 100%;
        position: relative;
        top: 50cqh;
        transform: translateY(-50%);
        height: fit-content;
        margin: 0;
        .v-btn {
            margin-right: 10px;
        }
    }
    .vu-validate {
        display: none;
    }
}
</style>
