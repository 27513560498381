/*
    js-stripe-client.js -- Client side payment processing
 */

export default class StripeClient {

    constructor(pubkey) {
        Stripe.setPublishableKey(pubkey)
    }

    async createStripeToken(card) {
        return new Promise((resolve, reject) => {
            Stripe.card.createToken({
                number: card.number,
                cvc: card.cvc,
                exp_month: card.month,
                exp_year: card.year

            }, (status, response) => {
                if (response.error) {
                    reject(response)
                } else {
                    resolve(response)
                }
            })
        })
    }
}
