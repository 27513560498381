<script setup>
import {onMounted, reactive, watch} from 'vue'
import {State, can, navigate} from '@/paks/vu-app'

const page = reactive({
    canBill: false,
    canMembers: false,
    canNotify: can('support'),
    canSettings: false,
    items: [],
    social: State.auth.social,
})

onMounted(async () => {
    let path = window.location.pathname + window.location.hash.replace(/^#\//, '')
    watch(
        () => State.cache.lastUpdated,
        async () => {
            let owner = State.auth.owner
            page.canBill = page.canSettings = owner
            page.canMembers = can('admin')
            setMenu()
        },
        {immediate: true}
    )
    if (path == '/account') {
        navigate('/account/profile')
        return
    }
})

function setMenu() {
    page.items = [
        {text: 'Profile', path: '/account/profile', enable: true},
        {text: 'Password', path: '/account/password', enable: !page.social},
        {text: 'Settings', path: '/account/settings', enable: page.canSettings},
        {text: 'Subscription', path: '/account/subscription', enable: page.canBill},
        {text: 'Billing', path: '/account/billing', enable: page.canBill},
        {text: 'Members', path: '/account/members', enable: can('admin') && page.canMembers},
    ]
}
</script>

<!--
    Account.vue
-->
<template>
    <v-row>
        <v-sheet class="submenu">
            <v-list class="pt-4 mt-4 text-right">
                <v-list-subheader class="text-right">Account</v-list-subheader>
                <v-list-item
                    class="text-right"
                    ripple
                    v-for="(item, i) in page.items.filter((i) => i.enable)"
                    :key="i"
                    :to="item.path"
                    :id="'nav-' + item.text">
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-sheet>
        <div class="pane">
            <router-view></router-view>
        </div>
    </v-row>
</template>

<style lang="scss" scoped>
.submenu {
    height: 100vh !important;
    padding-top: 36px;
    margin-left: 10px;
    width: 150px;
    border-right: 1px solid rgb(var(--v-theme-border-lighten-1));

    .v-list-subheader {
        font-size: 1.25rem;
        padding-left: 20px;
    }
    .v-list-item-title {
        font-size: 1rem;
        color: rgb(var(--v-theme-text));
    }
    a.theme--dark {
        .v-list-item-title {
            color: rgb(var(--v-theme-text-lighten-1));
        }
    }
    .v-list-item:hover.theme--dark {
        background-color: black;
    }
}
.pane {
    margin: 30px 0 0 30px;
    width: max(500px, 100% - 220px);
}
</style>
