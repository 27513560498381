/*
    Validation rules
    More rules available at: https://github.com/vuelidate/vuelidate
 */

const Rules = {
    accessKey: [
        (v) => !!v || 'Missing access key',
        (v) => (v && v.length == 20) || 'Bad access key, should be 20 characters',
    ],
    bucket: [
        (v) => !!v || 'Name bucket name',
        (v) =>
            (v && /^[\a-z\d\.\-]*$/.test(v)) ||
            'The bucket name can contain only lower-case characters, numbers, periods, and dashes.',
        (v) => (v && /^[\a-z\d]/.test(v)) || 'The bucket name must start with a lowercase letter or number.',
        (v) => (v && !/\-$/.test(v)) || `The bucket name can't end with a dash`,
        (v) => (v && !/\.+\./.test(v)) || `The bucket name can't have consecutive periods`,
        (v) => (v && !/\-+\.$/.test(v)) || `The bucket name can't end with dash adjacent to period`,
        (v) =>
            (v && !/^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/.test(v)) ||
            `The bucket name can't be formatted as an IP address`,
        (v) => (v && v.length >= 3 && v.length <= 63) || 'The bucket name can be between 3 and 63 characters long.',
    ],
    checked: [(v) => !!(v && Array.isArray(v) && v.length) || 'Missing a required selection'],
    cloud: [
        (v) => !!v || 'Missing cloud name',
        (v) => (v && v.length >= 2) || 'Cloud name too short',
        (v) => /^[^~`!@#$%^&\*(){}\[\]|\\\/:;,?=+]+$/.test(v) || 'Bad format for cloud name',
    ],
    domain: [
        (v) => !!v || 'Missing domain',
        (v) => /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/.test(v) || 'Bad format for domain',
    ],
    email: [
        (v) => !!v || 'Missing email',
        (v) =>
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                v
            ) || 'Bad format for email address',
    ],
    ipv4: [
        (v) => !!v || 'Missing IP address',
        (v) => /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(v) || 'Bad format for IP address',
    ],
    ipv6: [
        (v) => !!v || 'Missing IPv6 address',
        (v) =>
            /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/.test(
                v
            ) || 'Bad format for IPv6 address',
    ],
    ipv6prefix: [
        (v) => !!v || 'Missing IPv6 prefix length',
        (v) => /^[0-9]*/.test(v) || 'Bad format for IPv6 prefix length',
        (v) => (1 <= +v && +v < 129) || 'Bad format for IPv6 prefix length (out of range)',
    ],
    log: [
        (v) => !!v || 'Missing log name',
        // v => /^[^<>~`!@#$%^&\*(){}\[\]|\/:;,?=+]*:.*:[A-Z0-9_\-/\.]+$/i.test(v) || 'Bad format for log name',
        (v) => /^[a-z0-9_\-\/\.]+$/i.test(v) || 'Bad format for log group name',
    ],
    name: [
        //  Generally safe name for items
        (v) => !!v || 'Missing name',
        (v) => /^[^<>~`!@#$%^&\*(){}\[\]|\\\/:;?=+]+$/.test(v) || 'Bad format for name',
        (v) => (v && v.length >= 4) || 'Name too short',
    ],
    numeric: [(v) => v === 0 || !!v || 'Missing number', (v) => !isNaN(v) || 'Bad number'],
    number: [(v) => v === 0 || !!v || 'Missing number', (v) => !isNaN(v) || 'Bad number'],
    positive: [
        (v) => !isNaN(v) || 'Bad number',
        (v) => v >= 0 || 'Number must be positive',
    ],
    password: [
        (v) => !!v || 'Missing password',
        (v) =>
            (v &&
                v.length >= 8 &&
                /[A-Z]+/.test(v) &&
                /[a-z]+/.test(v) &&
                /[0-9]+/.test(v) &&
                /[!@#$%^&*(),.?":{}|<>]/.test(v)) ||
            'Password must >8 letters, with upper, lower, numeric and special characters',
    ],
    phone: [
        (v) => !!v || 'Missing phone number',
        (v) => (!v || v.length >= 8) || 'Phone number not fully qualified',
        (v) => (v && /^[0-9 -+.()]+$/.test(v)) || 'Invalid phone number',
    ],
    region: [
        (v) => !!v || 'Missing cloud region',
        (v) => /^[\w]+-[\w]+-[\w]+$/.test(v) || 'Bad format for cloud region',
    ],
    required: [(v) => !!v || 'Missing required field'],
    resource: [
        (v) => !!v || 'Missing resource',
        (v) => (/^[\w. _/-]+$/.test(v) && v != undefined) || 'Bad format for resource',
    ],
    simpleName: [
        //  Simple name with no punct for account names and product names
        (v) => !!v || 'Missing name',
        (v) => /^[a-zA-Z0-9 ]+$/.test(v) || 'Bad format for name. Only alphanumeric and spaces.',
        (v) => (v && v.length >= 2) || 'Name too short',
        (v) => (v && v.length < 40) || 'Name too long',
    ],
    stream: [
        (v) => !!v || 'Missing log stream name',
        (v) => /^[a-zA-Z0-9_\-\/\.\*\[\]\$]+$/.test(v) || 'Bad format for log stream name',
    ],
    text: [
        (v) => !!v || 'Missing text',
        (v) => /^[^^&\*(){}\\]+$/.test(v) || 'Bad format for text',
        (v) => (v && v.length >= 4) || 'Text too short',
    ],
    url: [
        (v) => !!v || 'Missing url',
        (v) =>
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
                v
            ) || 'Bad URL',
    ],
    version: [
        (v) => !!v || 'Missing version',
        (v) =>
            /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/.test(
                v
            ) || 'Bad version format',
    ],
    view: [(v) => !!v || 'Missing view name', (v) => /^[\w \-\_\/\[\]:]+$/.test(v) || 'Bad format for view name'],
    username: [
        //  First and last name. Names are hard, so black list certain characters
        (v) => !!v || 'Missing name',
        (v) => !/[\^\[\~\`\!\@\#\$\%\^\&\*\(\)\{\}\[\]\+\=\:\;\"\|\\\/\?\<\>\,\]]/.test(v) || 'Bad format for name',
        (v) => (v && v.length >= 2) || 'Name too short',
    ],
}

export {Rules}
