import {Account} from '@/models'
import {Feedback, Progress, State, can, fetch} from '@/paks/vu-app'

/*
    Run billing on an account. If accountId is null, run on all accounts if support
 */
export async function runBilling(accountId) {
    if (!can('support')) {
        accountId = State.auth.accountId
    }
    Progress.start('dialog', can('support') ? 'Running Billing' : 'Checking Purchase')
    try {
        /*
            Run billing service
         */
        let promise = fetch(`${State.config.api}/accounting/billing/run`, {
            method: 'POST',
            headers: {
                Authorization: State.auth.tokens.idToken.jwtToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({accountId, email: State.auth.email}),
            nologout: true,
        })
        if (accountId) {
            await promise
        } else {
            Feedback.info(`Billing initiated`)
        }
        //  Refresh account incase PO cleared
        if (accountId == State.auth.accountId) {
            let account = await Account.get({id: accountId})
            State.auth.setAccount(account)
        }
    } catch (err) {
        Feedback.error(`Cannot run billing ${err.message}.`)
        if (accountId == State.auth.accountId) {
            let account = await Account.get({id: accountId})
            State.auth.account.billing.error = account.billing.error
        }
        return false
    } finally {
        Progress.stop()
    }
    return true
}
