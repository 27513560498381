<script setup>
import {User} from '@/models'
import {onBeforeMount, reactive, ref, watch} from 'vue'
import {Feedback, Progress, State, can, canUser, getRoute} from '@/paks/vu-app'

const props = defineProps({id: String})
const emit = defineEmits(['input'])

const page = reactive({
    canDelete: false,
    canChangeRole: false,
    canResend: false,
    removing: false,
    roles: [],
    rules: [],
    saving: false,
    user: {email: null, role: 'user', sendInvite: true},
})

const route = getRoute()

const confirm = ref(null)

watch(() => page.user.email, changeEmail)

onBeforeMount(async () => {
    let id = props.id || route.params.id
    let users = await User.find()
    if (id) {
        page.user = users.find((u) => u.id == id)
        page.canResend = true
    }
    let user = page.user
    page.canChangeRole = can('admin')
    /*
        Rules
        - Can't delete only admin
        - Can't delete only owner
        - Can't delete self from account if no other admins
        - Can delete other from account
        - Can't delete other from account if owner
        let roles = Object.keys(State.config.roles).filter(
            (r) => r != 'support' && r != 'device' && r != 'builder' && r != 'cloud'
        )
    */
    let admins = users.filter((m) => m.id != user.id && canUser(m, 'admin'))
    let owners = users.filter((m) => m.id != user.id && canUser(m, 'owner'))

    page.canDelete = true
    let self = user.id == State.auth.userId

    if (self) {
        page.canDelete = false
    } else {
        if (!State.auth.owner) {
            //  Not ideal, but if we're not an owner, then we may be deleting the only owner
            page.canDelete = false
        }
    }
    let roles = []
    if (id && owners.length == 0 && canUser(user, 'owner')) {
        //  No other owners -- so don't allow changing role to remove the only owner
        roles.push('owner')
    } else {
        if (!id || admins.length >= 1) {
            //  Other admins so allow this user to be downgraded to a user
            roles.push('user')
        }
        if (can('admin')) {
            if (!canUser(user, 'owner') || owners.length >= 1) {
                //  Other owners or currently less than admin so allow this user to be downgraded admin
                roles.push('admin')
            }
        }
        if (can('owner')) {
            roles.push('owner')
        }
    }
    if (can('support') || user.role == 'support') {
        roles.push('support')
    }
    /*
    if (can('super') || user.role == 'super') {
        roles.push('super')
    } */
    page.roles = roles
})

async function save() {
    let user = page.user
    if (user.email.match(/<.*>/)) {
        user.email = user.email.replace(/^.*\<(.*)\>.*$/, '$1')
    }
    if (props.id) {
        await User.updateRole({
            id: user.id,
            role: user.role,
            sendInvite: user.sendInvite,
        })
    } else {
        await User.create(user)
    }
    emit('input')
}

async function remove() {
    if (await confirm.value.ask(`Do you want to remove ${page.user.email} from your account? `)) {
        try {
            page.removing = true
            Progress.start('short')
            await User.remove({id: props.id})
            Progress.stop()
            emit('input')
            Feedback.info('Member Removed')
        } catch (err) {
            Feedback.error(err.message)
        } finally {
            page.removing = true
        }
    }
}

function changeEmail() {
    let user = page.user
    if (user.email.match(/<.*>/)) {
        user.email = user.email.replace(/^.*\<(.*)\>.*$/, '$1')
    }
}
</script>

<template>
    <vu-form
        :data="page"
        :title="`${id ? 'Modify' : 'Invite'} Member`"
        help="/doc/ui/account/members/edit.html"
        :save="save">
        <p v-if="!page.user.id" class="vcol-12 mb-4">
            Enter email address of the user to invite to the account.
        </p>
        <vu-input
            v-model="page.user.email"
            cols="12"
            label="Email Address"
            type="text"
            :disabled="page.user.id != null"
            :rules="page.rules.email" />
        <vu-input
            v-model="page.user.role"
            cols="12"
            type="select"
            label="Role"
            :disabled="!page.canChangeRole"
            :items="page.roles" />
        <vu-input
            v-if="page.canResend"
            v-model="page.user.sendInvite"
            label="Resend Invite"
            type="checkbox"
            cols="12" />
        <div class="vcol-12 mt-2">
            <v-btn size="small" color="accent" class="mr-2" type="submit" :loading="page.saving">
                Save
            </v-btn>
            <v-btn size="small" color="none" class="mr-2" @click="emit('input')">Cancel</v-btn>
            <v-btn
                v-if="id && page.canDelete"
                size="small"
                dark
                color="error"
                :loading="page.removing"
                @click="remove('account')">
                Remove from Account
            </v-btn>
        </div>
        <vu-confirm ref="confirm" />
    </vu-form>
</template>
