<script setup>
import {onMounted, reactive, ref, watch} from 'vue'
import {State, clone} from '@/paks/vu-app'

const props = defineProps({
    modelValue: Object,
    mode: {type: String, default: 'dateTime'},
})

const emit = defineEmits(['update:modelValue'])

const page = reactive({
    count: 0,
    custom: {},
    units: null,
    newRange: null,
    range: {
        anchor: 'relative',
        refresh: 0,
        start: null,
        period: null,
        timezone: 'local',
    },
    ready: false,
    tab: null,
})

const picker = ref(null)

const Spans = [
    {units: 'minutes', period: 60},
    {units: 'hours', period: 3600},
    {units: 'days', period: 86400},
    {units: 'weeks', period: 7 * 86400},
    {units: 'months', period: 28 * 86400},
    {units: 'years', period: 365 * 86400},
]

onMounted(() => {
    let range = page.range = clone(props.modelValue)
    page.tab = range.anchor == 'absolute' ? 1 : 0
    updateRange()

    watch(() => page.count, countChanged)
    watch(() => page.units, countChanged)
    watch(() => page.tab, tabChanged)
})

function updateRange() {
    let {range} = page
    let period = range.period || 86400
    if (range.anchor == 'absolute') {
        page.units = 'months'
        page.count = 1
    } else {
        for (let span of Spans) {
            if (period >= span.period) {
                page.units = span.units
                page.count = Math.floor(period / span.period)
            }
        }
    }
    page.ready = true
}

function countChanged() {
    let range = page.range
    let span = Spans.find((s) => page.units == s.units)
    let period = page.count * span.period
    if (period != range.period) {
        range.period = period
        delete range.start
        updateRange()
    }
    trigger()
}

async function absChanged(values) {
    let range = page.range
    let {start, end} = values
    if (!start || !end) {
        return
    }
    range.period = (end - start) / 1000
    range.start = start
    trigger()
}

function trigger() {
    emit('update:modelValue', page.range)
}

async function tabChanged(tab) {
    let range = page.range
    if (tab == 'relative') {
        range.start = null
        range.anchor = 'relative'
    } else {
        range.anchor = 'absolute'
    }
    updateRange()
}
</script>

<template>
    <div class="range-edit" v-if="page.ready">
        <v-tabs v-model="page.tab" class="mb-3">
            <v-tab value="relative">Relative</v-tab>
            <v-tab value="absolute">Absolute</v-tab>
        </v-tabs>

        <v-window v-model="page.tab">
            <v-window-item value="relative">
                <div class="relative-range">
                    <v-text-field v-model="page.count" label="Count" class="count" hide-details density="compact" />
                    <v-select
                        v-model="page.units"
                        density="compact"
                        hide-details
                        :items="Spans"
                        item-title="units"
                        item-value="units"
                        label="Units"
                        class="units" />
                </div>
            </v-window-item>

            <v-window-item value="absolute" class="py-2 mr-4">
                <div class="picker">
                    <vu-date-picker
                        v-if="page.range.anchor == 'absolute'"
                        ref="picker"
                        v-model.range="page.custom"
                        :attributes="page.attributes"
                        :is-dark="State.app.dark"
                        mode="datetime"
                        :model-config="page.customConfig"
                        :timezone="page.timezone == 'local' ? null : 'UTC'"
                        @update:modelValue="absChanged"
                    >
                    </vu-date-picker>
                    <div class="timezone">
                        <v-radio-group v-model="page.range.timezone" hide-details inline>
                            <v-radio label="Local" value="local"></v-radio>
                            <v-radio label="UTC" value="utc"></v-radio>
                        </v-radio-group>
                    </div>
                </div>
            </v-window-item>
        </v-window>
    </div>
</template>

<style lang="scss">
.range-edit {
    width: 100%;
    padding: 8px;
    border: solid 1px rgb(var(--v-theme-border-lighten-1));
    background: rgb(var(--v-theme-background));
    .v-window {
        padding: 0 10px 0 10px;
        .relative-range {
            width: 60%;
            margin: 30px 0 14px 0;
            display: inline-flex;
            .v-text-field {
                padding-top: 0;
                margin-top: -6px;
            }
            .count {
                margin-left: 10px;
                padding-right: 20px;
                .v-field__overlay {
                    background: none;
                }
                label {
                    left: auto !important;
                    right: 0px !important;
                    position: absolute !important;
                }
                input {
                    text-align: right;
                    padding-bottom: 0;
                }
            }
            .units {
                margin-bottom: 8px;
                .v-field__overlay {
                    background: none;
                }
                label {
                    margin: -10px 0 0 0px;
                }
                .v-field__input {
                    padding-left: 0;
                    margin-top: -8px;
                    margin-bottom: 8px;
                }
            }
            .v-btn {
                padding: 4px;
                margin-right: 6px;
                border: solid 1px rgb(var(--v-theme-border));
            }
        }
    }
    .hint {
        color: rgba(80, 80, 80, 0.9);
        margin-bottom: 16px;
    }
    .picker {
            justify-content: center !important;
        margin-left: 10px;
        width: 250px;
        background: rgb(var(--v-theme-background));
        .timezone {
            text-align: center;
            justify-content: center !important;
            i {
                font-size: 20px !important;
            }
            .v-label {
                font-size: 14px;
            }
        }
        .vc-container {
            border-radius: 0 !important;
            border: 1px solid rgb(var(--v-theme-border)) !important;
        }
        .no-transition .v-stepper__content {
            transition: none;
        }
        .v-selection-control-group {
            display: block;
        }
    }
}
</style>
