/*
    Format a value according to a format string

        0 - digits with leading zero
        # - digit 
        , - Thousand separators
        % format as percent
        + Always show sign
        - Show sign if negative
        ^ Round up
        v Round down
 */

export function formatValue(value, options = {}) {
    if (typeof value == 'number' || !isNaN(value)) {
        return formatNumber(value, options)
    }
    return value
}

export function formatNumber(value, options = {}) {
    if (value == null) return
    let locale = options.locale || 'en-US'
    let format = options.format || '-0#,###.#^'
    let style
    if (format.indexOf('!') >= 0) {
        value = -value
    }
    if (format.indexOf('$') >= 0) {
        style = 'currency'
    } else if (format.indexOf('%') >= 0) {
        style = 'percent'
    } else {
        style = 'decimal'
    }
    let sign
    if (format.indexOf('+') >= 0) {
        sign = 'always'
    } else if (format.indexOf('-') >= 0) {
        //  Sign negative not zero
        sign = 'negative'
    } else {
        sign = 'auto'
    }
    let point = format.indexOf('.')
    let fraction =
        point >= 0
            ? format
                  .slice(point + 1)
                  .match(/[0#]/g)
                  .join('').length
            : 0

    let round
    if (format.indexOf('^') >= 0) {
        round = 'ceil'
    } else if (format.indexOf('v') >= 0) {
        round = 'floor'
    }
    let grouping = format.indexOf(',') >= 0 ? true : false

    let v = value.toLocaleString(locale, {
        sign: sign,
        style: style,
        maximumFractionDigits: fraction,
        roundingMode: round,
        useGrouping: grouping,
    })
    return v
}
