/*
    Gist.js -- Gist support chat
 */
import {Log, Progress, State, delay} from '@/paks/vu-app'

class GistClass {
    constructor() {
        this.need = {}
    }

    async init(config) {
        this.config = config
        this.gistId = config.gist.id
        let help = window.location.search.indexOf('?help') >= 0
        if (this.config.gist.enable || help) {
            if (!State.auth.assume) {
                //  Slight delay to give other components priority
                await delay(15 * 1000)
                this.load(help ? 'visible' : '')
            }
        }
    }

    /*
        Load the gist library
     */
    async load(visible) {
        let gistId = this.gistId
        this.need.chat = visible
        return await new Promise((resolve, reject) => {
            ;(function (d, h, w) {
                var gist = (w.gist = w.gist || [])
                gist.methods = ['trackPageView', 'identify', 'track', 'setAppId']
                gist.factory = function (t) {
                    return function () {
                        var e = Array.prototype.slice.call(arguments)
                        e.unshift(t)
                        gist.push(e)
                        return gist
                    }
                }
                for (var i = 0; i < gist.methods.length; i++) {
                    var c = gist.methods[i]
                    gist[c] = gist.factory(c)
                }
                var s = d.createElement('script')
                s.src = 'https://widget.getgist.com'
                s.async = !0
                var e = d.getElementsByTagName(h)[0]
                e.appendChild(s)
                s.addEventListener('load', (e) => {}, !1)
                gist.setAppId(gistId)
                gist.trackPageView()
            })(document, 'head', window)

            /*
                Wait till Gist is ready. Can take a while, so we need to accumulate "need"
                properties if identify() or chat() are called before Gist is loaded.
             */
            document.addEventListener('gistReady', () => {
                gist.chat('hide')
            })
            document.addEventListener('gistChatReady', () => {
                gist.chat('hideLauncher')
                if (this.need.identify) {
                    this.identify()
                }
                if (this.need.chat) {
                    this.open()
                }
                resolve()
            })
            document.addEventListener('messenger:closed', (data) => {
                this.close()
            })
        })
    }

    async open() {
        if (!global.gist) {
            Progress.start(10000)
            await this.load('visible')
        }
        if (global.gist && global.gist.options) {
            gist.chat('openNewConversation')
            this.need.chat = false
        } else {
            this.need.chat = true
        }
        Progress.stop()
        this.opened = true
    }

    close() {
        if (global.gist) {
            gist.chat('hide')
        }
        this.opened = false
    }

    async toggle() {
        if (this.opened) {
            this.close()
        } else {
            await this.open()
        }
    }

    identify(attributes = {}) {
        if (global.gist && global.gist.options && State.auth.ready) {
            try {
                let user = State.auth.user
                attributes = Object.assign(
                    {
                        user_id: user.id,
                        email: user.email,
                        first_name: user.first,
                        last_name: user.last,
                        company: State.auth.account.name,
                        embedthis_subscribe: true,
                        builder_registered: parseInt(new Date(State.auth.account.created) / 1000),
                    },
                    attributes
                )
                gist.identify(user.id, attributes)
                this.need.identify = false
            } catch (err) {
                Log.error('Cannot save user information to gist', {err})
            }
        } else {
            this.need.identify = true
        }
    }

    cancelAccount() {
        let user = State.auth.user
        if (global.gist && user && user.id) {
            gist.identify(user.id, {email: user.email, embedthis_status: 'closed'})
        }
    }

    //  NOT USED
    async event(event, args = {}) {
        if (global.gist && global.gist.options) {
            let user = State.auth.user
            if (user) {
                args = Object.assign({}, args)
                args.user_id = user.id
                args.email = user.email
                gist.track(event, args)
            }
        }
    }

    //  NOT USED
    pageEvent() {
        if (global.gist && global.gist.options) {
            gist.trackPageView()
        }
    }
}

export const Gist = new GistClass()
