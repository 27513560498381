<script setup>
import {onMounted, reactive} from 'vue'
import Dates from '@/paks/js-dates'
import {clone} from '@/paks/vu-app'
import {runBilling} from '@/compose/Billing'
import {Invoice} from '@/models'

const props = defineProps({arg: Object})

const emit = defineEmits(['input'])

const page = reactive({
    memo: null,
    nextReminder: null,
    saving: false,
})
const invoice = reactive(clone(props.arg))

onMounted(async () => {
    invoice.ref = invoice.accountId.slice(-6)
    invoice.due = Dates.format(invoice.due, 'mmm dd yyyy, HH:MM:ss')
    page.nextReminder = invoice.nextReminder
    page.memo = invoice.memo
})

async function confirmSave(confirm) {
    return await confirm.value.ask(`Do you want to make changes to the invoice "${invoice.number}"? `)
}

async function save() {
    await Invoice.updateReceived({accountId: invoice.accountId, id: invoice.id, received: +invoice.received})

    if (page.nextReminder && page.nextReminder.getTime() != invoice.nextReminder.getTime()) {
        await Invoice.updateReminder({accountId: invoice.accountId, id: invoice.id, nextReminder: page.nextReminder})
    }
    if (page.memo != invoice.memo) {
        await Invoice.updateMemo({accountId: invoice.accountId, id: invoice.id, memo: page.memo})
    }
    await runBilling(invoice.accountId)
    emit('input')
}
</script>

<template>
    <vu-form :data="page" :confirm="confirmSave" :save="save" help="/doc/ui/device/edit.html" title="Modify Invoice">
        <vu-input v-model="invoice.number" :disabled="true" label="Invoice Number" />
        <vu-input v-model="invoice.name" :disabled="true" label="Account" />
        <vu-input v-model="invoice.email" :disabled="true" label="Email" />
        <vu-input v-model="invoice.ref" :disabled="true" label="Invoice Reference" />
        <vu-input v-model="invoice.due" :disabled="true" label="Invoice Due" prefix="$" />
        <vu-input v-model="invoice.total" :disabled="true" label="Invoice Total" prefix="$" />
        <vu-input v-model="invoice.paid" :disabled="true" label="Invoice Paid" prefix="$" />
        <vu-input v-model="invoice.outstanding" :disabled="true" label="Invoice Outstanding" prefix="$" />
        <vu-input v-model="invoice.received" label="Amount Received" prefix="$" />
        <vu-input v-model="page.nextReminder" label="Next Reminder" type="date" datetime="datetime" />
        <vu-input v-model="page.memo" label="Invoice Memo" />

        <div class="actions">
            <v-btn color="accent" type="submit" :loading="page.saving">Save</v-btn>
            <v-btn color="none" @click="emit('input')">Cancel</v-btn>
        </div>
    </vu-form>
</template>
