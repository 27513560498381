/*
    GridLayout.js -- Dashboard layout
 */
import {State} from '@/paks/vu-app'

const Grid = 20
const MinWidth = 20
const MinHeight = 20

export default class Layout {
    rendering = false
    widgets = []

    constructor(options) {
        this.options = options
        this.dash = options.dash
        this.parent = options.parent
        this.grid = this.dash.snap ? Grid : 0
        this.vw = this.align(options.vw, 'down', Grid)
        this.moving = false
    }

    /*
        Run the layout.
        options.compact will eliminate horizontal space between widgets
        options.expand will grow widgets to fill horizontal view port
        dash.snap will align to a grid
     */
    run(widgets, options = {}) {
        if (widgets.length == 0) return
        if (this.rendering) return
        this.rendering = true

        if (options.initialize) {
            if (State.config.features.dash?.responsive) {
                if (window.innerWidth <= 640) {
                    options.expand = true
                }
            }
        }
        let vw = this.vw
        this.moving = widgets.filter((w) => w.moving).length ? true : false

        /*
            Set defaults, calculate absolute position and sort
         */
        let next = {x: 0, y: 0, height: 0}
        for (let i = 0; i < widgets.length; i++) {
            let widget = widgets[i]
            this.setWidgetDefaults(widget, next)
            this.getAbsPosition(widget)
        }
        /*
            Sort widgets:
            - If vertically overlapped, then left to right
            - If not vertically overlapped, then top to bottom
            - Else left to right
            - New widgets are placed at the end
         */
        this.sortWidgets(widgets)

        /*
            Resolve moving widgets
         */
        if (this.moving) {
            for (let widget of widgets) {
                if (!widget.moving) continue

                let proposed = widget.proposed
                proposed._width = widget._width
                proposed._height = widget._height

                let dir =
                    Math.abs(widget._top - proposed._top) > Math.abs(widget._left - proposed._left)
                        ? 'vertical'
                        : 'horizontal'
                let w = Object.assign({}, widget, proposed)
                let intersected
                for (let other of widgets) {
                    if (w.id == other.id || !this.intersect(w, other)) continue
                    if (
                        this.horizontallyOverlapped(w, other, 0.25) &&
                        this.verticallyOverlapped(w, other, 0.25)
                    ) {
                        if (dir == 'horizontal') {
                            if (widget._left < other._left) {
                                let left = this.getWidgetBefore(widgets, widget)
                                other._left = left
                                widget._left = other._left + other._width + this.grid
                            } else {
                                let left = this.getWidgetBefore(widgets, other)
                                widget._left = left
                                other._left = widget._left + widget._width + this.grid
                            }
                            widget._top = proposed._top
                        } else {
                            if (widget._top < other._top) {
                                let top = this.getWidgetAbove(widgets, widget)
                                other._top = top
                                widget._top = other._top + other._height + this.grid
                            } else {
                                let top = this.getWidgetAbove(widgets, other)
                                widget._top = top
                                other._top = widget._top + widget._height + this.grid
                            }
                            widget._left = proposed._left
                        }
                    }
                    intersected = true
                }
                if (!intersected) {
                    Object.assign(widget, proposed)
                }
            }
        }

        /*
            Resolve overlapping widgets
         */
        for (let widget of widgets) {
            for (let other of widgets) {
                if (widget == other) continue
                if (!this.intersect(widget, other)) continue

                let dir =
                Math.abs(widget._top - other._top) > Math.abs(widget._left - other._left)
                    ? 'vertical'
                    : 'horizontal'

                if (dir == 'horizontal') {
                    //  Move other right
                    other._left = widget._left + widget._width + Grid
                } else {
                    //  Move other down
                    other._top = widget._top + widget._height + Grid
                }
            }
        }

        /*
            Compact and expand
         */
        let {compact, expand} = options
        if (this.moving) {
            compact = expand = false
        }
        //  Always compacting if using a grid
        if (this.grid) {
            compact = true
        }
        //  Sort moving widget last so we can move other widgets into its place
        widgets = widgets.sort((a,b) => {
            if (a.moving) {
                return 1
            } else if (b.moving) {
                return -1
            }
            return  0
        })
        let bottom = 0
        for (let i = 0; i < widgets.length; i++) {
            let widget = widgets[i]
            if (widget._top && compact) {
                //  Compact up - test if any others above this widget
                let others = widgets.filter(
                    (w) =>
                        w != widget &&
                        w._top < widget._top &&
                        this.horizontallyOverlapped(w, widget)
                )
                let last = others.sort((a,b) => (a._top + a._height) - (b._top + b._height)).at(-1)
                let top
                if (last) {
                    top = last._top + last._height + Grid
                } else {
                    top = 0
                }
                if (widget._top != top) {
                    widget._top = top
                }
            }
            let row = widgets.filter((w) => w._top == widget.top)
            let gaps = row.length - 1
            if (expand) {
                let left = 0
                /*  
                    All rows round down. In the case of a one widget row, -1 so it rounds down too.
                 */
                let width = this.align((vw - (gaps ? gaps * Grid: 1)) / row.length, 'down')
                for (let w of row) {
                    w._width = width
                    w._left = left
                    left += width + Grid
                }
            }
            if (compact) {
                if (widget._left) {
                    //  Compact left or nudge right to the grid
                    let others = widgets
                        .filter(
                            (w) =>
                                w != widget &&
                                w._left < widget._left &&
                                this.verticallyOverlapped(w, widget)
                        )
                        .sort((a, b) => {
                            return a._left + a._width - (b._left + b._width)
                        })
                        .reverse()
                    let last = others[0]
                    if (last) {
                        let left = last._left + last._width + (this.grid)
                        if (left != widget._left) {
                            widget._left = left
                        }
                    } else {
                        widget._left = 0
                    }
                }
                if (this.grid) {
                    let _width = this.align(widget._width, 'up')
                    if (widget._width != _width) {
                        widget._width = _width
                    }
                }
            }
            this.positionElement(widget)
            bottom = widget._top + widget._height
        }
        this.parent.$el.style.height = `${bottom}px`
        this.rendering = false
    }

    getWidgetAbove(widgets, widget) {
        let others = widgets.filter(
            (w) =>
                w != widget && w._top < widget._top && this.horizontallyOverlapped(w, widget)
        )
        let last = this.sortWidgets(others).at(-1)
        if (last) {
            return last._top + last._height + this.grid
        }
        return 0
    }

    getWidgetBefore(widgets, widget) {
        let others = widgets.filter(
            (w) =>
                w != widget && w._left < widget._left && this.verticallyOverlapped(w, widget)
        )
        let last = others.sort((a, b) => {
            return a._left + a._width - (b._left + b._width)
        }).at(-1)
        if (last) {
            return last._left + last._width + this.grid
        }
        return 0
    }

    printWidgets(widgets) {
        print(' ')
        for (let widget of widgets) {
            console.log(
                `${widget.title} ${widget.type}: top ${widget._top} height ${widget._height} left ${
                    widget._left
                } right ${widget._left + widget._width}`
            )
        }
        print(' ')
    }

    /*
        Convert percentage left/width to absolute pixel positions and set _xxx fields
     */
    getAbsPosition(widget) {
        if (!widget) return
        let vw = this.vw
        if (this.grid && !this.moving) {
            widget.top = this.align(widget.top, 'up')
            widget.height = this.align(widget.height, 'up')
        }
        widget._top = widget.top - 0
        //  Round up left position to align on the current grid
        widget._left = this.getPixels(widget.left, 'up')
        widget._height = Math.max(widget.height - 0, MinHeight)
        //  Round to nearest width
        widget._width = Math.max(this.getPixels(widget.width, 'round'), MinWidth)
    }

    setWidgetDefaults(widget, next) {
        let vw = this.vw
        let el = document.getElementById(widget.id)
        if (widget.width == 'NaN') {
            widget.width = null
            widget.left = null
        }
        if (isNaN(widget.top) || isNaN(widget.left)) {
            widget.top = 0
            widget.left = 0
        }
        if (!el) return
        if (widget.width == null || widget.height == null) {
            widget.height = Math.max(this.align(el.offsetHeight, 'up', Grid), MinHeight)
            widget.width = Math.max(el.offsetWidth / vw, MinWidth / vw)
        } else if (widget.width > 1) {
            widget.width = Math.min(1, Math.max(widget.width / vw, MinWidth / vw))
        }
        this.placeWidget(widget, next)
    }

    /*
        Calculate the next best row/column for new widgets
        Called for all widgets so we can track the start of new rows
     */
    placeWidget(widget, next) {
        let vw = this.vw
        if (widget.left == null) {
            let w = widget.width * vw
            if (next.x > 0 && next.x + w > vw) {
                //  New row
                widget.top = this.align(next.y + next.height, 'up', Grid)
                next.x = 0
                next.height = 0
            } else {
                widget.top = next.y
            }
            widget.left = next.x / vw
        } else if (widget.top >= next.y + next.height && widget.left <= next.x) {
            //  Track the start of a new row for an existing widget
            next.height = 0
            next.x = widget.left * vw
        }
        if (isNaN(widget.top)) {
            widget.top = 0
        }
        if (isNaN(widget.left)) {
            widget.left = 0
        }
        next.y = widget.top - 0
        next.x = this.align(next.x + widget.width * vw, 'up', Grid)
        next.height = Math.max(next.height, this.align(widget.height - 0 + 1, 'up', Grid))
    }

    /*
        Update the CSS position of the element
     */
    positionElement(widget) {
        let vw = this.vw
        let el = document.getElementById(widget.id)
        if (!el) {
            return
        }
        let {_top: top, _left: left, _height: height, _width: width} = widget
        let style = el.style
        style.top = top + 'px'
        style.left = (left / vw) * 100 + '%'
        style.height = height + 'px'
        style.width = (width / vw) * 100 + '%'

        if (
            widget.top != top ||
            widget.height != height ||
            widget.left != left / vw ||
            widget.width != width / vw
        ) {
            widget.top = '' + top
            widget.height = '' + height
            widget.left = '' + left / vw
            widget.width = '' + Math.min(width / vw, 1)
        }
    }

    getPixels(v, dir = 'up', round = this.grid) {
        round = this.grid && !this.moving ? round : 0
        let vw = this.vw
        if (v == null) return 0
        if (v == 'rest') {
            v = vw - x - round
        } else if (v.toString().indexOf('%') >= 0) {
            v = Math.max((vw * parseInt(v)) / 100, 100)
        } else if (v <= 1) {
            v = vw * v
        }
        return this.align(v, dir, round)
    }

    align(v, dir = 'up', round = this.grid) {
        round = this.grid && !this.moving ? round : 0
        if (round) {
            if (dir == 'down') {
                v = Math.floor(v / round) * round
            } else if (dir == 'up') {
                v = Math.ceil(v / round) * round
            } else {
                v = Math.round(v / round) * round
            }
        }
        return parseInt(v) - 0
    }

    getPercent(v, vw) {
        return v / vw
    }

    /*
        Sort widgets:
        - If vertically overlapped, then left to right
        - If not vertically overlapped, then top to bottom
        - Else left to right
        - New widgets are placed at the end

        width may be a string fraction of the viewport width (0-1), 'rest'. May have % suffix.
        left may be a string faction of the viewport width.
        top and height are string numbers of pixels.
    */
    sortWidgets(widgets, grid = this.grid) {
        return widgets.sort((a, b) => {
            if (a._top == null) {
                if (b._top == null) {
                    return 0
                }
                return 1
            } else if (b._top == null) {
                return -1
            } else if (this.verticallyOverlapped(a, b, 1, grid)) {
                if (this.intersect(a, b, 1, grid)) {
                    if (a._top < b._top) {
                        return -1
                    } else if (a._top > b._top) {
                        return 1
                    }
                }
                if (a._left < b._left) {
                    return -1
                } else if (a._left > b._left) {
                    return 1
                } else if (a._top < b._top) {
                    return -1
                } else if (a._top > b._top) {
                    return 1
                }
                return 0
            } else if (a._top < b._top) {
                return -1
            } else if (a._top > b._top) {
                return 1
            } else if (a._left < b._left) {
                return -1
            } else if (a._left > b._left) {
                return 1
            }
            return 0
        })
    }

    /*
        Widgets are in same vertical column
     */
    verticallyOverlapped(a, b, percent = 1, grid = this.grid) {
        if (a._top + a._height * percent + grid <= b._top) {
            //  before
            return false
        }
        if (a._top >= b._top + b._height * percent + grid) {
            //  after
            return false
        }
        //  overlapped
        return true
    }

    /*
        Widgets are in same horizontal column
     */
    horizontallyOverlapped(a, b, percent = 1, grid = this.grid) {
        if (a._left + a._width * percent + grid <= b._left) {
            //  before
            return false
        }
        if (a._left >= b._left + b._width * percent + grid) {
            //  after
            return false
        }
        //  overlapped
        return true
    }

    intersect(a, b, percent = 1, grid = this.grid) {
        return (
            this.horizontallyOverlapped(a, b, percent, grid) &&
            this.verticallyOverlapped(a, b, percent, grid)
        )
    }
}
