/*
    cloud.js - Cloud model
 */

import {Rest} from '@/paks/js-rest'

export default new Rest('Cloud', {
    checkDomain: { method: 'POST', uri: '/:controller/checkDomain' },
    provision: { method: 'POST', uri: '/:controller/provision'},
    waitForTemplate: { method: 'POST', uri: '/:controller/waitForTemplate'},
    getHosts: { method: 'POST', uri: '/:controller/getHosts'},
}, {cache: true, service: '/api'})
