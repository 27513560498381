<script setup>
import {getCurrentInstance, onBeforeMount, reactive, ref} from 'vue'
import {Feedback, Log, Progress, State, can, delay, getRoute, waitRender} from '@/paks/vu-app'
import UUID from '@/paks/js-uuid'
import Planner from '@/common/planner'
import {Cloud, Plan, Token} from '@/models'

const Regions = [
    {value: 'ap-east-1', title: 'ap-east-1 (Hong Kong)'},
    {value: 'ap-northeast-1', title: 'ap-northeast-1 (Tokyo)'},
    {value: 'ap-northeast-2', title: 'ap-northeast-2 (Seoul)'},
    {value: 'ap-south-1', title: 'ap-south-1 (Mumbai)'},
    {value: 'ap-southeast-1', title: 'ap-southeast-1 (Singapore)'},
    {value: 'ap-southeast-2', title: 'ap-southeast-2 (Sydney)'},
    {value: 'ca-central-1', title: 'ca-central-1 (Canada)'},
    {value: 'eu-central-1', title: 'eu-central-1 (Frankfurt)'},
    {value: 'eu-north-1', title: 'eu-north-1 (Stockholm)'},
    {value: 'eu-west-1', title: 'eu-west-1 (Ireland)'},
    {value: 'eu-west-2', title: 'eu-west-2 (London)'},
    {value: 'eu-west-3', title: 'eu-west-3 (Paris)'},
    {value: 'me-central-1', title: 'me-central-1 (UAE)'},
    {value: 'me-south-1', title: 'me-south-1 (Bahrain)'},
    {value: 'sa-east-1', title: 'sa-east-1 (Sao Paulo)'},
    {value: 'us-east-1', title: 'us-east-1 (N. Virginia)'},
    {value: 'us-east-2', title: 'us-east-2 (Ohio)'},
    {value: 'us-west-1', title: 'us-west-1 (N. California)'},
    {value: 'us-west-2', title: 'us-west-2 (Oregon)'},

    // {value: 'af-south-1', title: 'af-south-1 (Cape Town)'},
    // {value: 'ap-northeast-3', title: 'ap-northeast-3 (Osaka)'},
    // {value: 'ap-south-2', title: 'ap-south-2 (Hyderabad)'},
    // {value: 'ap-southeast-3', title: 'ap-southeast-3 (Jakarta)'},
    // {value: 'ap-southeast-4', title: 'ap-southeast-4 (Melbourne)'},
    // {value: 'eu-central-2', title: 'eu-central-2 (Zurich)'},
    // {value: 'eu-south-1', title: 'eu-south-1 (Milan)'},
]
const CloudFormationTimeout = 8 * 60

let {proxy: self} = getCurrentInstance()
const props = defineProps({id: String})
const emit = defineEmits(['input'])

const page = reactive({
    ask: false,
    cloud: {type: 'host'},
    fresh: false,
    loading: false,
    managerDomain: State.config.managerDomain,
    price: 0,
    regions: Regions,
    rules: [],
    saving: false,
    start: null,
    template: null,
    timeout: 180,
    token: {},
    updating: false,
})

const route = getRoute()
const confirm = ref(null)
const form = ref(null)
const planner = new Planner(State.config.billing)

defineExpose({saving: page.saving})

onBeforeMount(async () => {
    let id = props.id || route.params.id
    let cloud
    if (id) {
        cloud = Object.assign(page.cloud, State.get('Cloud', id))
        cloud.plan = State.get('Plan', cloud.planId)
        page.updating = true
    } else {
        page.fresh = true
        cloud = Object.assign(page.cloud, {
            enable: true,
            iotPolicy: 'IotoThingPolicy',
            open: true,
            plan: {
                current: {},
                period: 'month',
                type: 'Host',
                units: 0,
            },
            region: State.app.region || (can('support') ? 'ap-southeast-1' : 'us-east-1'),
            type: 'host',
        })
    }
    cloud.plan = planner.initPlan(cloud.plan)
    await changeRegion()
})

async function runTemplate() {
    let cloud = page.cloud
    page.token = await Token.get({type: 'CloudToBuilder', owner: `cloud:${cloud.id}`, enable: true})
    await cleanupDialog()

    let profile = State.config.profile == 'prod' ? 'prod' : 'qa'
    let uri = `https://console.aws.amazon.com/cloudformation/home?region=${cloud.region}#/stacks/create/review?`
    let args = []
    args.push(`templateURL=${State.config.admin.formation}`)
    args.push(`param_AccountID=${State.auth.accountId}`)
    args.push(`param_BuilderAccessToken=${page.token.id}`)
    args.push(`param_Cloud=${cloud.id}`)
    args.push(`param_ExternalID=${cloud.external}`)
    args.push(`param_EmbedthisAwsAccount=${State.config.admin.account}`)
    args.push(`param_Profile=${profile}`)
    args.push(`stackName=Ioto`)
    page.template = encodeURI(uri + args.join('&'))
    page.ask = true

    startProgress(`Running CloudFormation`, CloudFormationTimeout)

    let start = Date.now()
    while (Date.now() < start + CloudFormationTimeout * 1000) {
        try {
            let cloud = await Cloud.waitForTemplate({id: page.cloud.id})
            if (!cloud) {
                throw new Error('Cloud create cancelled')
            }
            if (cloud.error || page.cloud.error) {
                throw new Error(cloud.error || page.cloud.error)
            }
            if (cloud.connected) {
                page.cloud.connected = cloud.connected
            }
            if (
                cloud.status &&
                cloud.status.indexOf('PENDING') < 0 &&
                cloud.status.indexOf('PROGRESS') < 0
            ) {
                return
            }
            await delay(5000)
        } catch (err) {
            Log.error(`CloudFormation template error ${err.message}`)
            // continue
        }
    }
    throw new Error(
        'Timeout waiting for Cloud Formation Template.' +
            'See AWS Console CloudFormation Ioto stack for error details. ' +
            'Contact support via chat if you need assistance.'
    )
}

async function startTemplate() {
    page.ask = false
    page.start = new Date()
    window.open(page.template, '_blank')
}

async function cancelTemplate() {
    let cloud = page.cloud
    if (page.fresh && !cloud.provisioned && !cloud.connected) {
        await Cloud.remove({id: cloud.id})
        await State.cache.update()
    }
    Progress.stop()
    await cleanupDialog()
    emit('input')
}

async function cleanupDialog() {
    let elements = document.getElementsByClassName('v-overlay theme--dark')
    if (elements) {
        for (let overlay of elements) {
            overlay.parentNode.removeChild(overlay)
        }
    }
    page.ask = null
    await waitRender()
}

async function preSave(validate) {
    let {cloud} = page
    if (cloud.error == 'Template aborted') {
        cloud.error = null
    }
    if (!cloud.name) {
        await validate.fieldError(form.value, 'name', 'Missing cloud name')
        return false
    }
    if (!cloud.id && State.cache.clouds.find((c) => c.name == cloud.name)) {
        await validate.fieldError(form.value, 'name', 'An existing cloud has the same name')
        return false
    }
    if (!cloud.region) {
        await validate.fieldError(form.value, 'region', 'Must define cloud region')
        return false
    }
    startProgress(`${cloud.id ? 'Updating' : 'Creating'} Cloud "${cloud.name}"`, 60)
    return true
}

async function save() {
    let cloud = page.cloud
    page.saving = true
    let params = {
        id: cloud.id,
        attachments: page.attachments,
        deviceRole: cloud.deviceRole,
        enable: cloud.enable,
        error: null,
        iotPolicy: cloud.iotPolicy,
        name: cloud.name.trim(),
        open: cloud.open,
        region: cloud.region.trim(),
        shadows: true,
        sync: true,
        type: 'host',
    }
    if (cloud.id) {
        cloud = page.cloud = await Cloud.update(params)
    } else {
        params.external = UUID()
        cloud = page.cloud = await Cloud.create(params, {feedback: false})
        cloud.external = params.external
    }
    //  Cache the plan
    await Plan.get({id: cloud.planId})
}

async function postSave(error, val) {
    let cloud = page.cloud
    if (!error) {
        try {
            if (cloud.enable && !cloud.connected) {
                await runTemplate()
            }
            await provision(cloud)
            State.app.region = cloud.region
            emit('input')
        } catch (err) {
            if (err != 'Cloud create cancelled') {
                //  Continue without emit
                val.error(err.message)
                Log.error(`Cloud provision error ${err.message}`)
                Feedback.error(err.message)
            }
        }
    }
    Progress.stop()
    page.saving = false
}

async function provision(cloud) {
    startProgress('Provisioning Cloud Resources', 300)
    cloud = await Cloud.provision({id: cloud.id})
    let prior = cloud.provisioned || new Date()

    let deadline = Date.now() + 3 * 60 * 1000
    while (Date.now() < deadline) {
        let error = cloud.error || page.cloud.error
        if (error) {
            throw new Error(error)
        }
        if (cloud.provisioned > prior) {
            return
        }
        await delay(5000)
        cloud = await Cloud.get({id: cloud.id}, {refresh: true, throw: false})
    }
    throw new Error('Timeout waiting for Provisioning')
}

async function removeCloud() {
    let {cloud} = page
    await State.cache.update()
    let clouds = State.cache.clouds
    if (cloud.type == 'host') {
        let deps = clouds.filter((c) => c.host == cloud.id)
        if (deps.length) {
            Feedback.error(`Cannot remove host with dependent clouds`)
            console.log(`Dependent clouds`, JSON.stringify(deps, null, 4))
            emit('input')
            return
        }
        if (!(await confirm.value.ask('WARNING: do you REALLY want to remove a hosting cloud'))) {
            return
        }
    }
    let confirmPrompt = `Delete ${cloud.name}`

    if (
        !(await confirm.value.ask(
            `
            <h2 class="mb-3">WARNING: Deleting a device cloud is permanent and cannot be reversed.</h2>
            <p>Deleting a device cloud will disconnect all devices from your device cloud and stop any devices being managed by Ioto.</p>
            <label>To confirm the deletion of this cloud, type <i>${confirmPrompt}</i> in the text box.</label>
            `,
            {
                confirmPrompt,
                width: 800,
            }
        ))
    ) {
        return
    }
    try {
        startProgress(`Deleting Cloud "${cloud.name}"`, 300)
        await Cloud.remove({id: cloud.id})
        let deadline = Date.now() + 5 * 60 * 1000
        while (Date.now() < deadline) {
            cloud = await Cloud.get({id: cloud.id}, {throw: false})
            if (!cloud) {
                break
            }
            if (cloud.error || page.cloud.error) {
                throw new Error(cloud.error)
            }
            await delay(1000)
        }
        await State.cache.update()

        await confirm.value.ask(
            `If you want to re-create the host, please wait ten minutes to allow previous resources to be fully removed.`,
            {title: 'Please Note', disagree: false}
        )
        Feedback.info('Host Data Deleted')
        emit('input')
    } catch (err) {
        Feedback.error('Cannot delete host')
    } finally {
        Progress.stop()
    }
}

async function changeRegion(value) {
    if (page.cloud.id) return
    let clouds = await Cloud.getHosts({region: page.cloud.region}, {refresh: true})
    let hosts = clouds.map((c) => c.name)
    for (let i = 0; i < 1000; i++) {
        if (hosts.indexOf(zpad(i, 2)) < 0) {
            if (State.config.profile == 'prod') {
                page.cloud.name = `${page.cloud.region}-host-${zpad(i, 2)}`
            } else {
                page.cloud.name = `qa-host-${page.cloud.region}-${zpad(i, 2)}`
            }
            self.$forceUpdate()
            return
        }
    }
    throw new Error('Cannot find free host name')
}

function startProgress(msg, timeout = page.timeout) {
    Progress.start('dialog', msg, {bar: true, duration: timeout * 1000, cancel: cancelProgress})
}

async function cancelProgress() {
    if (
        !(await confirm.value.ask(
            `<h2 class="mb-3">Your device cloud may be in a partial state.</h2>
            <p>You may re-save or delete the cloud to complete the operation.</p>`,
            {
                disagree: false,
                title: 'Cloud Operation Cancelled',
            }
        ))
    ) {
        return
    }
    page.cloud.error = 'Cancelled operation'
    page.saving = false
}
</script>

<template>
    <div class="host-edit">
        <vu-form
            :data="page"
            :pre-save="preSave"
            :post-save="postSave"
            :options="{progress: false}"
            :save="save"
            :title="`${props.id ? 'Modify' : 'Add'} Host`"
            class="cloud-edit"
            help="/doc/ui/clouds/edit.html"
            ref="form">
            <v-alert
                v-if="page.cloud.error"
                icon="$error"
                class="error vcol-12"
                :value="true"
                type="error"
                dismissible>
                <div>{{ page.cloud.error }}</div>
            </v-alert>

            <vu-input
                v-model="page.cloud.region"
                @change="changeRegion"
                cols="6"
                label="Host Region"
                name="region"
                type="select"
                :disabled="page.cloud.id != null"
                :items="page.regions"
                :rules="page.rules.region" />

            <vu-input
                v-model="page.cloud.name"
                cols="6"
                label="Host Name"
                name="name"
                type="text"
                :rules="page.rules.cloud" />

            <vu-input v-model="page.cloud.open" cols="6" lable="Open" name="open" type="checkbox" />

            <div class="actions">
                <v-btn color="accent" type="submit" :loading="page.saving">Save</v-btn>
                <v-btn color="none" @click="emit('input')">Cancel</v-btn>
                <v-btn color="error" v-if="props.id && can('admin')" @click="removeCloud">
                    Delete
                </v-btn>
            </div>
        </vu-form>

        <v-dialog v-model="page.ask" persistent width="700" content-class="iam-host-dialog">
            <vu-form :data="page" title="Connect Your AWS Account to Ioto" :save="startTemplate">
                <p>
                    Click
                    <b>OK</b>
                    to open the AWS console in a new browser tab in your desired AWS account to use
                    for Ioto. Then approve the AWS CloudFormation Template. This will create an AWS
                    IAM access role in your AWS account for use by Ioto.
                </p>
                <p>
                    Tick the checkbox and click "
                    <b>Create Stack</b>
                    " at the lower right hand side of the CloudFormation template. It will take up a
                    few minutes for AWS to complete the task.
                </p>
                <div>
                    <p>
                        <b>NOTE:</b>
                        if your browser is configured to block popups, click this link:
                    </p>
                    <p>
                        <a :href="page.template" :disabled="!page.template" target="_blank">
                            Open AWS Console.
                        </a>
                    </p>
                </div>
                <p>When complete, come back to this Embedthis Builder browser tab.</p>
                <div class="actions">
                    <v-btn
                        size="small"
                        color="accent"
                        :disabled="!page.template"
                        @click="startTemplate">
                        OK
                    </v-btn>
                    <v-btn size="small" color="none" @click="cancelTemplate">Cancel</v-btn>
                </div>
            </vu-form>
        </v-dialog>
        <vu-confirm ref="confirm" />
    </div>
</template>

<style lang="scss">
.host-edit {
    label.options {
        margin-top: 16px;
        padding-bottom: 8px;
        display: block;
        font-size: 1.125rem;
        color: rgb(var(--v-theme-text-darken-1));
    }
    .v-alert.error {
        border: none;
        h2 {
            color: white;
        }
        a {
            color: white;
            font-weight: bold;
        }
    }
    .v-btn {
        margin-left: 0;
    }
    .token {
        font-size: 1rem;
    }
    .actions {
        margin-top: 20px;
    }
    .expando {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .token {
        font-weight: bold;
    }
}
.iam-host-dialog {
    // background: white;
    h1 {
        background-color: rgb(var(--v-theme-primary));
        margin: -20px -20px 20px -20px !important;
        padding: 36px 16px 16px 16px;
        color: white;
    }
    .actions {
        margin-bottom: 0px;
    }
}
.dialog-progress {
    z-index: 200;
    .headline {
        color: white;
    }
}
</style>
