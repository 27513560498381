<script setup>
/*
    Validation rules
    More rules available at: https://github.com/vuelidate/vuelidate
 */
import {inject, reactive} from 'vue'
import {Rules} from '@/paks/vu-rules'
import {waitRender} from '@/paks/vu-app'

const props = defineProps({
    data: Object,
})

const page = reactive({
    errors: [],
})

const inputs = inject('inputs', [])

defineExpose({Rules, check, clear, error, exception, fieldError, hasErrors, showErrors})

function hasErrors() {
    return page.errors.length > 0
}

async function check(form) {
    page.errors = []
    let results = await form.validate()
    if (!results.valid) {
        showErrors(form)
        return false
    }
    return true
}

function clear() {
    page.errors = []
}

function error(title, message) {
    let header = message ? [`${title}: ${message}`] : [`${title}`]
    page.errors = header.concat(page.errors)
}

async function fieldError(component, field, message) {
    await waitRender()
    let form = component.$refs.form
    if (form) {
        let items = form.items
        if (items) {
            let input = items.find((i) => i.id == field)
            if (input?.errorMessages && input.errorMessages.find((b) => b == message) == null) {
                input.errorMessages.push(message)
            }
        }
    }
    showErrors(form)
}

async function exception(component, err) {
    let form = component.$refs.form
    if (err.details) {
        let fieldErrors = err.details.fieldErrors || {}
        let items = form.items
        for (let [field, value] of Object.entries(fieldErrors)) {
            let input = items.find((i) => i.id == field)
            if (input) {
                input.errorMessages.push(value)
            }
        }
        await waitRender()
    } else {
        error(err.message)
    }
    showErrors(form)
}

function showErrors(form) {
    for (let input of form.items) {
        if (input.errorMessages.length) {
            page.errors.push(input.errorMessages[0])
        }
    }
}
</script>

<template>
    <div class="vu-validate" v-if="page.errors.length">
        <v-card variant="outlined" class="validate">
            <v-card-text>
                <h3>Please correct the following errors</h3>
                <ul>
                    <li v-for="(error, index) in page.errors" :key="index">{{ error }}</li>
                </ul>
            </v-card-text>
        </v-card>
    </div>
</template>

<style lang="scss" scoped>
.vu-validate {
    width: 100%;
    .validate {
        background-color: rgb(var(--v-theme-error)) !important;
        color: white !important;
        border-radius: 0 !important;
        margin-bottom: 20px;
        h3 {
            color: white;
            padding-bottom: 10px;
        }
        ul {
            margin-left: 12px;
        }
        li {
            color: white;
            font-size: 0.75rem;
        }
    }
}
</style>
