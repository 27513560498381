<script setup>
import {onBeforeMount, reactive, ref} from 'vue'
import {Feedback, Progress, State, clone, getModels} from '@/paks/vu-app'
import DashRange from './DashRange.vue'
import CssEdit from './CssEdit.vue'
import UUID from '@/paks/js-uuid'

const props = defineProps({
    dashboard: Object,
    range: Object,
})
const emit = defineEmits(['input'])

const page = reactive({
    applying: false,
    askRange: false,
    dashboard: {widgets: []},
    design: null,
    grid: null,
    hasMetrics: null,
    layouts: true,
    multiple: null,
    saving: false,
})

const {Dashboard} = getModels()

//  Component refs
const self = ref(null)
const confirm = ref(null)

onBeforeMount(() => {
    if (props.dashboard) {
        page.dashboard = clone(props.dashboard)
    }
    let dashboard = page.dashboard

    //  Set defaults
    dashboard.range = dashboard.range || {}
    dashboard.css = dashboard.css || []
    dashboard.widgets = dashboard.widgets || []
    dashboard.widgetCss = dashboard.widgetCss || []
    dashboard.design = dashboard.design == null ? true : dashboard.design
    dashboard.framed = dashboard.framed == null ? true : dashboard.framed
    dashboard.full = dashboard.full == null ? false : dashboard.full
    dashboard.layout = dashboard.layout == null ? 'grid' : dashboard.layout
    dashboard.live = dashboard.live == null ? true : dashboard.live
    dashboard.snap = dashboard.snap == null ? true : dashboard.snap

    if (dashboard.name == 'New Dashboard') {
        dashboard.name = ''
    }
    page.hasMetrics =
        State.config.features.dash.metrics &&
        State.cache.dashboard.widgets.filter((w) => w.metric).length
            ? true
            : false
    page.multiple = State.config.features?.dash?.multiple ? true : false
    page.grid = dashboard.layout != 'grid' ? false : true
    page.design = dashboard.design
})

async function preSave() {
    let dashboard = page.dashboard
    if (dashboard.name == 'New Dashboard') {
        throw new Error('Must set dashboard name')
    }
    if (dashboard.id == null && (await Dashboard.get({name: dashboard.name}))) {
        throw new Error('Dashboard name already in use')
    }
    return true
}

async function apply() {
    let dashboard = page.dashboard
    let refresh = parseInt(dashboard.refresh)
    if (refresh < 5) {
        refresh = 5
    }
    /*
    if (dashboard.design && !dashboard.widgets.find(w => w.type == 'toolbar')) {
        dashboard.widgets.push({type: 'toolbar', css: [], range: {}, id: UUID(), framed: false})
    } */
    let params = {
        id: dashboard.id,
        css: dashboard.css,
        design: page.design,
        emulate: dashboard.emulate,
        framed: dashboard.framed,
        full: dashboard.full,
        live: dashboard.live,
        layout: page.grid ? 'grid' : 'exact',
        name: dashboard.name,
        range: dashboard.range,
        refresh: refresh,
        snap: page.grid ? true : false,
        widgets: dashboard.widgets,
        widgetCss: dashboard.widgetCss,
    }
    if (dashboard.id) {
        dashboard = await Dashboard.update(params)
    } else {
        dashboard = await Dashboard.create(params)
    }
    await Dashboard.set(dashboard)
}

async function save() {
    await apply()
    emit('input')
}

async function copy() {
    if (
        !(await confirm.value.ask(
            `Do you want to create a copy of the dashboard called "${page.dashboard.name} 2"?`,
            {
                width: 600,
            }
        ))
    ) {
        return
    }
    let dashboard = (page.dashboard = clone(page.dashboard))
    delete dashboard.id
    dashboard.name = `${dashboard.name} 2`
    await save()
}

async function cleanWidgets() {
    if (!(await confirm.value.ask(`Do you want to erase all widgets on the dashboard"?`))) {
        return
    }
    page.dashboard.widgets = []
    await apply()
}

async function deleteDash() {
    let dashboard = page.dashboard
    if (!dashboard.name) {
        return
    }
    if (!(await confirm.value.ask(`Do you want to delete the dashboard"?`))) {
        return
    }
    Progress.start('medium')
    await Dashboard.remove({id: dashboard.id})
    await Dashboard.set()
    Progress.stop()

    Feedback.info('Dashboard Deleted')
    emit('input')
}

async function changeDashboard() {
    let name = page.dashboard.name
    if (name == 'New Dashboard') {
        //  Initialize and clear the name
        page.dashboard = {layout: 'grid', snap: true, widgets: []}
    } else {
        //  See if the name exists
        let dashboard = await Dashboard.get({name})
        if (page.dashboard.id) {
            //  Changing from a current dashboard to a new one
            if (dashboard) {
                //  Found
                page.dashboard = dashboard
            }
        } else {
            //  Setting a name for a new dashboard. Check if name already in use
            if (dashboard) {
                let validate = self.value.validate
                if (validate) {
                    validate.clear()
                    await validate.fieldError(self.value, 'name', 'Dashboard name already in use')
                }
            } else {
                page.dashboard.name = name
            }
        }
    }
}
</script>

<template>
    <vu-form
        class="dashboard-edit"
        help="/doc/ui/dashboard/dashboard-edit.html"
        ref="self"
        :data="page"
        :pre-save="preSave"
        :save="save"
        :title="`${page.dashboard.id ? 'Modify' : 'Add'} Dashboard`">
        <vu-input
            v-if="page.multiple"
            v-model="page.dashboard.name"
            label="Dashboard Name"
            name="name"
            placeholder="Enter dashboard name"
            type="text"
            class="mb-2"
            @change="changeDashboard" />

        <div class="vrow">
            <vu-input
                label="Layout"
                v-model="page.grid"
                cols="4"
                type="switch"
                :suffix="page.grid ? 'Grid' : 'Exact'" />

            <!--
            <vu-input
                type="switch"
                v-if="page.grid"
                v-model="page.dashboard.snap"
                label="Snap to Grid"
                cols="4"
                :inset="true" />
            -->

            <vu-input
                type="switch"
                class="live-data"
                v-model="page.dashboard.live"
                label="Live Data"
                cols="4"
                inset />
        </div>
        <div class="vrow">
            <vu-input
                type="switch"
                v-model="page.dashboard.framed"
                label="Framed Widgets"
                cols="4"
                inset />
            <vu-input
                type="switch"
                v-model="page.dashboard.full"
                label="Full Screen"
                cols="4"
                inset />
            <vu-input type="switch" v-model="page.design" label="Design Mode" cols="4" inset />
        </div>

        <vu-input
            type="text"
            v-model="page.dashboard.refresh"
            label="Refresh"
            placeholder="Seconds"
            :clearable="false"
            suffix="secs"
            cols="6" />

        <vu-input
            v-if="!State.app.mobile"
            type="select"
            v-model="page.dashboard.emulate"
            label="Emulate"
            :items="['None', 'Mobile', 'Desktop']"
            cols="6" />

        <vu-input
            v-if="page.hasMetrics"
            v-model="page.askRange"
            type="checkbox"
            label="Time Range"
            hide-details 
        />

        <DashRange
            v-if="page.hasMetrics && page.askRange"
            v-model="page.dashboard.range"
            mode="date" />

        <CssEdit v-model="page.dashboard.css" title="Dashboard CSS Properties" class="mb-2" />
        <CssEdit
            v-model="page.dashboard.widgetCss"
            title="Default Widget CSS Properties"
            class="mb-3" />

        <div class="actions">
            <v-btn size="small" color="accent" type="submit" :loading="page.saving">Save</v-btn>
            <v-btn size="small" color="accent" @click="apply" :loading="page.applying">Apply</v-btn>
            <v-btn size="small" color="teal" @click="copy">Copy</v-btn>
            <v-btn size="small" color="none" @click="emit('input', null)">Cancel</v-btn>
            <!--
            <v-btn
                v-if="page.dashboard.id && page.dashboard.widgets.length"
                size="small"
                color="error"
                @click="cleanWidgets">
                Clean
            </v-btn>
            -->
            <v-btn
                size="small"
                color="error"
                v-if="page.multiple && page.dashboard.id"
                @click="deleteDash">
                Delete
            </v-btn>
        </div>
        <vu-confirm ref="confirm" />
    </vu-form>
</template>

<style lang="scss">
.dashboard-edit {
    .pick-dashboard {
        margin: 16px 0 0px 0;
        width: 100%;
        .v-input__control {
            border-color: black;
            border-radius: 4px;
        }
    }
    .v-select-list {
        padding: 0;
        border: solid 1px rgb(var(--v-theme-border-lighten-1));
        border-radius: 0;
    }
    h3 {
        font-weight: normal;
        color: rgb(var(--v-theme-text));
    }
    .layouts {
        margin: 10px 0 20px 0;
    }
    .actions {
        margin: 20px 0 20px 0;
    }
    .live-data {
        //  When grid, helps alignment
        flex-grow: 30;
    }
}
</style>
