<script setup>
/*
    Application main page - includes global styles
 */
import {onBeforeMount, reactive} from 'vue'
import {Navbar, Sidebar} from '@/paks/vu-nav'
import {State} from '@/paks/vu-app'
import WidgetSets from './WidgetSets.js'

const page = reactive({
    nav: null,
})

onBeforeMount(async () => {
    page.nav = State.config.features.nav || {}
    State.app.widgetSets = WidgetSets.filter((s) => s.enable !== false).sort(sortByName)
})

function sortByName(a, b) {
    if (a.name < b.name) {
        return -1
    } else if (b.name < a.name) {
        return 1
    }
    return 0
}
</script>

<template>
    <v-app>
        <Navbar v-if="page.nav.navbar" />
        <Sidebar v-if="page.nav.sidebar" />
        <v-main>
            <vu-tabs />
            <router-view :key="$route.fullPath"></router-view>
        </v-main>
    </v-app>
</template>

<style lang="scss">
:root {
    font-size: var(--v-font-size, '14px');
    font: 'Roboto', sans-serif;
    background-color: rgb(var(--v-theme-background));
}
html {
    height: 100%;
    width: 100%;
}
body {
    height: 100%;
    padding: 0;
    margin: 0;
    .theme--dark.v-application {
        background-color: rgb(var(--v-theme-background, #121212)) !important;
    }
    .theme--light.v-application {
        background-color: rgb(var(--v-theme-background, white)) !important;
    }
}
.v-main {
    color: rgb(var(--v-theme-text));
    padding-top: 0 !important;
    min-height: 800px;
    .display {
        max-width: none;
        padding: 30px;
    }
    .sheet {
        padding: 30px;
        width: 100%;
        background: rgb(var(--v-theme-surface));
        border: 1px solid rgb(var(--v-theme-border));
    }
    .page {
        padding: 24px 36px 36px 36px;
        width: 100%;
    }
    .action {
        margin-right: 10px !important;
        border: 1px solid rgb(var(--v-theme-border));
        background: none !important;
        box-shadow: none !important;
    }
    a {
        text-decoration: none;
        color: rgb(var(--v-theme-accent-darken-1));
    }
}
.slide-out-enter-active,
.slide-out-leave-active {
    transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) !important;
}
.slide-out-enter,
.slide-out-leave-to {
    transform: translateX(100%);
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease-in !important;
}
.v-icon.closer {
    position: fixed;
    padding: 6px;
    right: 0;
    top: 0;
}
.blackout {
    z-index: 198 !important;
}
.v-alert__border {
    border-width: 2px;
}
.v-btn__loader {
    .v-progress-circular {
        height: 13px !important;
        width: 13px !important;
    }
}
.tooltip {
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    background: rgba(10, 10, 10, 0.55);
    color: white;
    opacity: 0.95;
    border: 1px solid rgba(200, 200, 200, 0.5);
    border-radius: 6px;
    margin-top: 8px;
    padding: 8px;
    z-index: 200;
}
.loading {
    position: fixed;
    opacity: 0.5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    font-family: sans-serif;
    animation: pulse 1s infinite;
}
@keyframes pulse {
    0% {
        opacity: .2;
    }
    60% {
        opacity: .6;
    }
    100% {
        opacity: .2;
    }
}
</style>
