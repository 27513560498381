<script setup>
/*
    VuSpell.js - Vue Wizard spell directive

    <vu-spell id=“wiz-name” next=“” prev=“” position=“” nudge=“” height="" width=""
            title="" footer="" height="" width="">
        Message
    </vu-spell>

    Attributes:
        footer
        height = NN
        message
        nudge = {x, y}
        next
        position = {x, y}
        prev
        subtitle
        title
        width = NN
*/

import {getCurrentInstance, inject, onMounted, onUnmounted, reactive, ref, watch} from 'vue'

const {proxy: self} = getCurrentInstance()

const props = defineProps({
    action: {type: Function},
    footer: {type: String},
    height: {type: String, default: '50px'},
    id: {type: String},
    nudge: {
        type: Object,
        default: () => {
            return {
                x: 0,
                default: () => {
                    return {x: 0, y: 0}
                },
            }
        },
    },
    position: {
        type: [Object, String],
        default: () => {
            return {x: 'center', y: 'middle', placement: 'solo', default: true}
        },
    },
    subtitle: {type: String},
    title: {type: String},
    width: {type: String, default: '440px'},
})

const page = reactive({
    active: false,
    next: null,
    placement: null,
    prev: null,
})

const activate = inject('activate')

watch(() => page.active, activation)

onMounted(async () => {
    activate.value[props.id] = page
    let children = self?.$parent?.$slots.default().filter(c => c.type.__name == 'VuSpell')
    if (children) {
        let index = children.findIndex((c) => c.props?.id == props.id)
        if (index >= 0) {
            page.next = children[index + 1] ? children[index + 1].props.id : null
            page.prev = children[index - 1] ? children[index - 1].props.id : null
        }
    }
})

async function activation() {
    if (page.active) {
        //  In V2 had trouble getting a ref="content" to work
        const content = self.$refs.content
        if (content) {
            if (props.position) {
                let {x, y} = parsePosition(props.position)
                if (y.toString().indexOf('%') < 0) {
                    content.style.top = `${y}px`
                } else {
                    content.style.top = y
                }
                if (x.toString().indexOf('%') < 0) {
                    content.style.left = `${x}px`
                } else {
                    content.style.left = x
                }
            }
            content.style.height = props.height
            content.style.width = props.width
        }
    } else {
        page.active = false
    }
}

function parsePosition(pos = {x: 'center', y: 'middle', placement: 'solo', default: true}) {
    let x = 0
    let y = 0
    if (typeof pos == 'string') {
        let [id, place] = pos.split(':')
        page.placement = place || page.placement || 'bottom'

        let el = id[0] == '#' ? document.getElementById(id.slice(1)) : document.getElementsByClassName(id)[0]
        if (el) {
            let rect = el.getBoundingClientRect()
            if (page.placement == 'bottom') {
                y = rect.bottom + 16
                x = rect.left
            } else if (page.placement == 'top') {
                y = rect.top - parseInt(props.height) - 96 - 16
                x = rect.left
            } else if (page.placement == 'right') {
                x = rect.right + 16
                y = rect.top
            } else if (page.placement == 'left') {
                x = rect.left - parseInt(props.width)
                y = rect.top
            }
        } else {
            //  No element so just center
            let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
            let height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
            x = width / 2 - parseInt(props.width) / 2
            y = height / 2 - parseInt(props.height) / 2
            page.placement = 'unknown'
            self.$parent.advance(page.next)
        }
    } else {
        x = pos.x
        y = pos.y
        let place = pos.placement
        page.placement = place || page.placement
        let height = parseInt(props.height)
        let width = parseInt(props.width)

        if (x == 'left') {
            x = 0
        } else if (x == 'center') {
            x = window.innerWidth / 2 - width / 2
        } else if (x == 'right') {
            x = window.innerWidth - 1 - width
        } else if (x.toString().indexOf('%') > 0) {
        } else {
            x = parseInt(x)
        }
        if (y == 'top') {
            y = 0
        } else if (y == 'middle') {
            y = window.innerHeight / 2 - height * 2
        } else if (y == 'bottom') {
            y = window.innerHeight - 1 - height
        } else if (y.toString().indexOf('%') > 0) {
        } else {
            y = parseInt(y)
        }
    }
    if (page.placement != 'unknown') {
        if (x.toString().indexOf('%') < 0) {
            if (x < 0) {
                x = 0
            }
            x += props.nudge.x || 0
        }
        let nudgeY = props.nudge.y
        if (pos.default) {
            nudgeY = props.nudge.y || -100
        }
        if (y.toString().indexOf('%') < 0) {
            if (y < 0) {
                y = 0
            }
            y += nudgeY || 0
        }
    }
    return {x, y}
}

function advance(arg) {
    self.$parent.advance(arg)
}
</script>


<template>
    <div class="vu-spell" v-show="page.active">
        <div class="content" ref="content">
            <slot name="content">
                <v-card :class="page.placement">
                    <v-card-title class="title" v-if="title">{{ title }}</v-card-title>
                    <v-card-subtitle class="subtitle" v-if="subtitle && false">{{ subtitle }}</v-card-subtitle>
                    <v-card-text class="message"><slot /></v-card-text>
                    <v-card-actions class="footer" v-if="footer">{{ footer }}</v-card-actions>
                    <v-card-actions class="footer" v-if="!footer">
                        <v-btn theme="light" variant="elevated" :slim="false" size="small" color="accent" v-if="page.next" @click="advance(page.next)">Next</v-btn>
                        <v-btn theme="light" variant="elevated" size="small" v-if="page.prev" @click="advance(page.prev)">Prev</v-btn>
                        <v-btn theme="light" variant="elevated" size="small" :color="!page.next ? 'accent' : ''" @click="advance()">Done</v-btn>
                    </v-card-actions>
                </v-card>
            </slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.vu-spell {
    .content {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 201;

        .v-card {
            border: solid 2px #82b1ff;
            border-top: solid 4px #82b1ff;
            padding: 12px;
            overflow: visible;
            
            /*
            .bubble {
                width: 0;
                height: 0;
                border-left: 25px solid transparent;
                border-right: 25px solid transparent;
                border-bottom: 50px solid #555;
            } */

            .title {
                font-size: 1.25rem !important;
                font-weight: bold;
                letter-spacing: .0125em !important;
                margin-bottom: 12px;
            }
            .message {
                font-size: 1.1rem;
                line-height: 1.35;
            }
            .message:deep(p) {
                margin-bottom: 16px !important;
            }
            z-index: 202;
        }

        .v-card.top:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 15%;
            width: 0;
            height: 0;
            border: 16px solid transparent;
            border-top-color: rgb(var(--v-theme-accent));
            border-bottom: 0;
            margin-left: -16px;
            margin-bottom: -16px;
        }

        .v-card.bottom:after {
            content: '';
            position: absolute;
            top: 0;
            left: 15%;
            width: 0;
            height: 0;
            border-left: 0;
            border: 16px solid transparent;
            border-bottom-color: rgb(var(--v-theme-accent));
            border-top: 0;
            margin-left: -16px;
            margin-top: -16px;
        }

        .v-card.right:after {
            content: '';
            position: absolute;
            top: 36px;
            left: 0;
            width: 0;
            height: 0;
            border: 16px solid transparent;
            border-right-color: rgb(var(--v-theme-accent));
            border-left: 0;
            margin-top: -16px;
            margin-left: -16px;
            z-index: 200;
        }
        .v-card.left:after {
            content: '';
            position: absolute;
            right: 0;
            top: 36px;
            width: 0;
            height: 0;
            border: 16px solid transparent;
            border-left-color: rgb(var(--v-theme-accent));
            border-right: 0;
            margin-top: -16px;
            margin-right: -16px;
        }
        .v-card.solo:after {
            display: none;
        }
    }
}
</style>
