<script setup>
import {reactive} from 'vue'
import {Auth, clone} from '@/paks/vu-app'

const props = defineProps({modelValue: [Object]})
const emit = defineEmits(['input', 'update:modelValue'])

const page = reactive({
    auth: clone(props.modelValue),
    dialog: true,
    checkPassword: null,
    code: '',
    rules: [],
    saving: null,
    showPassword: false,
})

const checkPasswordRule = [
    (v) => page.auth.password == page.checkPassword || 'Confirmation does not match',
]

async function save() {
    let auth = page.auth
    await Auth.forgotConfirmation(auth.email, auth.password, page.code)
    auth.confirmed = true
    emit('update:modelValue', auth)
    emit('input', auth)
}

function cancel() {
    emit('input')
}
</script>

<template>
    <v-dialog v-model="page.dialog" persistent width="500" content-class="forgot-confirm-dialog">
        <vu-form :data="page" :save="save">
            <v-card>
                <v-card-title class="headline primary" primary-title>
                    Enter New Password
                </v-card-title>
                <v-card-text class="pt-4 px-4">
                    <v-text-field
                        v-model="page.code"
                        autocomplete="new-password"
                        name="code"
                        label="Confirmation Code"
                        required
                        clearable
                        :rules="page.rules.required" />
                    <v-text-field
                        v-model="page.auth.password"
                        name="new-password"
                        label="Password"
                        autocomplete="new-password"
                        hint="At least 8 characters"
                        counter
                        required
                        @click:append="page.showPassword = !page.showPassword"
                        :type="page.showPassword ? 'text' : 'password'"
                        :append-icon="page.showPassword ? 'visibility' : 'visibility_off'"
                        :rules="page.rules.password" />
                    <v-text-field
                        v-model="page.checkPassword"
                        autocomplete="confirm-password"
                        name="confirm-password"
                        label="Confirm Password"
                        type="password"
                        required
                        counter
                        :rules="checkPasswordRule" />
                </v-card-text>
                <v-card-actions class="px-4 pb-4">
                    <v-btn ripple :loading="page.saving" type="submit" color="accent">
                        Confirm
                    </v-btn>
                    <v-btn ripple @click="cancel">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </vu-form>
    </v-dialog>
</template>

<style lang="scss">
.forgot-confirm-dialog {
    .headline {
        color: white;
    }
    .vu-form {
        padding: 0 0 0 0 !important;
        .v-card {
            width: 100%;
        }
    }
}
.v-input--selection-controls {
    margin-top: 0;
}
</style>
