<script setup>
// import {onBeforeMount, reactive, ref, watch} from 'vue'
import { Feedback, } from '@/paks/vu-app'
const props = defineProps({
    text: String,
})

function copy(text) {
    navigator.clipboard.writeText(text)
    Feedback.info(`Copied to Clipboard`)
}
</script>

<template>
    <v-icon icon="$copy" size="14" @click="copy(text)" />
</template>