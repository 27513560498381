/*
    manager.js - Manager model
 */

import {Rest} from '@/paks/js-rest'

export default new Rest('Manager', {
    checkDomain: { method: 'POST', uri: '/:controller/checkDomain' },
    provision: { method: 'POST', uri: '/:controller/provision'},
    getSignedUrl: { method: 'POST', uri: '/:controller/getSignedUrl'},
}, {cache: true, service: '/api'})