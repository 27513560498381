/*
    js-config -- Lambda read configuration and manage profile mode
 */

import blend from '@/paks/js-blend'

const config = {}

export default function(groups, version, profile) {
    /*
        PROFILE may set in environment to the serverless stage.
     */
    let config = {}
    groups = Array.isArray(groups) ? groups : [groups]
    for (let group of groups) {
        blend(config, group)
    }
    profile = profile || config.profile || process.env.PROFILE || process.env.NODE_ENV || 'prod'
    if (profile == 'production') {
        profile = 'prod'
    } else if (profile == 'development') {
        profile = 'dev'
    }
    config.profile = profile || config.profile
    config.version = version || config.version

    if (config.profiles && config.profiles[profile]) {
        blend(config, config.profiles[profile])
    }
    delete config.profiles
    return config
}
