<script setup>
//  https://www.embedthis.com/images/logos/embedthis-logo-big.avif

import {onMounted, reactive, ref, watch} from 'vue'

const props = defineProps({widget: Object})

const page = reactive({
    density: null,
    elevation: null,
    size: null,
    text: null,
    variant: null,
})

defineExpose({update})

const self = ref(null)

function update() {
    let el = self.value.$el
    page.width = el.style['width']
    page.aspect = el.style['--w-aspect']
    page.cover = el.style['--w-cover'] == 'false' ? false : true
    page.gradient = el.style['--w-gradient']
}
</script>

<template>
    <vu-div ref="self" class="image-widget">
        <v-img
            :width="page.width"
            :apect-ratio="page.aspect"
            :cover="page.cover"
            :src="props.widget.url"
            :gradient="page.gradient" />
    </vu-div>
</template>

<style lang="scss">
.image-widget {
    height: 100%;
    width: 100%;
    .v-img {
        height: 100% !important;
        width: 100% !important;
    }
}
</style>
