<script setup>
/*
    GaugeWidget.js - Gauge directive
 */
import {reactive, ref} from 'vue'
import {State, clone, delay, waitRender} from '@/paks/vu-app'

const Spans = [
    {label: '5 mins', period: 5 * 60},
    {label: 'hr', period: 60 * 60},
    {label: 'day', period: 24 * 60 * 60},
    {label: 'week', period: 7 * 24 * 60 * 60},
    {label: 'month', period: 28 * 24 * 60 * 60},
    {label: 'year', period: 365 * 24 * 60 * 60},
]

const props = defineProps({
    widget: Object,
})

const page = reactive({
    animatedValue: null,
    configuring: false,
    frame: FRAME_RATE,
    low: null,
    height: null,
    high: null,
    min: props.widget.min || 0,
    max: props.widget.max || 100,
    period: props.widget.range?.period || PERIOD,
    offsetHeight: 0,
    offsetWidth: 0,
    styles: {},
    theme: {},
    units: null,        //  getUnits(props.widget),
    updating: null,
    width: null,
    value: props.widget.value,
    x: 0,
    y: 0,
})

const LightTheme = {
    background: '#FFFFFF',
    color: '#505050',
    highlight: '#7aa1d5',
}
const DarkTheme = {
    background: '#111217',
    color: '#FFFFFF',
    highlight: '#4f82d8',
}

const PERIOD = 1000
const FRAME_RATE = 10
const MIN_SIZE = 5

defineExpose({update})

//  Component references
const canvas = ref(null)
const self = ref(null)

/*
    Update the drawn value. Changes are animiated.
 */
 async function update(params = {}) {
    if (params.initialize || canvas.value?.height == 0) {
        configure()
    }
    let value = (page.value = boundValue(props.widget.value || 0))
    if (!canvas.value || page.updating || isNaN(value) || hidden()) {
        return
    }
    page.updating = true
    //  Save value to skip rendering on subsequent updates to the same value
    let steps = Math.min((page.period / 1000) * page.frame, 10)
    if (page.animatedValue > page.max) {
        page.animatedValue = page.max
    } else if (!page.animatedValue) {
        steps = 1
    }
    let originalDiff = value - page.animatedValue

    do {
        let diff = value - page.animatedValue
        let inc = diff / steps
        page.animatedValue += inc < 0 ? Math.floor(inc) : Math.ceil(inc)

        draw(value, page.animatedValue)

        diff = value - page.animatedValue
        if (Math.abs(diff) <= Math.abs(originalDiff / 100)) {
            break
        }
        await delay(250 / page.frame)
    } while (canvas.value && value == page.value)

    page.animatedValue = value
    page.updating = false
    await waitRender()
    if (value != page.value) {
        update(page.value)
    }
}

function configure() {
    if (!canvas.value || page.configuring) {
        return
    }
    page.configuring = true

    /*
        Calculate a maximal square with padding
     */
    let c = canvas.value.getContext('2d')
    let widgetElement = self.value
    let {offsetHeight, offsetWidth} = widgetElement

    page.square = Math.max(Math.min(offsetHeight, offsetWidth), MIN_SIZE)
    page.square = parseInt(page.square * 0.9)
    page.height = page.width = page.square
    page.x = (offsetWidth - page.square) / 2
    page.y = (offsetHeight - page.square) / 2

    if (props.widget.header) {
        page.y += Math.max(10, parseInt(page.square / 40))
    }
    c.canvas.width = offsetWidth
    c.canvas.height = offsetHeight
    c.clearRect(0, 0, canvas.value.width, canvas.value.height)
    page.configuring = false
}

/*
    Draw the gauge
 */
function draw(value, animatedValue) {
    if (!canvas.value) {
        return
    }
    let widgetElement = self.value

    let theme = clone(State.app.dark ? DarkTheme : LightTheme)
    theme.highlight = widgetElement.style['--w-highlight'] || theme.highlight
    theme.color = widgetElement.style.color || theme.color
    theme.background = window.getComputedStyle(widgetElement).backgroundColor

    setMinMax(value)
    let c = canvas.value.getContext('2d')
    let styles = window.getComputedStyle(canvas.value, null)
    let color = theme.color
    let height = parseInt(page.height)
    let width = parseInt(page.width)
    let radius = page.square * 0.55
    let arrowLength = radius * 1.1
    let sweep = 0.65 * Math.PI
    let startAngle = 1.5 * Math.PI - sweep / 2
    let endAngle = 1.5 * Math.PI + sweep / 2
    let font = styles.getPropertyValue('font-family') || 'Roboto'
    let fontSize = parseInt(styles.getPropertyValue('font-size')) || 14

    fontSize = Math.round(fontSize + Math.max(height - 200, 0) * 0.06)

    if (radius <= 1) {
        radius = 1
    }
    c.save()
    c.fillStyle = theme.background
    c.fillRect(page.x, page.y, width, height)
    // c.clearRect(0, 0, canvas.value.width, canvas.value.height)

    let y = page.y + height * 0.65

    //  Gauge max, min
    c.font = '' + fontSize + 'px ' + font
    c.fillStyle = theme.color
    c.textAlign = 'right'
    c.fillText('' + Math.round(page.high - 0).toLocaleString(), page.x + width, y)

    c.textAlign = 'left'
    c.fillText('' + Math.round(page.low - 0).toLocaleString(), page.x, y)
    y += fontSize / 2

    //  Gauge background
    let x = page.x + width / 2
    let cx = x
    let cy = y
    c.beginPath()
    c.moveTo(cx, cy)
    c.arc(cx, cy, radius, startAngle, endAngle, false)
    let g = c.createLinearGradient(0, cy, width, cy)
    g.addColorStop(0, lighten(theme.highlight, 55))
    g.addColorStop(1, lighten(theme.highlight, 0))
    c.fillStyle = g
    c.closePath()
    c.fill()

    // Center circle
    c.beginPath()
    c.moveTo(cx, cy)
    c.fillStyle = theme.background
    c.arc(cx, cy, radius * 0.3, 0, 2 * Math.PI, false)
    c.closePath()
    c.fill()
    y += fontSize * 2.3

    //  Gauge value
    c.font = 'bold ' + Math.round(fontSize * 1.6) + 'px ' + font
    c.textAlign = 'center'
    c.fillStyle = theme.color
    c.fillText(value.toLocaleString(), cx, y)

    //  Units
    if (page.units) {
        c.font = fontSize + 'px ' + font
        c.fillStyle = theme.color
        c.textAlign = 'left'
        let textWidth = c.measureText(value.toLocaleString()).width
        c.fillText(`/ ${page.units}`, x + textWidth, y)
    }
    y += fontSize * 2

    function arrow(angle) {
        if (angle < -1) {
            angle = -1
        } else if (angle > 1) {
            angle = 1
        }
        c.save()
        c.beginPath()
        c.translate(cx, cy)
        c.rotate(angle)

        c.moveTo(0, -arrowLength)
        c.lineTo(4, 0)
        c.lineTo(-4, 0)
        c.lineTo(0, -arrowLength)
        c.closePath()
        c.fillStyle = color
        c.fill()

        //  Arrow base
        c.beginPath()
        c.arc(0, 0, 4, 0, 2 * Math.PI, false)
        c.fillStyle = color
        c.closePath()
        c.fill()
        c.translate(-cx, -cy)
        c.restore()
    }
    animatedValue = Math.min(animatedValue, page.max)
    animatedValue = (animatedValue / page.max) * 100
    arrow(((animatedValue - 50) / 50) * (sweep / 2))
    c.restore()
}


function boundValue(v) {
    setMinMax(v)
    let value = v - 0
    if (value < page.min) {
        value = 0
    }
    if (value > page.max) {
        value = page.max
    }
    if (page.min != null) {
        page.low = page.min
    }
    if (page.max != null) {
        page.high = page.max
    }
    if (page.low == page.high) {
        page.low = 0
        page.high = 100
    }
    return value
}

/* KEEP
function rgb2hex(color) {
    if (!color) {
        color = 'rgb(0,0,0)'
    }
    var matches = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)/)
    if (!matches) {
        matches = color.match(/^rgba\((\d+),\s*(\d+),\s*(\d+)/)
    }
    if (!matches) {
        return '#000'
    }
    function hex(x) {
        return ('0' + parseInt(x).toString(16)).slice(-2)
    }
    return '#' + hex(matches[1]) + hex(matches[2]) + hex(matches[3])
} */

function lighten(color, percent) {
    if (color[0] != '#') {
        return color
    }
    let num = parseInt(color.slice(1), 16)
    let amt = Math.round(2.55 * percent)
    let R = (num >> 16) + amt
    let B = ((num >> 8) & 0x00ff) + amt
    let G = (num & 0x0000ff) + amt
    return (
        '#' +
        (
            0x1000000 +
            (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
            (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
            (G < 255 ? (G < 1 ? 0 : G) : 255)
        )
            .toString(16)
            .slice(1)
    )
}

function setMinMax(v) {
    page.min = props.min || 0
    page.max = props.max || v || 100
}

function hidden() {
    return document.hidden || document.msHidden || document.webkitHidden || document.mozHidden
}

function getUnits(widget) {
    let period = widget.range.period
    let span = Spans.find((s) => s.period >= period)
    return span ? span.label : 'hour'
}
</script>

<template>
    <div ref="self" class="gauge-widget">
        <canvas class="canvas" ref="canvas">
            This text is displayed if your browser does not support HTML5 Canvas.
        </canvas>
    </div>
</template>

<style lang="scss">
.gauge-widget {
    height: 100%;
    //  Necessary to stop the parent having a height increase of 6 due to scroll bars
    display: block;
    background-color: inherit;
}
</style>
