<script setup>
import {reactive, ref} from 'vue'
import {Progress, State, can} from '@/paks/vu-app'
import {User} from '@/models'
import AccountMemberEdit from './AccountMemberEdit.vue'

const page = reactive({
    canAdd: null,
    fields: [{name: 'edit', icon: '$edit', width: '8%'}, {name: 'email'}, {name: 'role'}, {name: 'state'}],
    showEdit: false,
    user: {},
    users: [],
})

//  Component references
const table = ref(null)

async function getData(args) {
    //  Fetch live incase a member has accepted the invite
    Progress.start('repeat')
    page.users = await User.find({}, {refresh: true})
    page.canAdd = can('admin') && !State.app.suspended
    Progress.stop()
    return page.users
}

async function clicked({action, item, items, column}) {
    if (action == 'add') {
        editUser({})
    } else if (action == 'edit') {
        for (let user of items) {
            editUser(user)
            break
        }
    } else if (action == 'cell') {
        editUser(item)
    }
}

async function editUser(user) {
    page.showEdit = user ? true : false
    page.user = user || {}
    if (!user) {
        await table.value.update()
    }
}
</script>

<template>
    <div class="sheet account-member-list">
        <vu-help url="/doc/ui/account/members/list.html" v-if="!page.showEdit" />
        <vu-table
            name="member-list"
            nodata="Fetching members..."
            options="dynamic,filter,refilter,toolbar"
            ref="table"
            title="Members"
            width="900px"
            :data="getData"
            :fields="page.fields"
            :select="{actions: {Add: 0, Edit: 1, Delete: 2}, multi: true, property: 'select'}"
            @click="clicked">
            <template v-slot:more="props">
                <v-btn color="accent" class="mr-2" v-if="can('admin')" :disabled="!page.canAdd" @click="editUser({})">
                    Invite Member
                </v-btn>
            </template>
        </vu-table>
        <vu-panel v-model="page.showEdit" @close="editUser" :widths="['600px']">
            <AccountMemberEdit :id="page.user.id" @input="editUser" />
        </vu-panel>
    </div>
</template>
