/*
    js-polyfill.js -- Useful polyfills and globals (dump, loadJson, print, serialize)
 */

try {
    //  Needed for custom-event used by dragula
    if (typeof global !== 'undefined') {
    } else if (typeof self !== 'undefined') {
        self.global = self
    } else if (typeof window !== 'undefined') {
        window.global = window
    } else {
        let self = Function('return this')()
        self.global = self
    }
} catch (e) {}

/*
    Debug assist
 */
export function debug(profile) {
    global.dump = (...args) => {
        let s = []
        for (let item of args) {
            s.push(
                JSON.stringify(
                    item,
                    function (key, value) {
                        if (this[key] instanceof Date) {
                            return this[key].toLocaleString()
                        }
                        return value
                    },
                    4
                )
            )
        }
        print(s.join(' '))
    }
    global.print = (...args) => {
        console.log(...args)
    }
    global.assert = (a) => {
        if (!a) {
            console.log(`Assertion failed`, new Error('Assertion'), {bug: true})
            debugger
        }
    }
}

export function allow(obj, ...mask) {
    let result = {}
    if (!obj) {
        obj = {}
    }
    if (mask.length == 1 && Array.isArray(mask[0])) {
        mask = mask[0]
    }
    if (Array.isArray(mask)) {
        for (let field of mask) {
            if (obj[field] !== undefined) {
                result[field] = obj[field]
            }
        }
    } else if (typeof mask == 'string') {
        result[mask] = obj[mask]
    } else {
        for (let field of Object.keys(mask)) {
            if (obj[field] !== undefined) {
                result[field] = obj[field]
            }
        }
    }
    return result
}

export function deny(obj, ...mask) {
    let result
    if (!obj) {
        obj = {}
    }
    if (mask.length == 1 && Array.isArray(mask[0])) {
        mask = mask[0]
    }
    if (Array.isArray(mask)) {
        result = Object.assign({}, obj)
        for (let key of mask) {
            delete result[key]
        }
    } else if (typeof mask == 'string') {
        result = {}
        for (let [key, value] of Object.entries(obj)) {
            if (key != mask) {
                result[key] = value
            }
        }
        result[mask] = obj[mask]
    } else {
        result = {}
        for (let field of Object.keys(mask)) {
            if (obj[field] !== undefined) {
                result[field] = obj[field]
            }
        }
    }
    return result
}

export function isDefined(value) {
    return !(value == undefined || value === '' || value.length == 0)
}

//  Return a number with two places
export function currency(n, places = 2) {
    let factor = Math.pow(10, places)
    return (Math.round(n * factor) / factor).toFixed(places) - 0
}

//  Return a string (Can't use toLocaleString we need both commas and places)
export function money(n, places = 2) {
    let factor = Math.pow(10, places)
    let number = +(Math.round(n * factor) / factor).toFixed(places)
    let fraction = number % 1
    let fixed = Math.floor(number)
    fixed = fixed.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    if (fraction) {
        fraction = (Math.round(fraction * factor) / factor).toFixed(places).replace(/^0\./, '.')
    } else {
        fraction = ''
    }
    return fixed + fraction
}

export function template(s, ...contexts) {
    s = s || ''
    for (let context of contexts) {
        if (s.indexOf('${') < 0) {
            break
        }
        s = s.replace(/\${(.*?)}/g, (a, b) => {
            if (context[b]) {
                return context[b]
            } else {
                return a
            }
        })
    }
    return s.replace(/\${(.*?)}/g, '')
}

//  Single word
export function toTitle(word) {
    return word ? word[0].toUpperCase() + word.slice(1) : ''
}

//  Multiword
export function titlecase(str) {
    if (!str) {
        return str
    }
    var words = str.toString().split(/[ \.]/g)
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
    }
    return words.join(' ')
}

export function toMap(a, property, indexed = false) {
    let result = {}
    if (property) {
        for (let i = 0; i < a.length; i++) {
            let item = a[i]
            if (indexed) {
                result[item[property]] = i
            } else {
                result[item[property]] = item
            }
        }
    } else {
        for (let i = 0; i < a.length; i++) {
            let item = a[i]
            result[item] = i
        }
    }
    return result
}

export function trap(obj, prop) {
    let actual = `_${prop}`
    obj[actual] = obj[prop]
    Object.defineProperty(obj, prop, {
        get: function () {
            return obj[actual]
        },
        set: function (value) {
            debugger
            obj[actual] = value
        },
    })
}

export function unique(a) {
    return Array.from(new Set(a))
    // return a.filter((value, index, self) => self.indexOf(value) === index)
}

export function zpad(n, size) {
    var s = n + ''
    while (s.length < size) s = '0' + s
    return s
}

/*
Object.replace = function (dest = {}, src = {}) {
    for (let key of Object.keys(dest)) {
        if (src[key] === undefined) {
            delete dest[key]
        }
    }
    return Object.assign(dest, Object.clone(src))
}

import combine from '@/paks/js-combine'
import blend from '@/paks/js-blend'
import clone from '@/paks/js-clone'

Object.combine = combine
Object.blend = blend
Object.clone = clone

export function serialize(o, pretty = false) {
    return JSON.stringify(o, null, pretty ? 4 : 0)
}
export const toTitle = (s) => (s ? s[0].toUpperCase() + s.slice(1) : '')

export function rotate(n, places) {
    n.unshift.apply(n, this.splice(places, n.length))
    return n
} 

Number.prototype.round = function (places = 12) {
    return parseFloat(this).toPrecision(places) - 0
}

Object.keyFields = function (obj, property) {
    let result = []
    for (let [key, value] of Object.entries(obj)) {
        result.push(value[property])
    }
    return result
}

export function makeArray(a) {
    if (a != null) {
        if (Array.isArray(a)) {
            return a
        }
        return [a]
    }
    return []
}

Array.prototype.remove = function (set) {
    return this.filter((value, index, self) => set.indexOf(value) < 0)
}
*/

/*
    Polyfill Object.keys and Object.values

const reduce = Function.bind.call(Function.call, Array.prototype.reduce)
const isEnumerable = Function.bind.call(Function.call, Object.prototype.propertyIsEnumerable)
const concat = Function.bind.call(Function.call, Array.prototype.concat)

const keys = Object.keys
if (!Object.values) {
    Object.values = function values(obj) {
        return reduce(keys(obj), (v, k) => concat(v, typeof k === 'string' && isEnumerable(obj, k) ? [obj[k]] : []), [])
    }
}

if (!Object.entries) {
    Object.entries = function entries(obj) {
        return reduce(
            keys(obj),
            (e, k) => concat(e, typeof k === 'string' && isEnumerable(obj, k) ? [[k, obj[k]]] : []),
            []
        )
    }
}
if (!RegExp.toJSON) {
    RegExp.prototype.toJSON = RegExp.prototype.toString
}

export function portable(s) {
    return s.toString().replace(/\\/g, '/')
}1
*/
