<script setup>
const NewsUrl = 'https://www.embedthis.com/blog/news.html'
</script>

<template>
    <v-sheet class="help-news">
        <iframe class="frame" :src="NewsUrl" frameborder="0" scrolling="auto"></iframe>
        <div class="footnote">
            <p>
                More blog articles on the
                <a href="https://www.embedthis.com/blog/" target="_blank">Embedthis Blog.</a>
            </p>
        </div>
    </v-sheet>
</template>

<style lang="scss">
.help-news {
    height: 100%;
    padding: 0;
    margin: -40px;
    .frame {
        height: 100%;
        width: 100%;
    }
    .footnote {
        padding: 0 0 20px 40px;
        a {
            text-decoration: none;
        }
    }
}
</style>
