<script setup>
import {reactive} from 'vue'
import {State} from '@/paks/vu-app'
import {Account} from '@/models'

const emit = defineEmits(['input'])

const account = State.auth.account

const page = reactive({
    account: account,
    saving: false,
    wire: account.billing.wire,
})

async function save() {
    page.saving = true
    let account = await Account.updatePayment({
        id: page.account.id,
        wire: page.wire,
    })
    State.auth.setAccount(account)
    page.saving = false
    emit('input')
}
</script>

<template>
    <vu-form :data="page" :save="save">
        <vu-sign name="wire-transfer-sign-1" title="Bank Transfers" color="accent" :required="true">
            <h3 class="mb-3">Payment by card is the preferred method of payment.</h3>
            <p><b>Note: payment by check is not available.</b></p>
            <p>
                If you
                <b>cannot</b>
                pay by credit card, you may pay by bank or wire transfer subject to the following
                restrictions:
            </p>
            <ul class="mb-4">
                <li>You are responsible for all bank and transfer fees including withholding taxes and other duties.</li>
                <li>
                    Your license, service activation and support credits will be enabled once funds
                    are fully received and cleared.
                </li>
                <li>
                    Once funds are transferred, please notify
                    <a href="mailto:admin@embedthis.com">admin@embedthis.com.</a>
                </li>
            </ul>

            <h4>Bank Transfer Details</h4>
            <code class="wire">
                Name: Embedthis Software, LLC
                <br />
                Account: 16698219
                <br />
                Bank: Bank of America
                <br />
                Route: 125000024
                <br />
                Swift: BOFAUS3N
                <br />
                Phone: +1 (408) 905-7017
                <br />
                Reference: {{ page.account.id.slice(-6) }}
                <br />
            </code>

            <label>Enable Pay by Bank Transfer</label>
            <v-checkbox v-model="page.wire" name="basic" label="Enable" color="primary" class="mt-3"></v-checkbox>

            <v-btn size="small" color="accent" class="mb-5" type="submit" :loading="page.saving">
                Save
            </v-btn>
        </vu-sign>
    </vu-form>
</template>

<style lang="scss" scoped>
.vu-sign {
    margin-top: 16px;
    .v-card-title {
        font-size: 1.7rem !important;
    }
    .wire {
        margin-bottom: 16px;
        padding: 16px;
        font-size: 1rem;
        line-height: 1.4em;
        background: rgba(250, 250, 250, 0.35);
        display: block;
        height: 100%;
        font-family: monospace;
        white-space: pre;
    }
    ul {
        margin-left: 16px;
    }
}
</style>
