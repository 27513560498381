<script setup>
import {reactive} from 'vue'
import {Feedback, State, getModels} from '@/paks/vu-app'
import Json5 from 'json5'

const props = defineProps({
    dashboard: Object,
    widget: Object,
})

const page = reactive({
    appendIcon: null,
    error: false,
    errors: [],
    key: true,
    items: [],
    prefix: null,
    prependIcon: null,
    suffix: null,
    rules: [],
    value: null,
})

defineExpose({update})

function update() {
    let widget = props.widget
    if (widget.items) {
        try {
            page.items = Json5.parse(widget.items)
        } catch (err) {
            console.log(`Cannot parse widget items`, {items: widget.items, widget})
        }
    } else {
        page.items = []
    }
    if (widget.prefix) {
        let [kind, text] = widget.prefix.split(':')
        if (kind == 'icon') {
            page.prependIcon = text
        } else {
            page.prefix = text
        }
    }
    if (widget.suffix) {
        let [kind, text] = widget.suffix.split(':')
        if (kind == 'icon') {
            page.appendIcon = text
        } else {
            page.suffix = text
        }
    }
    //  Set initial form values
    if (widget.form) {
        let form = State.cache.forms[widget.form]
        if (form) {
            form.value[widget.field] = widget.value
        }
    }
    page.key = !page.key
}

async function change(event, props) {
    let widget = props.widget
    if (widget.validate) {
        if (!new RegExp(widget.validate).exec(widget.value)) {
            page.errors = ['Bad format for field']
            page.error = true
            return
        }
    }
    page.errors = []
    page.error = false

    if (widget.form) {
        let form = State.cache.forms[widget.form]
        if (form) {
            form.value[widget.field] = widget.value
        }
    } else if (widget.action?.target) {
        await submit()
    }
}

async function submit() {
    let widget = props.widget
    let params = {}
    if (widget.input == 'form') {
        //  MOB - gather input fields
    } else {
        if (!widget.field) {
            console.error('Cannot submit input widget. Widget field not defined')
            return
        }
        //  MOB - must define field for an input
        params[widget.field] = widget.value
    }
    if (State.app.context.deviceId) {
        params.deviceId = State.app.context.deviceId
    }
    let {type, target} = widget.action
    let {Action} = getModels()
    if (target && type == 'action') {
        widget.loading = true
        try {
            await Action.invoke({name: target, params})
        } catch (err) {
            Feedback.error(err.message)
        }
        widget.loading = false
    }
}
</script>

<template>
    <vu-div class="input-widget" :key="page.key">
        <vu-input
            v-model="widget.value"
            :accept="widget.accept"
            :append-icon="page.appendIcon"
            :datetime="widget.datetime"
            :error="page.error"
            :errors="page.errors"
            :items="page.items"
            :min="widget.min"
            :max="widget.max"
            :label="widget.label"
            :hide-details="false"
            :multiple="widget.multiple"
            :numeric="true"
            :placeholder="widget.placeholder"
            :prefix="page.prefix"
            :prepend-icon="page.prependIcon"
            :rows="widget.rows"
            :suffix="page.suffix"
            :thumb="false"
            :timezone="widget.timezone"
            :type="widget.input"
            :validate="widget.validate"
            @change="change($event, props)" />
        <div v-if="dashboard.design" class="no-input">
            <v-icon icon="$close" size="large"/>
        </div>
    </vu-div>
</template>

<style lang="scss">
.input-widget {
    height: calc(100% - 10px);
    width: 100%;
    background-color: inherit;
    padding: 0 10px 0 10px;
    .vu-input {
        margin-top: 8px;
        height: 100%;
        .v-textarea {
            .v-field-label {
                margin-left: 0 !important;
                padding-left: 0 !important;
            }
            textarea {
                padding: 16px 0 !important;
            }
        }
    }
    .no-input {
        container-type: size;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: #888888;
        opacity: 25%;
        pointer-events: fill;
        cursor: move;
        z-index: 2;
        .v-icon {
            position: relative;
            top: 50cqh;
            left: 50cqw;
            transform: translate(-50%, -50%);
            display: block;
            width: 80cqw;
            height: 80cqh;
        }
    }
}
</style>
