/*
    vu-state/index.js -- Vue state store

    import {State, Store} from '@/paks/vu-state'

    Store.init({
        reset: false,
        persist: 'name',
        lazy: Delay
                modules: [],
        loader: (options) => return JSON.parse(localStorage[options.persist])
        log: true,
    })
    //  Add State providers
    Store.add('module', new MyState, {reactive: true})

    //  Consumers
    let x = State.module.property           //  invokes getter
    State.module.property = x               //  invokes setter
    await State.module.property(args)       //  invokes action

    class MyState {
        temp = 0
        speed: 42
        increment() {
            temp++
        }
        get speed() { return this.speed }
        set speed(v) { this.speed = v }
        async stop() {
            await db.stop()
        }
    }
 */

import {reactive, watch} from 'vue'
import {Log} from '@/paks/js-log'
import blend from '@/paks/js-blend'

const LazyDelay = 3000

export const State = {}

class StoreClass {
    modules = {}

    async init(options = {}) {
        this.options = options
        this.persist = `${options.config.name}-state`
        this.lazy = options.lazy !== false
        this.initialState = {}
        if (options.config?.profile != 'prod' || window.location.hostname == 'localhost') {
            global.State = State
        }
    }

    /*
    async reload(accountId) {
        let options = this.options
        options.persist = `${options.config.name}-${accountId}-state`
        for (let key of Object.keys(State)) {
            delete State[key]
        }
        this.initialState = this.loadState()
    } */

    /*
        Load all persisted state from browser local storage or options loader
     */
    loadState(persist) {
        this.persist = persist
        let options = this.options
        let data
        if (options.reset) {
            this.reset()
        } else {
            if (options.loader) {
                data = options.loader(options)
            } else {
                data = localStorage[this.persist]
                if (data) {
                    data = JSON.parse(data)
                }
            }
        }
        data = data || {}
        if (options.verbose) {
            dump('Initial state', data)
        }
        for (let name of Object.keys(this.modules)) {
            this.blendModuleState(name, data[name])
        }
    }

    /*
        Blend and filter initial state for a module. Blends with module inline state.
     */
    blendModuleState(name, data) {
        let module = this.modules[name]
        if (module.saveState === false || module.loadState === false) {
            data = {}
        } else if (typeof module.loadState == 'function') {
            //  Module can filter or overwrite state as it chooses
            data = module.loadState.call(module, data)
        }
        blend(module, data)
    }

    addMethods(methods) {
        blend(State, methods)
    }

    add(name, module, options = {}) {
        if (this.modules[name]) {
            return
        }
        this.modules[name] = module
        if (options.reactive === false) {
            module.saveState = false
            State[name] = module
        } else {
            State[name] = reactive(module)
            watch(
                State[name],
                () => {
                    this.saveState(name)
                },
                {deep: true}
            )
        }
    }

    /*
        Log mutations and other activities
     */
    log(verb, module, name, v) {
        if (this.options.log) {
            if (verb == 'Invoke') {
                Log.info(`Invoke: ${module}.${name}`)
            } else {
                Log.info(`${verb}: ${module}.${name} = ${v}`)
                if (this.options.verbose) {
                    let at = new Error()
                    let stack = at.stack.replace(/^Error/, '')
                    Log.info('From: ' + stack)
                }
            }
        }
    }

    //  Save all state. Do a lazy save by default.
    async saveState(module) {
        if (this.persist) {
            if (this.lazy) {
                if (!this.saveTimeout) {
                    this.saveTimeout = setTimeout(async () => {
                        await this.save()
                        this.saveTimeout = null
                    }, LazyDelay)
                }
            } else {
                this.save()
            }
        }
    }

    async save() {
        let state = {}
        for (let [name, module] of Object.entries(this.modules)) {
            if (module.saveState === false) {
                state[name] = {}
                continue
            }
            state[name] = Object.assign({}, State[name])
            if (typeof module.saveState == 'function') {
                //  functions may return {} and do their own persistence
                state[name] = await module.saveState.call(module, state[name])
            }
        }
        if (this.options.verbose) {
            dump('Saving state', state)
        }
        try {
            localStorage[this.persist] = JSON.stringify(state)
        } catch (err) {
            Log.error(`Cannot save to local storage`, {err})
        }
    }

    async reset() {
        try {
            for (let key of Object.keys(State)) {
                delete State[key]
            }
            await this.save()
        } catch (err) {
            Log.error(`Cannot reset local storage`, {err})
        }
    }
}

export const Store = new StoreClass()
