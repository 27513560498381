<script setup>
import {onMounted, onUnmounted, reactive, ref} from 'vue'
import {Feedback, Progress, State, can, delay, getModels, query} from '@/paks/vu-app'
import AlertEdit from './AlertEdit.vue'

const page = reactive({
    active: null,
    alert: null,
    alerts: [],
    fields: [
        {name: 'view', icon: (v, rec) => '$edit', desktop: true},
        {name: 'timestamp', title: 'Date'},
        {
            name: 'icon',
            desktop: true,
            icon: (v, rec) => `\$${rec.severity || 'info'}`,
            css: (v, rec) => `severity-${rec.severity}`,
        },
        {name: 'name', title: 'Action Name', desktop: true},
        {name: 'cloud'},
        {name: 'subject'},
        {name: 'count', align: 'right', desktop: true},
        {name: 'resolved', icon: (v, rec) => (rec.resolved ? '$complete' : '$close'), width: '10%', desktop: true},
    ],
    select: {actions: {Edit: 1, Delete: 2, Resolve: 2}, multi: true, property: 'select'},
    showEdit: false,
})

//  Component references
const table = ref(null)
const confirm = ref(null)

const {Alert} = getModels()

onMounted(async () => {
    let id = query('alert')
    if (id) {
        let alert = await Alert.get({id})
        if (alert) {
            editAlert(alert)
        }
    }
    daemon()
})

onUnmounted(() => {
    page.active = false
})

async function getData(params) {
    let alerts = await Alert.find({}, params)
    for (let alert of alerts) {
        alert.severity = alert.severity || 'info'
        alert.cloud = State.cache.clouds.find((c) => c.id == alert.cloudId)?.name
    }
    if (alerts.length == 0) {
        State.app.setNeed('alertUpdate')
    }
    return alerts
}

async function daemon() {
    page.active = true
    while (page.active) {
        if (State.auth.ready) {
            await table.value.update()
        }
        await delay(30 * 1000)
    }
}

async function clicked({action, item, items}) {
    if (action == 'add') {
        editAlert({})
    } else if (action == 'delete') {
        await deleteAlerts(items)
    } else if (action == 'cell') {
        editAlert(item)
    }
}

async function deleteAlerts(items) {
    if (!can('admin')) {
        Feedback.error('Insufficient Privilege')
        return
    }
    if (!(await confirm.value.ask(`Do you want to delete the selected alerts?`))) {
        return
    }
    Progress.start('long')
    for (let item of items) {
        await Alert.remove({id: item.id})
    }
    await table.value.update()
    Progress.stop()
    Feedback.info('Alerts Deleted')
}

async function alertAction({action, items}) {
    if (action) {
        Progress.start('repeat')
        if (action == 'delete') {
            await deleteAlerts(items)
        } else if (action == 'resolve') {
            for (let item of items) {
                await Alert.update({id: item.id, resolved: true})
            }
        }
        await table.value.update()
        Progress.stop()
    }
}

async function editAlert(alert) {
    page.showEdit = alert ? true : false
    page.alert = alert
    if (!alert) {
        await table.value.update()
    }
}

function filterAlerts(term) {
    table.value.update({filter: term})
}
</script>

<template>
    <div class="page alert-list">
        <vu-help url="/doc/ui/automations/alerts/list.html" v-if="!page.showEdit" />
        <vu-sign name="alert-list-sign-1" title="Alert Notices" subtitle="" color="accent">
            <p>Automated actions can create alert messages in the alert log.</p>
            <p>
                Alerts can be displayed in the Builder account or device specific alerts can be sent to the Device
                Manager UI for display to the user.
            </p>
        </vu-sign>
        <vu-table
            name="alerts"
            :__title="`${State.app.builder ? null : 'Alerts'}`"
            nodata="No alerts"
            options="filter, toolbar"
            sort="timestamp:desc"
            ref="table"
            :data="getData"
            :fields="page.fields"
            :pageSize="200"
            :width="'100%'"
            :select="page.select"
            @click="clicked"
            @action="alertAction">
            <template v-slot:more="props" v-if="!State.app.mobile">
                <v-btn outlined class="quick-search" @click="filterAlerts('unresolved')">Unresolved</v-btn>
            </template>
        </vu-table>

        <vu-panel v-model="page.showEdit" @close="editAlert" :widths="['700px']">
            <AlertEdit v-model="page.alert" @input="editAlert" />
        </vu-panel>
        <vu-confirm ref="confirm" />
    </div>
</template>

<style lang="scss">
.alert-list {
    .quick-search {
        margin-right: 10px !important;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background: none !important;
        box-shadow: none !important;
    }
    .v-icon {
        height: 20px !important;
        width: 20px !important;
    }
    .severity-info .v-icon {
        color: green !important;
    }
    .severity-warn .v-icon {
        color: orange !important;
    }
    .severity-error .v-icon {
        color: red !important;
    }
    .severity-critical .v-icon {
        color: red !important;
    }
}
</style>
