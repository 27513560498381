<script setup>
/*
    VuWizard.js - Vue Wizard directive

    <vu-wizard first="first-spell" delay="render-delay" effects="modal" name="wizard" before="fn" after="fn"
        <vu-spell ...>
    </vu-wizard>
*/
import {getCurrentInstance, onBeforeUnmount, onMounted, provide, reactive, ref, watch} from 'vue'
import {State, delay} from '@/paks/vu-app'

// const {proxy: self} = getCurrentInstance()

const inst = getCurrentInstance()

const props = defineProps({
    first: {type: String},
    delay: {type: Number, default: 250},
    effects: {type: String, default: 'modal'},
    name: {type: String},
})

const page = reactive({
    active: false,
    current: props.first,
    modal: null,
    spell: null,
})

defineExpose({advance})

const activate = ref({})
provide('activate', activate)

watch(() => page.active, display)

onMounted(async () => {
    await delay(props.delay)

    if (props.effects.split(',').indexOf('modal') >= 0) {
        page.modal = true
    }
    watch(() => State.app.wizardLaunch, (value) => {
        State.app.completeWizard(props.name, false)
        State.app.activateWizard(true)
        page.active = true
    })
    let prefs = State.auth.user ? State.auth.user.preferences || {signposts: true} : {signposts: true}
    if (prefs.signposts == true && State.app.wizardsCompleted[props.name] == null) {
        State.app.launchWizard(true)
    }
})

onBeforeUnmount(() => {
    finish()
})

function finish() {
    window.removeEventListener('keydown', keydown)
    page.active = false
    page.spell = null
    State.app.activateWizard(false)
}

async function display(active) {
    if (active) {
        if (props.first) {
            page.spell = findSpell(props.first)
            if (page.spell) {
                page.spell.active = true
            }
            window.addEventListener('keydown', keydown)
        }
    } else {
        window.removeEventListener('keydown', keydown)
    }
}

function keydown(event) {
    let key = event.key || event.keyCode
    if (key == 'Escape') {
        advance()
        event.preventDefault()
    } else if (key == 'Enter' || key == 'ArrowRight') {
        if (page.spell) {
            advance(page.spell.next)
        }
        event.preventDefault()
    } else if (key == 'Backspace' || key == 'ArrowLeft') {
        if (page.spell) {
            advance(page.spell.prev)
        }
        event.preventDefault()
    }
}

function move(dir) {
}

function advance(spellID) {
    if (page.spell) {
        page.spell.active = false
    }
    page.spell = findSpell(spellID)
    if (page.spell) {
        page.spell.active = true
    } else {
        State.app.completeWizard(props.name)
        finish()
    }
}

function findSpell(id) {
    if (activate.value[id]) {
        return activate.value[id]
    }
    return null
}
</script>


<template>
    <div class="vu-wizard">
        <div v-show="page.active">
            <div class="background">
                <div class="modal" v-if="page.modal" />
            </div>
            <slot />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.vu-wizard {
    .modal {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.25) !important;
        z-index: 200;
    }

}
</style>
