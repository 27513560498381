/*
    vu-nav/SidebarState.js -- Sidebar state management
 */

import {allow} from '@/paks/js-polyfill'
import {State} from '@/paks/vu-state'
import {getRoutePath} from '@/paks/vu-app'

export default class SidebarState {
    show = 'full'

    saveState(state) {
        return allow(state, ['show'])
    }

    loadState(state) {
        return allow(state, ['show'])
    }

    toggle() {
        let path = getRoutePath()
        let mini = State.app.mobile || (State.cache.dashboard.full && path == '/') ? 'none' : 'mini'
        this.show = this.show == 'full' ? mini : 'full'
    }

    changeDashboard() {
        this.changePath()
    }

    changePath() {
        if ((getRoutePath() == '/' && State.cache.dashboard.full) || State.app.mobile) {
            this.show = 'none'
        } else if (this.show == 'none') {
            this.show = 'full'
        }
    }
}
