/*
    Invoice.js - Invoice model
 */

import {Rest} from '@/paks/js-rest'

export default new Rest(
    'Invoice',
    {
        createQuote: {method: 'POST', uri: '/:controller/quote'},
        download: {method: 'POST', uri: '/:controller/download'},
        findOutstanding: {method: 'POST', uri: '/:controller/findOutstanding'},
        reissue: {method: 'POST', uri: '/:controller/reissue'},
        updateMemo: {method: 'POST', uri: '/:controller/updateMemo'},
        updateReceived: {method: 'POST', uri: '/:controller/updateReceived'},
        updateReminder: {method: 'POST', uri: '/:controller/updateReminder'},
    },
    {cache: false, service: '/api'}
)
