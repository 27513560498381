/*
    vu-app - Index for the global App singleton

    This splits the App class and initialization into App.js and Utils.js.
    The vue, vuetify and router references are saved in State.ref (non-reactive).
    The app configuration is saved in State.config.

    import {Auth, Chat, Feedback, Log, Progress, Rest, Routes, Rules, State, Storage, Store}
    import {
        allow, arrayBufferToBase64, blend, can, canUser, clone, delay, deny, disableAutoComplete, 
        equal, fetch, fixShadow, getVue, getModel, getModels, getRoute, getRoutePath, getRouter, 
        getSelf, getValue, hidden, initApp, money, navigate, query, renderDom, unique,
        vuetifyProps, waitRender
    }
    Usage:
        import {initApp} from '@/paks/vu-app'
        await initApp(params, config)
 */
import {App} from './App.js'
export {initApp} from './App.js'
export {Auth} from '@/paks/vu-auth'
export {Feedback} from '@/paks/vu-feedback'
export {Log} from '@/paks/js-log'
export {Progress} from '@/paks/vu-progress'
export {default as query} from '@/paks/js-query'
export {Rest} from '@/paks/js-rest'
export {Rules} from '@/paks/vu-rules'
export {Store, State} from '@/paks/vu-state'

export {default as Dates} from '@/paks/js-dates'
export {default as combine} from '@/paks/js-combine'
export {default as blend} from '@/paks/js-blend'
export {default as clone} from '@/paks/js-clone'
export {allow, deny, money, unique} from '@/paks/js-polyfill'

export {
    arrayBufferToBase64,
    can,
    canUser,
    delay,
    disableAutoComplete,
    equal,
    fetch,
    fixShadow,
    getVue,
    getVuetify,
    getModel,
    getModels,
    getRoutePath,
    getRoute,
    getRouter,
    getSelf,
    getValue,
    hidden,
    navigate,
    renderDom,
    titlecase,
    toTitle,
    vuetifyProps,
    waitRender,
} from './Utils.js'

const _Chat = App.chat
export {_Chat as Chat}

const _Routes = App.routes
export {_Routes as Routes}

const _Storage = App.storage
export {_Storage as Storage}
