<script setup>
import {reactive} from 'vue'
import {Auth, Feedback, clone, delay} from '@/paks/vu-app'

const emit = defineEmits(['input'])

const props = defineProps({
    auth: Object,
})

const page = reactive({
    auth: clone(props.auth),
    code: null,
    resending: false,
    rules: [],
    saving: false,
})

async function save() {
    await Auth.registerUserConfirmation(page.auth, page.code)
    emit('input', page.auth)
}

function postSave() {
    //  Keep loading indicator active as login can take a while
}

async function saveError(err, validate) {
    if (err.message == 'Invalid verification code provided, please try again') {
        err.message = 'Invalid code, please retry'
    }
    validate.error(err.message)
    page.saving = false
}

async function sendCode() {
    try {
        page.resending = true
        await Auth.sendCode(page.auth.username)
        await delay(1000)
        page.resending = false
    } catch (err) {
        Feedback.error('Cannot send code')
        throw err
    }
}
</script>

<template>
    <vu-form :data="page" :save="save" :error="saveError" :post-save="postSave" class="register-confirm">
        <v-card-text class="px-4 py-0">
            <h3 class="pb-2">Expect a code from @verificationemail.com</h3>
            <v-text-field label="Enter Code" v-model="page.code" :rules="page.rules.required" required clearable></v-text-field>
        </v-card-text>
        <v-card-actions class="px-4 pb-0 justify-space-between">
            <v-btn variant="elevated" size="small" ripple :loading="page.saving" type="submit" color="primary">Confirm</v-btn>
            <v-btn size="small" class="text-end" ripple :loading="page.resending" @click="sendCode">Resend code</v-btn>
        </v-card-actions>
    </vu-form>
</template>

<style lang="scss" scoped>
.register-confirm {
    padding: 20px 0 20px 0;
    .v-card-title {
        border-radius: 0 !important;
    }
    h3 {
        font-weight: normal;
        font-size: 0.9rem;
    }
}
</style>
