/*
    Cache.js - Local cache of database data
 */
import {Feedback, allow, can, delay} from '@/paks/vu-app'
import {State, Store} from '@/paks/vu-state'
import {Dashboard, User} from '@/models'

/*
    Map of Rest entities to cacheable collections
 */
const CacheMap = {
    Account: 'account',
    Cloud: 'clouds',
    Dashboard: 'dashboards',
    Manager: 'managers',
    Notification: 'notifications',
    Plan: 'plans',
    Product: 'products',
    Software: 'software',
}

const PersistState = ['dashboard', 'metrics']

export default class CacheState {
    clouds = []
    dashboard = {name: 'Default'}
    dashboards = [{name: 'Default'}]
    forms = {}
    getMetricsElapsed = 0
    lastUpdated = null
    lastGetMetrics = 0
    managers = []
    metrics = {}
    notifications = []
    plans = []
    products = []
    software = []

    saveState(state) {
        return allow(state, PersistState)
    }

    loadState(state) {
        Store.addMethods({
            find: this.find.bind(this),
            get: this.get.bind(this),
            remove: this.remove.bind(this),
            set: this.set.bind(this),
        })
        return allow(state, PersistState)
    }

    reset() {
        this.alarms = []
        this.cards = []
        this.clouds = []
        // this.dashboard = {widgets: []}
        this.dashboards = []
        this.invoices = []
        this.managers = []
        this.notifications = []
        this.plans = []
        this.products = []
    }

    /*
        Update cached data
    */
    async update(params = {}) {
        if (this.updating) {
            do {
                await delay(100)
            } while (this.updating)
        }
        if (!State.auth.authorized && !params.authorized) {
            this.reset()
            return
        }
        let now = Date.now()
        if (!params.cache && params.lazy && this.lastUpdated > new Date(now - 45 * 1000)) {
            return
        }
        this.updating = true

        try {
            //  Fetch: {clouds, dashboards, managers, notifications, plans, products, software}
            let cache = params.cache ? params.cache : await User.prefetch()
            for (let [key, value] of Object.entries(cache)) {
                this[CacheMap[key]] = value || []
            }
            for (let dashboard of this.dashboards) {
                //  REPAIR
                if (dashboard.design == null) {
                    dashboard.design = !dashboard.fixed
                }
                //  REPAIR
                if (dashboard.layout == 'flow') {
                    dashboard.layout = 'grid'
                }
            }
            let name = State.config.features?.dash?.name || this.dashboard?.name
            if (name) {
                this.dashboard = this.dashboards.find((d) => d.name == name)
                if (!this.dashboard && this.dashboards.length) {
                    this.dashboard = this.dashboards[0]
                }
            }
            this.lastUpdated = new Date()
        } catch (err) {
            Feedback.error('Network or service error, could not fetch data')
        } finally {
            this.updating = false
        }
        let hasCloud =
            can('support') ||
            this.clouds.filter((c) => c.type != 'host' && c.id != State.config.evalCloud).length > 0
        State.app.setContext('hasCloud', hasCloud)
        if (this.dashboard && this.dashboards.find((d) => d.full)) {
            State.app.setContext('fullDashboard', true)
        }
    }

    get(model, fields) {
        if (fields == undefined) {
            return null
        }
        model = CacheMap[model]
        let result = this.search(this[model], fields)
        if (result.length == 0) {
            return null
        }
        return result[0]
    }

    find(model, fields) {
        model = CacheMap[model]
        return this.search(this[model], fields)
    }

    remove(model, fields) {
        if (!fields) {
            return
        }
        model = CacheMap[model]
        if (Array.isArray(fields)) {
        } else {
            if (fields.id) {
                let result = this.searchIndexes(this[model], {id: fields.id})
                for (let index of result.reverse()) {
                    this[model].splice(index, 1)
                }
            } else {
                for (let item of this.search(this[model], fields)) {
                    let result = this.searchIndexes(this[model], {id: item.id})
                    for (let index of result.reverse()) {
                        this[model].splice(index, 1)
                    }
                }
            }
        }
    }

    set(model, fields) {
        model = CacheMap[model]
        if (fields == undefined) {
            return
        }
        if (Array.isArray(fields)) {
            this[model] = fields
        } else {
            let result = this.searchIndexes(this[model], {id: fields.id})
            if (result.length == 0) {
                this[model].push(fields)
            } else {
                let index = result[0]
                if (JSON.stringify(this[model][index]) != JSON.stringify(fields)) {
                    this[model][index] = fields
                }
            }
        }
        return fields
    }

    setForm(form, widget) {
        this.forms[form] = widget
    }
    
    /*
        Search for a record described by the fields and return [index, rec]
     */
    search(grid, fields) {
        if (grid) {
            grid = Array.isArray(grid) ? grid : [grid]
        } else {
            grid = []
        }
        if (typeof fields == 'string') {
            return grid.filter((i) => i.id == fields || i.name == fields || i.arn == fields)
        } else if (typeof fields == 'function') {
            return grid.filter(fields)
        } else {
            let result = []
            for (let data of Object.values(grid)) {
                if (
                    !fields ||
                    Object.keys(fields).every((key) => key == 'source' || data[key] == fields[key])
                ) {
                    result.push(data)
                }
            }
            return result
        }
    }

    searchIndexes(grid, fields) {
        let result = []
        if (grid) {
            grid = Array.isArray(grid) ? grid : [grid]
        } else {
            grid = []
        }
        for (let [index, data] of Object.entries(grid)) {
            if (!data) continue
            if (
                !fields ||
                Object.keys(fields).every((key) => key == 'source' || data[key] == fields[key])
            ) {
                result.push(index)
            }
        }
        return result
    }

    get map() {
        return CacheMap
    }
}
