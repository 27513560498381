/*
    Cognito.js --

    Helpful doc:

    https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pools-social-idp.html

    https://docs.aws.amazon.com/cognito/latest/developerguide/authorization-endpoint.html
    Social Login: https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pools-social-idp.html

    https://blog.rackspace.com/part-2-building-serverless-architecture-aws
    https://docs.aws.amazon.com/cognito/latest/developerguide/using-amazon-cognito-user-identity-pools-javascript-examples.html
    https://blog.jsecademy.com/how-to-authenticate-users-with-tokens-using-cognito/
    https://www.reddit.com/r/aws/comments/ak2r2k/cognito_user_pool_on_node_lambda_serverless/
    https://www.npmjs.com/package/amazon-cognito-identity-js-with-fetch?activeTab=readme

    https://docs.aws.amazon.com/cognito/latest/developerguide/using-amazon-cognito-user-identity-pools-javascript-examples.html

    https://www.npmjs.com/package/amazon-cognito-auth-js-promises
    https://github.com/aws/amazon-cognito-auth-js
    https://gerardnico.com/aws/cognito/js_auth

    https://appname.auth.us-east-1.amazoncognito.com/oauth2/authorize?redirect_uri=callback_url&response_type=token&client_id=5ss2kt2afmq3buc813mjfmkhrf&identity_provider=Facebook

    https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pools-specifying-attribute-mapping.html

    https:///senselogs.auth.us-east-1.amazoncognito.com/login?response_type=code&client_id=6g10ce3bt4bjdfh3q6o7oh5o0i&redirect_uri=http://localhost:8080/auth/login/social

    https://aaronparecki.com/oauth-2-simplified/
 */

// import AWS from 'aws-sdk'
import {
    AuthenticationDetails,
    CognitoAccessToken,
    CognitoIdToken,
    CognitoRefreshToken,
    CognitoUser,
    CognitoUserAttribute,
    CognitoUserPool,
    CognitoUserSession,
} from 'amazon-cognito-identity-js'

// import CognitoIdentityServiceProvider from 'aws-sdk/clients/cognitoidentityserviceprovider'

import {CognitoAuth} from 'amazon-cognito-auth-js'

const CognitoSessionDuration = 3600 * 1000

export default class Cognito {
    constructor(config) {
        this.config = config
        this.authenticated = false
        this.email = null
        this.expires = null
        this.loggedIn = null
        this.social = null
        this.tokens = null
        this.username = null
        this.userPool = new CognitoUserPool({
            UserPoolId: config.userPoolId,
            ClientId: config.clientId,
        })
    }

    async changePassword(oldPassword, newPassword) {
        let cognitoUser = this.getCurrentUser()
        await this.getSession(cognitoUser)
        if (cognitoUser) {
            await new Promise((resolve, reject) => {
                cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
                    if (err) {
                        reject(err)
                    } else {
                        resolve(result)
                    }
                })
            })
        }
    }

    async checkLoggedIn() {
        await this.getSession()
        if (this.userId) {
            return this.getAuth()
        }
        return null
    }

    //  Not tested yet
    async checkUserExists() {
        try {
            if (username) {
                username = username.toLowerCase()
            }
            await this.registerConfirmation(username, '0000000')
            return false
        } catch (err) {
            switch (err.code) {
                case 'UserNotFoundException':
                    return true
                case 'NotAuthorizedException':
                    return false
                case 'AliasExistsException':
                    // Email alias already exists
                    return false
                case 'CodeMismatchException':
                    return false
                case 'ExpiredCodeException':
                    return false
                default:
                    return false
            }
        }
    }

    async deleteUser(username) {
        let cognitoUser = this.getCurrentUser()
        await this.getSession(cognitoUser)
        if (cognitoUser) {
            return await new Promise((resolve, reject) => {
                cognitoUser.deleteUser((err, result) => {
                    if (err) {
                        reject(err)
                    } else {
                        resolve(result)
                    }
                })
            })
        }
    }

    async forgot(username) {
        if (username) {
            username = username.toLowerCase()
        }
        let cognitoUser = new CognitoUser({
            Username: username,
            Pool: this.userPool,
        })
        return await new Promise((resolve, reject) => {
            cognitoUser.forgotPassword({
                onSuccess: (result) => {
                    resolve(result)
                },
                onFailure: (err) => {
                    reject(err)
                },
                inputVerificationCode(data) {
                    resolve(data)
                },
            })
        })
    }

    async forgotConfirmation(username, password, code) {
        if (username) {
            username = username.toLowerCase()
        }
        let cognitoUser = new CognitoUser({
            Username: username,
            Pool: this.userPool,
        })
        return await new Promise((resolve, reject) => {
            cognitoUser.confirmPassword(code, password, {
                onSuccess: (result) => resolve(result),
                onFailure: (err) => reject(err),
            })
        })
    }

    getAttributes() {
        return this.attributes
    }

    getAuth() {
        return {
            attributes: this.attributes,
            email: this.email,
            social: this.social,
            username: this.username,
            userId: this.userId,
        }
    }

    async getDevice(cognitoUser) {
        cognitoUser = cognitoUser || this.getCurrentUser()
        return await new Promise((resolve, reject) => {
            cognitoUser.getDevice({
                onSuccess: (result) => {
                    resolve(result)
                },
                onFailure: (err) => {
                    resolve(null)
                },
            })
        })
    }

    async refresh(cognitoUser) {
        cognitoUser = cognitoUser || this.getCurrentUser()
        if (cognitoUser) {
            let tokens = this.tokens
            return await new Promise(async (resolve, reject) => {
                if (cognitoUser) {
                    if (this.tokens.refreshToken.token) {
                        cognitoUser.refreshSession(this.tokens.refreshToken, (err, result) => {
                            if (err) {
                                resolve(null)
                            } else {
                                this.setTokens(cognitoUser, result)
                                resolve(result)
                            }
                        })
                    } else {
                        resolve(null)
                    }
                } else {
                    resolve(null)
                }
            })
        }
        return this.tokens
    }

    async getSession(cognitoUser) {
        let attributes, session
        cognitoUser = cognitoUser || this.getCurrentUser()
        if (cognitoUser) {
            session = await new Promise((resolve, reject) => {
                if (cognitoUser) {
                    cognitoUser.getSession(async (err, result) => {
                        if (err) {
                            resolve(null)
                        } else {
                            resolve(result)
                        }
                    })
                } else {
                    resolve(null)
                }
            })
            if (session && session.isValid()) {
                let map = await this.getUserAttributes(cognitoUser)
                if (map) {
                    attributes = {}
                    for (let att of map) {
                        attributes[att.Name] = att.Value
                    }
                }

                cognitoUser.getCachedDeviceKeyAndPassword()
                this.device = cognitoUser.deviceKey
                /* KEEP
                if (this.device) {
                    this.deviceInfo = await this.getDevice(cognitoUser)
                } */

                /* Save values */
                this.attributes = attributes
                this.authenticated = true

                let payload = session.idToken.payload
                this.userId = payload.sub
                this.email = payload.email
                this.username = payload['cognito:username']

                if (
                    session.idToken &&
                    session.idToken.payload.identities &&
                    session.idToken.payload.identities[0].providerName
                ) {
                    this.social = true
                }
                this.setTokens(cognitoUser, session)

                if (this.expires < Date.now() + CognitoSessionDuration / 2) {
                    session = await this.refresh(cognitoUser)
                }
            }
        }
        return {attributes, session}
    }

    async getToken() {
        let response = await this.getSession()
        if (response) {
            let {session} = response
            if (session && session.isValid()) {
                return session.getIdToken().getJwtToken()
            }
        }
        return null
    }

    getCurrentUser() {
        return this.userPool.getCurrentUser()
    }

    getUsername() {
        return this.username
    }

    /* private */
    async getUserAttributes(cognitoUser) {
        return await new Promise((resolve, reject) => {
            cognitoUser.getUserAttributes((err, result) => {
                if (err) {
                    resolve(null)
                } else {
                    resolve(result)
                }
            })
        })
    }

    //  Not tested yet
    async getUser(username) {
        if (username) {
            username = username.toLowerCase()
        }
        let cognitoUser = new CognitoUser({
            Username: username,
            Pool: this.userPool,
        })
        return await new Promise((resolve, reject) => {
            let params = {
                UserPoolId: this.config.userPoolId,
                Username: username,
            }
            var cognito = new AWS.CognitoIdentityServiceProvider()
            cognito.adminGetUser(params, true, (err, result) => {
                if (err) {
                    reject(err)
                } else {
                    resolve(result)
                }
            })
        })
    }

    async deleteUsers(users) {
        let config = this.config
        let params = {UserPoolId: this.config.userPoolId}
        let creds = config.creds ? this.config.creds : {}
        let cognito = new AWS.CognitoIdentityServiceProvider(creds)
        for (let username of users) {
            await new Promise((resolve, reject) => {
                params.Username = username
                let data = cognito.adminDeleteUser(params, (err, result) => {
                    if (err) {
                        req.log.info(`Cannot remove user ${username}`, {err})
                        reject(err)
                    } else {
                        resolve(true)
                    }
                })
            })
        }
    }

    async listUsers(params = {}) {
        let args = {UserPoolId: this.config.userPoolId}
        if (params.attributes) {
            for (let [key, value] of Object.entries(params.attributes)) {
                args.Filter = `${key} = "${value}"`
            }
        }
        let users = []
        let token = null
        let creds = this.config.creds ? this.config.creds : {}
        let cognito = new AWS.CognitoIdentityServiceProvider(creds)
        do {
            let result = await new Promise((resolve, reject) => {
                args.PaginationToken = token
                let data = cognito.listUsers(args, (err, result) => {
                    if (err) {
                        reject(err)
                    } else {
                        let batch = []
                        for (let u of result.Users) {
                            let attributes = {}
                            for (let att of u.Attributes) {
                                attributes[att.Name] = att.Value
                            }
                            batch.push({
                                email: attributes.email,
                                verified: attributes.email_verified,
                                username: u.Username,
                                created: u.UserCreateDate,
                                enabled: u.Enabled,
                                status: u.UserStatus,
                            })
                        }
                        resolve({batch, token: result.PaginationToken})
                    }
                })
            })
            users = users.concat(result.batch)
            token = result.token
        } while (token)
        return users
    }

    async login(params, options = {persist: true}) {
        let {password, social, username} = params
        if (params.social) {
            return this.socialLoginResponse(params.social || window.location.href)
        }
        if (username) {
            username = username.toLowerCase()
        }
        if (!username) {
            throw new Error('Missing username')
        }
        if (!password) {
            throw new Error('Missing password')
        }
        return await new Promise((resolve, reject) => {
            let authenticationData = {Username: username, Password: password}
            let authenticationDetails = new AuthenticationDetails(authenticationData)
            let userData = {Username: username, Pool: this.userPool}
            let cognitoUser = new CognitoUser(userData)

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: async (tokenData) => {
                    this.setTokens(cognitoUser, tokenData)
                    await this.getSession(cognitoUser)
                    await this.rememberDevice(options.persist, cognitoUser)
                    this.social = false
                    resolve(this.getAuth())
                },
                onFailure: (err) => {
                    reject(err)
                },
                newPasswordRequired: (userAttributes, requiredAttributes) => {},
            })
        })
    }

    /*
        Normal user logout
     */
    async logout() {
        let cognitoUser = this.getCurrentUser()
        if (cognitoUser) {
            await this.getSession(cognitoUser)
            cognitoUser.signOut()
        }
        this.authenticated = false
        this.username = null
        this.userId = null
        this.social = null
    }

    async logoutAll() {
        let cognitoUser = this.getCurrentUser()
        if (cognitoUser) {
            await this.getSession(cognitoUser)
            await new Promise(function (resolve, reject) {
                cognitoUser.globalSignOut({
                    onFailure: (err) => {
                        reject(err)
                    },
                    onSuccess: (args) => {
                        resolve(args)
                    },
                })
            })
        }
    }

    /*
        Register a new user
     */
    async register(params) {
        let {password, username} = params
        if (username) {
            username = username.toLowerCase()
        }
        let attributes = []
        for (let [key, value] of Object.entries(params)) {
            if (!value) continue
            if (key == 'email' || key == 'family_name' || key == 'given_name') {
                attributes.push(
                    new CognitoUserAttribute({
                        Name: key,
                        Value: value,
                    })
                )
            }
        }
        return await new Promise((resolve, reject) => {
            this.userPool.signUp(username, password, attributes, null, (err, result) => {
                if (err) {
                    reject(err)
                } else {
                    resolve(result)
                }
            })
        })
    }

    /*
        Confirm a registration
     */
    async registerConfirmation(username, code) {
        if (username) {
            username = username.toLowerCase()
        }
        let cognitoUser = new CognitoUser({
            Username: username,
            Pool: this.userPool,
        })
        return await new Promise((resolve, reject) => {
            cognitoUser.confirmRegistration(code, true, (err, result) => {
                if (err) {
                    switch (err.code) {
                        case 'NotAuthorizedException':
                            if (err.message.indexOf('Current status is CONFIRMED') >= 0) {
                                resolve(true)
                                return
                            }
                            break
                        case 'UserNotFoundException':
                        case 'AliasExistsException': // Email alias already exists
                        case 'CodeMismatchException':
                        case 'ExpiredCodeException':
                        default:
                            break
                    }
                    reject(err)
                } else {
                    resolve(true)
                }
            })
        })
    }

    async rememberDevice(persist, cognitoUser) {
        if (!this.device) {
            return
        }
        try {
            cognitoUser = cognitoUser || this.getCurrentUser()
            if (cognitoUser) {
                if (persist) {
                    /* Now done in getSession
                    let deviceInfo = await cognitoUser.getCachedDeviceKeyAndPassword()
                    */
                    return await new Promise((resolve, reject) => {
                        cognitoUser.setDeviceStatusRemembered({
                            onSuccess: (result) => {
                                resolve(result)
                            },
                            onFailure: (err) => {
                                reject(err)
                            },
                        })
                    })
                } else {
                    return await new Promise((resolve, reject) => {
                        cognitoUser.setDeviceStatusNotRemembered({
                            onSuccess: (result) => resolve(result),
                            onFailure: (err) => reject(err),
                        })
                    })
                }
            } else {
                throw new Error('Not logged in')
            }
        } catch (e) {
            //  continue without remember
        }
    }

    /*
        Resend a password reset
     */
    async resend(username) {
        if (username) {
            username = username.toLowerCase()
        }
        let cognitoUser = new CognitoUser({
            Username: username,
            Pool: this.userPool,
        })
        return await new Promise((resolve, reject) => {
            cognitoUser.resendConfirmationCode((err, result) => {
                if (err) {
                    reject(err)
                } else {
                    resolve(result)
                }
            })
        })
    }

    setTokens(cognitoUser, tokens) {
        cognitoUser = cognitoUser || this.getCurrentUser()
        let config = this.config

        this.tokens = tokens
        /* KEEP -
            We are not using an identity pool so there are no credentials returned from CognitoIdentityCredentials in our use case.

            let logins = {}
            let key = `cognito-idp.${config.region}.amazonaws.com/${config.userPoolId}`
            logins[key] = this.tokens.idToken

            let credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: config.identityPoolId,
                Logins: logins,
            })
            AWS.config.update({region: config.region, credentials})
         */

        let payload = tokens.idToken.payload
        if (payload && payload.exp) {
            this.expires = new Date(payload.exp * 1000)
            this.loggedIn = new Date(payload.iat * 1000)
        }
        /* KEEP
        log.info(`Cognito issued: ${this.loggedIn}`)
        log.info(`       expires: ${this.expires}`)
        */
    }

    async socialLoginResponse(response, options = {persist: true}) {
        let config = this.config
        let auth = new CognitoAuth({
            AppWebDomain: config.domain,
            ClientId: config.clientId,
            RedirectUriSignIn: config.signIn, // + '/registration',
            RedirectUriSignOut: config.signOut,
            ResponseType: 'code',
            TokenScopesArray: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            UserPoolId: config.userPoolId,
        })
        auth.useCodeGrantFlow()
        let tokenData = await new Promise((resolve, reject) => {
            auth.userhandler = {
                onSuccess: (result) => {
                    resolve(result)
                },
                onFailure: (err) => {
                    reject(err)
                },
            }
            auth.parseCognitoWebResponse(response)
        })
        if (!tokenData) {
            return {error: 1, message: 'Cannot use social login'}
        }
        let cognitoUser = this.getCurrentUser()
        // this.setTokens(cognitoUser, tokenData)
        await this.getSession(cognitoUser)
        await this.rememberDevice(options.persist, cognitoUser)
        this.social = true
        return this.getAuth()
    }

    socialLoginUrl(vendor = 'Google') {
        if (vendor == 'Amazon') {
            vendor = 'LoginWithAmazon'
        }
        let config = this.config
        let type = 'code'
        let url = 
            `https://${config.domain}/oauth2/authorize?redirect_uri=${config.signIn}&response_type=${type}&` +
            `client_id=${config.clientId}&identity_provider=${vendor}`
        if (vendor == 'Facebook') {
            url = url + '&config_id=490193510236637'
        }
        return url
        /*
        return 'https://' + config.domain + '/oauth2/authorize'
            + '?redirect_uri=' + config.signIn
            + '&response_type=token'
            + '&client_id=' + config.clientId
            //   LoginWithAmazon, Facebook, Google, COGNITO
            + `&identity_provider=${vendor}`
        */
    }

    socialLogoutUrl() {
        let config = this.config
        return `https://${config.domain}/logout?logout_uri=${config.signOut}&response_type=code&client_id=${config.clientId}`
    }

    /*
        Update a user
     */
    async update(username, details) {
        if (username) {
            username = username.toLowerCase()
        }
        let cognitoUser = this.getCurrentUser()
        await this.getSession(cognitoUser)
        if (cognitoUser) {
            let attributes = []
            for (let [key, value] of Object.entries(details)) {
                attributes.push(
                    new CognitoUserAttribute({
                        Name: key,
                        Value: value,
                    })
                )
            }
            return await new Promise((resolve, reject) => {
                cognitoUser.updateAttributes(attributes, (err, result) => {
                    if (err) {
                        reject(err)
                    } else {
                        resolve(result)
                    }
                })
            })
        }
    }

    /*
        Update a user attributes by administrator
     */
    async setUserAttributes(username, details) {
        let creds = this.config.creds ? this.config.creds : {}
        let cognito = new AWS.CognitoIdentityServiceProvider(creds)
        let attributes = []
        for (let [key, value] of Object.entries(details)) {
            attributes.push(
                new CognitoUserAttribute({
                    Name: key,
                    Value: value,
                })
            )
        }
        return await new Promise((resolve, reject) => {
            cognito.adminsetUserAttributes(
                {
                    UserAttributes: attributes,
                    UserPoolId: this.config.userPoolId,
                    Username: username,
                },
                (err, result) => {
                    if (err) {
                        print(`Cognito update failed ${err}`)
                        reject(err)
                    } else {
                        resolve(result)
                    }
                }
            )
        })
    }
}
