<script setup>
import {reactive} from 'vue'

const props = defineProps({
    widget: Object,
})

const page = reactive({
    progress: 0,
})

defineExpose({update})

function update(params = {}) {
    let widget = props.widget
    let min = widget.min || 0
    let max = widget.max || 100
    let span = max - min
    page.progress = parseInt(((widget.value || 0) / span) * 100)
}
</script>

<template>
    <div class="progress-widget">
        <div class="progress-line">
            <v-progress-linear rounded reactive 
                    color="accent" 
                    height="28" :modelValue="page.progress">
                {{ Math.ceil(page.progress) }}%
            </v-progress-linear>
        </div>
    </div>
</template>

<style lang="scss">
.progress-widget {
    height: 100%;
    width: 100%;
    .progress-line {
        position: absolute;
        top: 50% !important;
        width: 100%;
        margin-top: -20px;
        padding: 16px;

        .v-progress-linear {
            // color: rgb(var(--v-theme-text-lighten-1)) !important;
            font-size: 1.4rem;
            border-radius: 6px;
            v-progress-linear__content {
                font-size: 1.2rem;
            }
        }
    }
    .footer {
        padding-top: 40px;
        align-self: center;
        font-size: 1.4rem;
        font-weight: normal;
        color: rgb(var(--v-theme-text-lighten-1)) !important;
    }
}
</style>
